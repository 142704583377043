import React from 'react';
import styled from 'styled-components';
import { Color, FontSize } from '../../constants';
import { StyledBase } from './StyledBase';
import { StyledText } from './StyledText';
import { FormattedDate } from './FormattedDate';

const RowsView = styled.div`
	${StyledBase}
	div:last-child {
		border-bottom: none;
	}
`;

const Row = styled.div`
	${StyledBase}
	padding: 0.75rem 0;
	justify-content: space-between;
	display: flex;
	border-bottom: 1px solid ${Color.nile15};
`;

export const TextTimestampRows = (props) => (
	<RowsView {...props}>
		{
			props.rows.map(row => 
				<Row key={row.text + row.createdAt?.toString()}>
					<StyledText color={Color.nile90} textAlign='left' paddingRight='5rem'>{row.text}</StyledText>
					<FormattedDate textAlign='right' fontSize={FontSize.caption1} date={row.created} color={Color.nile50}/>
				</Row>
			)
		}
	</RowsView>
);
