import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Color, FontSize} from '../../constants';
import {FlexTableController} from '../Shared/FlexTableController';
import {StyledText} from '../Shared/StyledText';
import {Button} from '../Shared/Button';
import {faStar,} from '@fortawesome/free-solid-svg-icons';
import {PageIndex} from '../Shared/PageIndex';
import {PageController} from '../Shared/PageController';
import {faTimes} from "@fortawesome/pro-regular-svg-icons";
import {API} from "../../projlibs/api";

const ContactsView = styled.div`
	background-color: ${Color.white};
`;

const ContactListView = styled.div``;

const PageIndexContainer = styled.div`
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const NoResultsText = styled.h3`
	color: ${Color.nile50};
	align-self: center;
	text-align: center;
`;

const SetupTableData = (contacts,client,SetPrimary, removeContact, setTrigger) => {

	let tableData = {
		titles: [
			'Name',
			'Email',
			'Phone',
			'',
			'',
		],
		rows: contacts.Contact?.map((contact) => {
			let isPrimary=(contact.contact_id===client?.primary_contact_id);
			return [
				{
					sortValue: contact.name,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body1}>
							{contact.name}
						</StyledText>
					),
				},
				{
					sortValue: contact.email,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body1}>
							{contact.email}
						</StyledText>
					),
				},
				{
					sortValue: contact.phone,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body1}>
							{contact.phone}
						</StyledText>
					),
				},
				{
					element: (
						isPrimary ? 
							<div title='Currently Primary Contact'>
								<Button icon={faStar} iconColor={Color.sun} disabled={true} />
							</div>
						:
							<div title='Mark as Primary Contact'>
								<Button icon={faStar} iconColor={Color.nile15} onClick={() => {SetPrimary(contact.contact_id)}} />
							</div>
					)
				},
				{
					element: (
						isPrimary ?
						<div>
							<Button icon={faTimes} iconSize='2x' iconColor={Color.nile50} disabled={true} onClick={() => {removeContact(contact.contact_id)}}/>
						</div>
							:
						<div>
							<Button icon={faTimes} iconSize='2x' iconColor={Color.nile50} onClick={() => {
								removeContact(contact.contact_id);
								setTrigger(prev => !prev);
							}}/>
						</div>
					)
				},
			];
		}),
	};
	return tableData;
}

export const Contacts = (props) => {
	const [contacts, setContacts ] =useState([])
	const [trigger, setTrigger] = useState(false)

	useEffect(() => {
		API.get('/client/' + props.client_id + '/contact').then((data) => {
			setContacts(data.data);
		})
	}, [props.client_id, trigger])

	return (
		<ContactsView>
			<ContactListView>
				<PageIndexContainer>
                    <PageIndex resultCount={contacts?.Contact ? contacts.Contact?.length : 0} currentPage={0} />
                </PageIndexContainer>
				<FlexTableController
					tableData={SetupTableData(contacts,props.client,props.SetPrimaryContact, props.removeContact, setTrigger)}
				/>
				{
                    contacts?.Contact?.length === 0 &&
                    <NoResultsText>No Services</NoResultsText>
                }
                <PageController pageNumber={0} numPages={contacts.Contact?.length > 0 ? 1 : 0} />
			</ContactListView>
		</ContactsView>
	);
};
