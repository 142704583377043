import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Color, COMPLETED_DATE, PROJECT, TOTAL_HOURS, MS_PER_S, PROJECT_ARCHIVE_STATUS} from '../../constants';
import {StyledText} from '../Shared/StyledText';
import {breakpoint} from '../../breakpoints';
import {FlexTableController} from '../Shared/FlexTableController';
import {date, FormattedDate} from '../Shared/FormattedDate';
import {PageController} from '../Shared/PageController';
import {ProjectInfo} from '../Shared/ProjectInfo';
import {DropdownWithText} from '../Shared/DropDownWithText';
import {API, useGetData} from '../../projlibs/api';
import {convertMinutesToHours, formatText} from '../../projlibs/helperFunctions';
import downloadCsv from 'download-csv';
import {ReportHeader} from './ReportHeader';
import {PageIndex} from '../Shared/PageIndex';
import moment from 'moment';
import {updateSorting} from "./Reports";
import { DateTimeFormInput, DateInputType } from '../Shared/DateTimeFormInput';

const CustomizeReportView = styled.div`
	padding: 0.5rem 1.5rem;
`;

const ProjectSelectionView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    ${breakpoint('medium up')} {
        flex-direction: row;
    }
`;

const ReportToolbar = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    ${breakpoint('medium up')} {
        flex-direction: row;
    }
`;

const SetupTableData = (completedProjects = []) => ({
    titles: [
        PROJECT,
        TOTAL_HOURS,
        COMPLETED_DATE,
    ],
    rows: completedProjects.map(project => [
        {
            doesGrow: true,
            sortValue: project.name,
            element: (
                <ProjectInfo
                    clientId={project.client_id}
                    id={project.project_id}
                    name={project.name}
                    clientName={project.Client?.name}
                />
            ),
        },
        {
            sortValue: project.total_logged_mins,
            element: (
                <StyledText>
                    {convertMinutesToHours(project.total_logged_mins)}
                </StyledText>
            ),
        },
        {
            sortValue: project.status_set_at,
            element: (
                <FormattedDate
                    date={project.status_set_at}
                    hideTime={true}
                    format='MM/DD/YYYY'
                    color={Color.nile50}
                    fontStyle='italic'
                />
            ),
        },
    ]),
});

//NOTE: "Completed" Projects are really Archived projects, as per CRCCRM-326
export const CompletedProjects = () => {
    const [pageIndex, setPageIndex] = useState(0);
    const [{ data: projectTypesData }] = useGetData('/project/type');
    const projectTypes = projectTypesData?.Project_Type?.map(type => ({ textField: formatText(type.name), valueField: type.name }));
    const [selectedProjectType, setSelectedProjectType] = useState('All');
    const [persistSelectedProjectType, setPersistSelectedProjectType] = useState('');
    const [completedProjects, setCompletedProjects] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [persistStartDate, setPersistStartDate] = useState(null);
    const [endDate, setEndDate] = useState(moment().unix());
    const [persistEndDate, setPersistEndDate] = useState(null);
    const [resultCount, setResultCount] = useState(0);
    const [orderBy, setOrderBy] = useState("");

    const handleProjectTypeChange = (value) => {
        if (typeof value === 'string') {
            setSelectedProjectType(value);
        }
    };
    const handleGenerate = () => {
        setPersistSelectedProjectType(selectedProjectType);
        setPersistStartDate(startDate);
        setPersistEndDate(endDate);
    };
    const handleCSV = () => {
        if (resultCount !== 0) {
            const startDateUnix = persistStartDate;
            const endDateUnix = persistEndDate;
            let projectSearchUrl = `/project/search?page_size=&page=${pageIndex}&match_project_status=${PROJECT_ARCHIVE_STATUS}`;
            if (persistSelectedProjectType !== 'All') {
                projectSearchUrl += `&match_project_type=${persistSelectedProjectType}`;
            }
            if (persistStartDate) {
                projectSearchUrl += `&after=${startDateUnix}`;
            }
            if (persistEndDate) {
                projectSearchUrl += `&before=${endDateUnix}`;
            }
            if (orderBy) {
                if(orderBy.includes('project_name')){
                    const newOrderBy = orderBy.replace('project_name', 'name');
                    projectSearchUrl += `&${newOrderBy}`;
                }
                else{
                    projectSearchUrl += `&${orderBy}`;
                }
            }
            API.get(projectSearchUrl).then(response => {
                const results = response.data.Project ? response.data.Project : [];
                const csvData = results.map((result) => {
                    //NOTE: we need double-quotes for csv formatting so the values themselves need to not include any
                    const project_name=result?.name.replaceAll('"','');
                    const project_type=result?.Project_Type?.name.replaceAll('"','');
                    const client_name=result?.Client?.name.replaceAll('"','');
                    return {
                        Project_Id: `${result.project_id}`,
                        Project_Name: `"${project_name.replaceAll('"', '').replaceAll(',','')}"`,
                        Project_Type: `"${project_type.replaceAll('"', '').replaceAll(',','')}"`,
                        Client_Id: `${result.client_id}`,
                        Client_Name: `"${client_name.replaceAll('"', '').replaceAll(',','')}"`,
                        Hrs: convertMinutesToHours(result.total_logged_mins),
                        Date_Completed: `"${result.status_set_at ? date({ date: result.status_set_at }) : `No Date`}"`,
                    };
                });
                downloadCsv(csvData, null, `${persistSelectedProjectType}_Completed_Projects_${Math.floor(new Date().getTime() / MS_PER_S)}`);
            }).catch(error => API.default_error_handler);
        }
    };
    useEffect(() => {
        if (persistSelectedProjectType) {
            const startDateUnix = persistStartDate;
            const endDateUnix = persistEndDate;
            let projectSearchUrl = `/project/search?page_size=10&page=${pageIndex}&match_project_status=${PROJECT_ARCHIVE_STATUS}`;
            if (persistSelectedProjectType !== 'All') {
                projectSearchUrl += `&match_project_type=${persistSelectedProjectType}`;
            }
            if (persistStartDate) {
                projectSearchUrl += `&after=${startDateUnix}`;
            }
            if (persistEndDate) {
                projectSearchUrl += `&before=${endDateUnix}`;
            }
            if (orderBy) {
                if(orderBy.includes('project_name')){
                    const newOrderBy = orderBy.replace('project_name', 'name');
                    projectSearchUrl += `&${newOrderBy}`;
                }
                else{
                    projectSearchUrl += `&${orderBy}`;
                }
            }
            API.get(projectSearchUrl).then(response => {
                setResultCount(response.data.result_count);
                setCompletedProjects(response.data.Project ? response.data.Project : []);
            }).catch(API.default_error_handler);
        }
    }, [persistSelectedProjectType, pageIndex, persistStartDate, persistEndDate, orderBy]);
    return (
        <CustomizeReportView>
            <ReportHeader generateOnClick={handleGenerate} csvOnClick={handleCSV} csvDisabled={!persistSelectedProjectType} generateDisabled={!selectedProjectType}/>
            <ReportToolbar>
                <ProjectSelectionView>
                    <DropdownWithText
                        disableFiltering
                        defaultValue='All'
                        label='Select a Project Type'
                        data={projectTypes ? [{ textField: 'All', valueField: 'All' }, ...projectTypes] : []}
                        textField='textField'
                        width='30%'
                        height='42px'
                        placeholder='-select-'
                        onSelect={(value) => handleProjectTypeChange(value.valueField)}
                    />
                    <DateTimeFormInput
                        label='Start Date'
                        placeholder='mm/dd/yyyy'
                        format='MMM D, YYYY'
                        width='25%'
                        marginLeft='12px'
                        value={startDate ? startDate * MS_PER_S : null}
                        type={DateInputType}
                        onChange={(e) => setStartDate(e ? e.unix() : null)}
                    />
                    <DateTimeFormInput
                        label='End Date'
                        placeholder='mm/dd/yyyy'
                        format='MMM D, YYYY'
                        width='25%'
                        marginLeft='12px'
                        value={endDate ? endDate * MS_PER_S : null}
                        type={DateInputType}
                        onChange={(e) => setEndDate(e ? e.unix() : null)}
                    />
                </ProjectSelectionView>
                <PageIndex resultCount={resultCount} currentPage={pageIndex} />
            </ReportToolbar>
            <FlexTableController rowPadding='0.5rem 1rem 0.5rem 1rem' tableData={SetupTableData(completedProjects)} sort={(e, title) => updateSorting(e,title, setOrderBy)}/>
            <PageController pageNumber={pageIndex} setPageNumber={setPageIndex} numPages={Math.ceil(resultCount / 10)} />
        </CustomizeReportView>
    );
};
