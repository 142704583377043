import styled from 'styled-components';

import { Color } from '../../constants';

export const OldLinkView = styled.div`
	text-align:center;
	font-size: 0.8125rem;
	color: ${Color.nile50};
	padding: 1rem 0 0.5rem;
	width:100%;
	a {
		font-size:0.8125rem;
		color:${Color.nile};
		transition:color 0.5s ease;
		&:hover, &:focus, &:active {
			color:${Color.nile50};
		}
	}
`;

