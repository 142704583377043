import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Color, FontSize } from '../../constants';

// StyledLink: General component used to display a router Link
// Props:
export const StyledLink = styled(Link)`
	${props => css`
		display: ${props.display};
		width: ${props.width};
		height: ${props.height};
		min-width: ${props.minWidth};
		min-height: ${props.minHeight};
		color: ${props.color};
		background-color: ${props.backgroundColor};
		line-height: ${props.lineHeight};
		font-size: ${props.fontSize};
		font-weight: ${props.fontWeight};
		font-family: ${props.fontFamily};
		font-style: ${props.fontStyle};
		text-align: ${props.horizontalAlign};
		text-decoration: ${props.textDecorationLine};
		border-radius: ${props.borderRadius};
		border-width: ${props.borderWidth};
		border-color: ${props.borderColor};
		border-style: ${props.borderStyle};
		padding-top: ${props.paddingTop};
		padding-right: ${props.paddingRight};
		padding-bottom: ${props.paddingBottom};
		padding-left: ${props.paddingLeft};
		margin-top: ${props.marginTop};
		margin-right: ${props.marginRight};
		margin-bottom: ${props.marginBottom};
		margin-left: ${props.marginLeft};
		box-shadow: ${props.boxShadow};
		z-index: ${props.zIndex};
		transition: color 0.3s ease;

		&:hover, &:active, &:focus {
			color: ${Color.nile};
		}
	`}
`;

StyledLink.defaultProps = {
	color:Color.nile70,
	fontSize:FontSize.body1
};
