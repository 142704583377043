import React from 'react';
import styled from 'styled-components';
import { FormInput, SubmitButton } from '../Shared/CreateForm';
import { breakpoint } from '../../breakpoints';
import { StyledText } from '../Shared/StyledText';
import { Color, FontSize, FontWeight } from '../../constants';

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding: 2rem;
`;

const Title = styled(StyledText)`
    color: ${Color.zest};
    font-size: ${FontSize.header4};
    padding-bottom: 2rem;
    font-weight: ${FontWeight.semibold};
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${breakpoint('medium up')} {
        flex-direction: row;
    }
`;

const ResetPassButton = styled(SubmitButton)`
    margin: 2rem 0 0 0;
    width: 100%;
    ${breakpoint('medium up')} {
        width: 200px;
    }
`;

const BackButton = styled(SubmitButton)`
    margin: 2rem 0 0 0;
    width: 100%;
    ${breakpoint('medium up')} {
        width: 150px;
        margin: 2rem 0 0 2rem;
    }
`;

const ErrorsView = styled.div`
    display: flex;
    flex-direction: column;
`;

const ErrorText = styled(StyledText)`
    color: ${Color.zest};
    font-size: ${FontSize.body2};
`;

export const ForgotPassword = (props) => (
    <InputsContainer>
        <Title>Reset Password</Title>
        <FormInput
            label='Email'
            placeholder='...'
            width='400px'
            onChange={(e) => props.setResetEmail(e.target.value)}
        />
        <ErrorsView>
            {
                props.errors?.map(error => <ErrorText key={error}>{error}</ErrorText>)
            }
        </ErrorsView>
        <ButtonsContainer>
            {
                <ResetPassButton
                    disabled={!props.resetEmail || props.loading}
                    title={props.loading ? 'Sending...' : 'Send Reset Link'}
                    onClick={() => props.SubmitResetEmail()}
                />
            }
            <BackButton
                title='Back'
                onClick={() => props.setPage('sign_in')}
            />
        </ButtonsContainer>
    </InputsContainer>
);
