import React from 'react';
import styled from 'styled-components';
import { NoteSearchResult } from '../Search/NoteSearchResult';
import { StyledBase } from '../StyledBase';
import { isAFunction } from '../../../projlibs/helperFunctions';

const NoteSearchesContainer = styled.div`
    ${StyledBase}
`;

export const NoteSearches = (props) => (
    <NoteSearchesContainer {...props}>
        { props.notes && props.notes.map(note => 
            <NoteSearchResult
                key={note.id}
                refId={note.refId}
                type={note.type}
                note={note.note}
                header={note.header}
                name={note.name}
                date={note.date}
                closeSearch={isAFunction(props.closeSearch) ? props.closeSearch : undefined}
            />
        )}
    </NoteSearchesContainer>
);
