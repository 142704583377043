import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {breakpoint} from '../../breakpoints';
import {CreateForm, FormDropdown, FormInput, HeaderExitInline, Overlay, SubmitButton} from '../Shared/CreateForm';
import {UserSelect} from '../Shared/UserSelect';
import {API, useGetData} from '../../projlibs/api';
import {StyledText} from '../Shared/StyledText';
import {Color, FontSize, MILLISECONDS_PER_DAY, SECONDS_PER_DAY} from '../../constants';
import {formatText, getNextMonthsDate, getNextWeeksDate, getTomorrowsDate, getTodaysDate} from '../../projlibs/helperFunctions';
import {success} from '../../projlibs/feedback';
import {DataContext} from '../../data-context';
import { DateTimeFormInput, DateInputType } from '../Shared/DateTimeFormInput';

const CUSTOM_DATE = 'Custom Date';
const X_TIME_AFTER = 'X time after [status] is set';
const TOMORROW = 'Tomorrow';
const NEXT_WEEK = 'Next Week';
const NEXT_MONTH = 'Next Month';
const TODAY = 'Today';

const SCHEDULE_LIST = [
	TODAY,
	CUSTOM_DATE,
	TOMORROW,
	X_TIME_AFTER,
	NEXT_WEEK,
	NEXT_MONTH,
];

const AlertOverlay = styled(Overlay)`
	height: calc(100% - 6rem);
	@media (min-height: 500px) {
		height: fit-content;
	}
`;

const SubmitAlertButton = styled(SubmitButton)`
	width: 100%;
	${breakpoint('medium up')} {
		align-self: flex-end;
		margin: 0;
		width: 200px;
	}
`;

const ButtonView = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const ErrorsView = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 1rem;
	${breakpoint('medium up')} {
		align-items: center;
	}
`;

export const ErrorText = styled(StyledText)`
	color: ${Color.zest};
	font-size: ${FontSize.body2};
`;

const generateSearchUrl = (baseUrl, search) => `${baseUrl}/search?page_size=5&match_name=${search}&order_by=created_at:desc`;

const normalizeProjectData = (data) => {
	return data?.Project ? data.Project.map((project, index) => ({
		name: `${project.name} - Project`,
		value: `${project.project_id.toString()}-Project`,
		photo: project.Client?.img_s3_path,
	})) : [];
};

const normalizeClientData = (data) => {
	return data?.Client ? data.Client.map((client, index) => ({
		name: `${client.name} - Client`,
		value: `${client.client_id.toString()}-Client`,
		photo: client.img_s3_path,
	})) : [];
};

const normalizeUserData = (data) => {
	return data?.User ? data.User.map((user, index) => ({
		name: `${user.full_name} - User`,
		value: `${user.user_id.toString()}-User`,
		photo: user.img_s3_path,
	})) : [];
}

const normalizeRoleData = (data) => {
	return data?.Group ? data.Group.map((group) => ({
		name: `${formatText(group.group_name)} - Role`,
		value: `${group.group_id.toString()}-Role`,
	})) : [];
};

const normalizeContactData = (data) => {
	return data?.Contact ? data.Contact.map((contact) => ({
		name: `${contact.name} - Contact`,
		value: `${contact.contact_id}-Contact`,
		photo: contact.img_s3_path,
	})) : [];
};

const calculateTime = (type) => {
	switch(type) {
		case TOMORROW:
			return getTomorrowsDate();
		case NEXT_WEEK:
			return getNextWeeksDate();
		case NEXT_MONTH:
			return getNextMonthsDate();
		case TODAY:
			return getTodaysDate();
		default:
			throw Error('Please use a valid schedule type.');
	}
}

const sortLogic = (a, b) => {
	const aName = a.name.toUpperCase();
	const bName = b.name.toUpperCase();
	if (aName < bName) {
		return -1;
	}
	if (aName > bName) {
		return 1;
	}
	return 0;
};

const calculateSecondsWeeksDays = (weeks, days) => ((weeks * 7 * MILLISECONDS_PER_DAY) + (days * MILLISECONDS_PER_DAY)) / 1000;

function orderStatuses(Project_Status, defaultStatusOrder) {
	const orderedStatuses = [];
	for(const status of defaultStatusOrder) {
		if (Project_Status.map(status => status.name).includes(status)) {
			orderedStatuses.push(Project_Status.find(proj_status => proj_status.name === status));
		}
	}
	return orderedStatuses;
}

function getAttach(attach) {
	if (!attach) {
		return null;
	}
	const [attachType, attachID] = attach.url.substr(1).split('/');
	let type = '';
	switch (attachType) {
		case 'projects':
			type = 'Project';
			break;
		case 'clients':
			type = 'Client';
			break;
		case 'contacts':
			type = 'Contact';
			break;
		default:
			type = '';
	}
	return attachID + '-' + type;
}

async function getDefaultAttached(attach) {
	return new Promise(async (resolve, reject) => {
		try {
			let [attachType, attachID] = attach.url.substr(1).split('/');
			attachType = attachType.slice(0, -1)
			if(attachType === 'contact'){
				const {data: attached} = await API.get(`/contact/search?match_name=${attach.name}`);
				const data = normalizeContactData(attached);
				resolve(data.sort(sortLogic).slice(0, 5));
			}
			else{
				const {data: attached} = await API.get(`/${attachType}/${attachID}`);
				let data = {};
				switch (attachType) {
					case 'project':
						data = normalizeProjectData(attached);
						break;
					case 'client':
						data = normalizeClientData(attached);
						break;
					default:
						data = {};
				}
				resolve(data.sort(sortLogic).slice(0, 5));
			}
		} catch (e) {
			reject(e);
		}
	});
}

function getWeeks(show_after_status_timedelta) {
	return Math.floor(show_after_status_timedelta / (7 * SECONDS_PER_DAY));
}

function getDays(show_after_status_timedelta) {
	const leftoverDays = show_after_status_timedelta % (7 * SECONDS_PER_DAY);
	return Math.floor(leftoverDays / SECONDS_PER_DAY);

}

export const AddAlertForm = (props) => {
	const { getAlerts, defaultStatusOrder, userId } = useContext(DataContext);
	const [selectedSchedule, setSelectedSchedule] = useState(props.mode === 'edit' && props.alert.show_after_status_timedelta !== null ? X_TIME_AFTER : TODAY);
	const [scheduleWeeks, setScheduleWeeks] = useState(props.mode === 'edit' && props.alert.show_after_status_timedelta !== null ? getWeeks(props.alert.show_after_status_timedelta) : 0);
	const [scheduleDays, setScheduleDays] = useState(props.mode === 'edit' && props.alert.show_after_status_timedelta !== null ? getDays(props.alert.show_after_status_timedelta) : 0);
	const [scheduleStatus, setScheduleStatus] = useState(props.mode === 'edit' && props.alert.show_after_status !== null ? props.alert.show_after_status : '');
	const [who, setWho] = useState(props.mode === 'edit' ? props.alert.visibility_user_id !== null ? props.alert.visibility_user_id + `-User` : props.alert.visibility_group_id + `-Role` : userId + `-User`);
	const [message, setMessage] = useState(props.mode === 'edit' ? props.alert.title : '');
	const [attach, setAttach] = useState(props.mode === 'edit' ? (props.alert.attach ? getAttach(props.alert.attach) : '') : '');
	const [customDate, setCustomDate] = useState(props.mode === 'edit' && props.alert.show_at_time !== null ? props.alert.show_at_time : null);
	const [dateDue, setDateDue] = useState(props.mode === 'edit' && props.alert.due_date !== null ? props.alert.due_date : null);
	const [defaultAttached, setDefaultAttached] = useState('');
	const [errors, setErrors] = useState([]);
	const [{ data: roleData }] = useGetData('/group');
	const [{ data: userData }] = useGetData('/user?page_size=');
	const users = normalizeUserData(userData);
	const roles = normalizeRoleData(roleData);
	const [statuses, setStatuses] = useState([]);
	const [statusIds, setStatusIds] = useState([]);

	const SubmitForm = (event) => {
		event.preventDefault();
		const [visibility, visibilityType] = who.split('-');
		const [attachTo, attachType] = attach.split('-');
		const showTime = selectedSchedule !== X_TIME_AFTER ?
			(selectedSchedule !== CUSTOM_DATE ? calculateTime(selectedSchedule).getTime() / 1000 : customDate) : '';
		const postData = {
			content: message,
			visibility_group_id: visibilityType === 'Role' ? parseInt(visibility) : undefined,
			visibility_user_id: visibilityType === 'User' ? parseInt(visibility) : undefined,
			due_date: dateDue ? dateDue : undefined,
			show_at_time: selectedSchedule !== X_TIME_AFTER ? showTime : undefined,
			show_after_status: selectedSchedule === X_TIME_AFTER ? (attachType !== 'Project' ? scheduleStatus : parseInt(scheduleStatus)) : undefined,
			show_after_status_timedelta: selectedSchedule === X_TIME_AFTER ? calculateSecondsWeeksDays(scheduleWeeks, scheduleDays) : undefined,
			project_id: attachType === 'Project' ? parseInt(attachTo) : undefined,
			client_id: attachType === 'Client' ? parseInt(attachTo) : undefined,
			contact_id: attachType === 'Contact' ? parseInt(attachTo) : undefined,
		};
		if(props.mode === 'edit'){
			API.put(`/alert/${props.alert.id}`, { data: postData }).then(() => {
				getAlerts();
				success('Alert successfully updated.');
			}).catch(API.default_error_handler);
		}
		else {
			API.post('/alert', {data: postData}).then(() => {
				getAlerts();
				success('Alert successfully created.');
			}).catch(API.default_error_handler);
		}
		props.onClose();
		if (props.closeGlobalAdd) {
			props.closeGlobalAdd();
		}
	};

	useEffect(() => {
		if(props.mode === 'edit' && props.alert.attach){
			getDefaultAttached(props.alert.attach).then(response => setDefaultAttached(response[0]))
		}
	}, [props.alert, props.mode]);

	useEffect(() => {
		if (!attach) {
			return;
		}
		const [id, attachType] = attach.split('-');
		switch(attachType) {
			case 'Project':
				API.get(`/project/${id}`).then(({ data }) => {
					const type = data.Project[0].Project_Type.project_type_id;
					API.get(`/project/status?type=${type}`).then(({ data }) => {
						const orderedStatuses = orderStatuses(data.Project_Status, defaultStatusOrder);
						const projectStatuses = orderedStatuses.map(status => status.name);
						const projectStatusIds = orderedStatuses.map(status => status.project_status_id);
						setStatuses(projectStatuses);
						setStatusIds(projectStatusIds);
					}).catch(API.default_error_handler);
				});

				break;
			case 'Client':
				API.get('/client/status').then(({ data }) => {
					setStatuses(data.map(formatText));
					setStatusIds(data);
				}).catch(API.default_error_handler);
				break;
			case 'Contact':
				API.get('/contact/status').then(({ data }) => {
					const contactStatuses = data.Contact_Status ? data.Contact_Status.map(status => formatText(status.name)) : [];
					const contactStatusIds = data.Contact_Status ? data.Contact_Status.map(status => status.name) : [];
					setStatuses(contactStatuses);
					setStatusIds(contactStatusIds);
				}).catch(API.default_error_handler);
				break;
			default:
				break;
		}
	}, [attach, defaultStatusOrder]);

	const fetchSearchResults = (query) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { data: clientData } = await API.get(generateSearchUrl('/client', query));
				const clients = normalizeClientData(clientData);
				const { data: projectData } = await API.get(generateSearchUrl('/project', query));
				const projects = normalizeProjectData(projectData);
				const { data: contactData } = await API.get(generateSearchUrl('/contact', query));
				const contacts = normalizeContactData(contactData);
				resolve([...clients, ...projects, ...contacts].sort(sortLogic).slice(0, 5));
			} catch (e) {
				reject(e);
			}
		});
	};

	const fetchWhoSearchResults = (query) => {
		return new Promise(async (resolve, reject) => {
			try {
				resolve([...users, ...roles].filter(resource => resource.name.toUpperCase().includes(query.toUpperCase())).sort(sortLogic))
			} catch (e) {
				reject(e);
			}
		});
	};

	const handleAttachUpdate = (attach) => {
		if (!(attach.target?.value || attach.target?.value === '')) {
			setAttach(attach);
		}
	};

	const handleWhoUpdate = (who) => {
		if (!(who.target?.value || who.target?.value === '')) { // If the text was changed
			setWho(who);
		}
	}

	useEffect(() => { // Setting Error messages
		setErrors([]);
		if (!who) {
			setErrors(prev => [...prev, 'User/Role field required.']);
		}
		if (!message) {
			setErrors(prev => [...prev, 'Message field required.']);
		}
		if (selectedSchedule === CUSTOM_DATE) {
			if (!customDate) {
				setErrors(prev => [...prev, 'Custom date field required.']);
			}
			
		} else if (selectedSchedule === X_TIME_AFTER) {
			if (!scheduleWeeks && isNaN(scheduleWeeks)) {
				setErrors(prev => [...prev, 'Weeks field required.']);
			}
			if (!scheduleDays && isNaN(scheduleDays)) {
				setErrors(prev => [...prev, 'Days field required.']);
			}
			if (!scheduleStatus || scheduleStatus === 'placeholder') {
				setErrors(prev => [...prev, 'Status field required.']);
			}
		}
	}, [selectedSchedule, customDate, scheduleWeeks, scheduleDays, message, who, attach, scheduleStatus]);
	return (
		<AlertOverlay height='fit-content'>
			<HeaderExitInline onClose={props.onClose} title={props.mode === 'edit' ? 'Edit Alert' : 'Add Alert'}/>
			<CreateForm>
				{
					users && roles &&
					<UserSelect
						getOptions={fetchWhoSearchResults}
						options={[...users, ...roles].filter(resource => resource.value === who)}
						onChange={handleWhoUpdate}
						value={who}
						flex='0 0 100%'
						title='Who will see this?'
						placeholder='Find user or role...'
					/>
				}
				<FormInput
					value={message}
					onChange={(e) => setMessage(e.target.value)}
					label='Message'
					placeholder='...'
				/>
				<UserSelect
					getOptions={fetchSearchResults}
					onChange={handleAttachUpdate}
					options={defaultAttached ? [defaultAttached] : ''}
					value={defaultAttached.value}
					flex='0 0 100%'
					title='Link to:'
					placeholder='Find contact, client, company, project & more...'
				/>
				<FormDropdown
					selectedOption={selectedSchedule}
					label='Schedule'
					options={SCHEDULE_LIST}
					values={SCHEDULE_LIST}
					flex='0 0 49%'
					onChange={(e) => setSelectedSchedule(e.target.value)}
				/>
				{
					selectedSchedule === CUSTOM_DATE &&
					<DateTimeFormInput
						label='Custom Date'
						format='MM/DD/YYYY'
						placeholder='mm/dd/yyyy'
						width='49%'
						marginLeft='12px'
						value={customDate ? customDate * 1000 : null}
						type={DateInputType}
						onChange={(e) => setCustomDate(e?.unix())}
					/>
				}
				{
					selectedSchedule === X_TIME_AFTER &&
					<FormInput
						value={scheduleDays}
						onChange={(e) => setScheduleDays(e.target.value)}
						label='Days'
						type='number'
						placeholder='#'
						width='9%'
					/>
				}
				{
					selectedSchedule === X_TIME_AFTER &&
					<FormInput
						value={scheduleWeeks}
						onChange={(e) => setScheduleWeeks(e.target.value)}
						label='Weeks'
						type='number'
						placeholder='#'
						width='9%'
					/>
				}
				{
					selectedSchedule === X_TIME_AFTER &&
					<FormDropdown
						disabled={!attach}
						options={statuses}
						values={statusIds}
						value={scheduleStatus}
						onChange={(e) => setScheduleStatus(e.target.value)}
						label='Status'
						placeholder='Select a status'
						width='29%'
						noDefault={props.mode === 'edit'}
					/>
				}
				<DateTimeFormInput
					label='Date Due'
					width='49%'
					format='MM/DD/YYYY'
					placeholder='mm/dd/yyyy'
					value={dateDue ? dateDue * 1000 : null}
					type={DateInputType}
					onChange={(e) => setDateDue(e?.unix())}
				/>
				<ErrorsView>
					{
						errors.map(error => <ErrorText key={error}>{error}</ErrorText>)
					}
				</ErrorsView>
				<ButtonView>
					<SubmitAlertButton disabled={errors.length > 0} title='Submit' onClick={e => SubmitForm(e)} />
				</ButtonView>
			</CreateForm>
		</AlertOverlay>
	);
};
