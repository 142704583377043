import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {breakpoint} from '../../breakpoints';
import {buildProject, HeaderExitInline, Overlay} from '../Shared/CreateForm';
import {StyledText} from '../Shared/StyledText';
import {Color, FontSize, FontWeight, NAV_PROJECTS} from '../../constants';
import {FlatPrimaryButton, TransparentButton} from '../Shared/Button';
import {DropdownWithText} from '../Shared/DropDownWithText';
import {DataContext} from '../../data-context';
import {getExistingMetaFields, getDetailedMetaData} from '../Projects/CreateProjectsForm';
import {API, useGetData} from '../../projlibs/api';
import {success} from '../../projlibs/feedback';
import {useHistory} from 'react-router-dom';

const ConfirmationOverlay = styled(Overlay)`
	height: calc(100% - 6rem);
	@media (min-height: 500px) {
		height: fit-content;
    }
    ${breakpoint('medium up')} {
        width: 300px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const DeleteButton = styled(FlatPrimaryButton)`
    background-color: ${Color.zest};
    width: 45%;
`;

const CancelButton = styled(TransparentButton)`
    width: 45%;
`;

const DialogText = styled(StyledText)`
    display: inline-block;
    padding-left: 5px;
    padding-bottom: 1rem;
    font-size: ${FontSize.body1};
    font-weight: ${FontWeight.normal};
`;

export const ErrorsView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
`;

export const ErrorText = styled(StyledText)`
    color: ${Color.zest};
    font-size: ${FontSize.body2};
`;

//filter to clear parts of the "office use" metadata section
function filterCopiedMetaData(project_meta_sections,old_project_id) {
/*
    The items that do not need to be carried over are the following:
*    //Any of the date fields
*    //the designer assigned to the docket
*    //ordered by content
*    //logged hours
*    //attachments
*    //actual hours calculation incl. time logged
*    //rate
*    //project details - however this sentence should replace it "This is a reorder of [existing job number]”
*    //notes
*    //activity log
*    //quoted hours

    The things that do need to be carried over include:
*    job description
*    supplier
*    shipping notes
*    production notes
*    Account manager notes
*    billing instructions - then if the sales rep needs to tweak them or delete them they can
*/

    let new_project_meta_sections=[];
    for(let section_idx=0; section_idx<project_meta_sections.length; section_idx++){
        const meta_section=project_meta_sections[section_idx];
        //NOTE: we use JSON here to implement a datawise copy instead of getting a pointer to the same object
        let new_meta_section=JSON.parse(JSON.stringify(meta_section));
        
        for(let field_idx=0;field_idx<new_meta_section.meta_value.length;field_idx++){
            let field=new_meta_section.meta_value[field_idx];
            
            //omit any date information
            if(field['type']==='date'){
                field['value']='';
            }
            
            //omit any ordered by content
            //and logged hours information
            //and rate information
            if(['Ordered By','Actual Hours','Total Hours Logged','Quoted Hours Min','Quoted Hours Max'].includes(field['label'])){
                field['value']='';
            }
            
            //reset Project Details
            if(field['label']==='Project Details'){
                field['value']='This is a reorder of '+old_project_id;
            }
            
            new_meta_section.meta_value[field_idx]=field;
        }
        
        new_project_meta_sections.push(new_meta_section);
    }
    
    return new_project_meta_sections;
}

export const CopyProjectForm = (props) => {
    const { projectStatuses } = useContext(DataContext);
    const history = useHistory();
    const [{ data: { Project: project }}] = useGetData(`/project/${props.project.project_id}`);
    const [{ data: projectTypes }] = useGetData("/project/type");
    const [errors, setErrors] = useState([]);
    const [defaultStatus, setDefaultStatus] = useState('');
    
    const CopyProject = async () => {
        let errors = [];
        let projectToSend = buildProject(
            project[0]?.Project_Type?.name,
            project[0]?.client_id,
            project[0]?.name,
            null, //date_due (project[0]?.date_due)
            null, //date_quoted (project[0]?.date_quoted)
            null, //date_initial_contact (project[0]?.date_initial_contact)
            filterCopiedMetaData(getDetailedMetaData(getExistingMetaFields(project[0]?.project_meta), projectTypes, project[0]?.Project_Type?.name),project[0]?.project_id),
            [], //assigned_users (project[0]?.assigned_users.map(convertUser()))
            project[0]?.retail_price,
            project[0]?.delivery_price,
            project[0]?.setup_fee,
            project[0]?.discount,
            null, //actual_hours (project[0]?.actual_hours)
            null, //hourly_rate (project[0]?.hourly_rate)
            true, //isUsingTimestamps
            project[0]?.project_id, ///copiedFromId
        );
        projectToSend.contact_id = project[0]?.contact_id;
        projectToSend.project_manager = project[0]?.project_manager;
        if (!defaultStatus) {
            errors.push('Project status is required.');
        }
        projectToSend.project_status = defaultStatus;
        setErrors(errors);
        if (errors.length === 0) {
            API.post('/project', { data: projectToSend }).then((response) => {
                props.onClose();
                success(`New project successfully copied from: ${project[0]?.name}`);
                history.push(`${NAV_PROJECTS}/${response.data.Project[0].project_id}`);
            }).catch(API.default_error_handler);
        }
    };

    return (
        <ConfirmationOverlay>
            <HeaderExitInline onClose={props.onClose} title='Copy Project'/>
            {
                project &&
                <>
                    <DialogText>
                        Are you sure you want to copy the project: <strong>{project[0]?.name}</strong>?
                    </DialogText>
                    <DropdownWithText
                        label='Please select a default status for the copied project'
                        data={projectStatuses[project[0]?.Project_Type?.name]}
                        placeholder='-select-'
                        marginBottom='2rem'
                        onChange={value => setDefaultStatus(value)}
                    />
                </>
            }
            <ErrorsView>
                {
                    errors.map(error => <ErrorText key={error}>{error}</ErrorText>)
                }
            </ErrorsView>
            <ButtonContainer>
                <CancelButton title='Cancel' onClick={props.onClose} />
                <DeleteButton disabled={!defaultStatus} hoverBackground={Color.fuel} title='Copy' onClick={CopyProject} />
            </ButtonContainer>
        </ConfirmationOverlay>
    );
};
