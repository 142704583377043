import React from 'react';
import styled from 'styled-components';
import { ClientSearchResult } from './ClientSearchResult';
import { StyledBase } from '../StyledBase';
import { isAFunction } from '../../../projlibs/helperFunctions';

const ClientSearchesContainer = styled.div`
	${StyledBase}
`;

export const ClientSearches = (props) => (
	<ClientSearchesContainer {...props}>
		{ props.clients && props.clients.map(client =>
			<ClientSearchResult
				key={client.clientId}
				clientColor={client.clientColor}
				clientId={client.clientId}
				clientName={client.clientName}
				closeSearch={isAFunction(props.closeSearch) ? props.closeSearch : undefined}
			/>
		)}
	</ClientSearchesContainer>
);
