export const faFolderCheck = {
    prefix: 'fac',
    iconName: 'FolderCheck',
    icon: [
        24,
        24,
        [],
        null,
        'm10.45 4 1.8 1.75h7.2c.9405 0 1.71855.71071875 1.7940037 1.60701406l.0059963.14298594v8.75c0 .914375-.731025 1.6708125-1.6529288 1.7441703l-.1470712.0058297-9.3461345.0009494c-.78822757 1.9052961-2.66692036 3.2490506-4.8538655 3.2490506-2.89510244 0-5.25-2.3548976-5.25-5.25 0-2.1888498 1.34609614-4.0689101 3.25403002-4.8559225l.00496998-5.3940775c0-.914375.7229025-1.6708125 1.643994-1.74417031l.147006-.00582969zm-5.2 8c-2.20556024 0-4 1.7944584-4 4 0 2.2056908 1.79445841 4 4 4 2.20567214 0 4-1.7943279 4-4 0-2.2055602-1.79432786-4-4-4zm2.1788433 2.5128633c.19413979.2045428.20809683.514541.04715172.7342345l-.06854264.0777697-2.30304589 2.1841057c-.10806059.1020931-.24918993.1572455-.39493774.1572455-.12302634 0-.24256135-.0392441-.34141972-.1124369l-.0699984-.0611772-1.21082797-1.2431565c-.22129558-.2272444-.21649516-.5908186.0107379-.8121032.20205482-.1967945.51156248-.2148286.7334122-.0567997l.0787036.0675085.81492364.8369463 1.89191088-1.7934541c.2301621-.2184922.59376449-.2087264.81193242.0213174zm12.0211567-7.0128633h-14.4l.00076031 3.2537285c.06611163-.0024783.13253226-.0037285.19923969-.0037285 2.89510244 0 5.25 2.3548976 5.25 5.25 0 .0842706-.0019952.1680835-.005941.2513939l8.955941-.0013939z',
    ],
};