import React, {useState} from 'react';
import styled from 'styled-components';
import {CreateForm, FormDropdown, HeaderExitInline, Overlay, SubmitButton} from '../Shared/CreateForm';

const ApproveOverlay = styled(Overlay)`
	height: calc(100% - 6rem);
	@media (min-height: 500px) {
		height: fit-content;
	}
`;

export const ApproveProjectForm = (props) => {
    const [status, setStatus] = useState("");

    const SubmitForm = (e) => {
        e.preventDefault()
        if(props.approve){
            props.approveQuote(status);
        }
        else {
            props.unapproveProject(status);
        }
    };

    return (
        <ApproveOverlay height='fit-content'>
            <HeaderExitInline onClose={props.onClose} title={props.approve ? 'Approve Quote' : 'Unapprove Project'}/>
            <CreateForm>
                <FormDropdown
                    margin='auto'
                    label={props.approve ? 'New Project Status' : 'New Quote Status'}
                    placeholder='-select-'
                    options={props.statuses}
                    values={props.statuses}
                    flex='0 0 65%'
                    onChange={(e) => setStatus(e.target.value)}
                />
                <SubmitButton width='200px' disabled={status.length === 0} marginTop='24px' title={props.approve ? 'Approve' : 'Unapprove'} onClick={e => SubmitForm(e)} />
            </CreateForm>
        </ApproveOverlay>
    );
};
