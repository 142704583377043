import React from 'react';
import { withRouter } from 'react-router-dom';
import { AppView } from './AppView';

class AppController extends React.Component {
	render() {
		return <AppView {...this.props} />;
	}
}
export default withRouter(AppController);
