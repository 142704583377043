import config from "../../config";
import {parse_cookies} from './libformat';
import {AWS_IDENTITY_ID, AWS_TOKEN} from "../../constants";
import {clearCookies} from "./helperfunctions";
import {set_cookie} from "../../projlibs/cookie-management";

let AWS = require('aws-sdk');
const UPLOAD_EXPIRY_TIME = 60 * 60 * 60;
const GET_SIGNED_URL = 'getSignedUrl';
const UPLOAD_FILE = 'uploadFile';
const GET_OBJECT = 'getObject';
class S3Request {
    constructor(requestType, requestParams, errorCallback, successCallback) {
        this.requestType = requestType;
        this.requestParams = requestParams;
        this.errorCallback = errorCallback;
        this.successCallback = successCallback;
    }
}

class RAAWSUtils {
    constructor() {
        if (RAAWSUtils.instance) {
            return RAAWSUtils.instance;
        }

        this.queuedRequests = [];
        this.aws = AWS;
        RAAWSUtils.instance = this;
        this.initAWSInstance = this.initAWSInstance.bind(this);
        this.getIdentityId = this.getIdentityId.bind(this);
        this.getToken = this.getToken.bind(this);
        this.setIdentityId = this.setIdentityId.bind(this);
        this.setToken = this.setToken.bind(this);
        this.getCredentials = this.getCredentials.bind(this);
        this.handleCredentialRenewalError = this.handleCredentialRenewalError.bind(this);
        this.flushRequests = this.flushRequests.bind(this);
        this.getCredentialPromise = this.getCredentialPromise.bind(this);
        this.resolveCredentialPromise = this.resolveCredentialPromise.bind(this);
        this.getSignedUrl = this.getSignedUrl.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.getObject = this.getObject.bind(this);
        this.validTokenGetObject = this.validTokenGetObject.bind(this);
        this.validTokenUploadFile = this.validTokenUploadFile.bind(this);
        this.validTokenGetSignedUrl = this.validTokenGetSignedUrl.bind(this);
        this.flushQueueCheck = this.flushQueueCheck.bind(this);
        this.addToQueueHandler = this.addToQueueHandler.bind(this);
        return RAAWSUtils.instance;
    }

    initAWSInstance(awsIdentityID = null, awsToken = null, region = null, identityPoolId = null) {
        this.aws.config.update({
            region: region ? region : config.aws_region_id,
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: identityPoolId ? identityPoolId : config.identity_pool_id,
                IdentityId: this.getIdentityId(),
                Logins: {
                    'cognito-identity.amazonaws.com': this.getToken()
                }
            })
        });

        this.setToken(awsToken);
        this.setIdentityId(awsIdentityID);

        this.s3 = new this.aws.S3();
        this.cognitoIdentity = new this.aws.CognitoIdentity({region: config.aws_region_id});

        // Wrap this getCredentials call in a promise so it works with the rest of the
        // AWS functions.
        return new Promise((resolve, reject) => {
            this.aws.config.getCredentials(function (error) {
                if (error) {
                    reject(error);
                } else {
                    resolve();
                }
            });
        });
    }

    getIdentityId() {
        if (this.identityId){
            return this.identityId;
        } else {
            let cookies = parse_cookies();
            return cookies[AWS_IDENTITY_ID];
        }
    }

    getToken() {
        if (this.token){
            return this.token;
        } else {
            let cookies = parse_cookies();
            return cookies[AWS_TOKEN];
        }
    }

    setIdentityId(awsIdentityID) {
        this.identityId = awsIdentityID;
        set_cookie(AWS_IDENTITY_ID, awsIdentityID);
    }

    setToken(token) {
        this.token = token;
        set_cookie(AWS_TOKEN, token);
    }

    getCredentials() {
        let params = {
            IdentityId: this.getIdentityId(),
            Logins: {
                'cognito-identity.amazonaws.com': this.getToken()
            }
        };

        return new Promise((resolve, reject) => {
            this.cognitoIdentity.getCredentialsForIdentity(params, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    this.aws.config.update({
                        credentials: data.Credentials
                    });
                    resolve();
                }
            });
        });
    }

    // This function should only be called when initAWSInstance fails due to an expired token.
    // This function should be overridden
    async handleCredentialRenewalError() {
        clearCookies();
        await Promise.resolve();
    }

    flushRequests() {
        let tmpArray = [...this.queuedRequests];
        for (let i = 0; i < tmpArray.length; i++) {
            let r = tmpArray[i];
            switch(r.requestType){
                case GET_SIGNED_URL:
                    this.getSignedUrl(r.requestParams.Bucket, r.requestParams.Key, r.errorCallback, r.successCallback, true);
                    break;
                case UPLOAD_FILE:
                    this.uploadFile(r.requestParams.Bucket, r.requestParams.Key, r.requestParams.Body, r.errorCallback, r.successCallback, true);
                    break;
                case GET_OBJECT:
                    this.getObject(r.requestParams.Bucket, r.requestParams.Key, r.errorCallback, r.successCallback, true);
                    break;
                default:
                    break;
            };
            this.queuedRequests.shift();
        }
    }

    getCredentialPromise(){
        if (this.s3 === undefined || this.cognitoIdentity === undefined || this.s3.config.credentials.expired) {
            return this.initAWSInstance(this.getIdentityId(), this.getToken(), config.aws_region_id, config.identity_pool_id);
        }
        return false;
    }
    
    resolveCredentialPromise(credentialsPromise,params,errorCallback,successCallback,requestType){
        return (credentialsPromise.catch((error) => this.handleCredentialRenewalError()).then(() => {
            switch(requestType){
                case GET_SIGNED_URL:
                    this.validTokenGetSignedUrl(params,errorCallback,successCallback,true);
                    break;
                case UPLOAD_FILE:
                    this.validTokenUploadFile(params,errorCallback,successCallback,true);
                    break;
                case GET_OBJECT:
                    this.validTokenGetObject(params,errorCallback,successCallback,true);
                    break;
                default:
                    break;
            }
        }));
    }

    /*
        Gets a signed url for an AWS S3 Object.

        Control Flow -
            1. Check if credentials (access key, access secret key, access token) need to be renewed. (Aws Config Credentials)
                1a. If they do need to be renewed, try to renew them by calling initAWSInstance(...)
                1b. If this fails, we need to refresh the token or log the user out.  Subclassed implementations of refreshToken()
                    will make an API request to get a new token and then retry initAWSInstance(...).
            2. If requests are attempted while credentials are being renewed (1a, 1b), then store requests in a queue.
               Once credentials are renewed, we flush the queue and the requests complete.
            2. Make S3 getPresignedUrl(...) request.
     */

    getSignedUrl(bucketName, key, errorCallback, successCallback, flushing = false) {
        if(!key){
            return false;
        }
        const params = {
            Bucket: bucketName,
            Key: key.replace(/\+/g, ' '),
            Expires: UPLOAD_EXPIRY_TIME
        };

        if(this.addToQueueHandler(flushing,new S3Request(GET_SIGNED_URL, params, errorCallback, successCallback))){
            return false;
        }

        let credentialsPromise = this.getCredentialPromise();
        if (credentialsPromise) {
            if (!flushing){
                this.queuedRequests.push(new S3Request(GET_SIGNED_URL, params, errorCallback, successCallback));
            }
            this.resolveCredentialPromise(credentialsPromise,params,errorCallback,successCallback,GET_SIGNED_URL);
        } else {
            this.validTokenGetSignedUrl(params,errorCallback,successCallback);
        }
        return true;
    }

    uploadFile(bucketName, key, file, errorCallback, successCallback, flushing = false){
        let body=file;
        if(file.hasOwnProperty('isBlob')){
            body=file.content;
        }
        const params = {
                Bucket: bucketName,
                Key: key,
                ContentType: file.type,
                Body: body,
                Expires: UPLOAD_EXPIRY_TIME
        };
        if(this.addToQueueHandler(flushing,new S3Request(UPLOAD_FILE, params, errorCallback, successCallback))){
            return false;
        }

        let credentialsPromise = this.getCredentialPromise();
        if (credentialsPromise) {
            if (!flushing){
                this.queuedRequests.push(new S3Request(UPLOAD_FILE, params, errorCallback, successCallback));
            }
            this.resolveCredentialPromise(credentialsPromise,params,errorCallback,successCallback,UPLOAD_FILE);
        } else{
            this.validTokenUploadFile(params,errorCallback,successCallback);
        }
        return true;
    }

    /*
        get an object from amazon s3 containing all information on a file
    */
    getObject(bucketName, key, errorCallback, successCallback, flushing = false){
        const params = {
            Bucket: bucketName, // your bucket name,
            Key: key // path to the object you're looking for,
        };
        if(this.addToQueueHandler(flushing,new S3Request(GET_OBJECT, params, errorCallback, successCallback))){
            return false;
        }

        let credentialsPromise = this.getCredentialPromise();
        if (credentialsPromise) {
            if (!flushing){
                this.queuedRequests.push(new S3Request(GET_OBJECT, params, errorCallback, successCallback));
            }
            this.resolveCredentialPromise(credentialsPromise,params,errorCallback,successCallback,GET_OBJECT);
        } else{
            this.validTokenGetObject(params,errorCallback, successCallback);
        }
        return true;
    }

    validTokenGetObject(params,errorCallback, successCallback, flush = false){
        this.s3.getObject(params, (err, data) => {
            if(err){
                errorCallback(err);
            }else{
                successCallback(data);
                this.flushQueueCheck(flush);
            }
        });
    }

    validTokenUploadFile(params,errorCallback, successCallback, flush = false){
        this.s3.config.httpOptions.timeout = 0;
        this.s3.upload(params, (err, data) => {
                if (err) {
                    errorCallback(err);
                } else if (data) {
                    successCallback(data);
                    this.flushQueueCheck(flush);
                }
        });
    }

    validTokenGetSignedUrl(params,errorCallback,successCallback, flush = false){
        this.s3.getSignedUrl(GET_OBJECT, params, (err, url) => {
            if (err) {
                errorCallback(err);
            } else if (url) {
                successCallback(url);
                this.flushQueueCheck(flush);
            }
        });
    }

    flushQueueCheck(flush=false){
        if(flush && this.queuedRequests){
            this.flushRequests();
        }
    }

    //NOTE: this function only adds a request to the queue if a queue already exists
    //it is up to the calling code to handle the return value of this function
    //and if this function returns false the calling code must then make the correct request
    //since it won't be added to the queue
    addToQueueHandler(flushing,S3Request){
        if(this.queuedRequests.length > 0 && !flushing){
            this.queuedRequests.push(S3Request);
            return true;
        }
        return false;
    }
}

export default RAAWSUtils;
