import React, {useContext, useState} from 'react';
import styled, {css} from 'styled-components';
import {Button} from './Button';
import {Color, FontSize, FontWeight} from '../../constants';
import {faArrowLeft, faCheck, faClock, faEdit, faPlus, faStopwatch} from '@fortawesome/pro-regular-svg-icons';
import {OverlayView} from './OverlayView';
import {DataContext} from '../../data-context';

// OverlayViewButton: button used to create an OverlayView that cover the view and display OverlayViewButton's children on top of everything.
// Note: use OverlayView directly if the functionality does not need a button to open the OverlayView.
// Props:
// Inherits all of Buttons props.
// Inherits OverlayView's props. including location. i.e. left, right, center.
export const OverlayViewButton = (props) => {
	const [visible, setVisibility] = useState(props.selected);
	const { setOverlayVisibility } = useContext(DataContext);
	const setOverlayStatus = (overlayIsOpen) => {
		setOverlayVisibility(overlayIsOpen);
		setVisibility(overlayIsOpen);
	};
	return (
		<>
			<Button {...props} onClick={() => setOverlayStatus(!visible)} />
			<OverlayView
				{...props}
				visible={visible}
				onClose={() => setOverlayStatus(false)}
			/>
		</>
	);
};

OverlayViewButton.defaultProps = {};

export const ActivityViewButton = styled(OverlayViewButton)`
	&::after {
		content:'';
		background-color:${Color.coral};
		position:relative;
		border-radius:50%;
		width:10px;
		height:10px;
		transform:translateX(10px) translateY(-10px);
		margin:0px -5px;
		
		${props => css`
			display:${props?.notes?.length>0?'block':'none'};
		`}
	}
`;

ActivityViewButton.defaultProps = {
	location: 'right',
	width: '62px',
	height: '56px',
	icon: faArrowLeft,
	iconColor: Color.white,
	selectedIconColor: Color.white,
	backgroundColor: Color.nile,
	selectedBackgroundColor: Color.nile,
	borderRadius: '28px 0 0 28px',
};

export const TimeWidgetButton = styled(OverlayViewButton)``;

TimeWidgetButton.defaultProps = {
	location: 'center',
	title: 'Add Time',
	icon: faClock,
	alignItems: 'center',
	iconXOffset: '0.5rem',
	borderWidth: '1px',
	iconColor: Color.nile,
	color: Color.nile,
	borderColor: Color.nile15,
	padding: '0.5rem 1.5rem 0.5rem 0.5rem',
	marginRight: '1rem',
	hoverBackground: Color.fuel,
	hoverBorderColor: Color.transparent,
};

export const SideNavButton = styled(OverlayViewButton)``;

SideNavButton.defaultProps = {
	location: 'left',
	width: '48px',
	height: '48px',
	iconColor: Color.white,
	iconSize: '2x',
	borderRadius: '50%',
	backgroundColor: Color.transparent,
	hoverBackground: Color.nile10,
};
export const CreateFormButton = styled(OverlayViewButton)``;

CreateFormButton.defaultProps = {
	location: 'center',
	icon: faPlus,
	iconColor: Color.white,
	iconXOffset: '0.5rem',
	fontWeight: FontWeight.normal,
	backgroundColor: Color.zest,
	color: Color.white,
	padding: '0.5rem 1.5rem',
	hoverBackground: Color.fuel
};

export const EditFormButton = styled(OverlayViewButton)``;

EditFormButton.defaultProps = {
	title: 'Edit',
	icon: faEdit,
	iconColor: Color.nile,
	iconXOffset: '0.5rem',
	borderColor: Color.nile15,
	color: Color.nile,
	borderWidth: '1px',
	padding: '0.5rem 1.5rem 0.5rem 0.5rem',
	marginRight: '1rem',
	hoverBackground: Color.fuel,
	hoverBorderColor: Color.transparent,
};

export const AddAlertButton = styled(OverlayViewButton)``;

AddAlertButton.defaultProps = {
	title: 'Add Alert',
	location: 'center',
	icon: faStopwatch,
	iconColor: Color.nile90,
	iconXOffset: '0.5rem',
	fontSize: FontSize.body2,
	fontWeight: FontWeight.semibold,
	backgroundColor: Color.white,
	color: Color.nile90,
	borderWidth: '1px',
	borderColor: Color.nile15,
	padding: '0.5rem 1.5rem 0.5rem 1.0rem',
	hoverBackground: Color.fuel,
	hoverBorderColor: Color.transparent,
};

export const GlobalAddButton = styled(OverlayViewButton)``;

GlobalAddButton.defaultProps = {
	height: '40px',
	width: '280px',
	fontSize: '16px',
	color: Color.nile,
	iconXOffset: '16px',
	iconColor: Color.nile50,
	display: 'flex',
	contentAlign: 'left',
	paddingLeft: '0px',
};

export const DeleteButton = styled(OverlayViewButton)``;

DeleteButton.defaultProps = {
	textDecorationLine: 'underline',
	lineHeight: '1',
	backgroundColor: Color.transparent,
	hoverColor: Color.poppy,
	fontWeight: FontWeight.normal,
};

export const UploadFileButton = styled(OverlayViewButton)``;

UploadFileButton.defaultProps = {
	height: '40px',
	fontSize: '14px',
	color: Color.nile,
	iconXOffset: '0.5rem',
	iconColor: Color.nile50,
	borderColor: Color.nile15,
	borderRadius: '2px',
	marginLeft: '16px',
	hoverBoxShadow: '0 1px 4px 0 rgba(15,22,45,0.18)',
};

export const ApproveButton = styled(OverlayViewButton)``;

ApproveButton.defaultProps = {
	icon: faCheck,
	iconColor: Color.meadow,
	iconXOffset: '0.5rem',
	borderColor: Color.nile15,
	color: Color.nile,
	padding: '0.5rem 1.5rem 0.5rem 0.5rem',
	marginRight: '1rem',
	hoverBackground: Color.nile10
};

export const CheckButton = styled(OverlayViewButton)``;

CheckButton.defaultProps = {
	fontSize:'1.5rem',
	icon:faCheck,
	iconSize:'lg',
	iconColor:Color.black
};


