import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Color } from '../../constants';
import { breakpoint } from '../../breakpoints';

// OverlayView: an overlay view used to cover the view and display OverlayView's children on top of everything.
// OverlayView is full screen.  Inner elements keep their own styles.
// Note: use OverlayViewButton if the functionality uses a button to open the overlayView.
// Props:
// location: left, right and center(default).  Left and right will slide in / out. Center will fade in / out.
const OverlayOuterView = styled.div`
	${(props) => css`
		visibility: ${props.isOpen ? 'visible' : 'hidden'};
		position: fixed;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		background: ${Color.nile90};
		z-index: 10;
		animation-name: ${props.firstRun
			? 'none'
			: props.isOpen
			? 'fadeIn'
			: 'fadeOut'};

		animation-duration: 0.3s;
		@keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
				visibility: visible;
			}
		}
		@keyframes fadeOut {
			0% {
				opacity: 1;
				visibility: visible;
			}
			100% {
				opacity: 0;
			}
		}
	`}
`;

const OverlayInnerView = styled.div`
	${(props) => css`
		visibility: ${props.isOpen ? 'visible' : 'hidden'};
		position: fixed;
		max-width: 704px;
		width: 100%;
		${breakpoint('medium up')} {
			width: ${props.overlayWidth ? props.overlayWidth : '100%'};
		}
		height: 100%;
		top: 0px;
		bottom: 0px;
		margin: auto;
		z-index: 10;
		transition: left 0.4s ease-in-out, right 0.4s ease-in-out, opacity 0.4s ease-in-out;
		left: ${props.location === 'right'
			? 'auto' : (props.location === 'left'
			? (props.isOpen ? '0%' : '-100%') : '0%')};
		right: ${props.location === 'right'
			? (props.isOpen ? '0%' : '-100%')
			: (props.location === 'left' ? 'auto' : '0%')};
		opacity: ${props.isOpen ? '100%' : '0%'};
		animation-duration: 0.4s;
		animation-name: ${props.firstRun
			? 'none'
			: props.isOpen
			? 'open'
			: 'close'};
		animation-duration: 0.4s;
		@keyframes open {
			0% {
			}
			100% {
				visibility: visible;
			}
		}

		@keyframes close {
			0% {
				visibility: visible;
			}
			100% {
			}
		}
	`}
`;

// If OverlayView is opened by OverlayButton, then button controls visibility, otherwise, overlay controls visibility directly.
// Cloning OverlayView's children, so onClose prop can be passed to children and inner element can use it to close overlay.
// Pass closeOnOutsideClick prop to the OverlayViewButton in order to enable/disable that behaviour
export const OverlayView = (props) => {
	var isOpen = props.visible;
	var onClose = props.onClose;
	var closeOnOutsideClick = props.closeOnOutsideClick;
	const [visible, setVisibility] = useState();
	if (props.visible === undefined) {
		isOpen = visible;
		onClose = setVisibility;
	}
	let firstRun = isOpen === undefined;

	if(!isOpen){
        return null;
	}
	
	return (
		<>
			<OverlayOuterView
				firstRun={firstRun}
				isOpen={isOpen}
				onClick={() => closeOnOutsideClick ? onClose() : null}
			/>
			{props.children && (
				<OverlayInnerView
					{...props}
					firstRun={firstRun}
					isOpen={isOpen}>
					{React.cloneElement(props.children, {
						onClose: () => onClose(!isOpen),
					})}
				</OverlayInnerView>
			)}
		</>
	);
};
