import React from 'react';
import styled from 'styled-components';
import {breakpoint} from '../../breakpoints';
import {HeaderExitInline, Overlay} from '../Shared/CreateForm';
import {StyledText} from '../Shared/StyledText';
import {Color, FontWeight} from '../../constants';
import {FlatPrimaryButton, TransparentButton} from './Button';

const ConfirmationOverlay = styled(Overlay)`
	height: calc(100% - 6rem);
	@media (min-height: 500px) {
		height: fit-content;
    }
    ${breakpoint('medium up')} {
        width: 300px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ConfirmButton = styled(FlatPrimaryButton)`
    background-color: ${Color.zest};
    width: 45%;
`;

const CancelButton = styled(TransparentButton)`
    width: 45%;
`;

const DialogText = styled(StyledText)`
    padding-left: 5px;
    padding-bottom: 1rem;
    font-weight: ${FontWeight.normal};
`;

export const Confirmation = (props) => (
    <ConfirmationOverlay>
        <HeaderExitInline onClose={props.onClose} title={props.title}/>
        <DialogText>
            {props.text}
        </DialogText>
        <ButtonContainer>
            <CancelButton title='Cancel' onClick={props.onClose} />
            <ConfirmButton hoverBackground={Color.poppy} title='Confirm' type='button' onClick={() => {
                props.onClose();
                props.onConfirm()}}
            />
        </ButtonContainer>
    </ConfirmationOverlay>
);
