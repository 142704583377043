import React from 'react';
import {ProjectOverview} from './ProjectOverview';
import {DataContext} from '../../data-context.js';
import {ProjectStatusGroupController} from '../Projects/ProjectStatusGroupController';
import {API} from '../../projlibs/api.js';
import {StyledText} from '../Shared/StyledText';
import styled from 'styled-components';
import {Color, FontSize, PROJECT_ARCHIVE_STATUS, QUOTE_REJECTED_STATUS} from '../../constants';
import {UnderlineLink} from '../Shared/Button';
import {success} from '../../projlibs/feedback';

const TitleView = styled.div`
	display: flex;
	padding-bottom: 1.5rem;
	flex-direction: row;
	justify-content: space-between;
`;

const StatusTitle = styled(StyledText)`
	color: ${Color.regent};
	font-size: ${FontSize.header5};
`;

const ExpandText = styled(UnderlineLink)`
	color: ${Color.black};
	font-size: ${FontSize.body1};
`;

// ProjectOverviewController: Controller for ProjectOverview
// Props:none
//
// State:
function orderGroups(projectGroups, allStatuses) {
	const orderedGroups = [];
	for(const status of allStatuses) {
		if (projectGroups.map(group => group.status).includes(status)) {
			orderedGroups.push(projectGroups.find(group => group.status === status));
		}
	}
	return orderedGroups;
}

// isCollapsed: list of statuses are either expanded or collapsed.
export class ProjectOverviewController extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isProjectCollapsed: true,
			isQuoteCollapsed: true,
			isExpanded: false,
			projectGroups: [],
			quoteGroups: [],
		};
	}

	toggleProjectOverview = () => {
		this.setState((state, props) => ({
			isProjectCollapsed: !state.isProjectCollapsed,
		}));
		return false;
	};

	toggleQuoteOverview = () => {
		this.setState((state, props) => ({
			isQuoteCollapsed: !state.isQuoteCollapsed,
		}));
		return false;
	};

	toggleExpand = () => {
		this.setState(prev => ({ isExpanded: !prev.isExpanded }));
	};

	componentDidMount() {
		this.fetchAllProjectStatusGroups();
		this.fetchAllQuoteStatusGroups();
	};

	getStatusHighlightColorFor = function (stsName) {
		switch (stsName) {
			case '':
				return 'transparent';
			//TODO: apply the highlight color appropriate for the given status name
			//different statuses will presumably have different colors but I have no idea what those colors are meant to be
			default:
				return 'blue'; //debug
		}
	};

	generateProjectSearchUrl = function (status, pageSize = 25, orderBy = '') {
		return this.generateSearchUrl('project', pageSize, status, orderBy);
	};

	generateSearchUrl(type, pageSize, status, orderBy) {
		let url = `/${type}/search?page_size=${pageSize}&page=0&match_project_status=${status}`
		if (this.props.userId) {
			url += `&match_pm_or_assigned_user=${this.props.userId}`
		}
		if (orderBy) {
			url += `&${orderBy}`;
		} else {
			url += `&order_by=created_at:asc`;
		}
		return url;
	}

	generateQuoteSearchUrl = function (status, pageSize = 25, orderBy = '') {
		return this.generateSearchUrl('quote', pageSize, status, orderBy);
	};

	updateProjectStatus = function (newStatus, projectId, projectType) {
		API.put(`/project/${projectId}`, { data: { project_status: newStatus, project_type: projectType }}).then(() => {
			this.setState({ isExpanded: false });
			this.fetchAllProjectStatusGroups();
			this.fetchAllQuoteStatusGroups();
			success('Status Updated Successfully');
		}).catch(API.default_error_handler);
	}.bind(this);

	manualGroupExpand = function (name) {
		const projectGroups = this.state.projectGroups;
		const updatedProjectGroups = projectGroups.map(group => {
			if (group.name === name) {
				return {...group, groupExpand: true};
			}
			return group;
		});
		this.setState(prev => ({...prev, projectGroups: updatedProjectGroups}));
	}.bind(this);

	manualQuoteGroupExpand = function (name) {
		const quoteGroups = this.state.quoteGroups;
		const updatedQuoteGroups = quoteGroups.map(group => {
			if (group.name === name) {
				return {...group, groupExpand: true};
			}
			return group;
		});
		this.setState(prev => ({...prev, quoteGroups: updatedQuoteGroups}));
	}.bind(this);

	fetchAllProjectStatusGroups = function () {
		let groupCount = 0;
		const fetchedProjectStatusGroups = [];
		let url = '/dashboard/admin/project-status-overview';
		if(this.props.userId){
			url += `?user_id=${this.props.userId}`
		}
		API.get(url).then(response => {
			if (!response.data?.Project_Status_Overview) {
				return;
			}
			let projectStatusGroups = {}
			response.data.Project_Status_Overview.forEach(group => {
				if (projectStatusGroups[group.status]) {
					projectStatusGroups[group.status].count += group.count;
				} else {
					projectStatusGroups[group.status] = group;
				}
			});
			let currentCount = groupCount;
			for (const { status, type, count } of Object.values(projectStatusGroups)) {
				const name = status;
				const loadMore = (page, orderBy = '') => {
					API.get(this.generateProjectSearchUrl(status, (page + 1) * 25, orderBy)).then(response => {
						this.setState(prev => {
							const projectGroups = prev.projectGroups;
							const updatedProjectGroups = projectGroups.map(group => {
								if (group.name === name) {
									return {
										...group,
										resultCount: response.data.result_count,
										projects: response.data?.Project ? response.data?.Project : [],
									};
								}
								return group;
							});
							return {...prev, projectGroups: updatedProjectGroups};
						});
					}).catch(API.default_error_handler);
				};
				fetchedProjectStatusGroups.push({
					name: name,
					status: status,
					type: type,
					projects: [],
					order: currentCount,
					highlightColor: Color.darkBlue,
					resultCount: count,
					groupExpand: false,
					updateProjectStatus: this.updateProjectStatus,
					loadMore: loadMore,
				});
				groupCount += 1;
			}
			this.setState(prev => ({...prev, projectGroups: fetchedProjectStatusGroups}))
		}).catch(API.default_error_handler);
	}

	fetchAllQuoteStatusGroups = function () {
		let groupCount = 0;
		const fetchedQuoteStatusGroups = [];
		let url = '/dashboard/admin/project-status-overview?is_quote=true';
		if(this.props.userId){
			url += `&user_id=${this.props.userId}`
		}
		API.get(url).then(response => {
			if (!response.data?.Project_Status_Overview) {
				return;
			}
			let projectStatusGroups = {}
			response.data.Project_Status_Overview.forEach(group => {
				if (projectStatusGroups[group.status]) {
					projectStatusGroups[group.status].count += group.count;
				} else {
					projectStatusGroups[group.status] = group;
				}
			});
			let currentCount = groupCount;
			for (const {status, type, count} of Object.values(projectStatusGroups)) {
				const name = status;
				const loadMore = (page, orderBy = '') => {
					API.get(this.generateQuoteSearchUrl(status, (page + 1) * 25, orderBy)).then(response => {
						this.setState(prev => {
							const quoteGroups = prev.quoteGroups;
							const updatedQuoteGroups = quoteGroups.map(group => {
								if (group.name === name) {
									return {
										...group,
										resultCount: response.data.result_count,
										projects: response.data?.Project ? response.data?.Project : [],
									};
								}
								return group;
							});
							return {...prev, quoteGroups: updatedQuoteGroups};
						});
					}).catch(API.default_error_handler);
				};
				fetchedQuoteStatusGroups.push({
					name: name,
					status: status,
					type: type,
					projects: [],
					order: currentCount,
					highlightColor: Color.darkBlue,
					resultCount: count,
					groupExpand: false,
					updateProjectStatus: this.updateProjectStatus,
					loadMore: loadMore,
				});
				groupCount += 1;
			}
			this.setState(prev => ({...prev, quoteGroups: fetchedQuoteStatusGroups}))
		}).catch(API.default_error_handler);
	}

	render() {
		const projectGroups = orderGroups(this.state.projectGroups, this.context.allStatuses);
		const quoteGroups = orderGroups(this.state.quoteGroups, this.context.quoteStatuses);

		return (
			<>
				<ProjectOverview
					title='Project Status Overview'
					handleManualExpand={this.manualGroupExpand}
					projectGroups={projectGroups.filter(group => group.status !== PROJECT_ARCHIVE_STATUS)}
					statuses={projectGroups.filter(group => group.status !== PROJECT_ARCHIVE_STATUS).map(group => group.status)}
					isCollapsed={this.state.isProjectCollapsed}
					toggleProjectOverview={this.toggleProjectOverview}
				/>
				{((!this.props.userId) || (quoteGroups.length>0)) && (<ProjectOverview
					title='Quote Status Overview'
					handleManualExpand={this.manualQuoteGroupExpand}
					projectGroups={quoteGroups.filter(group => group.status !== QUOTE_REJECTED_STATUS)}
					statuses={quoteGroups.filter(group => group.status !== QUOTE_REJECTED_STATUS).map(group => group.status)}
					isCollapsed={this.state.isQuoteCollapsed}
					toggleProjectOverview={this.toggleQuoteOverview}
				/>)}
				<TitleView>
					<StatusTitle>Statuses</StatusTitle>
					<ExpandText title={this.state.isExpanded ? 'Collapse All' : 'Expand All'} underline='underline' onClick={() => this.toggleExpand()} />
				</TitleView>
				{projectGroups.filter(group => group.status !== PROJECT_ARCHIVE_STATUS).map((projStsRow, projStsRowIdx) => {
					return (
						<div key={'project-status-group-' + projStsRowIdx} id={projStsRow.name}>
							<ProjectStatusGroupController
								key={`${projStsRowIdx}-${projStsRow.resultCount}`}
								groupExpand={projStsRow.groupExpand}
								isOpen={this.state.isExpanded}
								statuses={this.props.statuses}
								projectType={projStsRow.type}
								handleProjectStatusChange={projStsRow.updateProjectStatus}
								handleLoadMore={projStsRow.loadMore}
								resultCount={projStsRow.resultCount}
								title={projStsRow.name}
								highlightColor={projStsRow.highlightColor ? projStsRow.highlightColor : Color.bay}
								projects={projStsRow.projects}
							/>
						</div>
					);
				})}
				{((!this.props.userId) || (quoteGroups.length>0)) && quoteGroups.filter(group => group.status !== QUOTE_REJECTED_STATUS).map((projStsRow, projStsRowIdx) => {
					return (
				 		<div key={'project-status-group-' + projStsRowIdx} id={projStsRow.name}>
							<ProjectStatusGroupController
								key={`${projStsRowIdx}-${projStsRow.resultCount}`}
								groupExpand={projStsRow.groupExpand}
								isOpen={this.state.isExpanded}
								statuses={this.props.statuses}
								projectType={projStsRow.type}
								handleProjectStatusChange={projStsRow.updateProjectStatus}
								handleLoadMore={projStsRow.loadMore}
								resultCount={projStsRow.resultCount}
								title={projStsRow.name}
								highlightColor={projStsRow.highlightColor ? projStsRow.highlightColor : Color.bay}
								projects={projStsRow.projects}
								isQuote
								quoteStatuses={this.props.quoteStatuses}
							/>
						</div>
					);
				})}
			</>
		);
	}
}

ProjectOverviewController.contextType = DataContext;
