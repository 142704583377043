import React, { useState } from 'react';
import styled from 'styled-components';
import { Color, FontSize } from '../../constants';
import { StyledText } from '../Shared/StyledText';
import { breakpoint } from '../../breakpoints';
import { SalesOverview } from '../Overview/SalesOverview';
import { CompanyContact } from '../Shared/CompanyContact';
import { AddAlertButton } from '../Shared/OverlayViewButton';
import { AddAlertForm } from '../Overview/AddAlertForm';

const DashboardSalesView = styled.div`
	margin: 32px 32px 32px 24px;
`;

const HeaderView = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 32px;
`;

const MainView = styled.div`
	display: flex;
	${breakpoint('small only')} {
		flex-direction: column;
	}
`;

const ColumnView = styled.div`
	margin-bottom: 16px;
	${breakpoint('medium')} {
		width: 70%;
		min-width: 615px;
		margin-right: 16px;
	}
`;

const ContactView = styled.div`
	${breakpoint('medium')} {
		width: 30%;
		min-width: 284px;
	}
`;

export const DashboardSales = (props) => {
	const [selectedContact, setSelectedContact] = useState("");
	return (
		<DashboardSalesView {...props}>
			<HeaderView>
				<StyledText as='h1' color={Color.nile} fontSize={FontSize.header2}>
					Leads
				</StyledText>
				<AddAlertButton>
					<AddAlertForm />
				</AddAlertButton>
			</HeaderView>
			<MainView>
				<ColumnView>
					<SalesOverview setSelectedContact={setSelectedContact} selectedContact={selectedContact}/>
				</ColumnView>
				{
					selectedContact &&
					<ContactView>
						<CompanyContact contact={selectedContact}/>
					</ContactView>
				}
			</MainView>
		</DashboardSalesView>
	);
}
