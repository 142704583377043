import React, {useContext} from 'react';
import styled, {css} from 'styled-components';
import {Color, FontSize, FontWeight} from '../../constants';
import {StyledText} from '../Shared/StyledText';
import {FormattedDate} from '../Shared/FormattedDate';
import {StyledBase} from '../Shared/StyledBase';
import {useHistory} from 'react-router-dom';
import {Button} from "../Shared/Button";
import {faEdit, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {API} from "../../projlibs/api";
import {DataContext} from "../../data-context";
import {DeleteButton} from "../Shared/OverlayViewButton";
import {AddAlertForm} from "./AddAlertForm";
import moment from 'moment';

// AlertCell: Displays alert title, time since alert and StyledText for alert badge
// Props:
// title: alert title to display.
// date: date of the alert. Will display in timeSince format
// overdue: if overdue badge should be displayed.
const AlertCellView = styled.div`
	.inner-html {
		p, li {
			font-weight: ${FontWeight.normal};
		}
	}
	border-bottom: 0.0625rem solid ${Color.nile10};
	margin-top: 1rem;
	text-align: left;
	position: relative;
`;

const ResponsiveConfirmButton = styled(DeleteButton)`
	border-width: 0;
	padding: 1rem 0;
	@media (min-width: 480px) {
		padding: 4px 16px;
	}
`;

const ButtonContainer = styled.div`
	flex: 0.5;
`;

const Header = styled.div`
	display: flex;
`;

const Title = styled.div`
	${props => css`
		flex: 1;
		text-align: start;
		color: ${Color.nile};
		font-size: ${FontSize.body1};
	`}
`;

const FooterView = styled.div`
	min-height: 1.25rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0.5rem 0rem;
`;

const LinkBubble = styled.div`
	${(props) => css`
		${StyledBase}
		display: flex;
		width: fit-content;
		background-color: ${Color.nile10};
		color: ${Color.black};
		border-radius: 20px;
		padding: 8px;
		border-color: ${props.value > 0 ? 'transparent' : Color.porcelain};
	`}
`;

LinkBubble.defaultProps = {
	transitionTime: '0.3s',
	hoverBackground: Color.fuel,
	hoverColor: Color.white,
	cursor: 'pointer',
	fontSize: FontSize.body2,
};

export const AlertCell = props => {
	const history = useHistory();
	const { userId, getAlerts } = useContext(DataContext);
	const closeAlert = () => {
		API.post(`/alert/${props.alert.id}/close/${userId}`).then(() =>
			getAlerts()
		).catch(API.default_error_handler);

	}

	return (
		<AlertCellView {...props}>
			<Header className='test'>
				<Title>{props.alert.title}</Title>
				{
					!props.alert.isNotification && props.isEditable &&
					<ButtonContainer>
						<Button
							icon={faEdit}
							iconSize='1x'
							top='0px'
							right='25px'
							position='absolute'
							onClick={() => document.getElementById(`editAlert-${props.alert.id}`).click()}
						/>
						<ResponsiveConfirmButton type='button' id={`editAlert-${props.alert.id}`} title=''>
							<AddAlertForm mode='edit' alert={props.alert}/>
						</ResponsiveConfirmButton>
						<Button
							icon={faTimes}
							iconSize='1x'
							top='0px'
							right='0px'
							position='absolute'
							onClick={closeAlert}
						/>
					</ButtonContainer>
				}
			</Header>
			{
				props.alert.attach &&
				(
					props.alert.attach.url && !props.alert.attach.url.includes('contact') ?
					<LinkBubble onClick={() => history.push(props.alert.attach.url)}>{props.alert.attach.name}</LinkBubble> :
					<StyledText paddingTop='0.5rem' color={Color.nile70} fontSize={FontSize.body2}>{props.alert.attach.name}</StyledText>
				)
			}
			<FooterView>
				<FormattedDate
					date={props.alert.date}
					useTimeSinceFormat={true}
					color={Color.nile50}
					fontSize='0.6875rem'
				/>
				{
					props.alert.due_date && props.alert.due_date <= moment().unix() &&
					<StyledText
						value='OVERDUE'
						color='white'
						backgroundColor={Color.poppy}
						fontSize={FontSize.caption1}
						borderRadius='0.625rem'
						paddingTop='0.25rem'
						paddingBottom='0.25rem'
						paddingLeft='0.5rem'
						paddingRight='0.5rem'>
						OVERDUE
					</StyledText>
				}
			</FooterView>
		</AlertCellView>
	);
};
