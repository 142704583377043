/*
S3Image - An S3Image is a image that will take in a s3 path and return image from an s3 server.
*/

import React from 'react';
import PropTypes from 'prop-types';
import {config} from '../../config';
import {Image}from './Image';
import { API } from '../../projlibs/api';
import { DataContext } from '../../data-context';
/*
props (component-level arguments):
	s3_path: the s3 path of this image (for which a signed_url will be generated)
	additionalClasses: Additional Classes for style purposes
	placeholderImageURL: A place holder image URL. Must be a full valid path.
	altText: alternative text for screen readers and to display if the image couldn't load

state (component-level arguments):
	signed_url: the full url generated for this s3 object
*/
class S3ImageController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			signed_url: null,
		};
		this.getSignedURL=this.getSignedURL.bind(this);
	}

	componentDidMount() {
		this.getSignedURL(this.props.s3_path);
	}

	getSignedURL(s3_path) {
		if(s3_path) {
			let aws = this.context.aws;
			aws.getSignedUrl(config.bucket_name, s3_path, API.default_error_handler, (url)=>this.setState({signed_url:url}) );
		} else {
			this.setState({
				signed_url: null
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.s3_path !==prevProps.s3_path) {
			this.getSignedURL(this.props.s3_path);
		}
	}

	render() {
		return (
			<Image
				src={this.state.signed_url}
				{...this.props}
			/>
		);
	}
}

S3ImageController.contextType = DataContext;

S3ImageController.defaultProps={
	additionalClasses:'',
};

S3ImageController.propTypes={
	s3_path:PropTypes.string,
	additionalClasses:PropTypes.string,
	placeholderImageURL:PropTypes.string,
	altText:PropTypes.string,
};

export default S3ImageController;
