import {
	QUOTED_HRS_MIN,
	QUOTED_HRS_MAX,
	TOTAL_HRS,
	ACTUAL_HRS,
	HOURLY_RATE,
	SETUP_FEE,
	PO_NUMBER,
	BILLING_INST,
	SUPPLIER,
	DATE_ORDER,
	ORDERED_BY,
	PRODUCT_COST,
	DELIVERY_COST,
	MARKUP,
	RETAIL_PRICE,
	DELIVERY_PRICE,
	DISCOUNT,
	TOTAL_PRICE,
	EXPECTED_DELIVERY_DATE
} from "./office-use-constants.js";

export const config = {
	//this is the path of the theme being used
	//it should be a directory under src/themes/
	//and should contain at a minimum a "styles.css" file
	//and if there are any theme-specific components
	//then they should be under the theme directory under "components"
	//components which share a name with core components
	//are used as an entry point for theme-specific code
	theme: "country-road-theme",
	tinyMCEKey: "0mhipu9vhg369tajzulkqpndbp2oouvpka42pqo5b7ijo9d6",

	// This controls the office use ordering of the metadata fields, you may change ordering or add/remove fields
	office_use_ordering: {
		[QUOTED_HRS_MIN]: 1,
		[QUOTED_HRS_MAX]: 2,
		[TOTAL_HRS]: 3,
		[ACTUAL_HRS]: 4,
		[HOURLY_RATE]: 5,
		[SETUP_FEE]: 6,
		[PO_NUMBER]: 7,
		[BILLING_INST]: 8,
		[SUPPLIER]: 9,
		[DATE_ORDER]: 10,
		[ORDERED_BY]: 11,
		[PRODUCT_COST]: 12,
		[DELIVERY_COST]: 13,
		[MARKUP]: 14,
		[RETAIL_PRICE]: 15,
		[DELIVERY_PRICE]: 16,
		[DISCOUNT]: 17,
		[TOTAL_PRICE]: 18,
		[EXPECTED_DELIVERY_DATE]: 19,
	},

	//axios networking configuration
	axios:{
		// `baseURL` will be prepended to `url` unless `url` is absolute.
		// It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
		// to methods of that instance.
		//backend server
		baseURL: 'https://api.crm.crcreativeco.com',

		// `headers` are custom headers to be sent
		headers: {'Authorization':''},

		// `timeout` specifies the number of milliseconds before the request times out.
		// If the request takes longer than `timeout`, the request will be aborted.
		timeout: 15000, // default is `0` (no timeout)

		// `withCredentials` indicates whether or not cross-site Access-Control requests
		// should be made using credentials
		withCredentials: true,

		// `responseType` indicates the type of data that the server will respond with
		// options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
		//       browser only: 'blob'
		responseType: 'json', // default

		// `responseEncoding` indicates encoding to use for decoding responses
		// Note: Ignored for `responseType` of 'stream' or client-side requests
		responseEncoding: 'utf8', // default

		// `maxContentLength` defines the max size of the http response content in bytes allowed
		maxContentLength: null,

		// `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
		maxBodyLength: null,
	},
	bucket_name: 'crcreativeco-crm',
	aws_region_id: 'ca-central-1',
	identity_pool_id: 'ca-central-1:fba09c98-88b3-4b56-8596-1f49f67e08b6',
};

export default config;


