import React from 'react';
import styled from 'styled-components';
import { Color } from '../../constants';
import { StyledBase } from '../Shared/StyledBase';
import { Button } from '../Shared/Button';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Input } from '../Shared/Input';

const SearchForm = styled.form`
	position: relative;
	display: flex;
	flex-direction: row;
	${StyledBase}
`;

export const SearchInput = (props) => (
	<SearchForm className='search-input' {...props.containerStyle} onSubmit={props.onSubmit}>
		<Input
			backgroundColor={Color.white}
			paddingRight='34px'
			placeholder='Search'
			{...props}
		/>
		<Button
			backgroundColor={Color.transparent}
			position='absolute'
			top={props.label ? '45%' : '20%'}
			right='0px'
			type='submit'
			icon={faSearch}
			iconColor={Color.nile}
		/>
	</SearchForm>
);

SearchForm.defaultProps = {
	width: 'fit-content',
	height: 'fit-content',
	whiteSpace: 'nowrap',
	borderColor: Color.nile15,
	hoverBorderColor: Color.nile5,
};
