import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Color, FontSize, NAV_CLIENTS, CONTACT, NAV_PROJECTS, CLIENT } from '../../../constants';
import { StyledText } from '../StyledText';
import { date } from '../FormattedDate';

const NoteResultView = styled.div`
	padding: 0.5rem 1rem 0.5rem 1rem;
	border-radius: 3px;
	background-color: ${Color.white};
	cursor: pointer;
	&:hover {
		background-color: ${Color.ghost};
	}
`;

const DetailsText = styled(StyledText)`
	color: ${Color.nile50};
	font-size: ${FontSize.caption1};
`;

const NoteHeader = styled(StyledText)`
	color: ${Color.nile90};
	font-size: ${FontSize.body1};
`;

const NoteBody = styled(StyledText)`
	color: ${Color.nile70};
	font-size: ${FontSize.body2};
`;

const MAX_NOTE_LENGTH = 240;

const generateDetails = (name, createdDate) => {
	return name + "   -   " + date({ date: createdDate }, 'MMM DD, YYYY  hh:mm a');
};

const truncateText = (text, maxLength) => {
	if (text.length <= maxLength) {
		return text;
	}
	return text.slice(0, maxLength) + "...";
};

const getBaseUrl = (type) => {
	switch(type) {
		case CLIENT:
			return NAV_CLIENTS;
		default:
			return NAV_PROJECTS;
	}
};

export const NoteSearchResult = ({ note, header, name, refId, type, date, closeSearch }) => (
	<Link to={type === CONTACT ? '/dashboard-sales' : `${getBaseUrl(type)}/${refId}`} style={{ textDecoration: 'none' }} onClick={closeSearch}>
		<NoteResultView>
			<DetailsText>{generateDetails(name, date)}</DetailsText>
			<NoteHeader>{header}</NoteHeader>
			<NoteBody>{truncateText(note, MAX_NOTE_LENGTH)}</NoteBody>
		</NoteResultView>
	</Link>
);
