import React from 'react';
import styled from 'styled-components';
import {Color, FontSize, PROJECT_ARCHIVE_STATUS} from '../../constants';
import {StyledText} from '../Shared/StyledText';
import {ProjectOverviewController} from '../Overview/ProjectOverviewController';
import {breakpoint} from '../../breakpoints';
import {AddAlertButton} from '../Shared/OverlayViewButton';
import {AddAlertForm} from '../Overview/AddAlertForm';
import {useGetData} from '../../projlibs/api';
import {AlertList} from '../Overview/AlertList';

// Dashboard: display contents of the admin dashboard
// Props:none
const DashboardAdminView = styled.div`
	margin: 32px 32px 32px 24px;
`;

const HeaderView = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 32px;
`;

const MainView = styled.div`
	display: flex;
	${breakpoint('small only')} {
		flex-direction: column;
	}
`;

const ColumnView = styled.div`
	margin-bottom: 16px;
	${breakpoint('medium')} {
		width: 66%;
		margin-right: 16px;
	}
`;

const AlertListView = styled.div`
	${breakpoint('medium')} {
		width: 33%;
	}
`;

export const Dashboard = (props) => {
	const [{ data: projectStatuses }] = useGetData('/project/status');
	const statuses = projectStatuses.Project_Status?.map(status => ({ name: status.name, id: status.project_status_id, type_id: status.project_type_id }));
	const [{ data: projectTypes }] = useGetData("/project/type");
	const types = projectTypes.Project_Type?.map(type => ({ name: type.name, color: type.color, id: type.project_type_id }));
	let [{data: quoteStatuses}] = useGetData('/project/status?is_quote=true');
	quoteStatuses = [...new Set(quoteStatuses.Project_Status?.filter(status => status.name !== PROJECT_ARCHIVE_STATUS).map(status => status.name))];

	return (
		<DashboardAdminView {...props}>
			<HeaderView>
				<StyledText as='h1' color={Color.nile} fontSize={FontSize.header2}>
					{props.userId ? 'Dashboard' : 'Overview'}
				</StyledText>
				<AddAlertButton>
					<AddAlertForm />
				</AddAlertButton>
			</HeaderView>
			<MainView>
				<ColumnView>
					{
						(statuses && types) &&
						<ProjectOverviewController {...props} quoteStatuses={quoteStatuses} statuses={statuses} projectTypes={types} userId={props.userId}/>
					}
				</ColumnView>
				<AlertListView>
					<AlertList />
				</AlertListView>
			</MainView>
		</DashboardAdminView>
	);
};

Dashboard.defaultProps = {
	className: 'Dashboard',
};
