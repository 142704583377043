import styled, { css } from 'styled-components';
import { Color, FontSize } from '../../constants';
import { StyledBase } from './StyledBase';
import { getInitials } from '../../projlibs/helperFunctions';

// StyledInitials: General component used to display a initials
// Props:
// children : text used to display initials.
// numberOfInitials: number of first letters to take from each word to make the initials.
export const StyledInitials = styled.p.attrs((props) => ({
	children: getInitials(props.children, props.numberOfInitials),
}))`
	${StyledBase}
	${(props) => css`
		cursor: ${props.size};
		min-width: ${props.size};
		min-height: ${props.size};
		align-self: ${props.alignSelf};
		left: ${props.left};
		width: ${props.size};
		height: ${props.size};
	`}
`;

StyledInitials.defaultProps = {
	display: 'flex',
	alignItems: 'center',
	numberOfInitials: 2,
	size: '56px',
	borderRadius: '50%',
	color: Color.white,
	fontSize: FontSize.header3,
	backgroundColor: Color.zest,
	borderWidth: '1px',
	borderColor: Color.transparent,
	borderStyle: 'solid',
	flexShrink: '0',
	transitionTime: '0.4s',
};
