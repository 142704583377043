import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {useGetData} from '../../projlibs/api.js';
import {Color, FontSize, NAV_USERS, USER_CREATE_PERM} from '../../constants';
import {FlexTableController} from '../Shared/FlexTableController';
import {StyledText} from '../Shared/StyledText';
import {Button, FlatPrimaryButton} from '../Shared/Button';
import {StatusText} from '../Shared/StatusText';
import S3ImageController from '../Shared/S3ImageController';
import {faAngleRight} from '@fortawesome/pro-regular-svg-icons';
import {SearchInput} from "../Shared/SearchInput";
import {FilterDropdown} from "../Shared/Dropdown";
import {TableHeader} from "../Projects/Projects";
import {PageController} from "../Shared/PageController";
import {CreateFormButton} from "../Shared/OverlayViewButton";
import {CreateUserForm} from "./CreateUserForm";
import {breakpoint} from '../../breakpoints';
import {StyledInitials} from "../Shared/StyledInitials";
import {PageIndex} from '../Shared/PageIndex.jsx';
import {DataContext} from '../../data-context.js';
import {Helmet} from 'react-helmet';

const UserView = styled.div`
	background-color: ${Color.white};
	height: 100%;
`;

const UserHeader = styled.div`
	padding: 2rem 1.5rem;
	background-color: ${Color.nile5};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const UserListView = styled.div``;

export const FilterControlsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem 1rem 1rem 0;
	margin-right: 0.5rem;
	${breakpoint('medium up')} {
		margin: 0;
		flex-wrap: wrap;
		flex-direction: row;
	}
`;

const StyledFilterDropdown = styled(FilterDropdown)`
	flex: 1;
	width: 100%;
	margin: 0.25rem 0.4rem;
	${breakpoint('medium up')} {
		margin: 0.25rem 0.4rem;
		width: fit-content;
		max-width: 200px;
	}
`;

const FullNameContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
`;

const UserImage = styled(S3ImageController)`
	margin-right: 1rem;
	border-radius: 50%;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const RolesButton = styled(FlatPrimaryButton)`
	${breakpoint('medium up')} {
		margin-right: 0.5rem;
		width: 122px; 
	}
`;

function SetupTableData(users) {
	let history = useHistory();


	let tableData = {
		titles: ['', 'Name', 'Role', 'Email', 'Status', ''],

		rows: users.User?.map((user, index) => {
			return [
				{
					element: (
						user.img_s3_path
						?
						<UserImage
							height='40px'
							width='40px'
							s3_path={user.img_s3_path}
						/>
						:
						<StyledInitials
							size='40px'
							marginRight='1rem'
							marginTop='0'
							fontSize={FontSize.header5}
							marginBottom='0'
							borderRadius='50%'
							backgroundColor={user.colour ? user.colour : Color.zest}
							numberOfInitials={1}>
							{user.full_name}
						</StyledInitials>
					),
				},
				{
					doesGrow: true,
					sortValue: user.full_name,
					element: (
						<FullNameContainer>
							<StyledText fontSize={FontSize.body1}>{user.full_name}</StyledText>
						</FullNameContainer>
					),
				},
				{
					minWidth: '200px',
					sortValue: user.groups,
					element: (
						<StyledText textTransform='capitalize' fontSize={FontSize.body2}>
							{user.groups
								? user.groups
										.map((r) => r.group_name)
										.join(', ')
								: 'No Role'}
						</StyledText>
					),
				},
				{
					element: (
						<StyledText
							as='a'
							href={`mailto:${user.email}`}
							color={Color.nile50}
							fontSize={FontSize.body2}
							textDecorationLine='underline'>
							{user.email}
						</StyledText>
					),
				},
				{
					sortValue: user.valid,
					element: (
						<StatusText
							height='8px'
							width='8px'
							left='6px'
							statusColor={
								user.valid ? Color.meadow : Color.poppy
							}
							fontSize='13px'
						>
							{user.valid ? "Active" : "Inactive"}
						</StatusText>
					),
				},
				{
					element: (
						<Button
							fontSize='1.5rem'
							icon={faAngleRight}
							iconSize='lg'
							onClick={(event) =>
								history.push(NAV_USERS + '/' + user.user_id)
							}
						/>
					),
				},
			];
		}),
	};

	return tableData;
}

export const getURL = (searchToSubmit, role, status, orderBy) => {
	let url = '/user/search?';

	if(searchToSubmit !== ""){
		url += "match_name=" + searchToSubmit + "&";
	}
	if(status !== "" && status !== "placeholder"){
		url += "match_valid=" + (status === "Active") + "&";
	}
	if(role !== "" && role !== "placeholder"){
		url += "match_group=" + role + "&";
	}
	if(orderBy !== ""){
		url += orderBy + "&";
	}
	url += "show_invalid=true&"
	return url;
};

export const Users = (props) => {
	const [{ data }, setUrl] = useGetData('/user');
	const [{ data: roleData }] = useGetData('/group');
	const { userPerms } = useContext(DataContext);
	const history = useHistory();
	const roles = roleData.Group?.map(role => role.group_name);

	const [searchValue, setSearchValue] = useState("");
	const [searchToSubmit, setSearchToSubmit] = useState("");
	const [role, setRole] = useState("");
	const [status, setStatus] = useState("");
	const [orderBy, setOrderBy] = useState('');
	const [currentPage, setCurrentPage] = useState(0);
	const [numPages, setNumPages] = useState(0);
	const [resultCount, setResultCount] = useState(0);
	const [searchQuery, setSearchQuery] = useState('');
	/*TODO currently filtering ONLY works on user's names and sorting does not work, this is because they've haven't
		been implemented in the server yet. When the server is updated some field names will need to be updated here to
		match the server but other than that this is complete
	*/

	useEffect(() => {
		let url = getURL(searchToSubmit, role, status, orderBy);

		//if the url is different then the query has changed and we want to go back to the first page
		if(url !== searchQuery){
			setCurrentPage(0);
		}
		setSearchQuery(url);
		url += "page=" + currentPage;
		setUrl(url);
	}, [status, role, currentPage, setUrl, orderBy, searchToSubmit, searchQuery]);

	useEffect(() => {
		setNumPages(data.page_count);
		setResultCount(data.result_count);
	}, [data]);

	const handleSearchSubmit = (e) => {
		setSearchToSubmit(searchValue);
		e.preventDefault();
	}

	const updateOrderBy = (e, title) => {
		let newOrderBy = "";

		switch(title){
			case "Name":
				newOrderBy = "order_by=name:" + e.currentTarget.dataset.sortorder;
				break;
			case "Role":
				newOrderBy = "order_by=role:" + e.currentTarget.dataset.sortorder;
				break;
			case "Status":
				newOrderBy = "order_by=valid:" + e.currentTarget.dataset.sortorder;
				break;
			default:
				newOrderBy = "";
		}
		setOrderBy(newOrderBy)
		e.currentTarget.dataset.sortorder = e.currentTarget.dataset.sortorder === 'asc' ? 'desc' : 'asc';
	}

	return (
		<UserView {...props}>
			<Helmet>
				<meta name="description" content='Users' />
			</Helmet>
			<UserHeader>
				<StyledText
					as='h1'
					color={Color.nile}
					fontSize={FontSize.header2}>
					Users
				</StyledText>
				<ButtonContainer>
					<RolesButton title='Roles' onClick={() => history.push(`${NAV_USERS}/groups`)} />
					{
						userPerms[USER_CREATE_PERM] &&
						<CreateFormButton title="New User">
							<CreateUserForm />
						</CreateFormButton>
					}
				</ButtonContainer>
			</UserHeader>
			<TableHeader>
				<FilterControlsWrapper>
					<SearchInput
						containerStyle={{ flex: '1', minWidth: '200px', width: '100%', margin: '0.5rem 0rem 0.5rem 0.75rem' }}
						placeholder='Search users'
						borderColor={Color.nile15}
						hoverBorderColor={Color.nile5}
						value={searchValue}
						onChange={e => setSearchValue(e.target.value)}
						onSubmit={e => handleSearchSubmit(e)}
					/>
					<StyledFilterDropdown
						placeholder='Role'
						options={roles}
						values={roles}
						onChange={(e) => setRole(e.target.value)}
					/>
					<StyledFilterDropdown
						placeholder='Status'
						options={["Active", "Inactive"]}
						values={["Active", "Inactive"]}
						onChange={(e) => setStatus(e.target.value)}
					/>
				</FilterControlsWrapper>
				<PageIndex resultCount={resultCount} currentPage={currentPage} />
			</TableHeader>
			<UserListView>
				<FlexTableController tableData={SetupTableData(data)} sort={(e, title) => updateOrderBy(e,title)} />
			</UserListView>
			<PageController pageNumber={currentPage} setPageNumber={setCurrentPage} numPages={numPages} />
		</UserView>
	);
};
