import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Color, FontSize } from '../../constants';
import { Button, PrimaryButton } from './Button';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { StyledText } from './StyledText';
import { StyledLink } from './StyledLink';
import { Input } from './Input';
import { API } from '../../projlibs/api';
import { DataContext } from '../../data-context';
import { breakpoint } from '../../breakpoints';
import { success } from '../../projlibs/feedback';

const AddTimeView = styled.div`
	${breakpoint('small only')} {
		width: calc(100% - 1.5rem);
	}
	padding: 1.5rem;
	height: fit-content;
	width: fit-content;
	background-color: ${Color.white};
	position: relative;
	border-radius: 4px;
	box-shadow: 0 1px 24px 0 ${Color.nile18};
	margin: 1.5rem auto 0;
`;

const MainView = styled.div``;

const TimeForm = styled.form`
	${breakpoint('small only')} {
		width: 100%;
		grid-template-columns: repeat(2, calc(50% - 0.75rem));
	}
	padding-top: 1.5rem;
	display: grid;
	grid-template-columns: repeat(2, 10.5rem);
	grid-template-rows: repeat(2, auto);
	grid-gap: 1rem;
`;

const TimeSubmit = styled(PrimaryButton)`
	grid-column: 1 / span 2;
`;

const TimeInput = styled(Input)`
	flex-basis: 50%;
`;

const ErrorsView = styled.div`
	grid-column-start: 1;
	grid-column-end: span 2;
	display: flex;
	width: 100%;
	align-items: center;
	flex-direction: column;
`;

const ErrorText = styled(StyledText)`
	color: ${Color.zest};
	font-size: ${FontSize.body1};
`;

export const TimeWidget = (props) => {
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [errors, setErrors] = useState([]);
	const context = useContext(DataContext);

	const generateTimeSubmitUrl = (projectId) => `/project/${projectId}/time`;

	const totalTime = () => (parseInt(hours) * 60) + parseInt(minutes);

	const SubmitTime = (event) => {
		event.preventDefault();
		API.post(generateTimeSubmitUrl(props.project?.project_id), {
			data: {
				logged_for_user_id: context.userId,
				duration_mins: totalTime(),
			},
		}).then(() => success('Time entry successfully submitted.', 3500)).catch(API.default_error_handler);
		props.onClose();
	};

	const handleHoursChange = (newHours) => setHours(newHours !== '' ? parseFloat(newHours) : 0);

	const handleMinutesChange = (newMinutes) => setMinutes(newMinutes !== '' ? parseFloat(newMinutes) : 0);

	useEffect(() => {
		setErrors([]);
		if (!Number.isInteger(hours)) {
			setErrors(prev => [...prev, 'Hours must be an integer, no decimals.'])
		}
		if (!Number.isInteger(minutes)) {
			setErrors(prev => [...prev, 'Minutes must be an integer, no decimals.'])
		}
		if (Number.isInteger(minutes) && minutes < 0) {
			setErrors(prev => [...prev, 'Minutes must be a positive number.']);
		}
		if (Number.isInteger(hours) && hours < 0) {
			setErrors(prev => [...prev, 'Hours must be a positive number.']);
		}
		if (Number.isInteger(hours) && Number.isInteger(minutes) && hours + minutes <= 0) {
			setErrors(prev => [...prev, 'Total time must be greater than 0.'])
		}
	}, [minutes, hours]);

	return (
		<AddTimeView>
			<Button
				icon={faTimes}
				iconSize='2x'
				onClick={props.onClose}
				position='absolute'
				right='1.5rem'
				top='1.5rem'
			/>
			<MainView>
				<StyledText
					as='h2'
					color={Color.nile}
					fontSize={FontSize.header4}
					marginBottom='2rem'>
					Add Time
				</StyledText>
				<StyledText
					as='p'
					color={Color.nile50}
					fontSize={FontSize.body2}>
					{`#${props.project?.project_id}`}
				</StyledText>
				<StyledText
					as='h3'
					color={Color.nile}
					fontSize={FontSize.header5}
					marginBottom='0.25rem'>
					{props.project?.name}
				</StyledText>
				<StyledLink
					to={`/clients/${props.project?.Client.client_id}`}
					color={Color.nile50}
					fontSize={FontSize.body2}
					display='inline-block'
					marginbottom='1.5rem'>
					{props.project?.Client?.name}
				</StyledLink>
				<TimeForm>
					<TimeInput
						onChange={(e) => handleHoursChange(e.target.value)}
						type='number'
						min={0}
						label='Hours'
						inputId='addTimeHours'
						placeholder='0'
					/>
					<TimeInput
						onChange={(e) => handleMinutesChange(e.target.value)}
						type='number'
						min={0}
						max={60}
						label='Minutes'
						inputId='addTimeMinutes'
						placeholder='0'
					/>
					{
						<ErrorsView>
							{
								errors.map(error => <ErrorText key={error}>{error}</ErrorText>)
							}
						</ErrorsView>
					}
					<TimeSubmit disabled={errors.length > 0} onClick={SubmitTime} width='100%' title='Submit' display='block' />
				</TimeForm>
			</MainView>
		</AddTimeView>
	);
};
