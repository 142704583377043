import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Color, LOAD_MORE } from '../../constants';
import { API } from '../../projlibs/api.js';
import { DropdownWithText } from '../Shared/DropDownWithText';

const StyledDropdownWithText = styled(DropdownWithText)`
    ${props => css`
		.rw-list-option:nth-child(n+2):last-child {
			color: ${props.showMore ? Color.marine : 'unset'};
		}
	`}
`;

export const ClientSearchDropdown = (props) => {
	const [hasLoadedClients, setHasLoadedClients] = useState(false);
	const [clientDropdownIsOpen, setClientDropdownIsOpen] = useState(false);
	const [clientQuery, setClientQuery] = useState('');
	const [clientPageIndex, setClientPageIndex] = useState(0);
	const [totalClientResults, setTotalClientResults] = useState(0);
	const [clientResults, setClientResults] = useState([]);
	const [clientResultsLoading, setClientResultsLoading] = useState(false);
	const [client, setClient] = useState(props.project ? { valueField: props.project.client_id, textField: props.project.Client?.name } : "");
	
	//for new projects don't show inactive clients
	//but do show them when editing existing projects
	const show_inactive_str = (props.mode==='edit')?'true':'false';
	
	const handleClientSearchChange = useCallback((query) => {
		if (typeof query === 'string') {
			setClient({ textField: query });
			setClientDropdownIsOpen(true);
			setClientPageIndex(0);
			setClientResultsLoading(true);
			API.get(`/client/search?page_size=10&match_name=${query}&order_by=created_at:desc&show_inactive=${show_inactive_str}`).then(({ data }) => {
				setClientQuery(query);
				const results = data.Client;
				const normalizedResults = results ? results.map(result => ({
					textField: result.name,
					valueField: result.client_id,
				})) : [];
				setTotalClientResults(data.result_count);
				setClientResults(normalizedResults);
				setClientResultsLoading(false);
			}).catch(error => {
				API.default_error_handler(error);
				setClientResultsLoading(false);
			});
		}
	}, [setClient,setClientDropdownIsOpen,setClientPageIndex,setClientResultsLoading,show_inactive_str]);
	
	const handleClientChange = (value, event) => {
		if (value.target?.value === '') {
			props.onChange(value.target.value);
		}
		if (value && value.valueField && value.valueField !== 'LOAD_MORE') {
			setClientDropdownIsOpen(false);
			setClient(value);
			if (props.returnName) {
				props.onChange(value);
			} else {
				props.onChange(value.valueField);
			}
		}
		if (value && value.valueField === 'LOAD_MORE') {
			event.originalEvent.preventDefault();
			event.originalEvent.stopPropagation();
			setClientDropdownIsOpen(true);
			setClientResultsLoading(true);
			API.get(`/client/search?page_size=10&page=${clientPageIndex + 1}&match_name=${clientQuery}&order_by=created_at:desc&show_inactive=${show_inactive_str}`).then(({ data }) => {
				const results = data.Client;
				const normalizedResults = results ? results.map(result => ({
					textField: result.name,
					valueField: result.client_id,
				})) : [];
				setClientPageIndex(clientPageIndex + 1);
				setClientResults([...clientResults, ...normalizedResults]);
				setClientResultsLoading(false);
			}).catch(error => {
				API.default_error_handler(error);
				setClientResultsLoading(false);
			});
		}
	}
	
	useEffect(() => {
		if (!props.project?.project_id && !hasLoadedClients) {
			handleClientSearchChange('');
			setClientDropdownIsOpen(false);
			setHasLoadedClients(true);
		}
	}, [props.project, hasLoadedClients, client, clientResults, handleClientSearchChange]);
	
	return (
		<StyledDropdownWithText
			{...props}
			showMore={clientResults.length < totalClientResults}
			open={clientDropdownIsOpen}
			onToggle={() => setClientDropdownIsOpen(!clientDropdownIsOpen)}
			disableFiltering
			busy={clientResultsLoading}
			label={props.label}
			data={clientResults.length < totalClientResults ? [...clientResults, { textField: 'Load More...', valueField: LOAD_MORE }] : clientResults}
			textField='textField'
			width={props.width}
			height={props.height}
			placeholder={props.placeholder}
			defaultValue={props.mode === 'edit' ? (props.project?.Client ? { textField: props.project.Client?.name, valueField: props.project.Client?.client_id } : undefined) : undefined}
			value={client}
			onChange={(value) => handleClientSearchChange(value)}
			onSelect={(value, event) => handleClientChange(value, event)}
		/>
	);
}
