import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {Color, CONTACT, FontSize} from '../../constants';
import {Button} from '../Shared/Button';
import {StyledBase} from './StyledBase';
import {StatusText} from './StatusText';
import {StyledText} from './StyledText';
import {Link} from 'react-router-dom';
import {faAddressBook, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import {TextTimestampRows} from './TextTimestampRows';
import {Notes} from '../Activity/Notes';
import S3ImageController from "./S3ImageController";
import {StyledInitials} from "./StyledInitials";
import {API, useGetData} from "../../projlibs/api";
import {formatText} from "../../projlibs/helperFunctions";

const ACTIVE = 'Active';

const CompanyContactView = styled.div`
	${StyledBase}
	position: relative;
	background-color: ${Color.white};
	box-shadow: 0rem 0.0625rem 0.375rem ${Color.nile15};
	border-radius: 3px 3px 0 0;
	display: flex;
	flex-direction: column;
`;

const ContactDetailsView = styled.div`
	${StyledBase}
	${props => css`
		padding: ${props.hasStatus ? '0 1.5rem' : '1.5rem 1.5rem 0 1.5rem'};
	`}
	margin-bottom: 1rem;
	display: flex;
	flex-direction: row;
`;

const ContactInfoView = styled.div`
	${StyledBase}
	margin: 0 0 0.5rem 1rem;
	display: flex;
	flex-direction: column;
`;

const ContactTextView = styled.div`
	${StyledBase}
	margin: 0 0 1rem 1rem;
	padding-bottom: 1rem;
	margin-bottom: 0.5rem;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid ${Color.nile15};
`;

const ColumnDetails = styled.div`
	${StyledBase}
	align-items: flex-start;
	display: flex;
	flex-direction: column;
`;

const LatestActivitiesView = styled.div`
	${StyledBase}
	margin-bottom: 2rem;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
`;

const NotesView = styled.div`
	${StyledBase}
`;

const TitleView = styled.div`
	${StyledBase}
	border-bottom: 1px solid ${Color.nile15};
	padding-bottom: 0.5rem;
`;

const TitleText = styled(StyledText)`
	padding-left: 1.5rem;
	font-size: ${FontSize.body2};
	color: ${Color.nile50};
`;

const StatusTextStyling = {
	alignSelf: 'flex-end',
	padding: '1.5rem 1.5rem',
};

const CompanyLink = ({ companyId, companyName }) => (
	<Link to={'/clients/' + companyId}>
		<StyledText color={Color.nile50}>
			{companyName}
		</StyledText>
	</Link>
);

const convertToText = (rawActivities, contactStatuses) => {
	return rawActivities.Activity?.map(rawActivity => {
		if(rawActivity.field_changed === 'status'){
			const status = contactStatuses?.Contact_Status?.find(status => status.contact_status_id === parseInt(rawActivity.new_value))
			return {
				created: rawActivity.created_at,
				text: `Changed ${formatText(rawActivity.field_changed)} to ${status?.name}`,
			}
		}
		else{
			console.log(rawActivity.new_value)
			return {
				created: rawActivity.created_at,
				text: `Changed ${formatText(rawActivity.field_changed)} to ${rawActivity.new_value}`,
			}
		}
	})
}

export const CompanyContact = (props) => {
	let [{data: rawActivities}] = useGetData(`/contact/${props.contact?.contact_id}/activity`);
	const [{ data: contactStatuses }] = useGetData("/contact/status");
	rawActivities = rawActivities === null ? [] : rawActivities;
	const [activities, setActivities] = useState(convertToText(rawActivities, contactStatuses));

	useEffect(() => {
		API.get(`/contact/${props.contact?.contact_id}/activity`).then(response => {
			API.get("/contact/status").then(statusResponse => {
				setActivities(response.data === null ? [] : convertToText(response.data, statusResponse.data));
			});
		});
	}, [props.contact]);

	return (
		<CompanyContactView {...props}>
			{
				props.contact?.status &&
				<StatusText
					{...StatusTextStyling}
					height='0.5rem'
					width='0.5rem'
					left='0.5rem'
					fontSize={FontSize.body2}
					statusColor={props.contact?.status === ACTIVE ? Color.meadow : Color.sun}>
					{props.contact?.status}
				</StatusText>
			}
			<ContactDetailsView hasStatus={!!props.contact?.status}>
				{props.contact?.img_s3_path ?
					<S3ImageController
						height='75px'
						width='75px'
						marginTop='0'
						marginBottom='0'
						marginRight='10px'
						marginLeft='10px'
						borderRadius='50%'
						borderWidth='1px'
						borderColor={Color.white}
						s3_path={props.contact.img_s3_path}
					/>
				:
					<StyledInitials
						size='75px'
						height='75px'
						width='75px'
						marginTop='0'
						marginBottom='0'
						marginRight='10px'
						marginLeft='10px'
						borderRadius='50%'
						fontSize={FontSize.header2}
						numberOfInitials={2}
						backgroundColor={props.contact?.clientContact && props.contact?.clientContact[0]?.Client?.color}>
						{props.contact.name}
					</StyledInitials>
				}
				<ColumnDetails>
					<ContactTextView>
						<StyledText paddingBottom='0.5rem' fontSize={FontSize.header4}>{props.contact?.name}</StyledText>
						<StyledText paddingBottom='0.5rem' color={Color.nile50}>{props.contact?.title}</StyledText>
						{props.contact.clientContact &&
						<CompanyLink companyId={props.contact?.clientContact[0]?.Client?.client_id} companyName={props.contact?.clientContact[0]?.Client?.name}/>
						}
					</ContactTextView>
					<ContactInfoView>
						{
							(props.contact?.phone || props.contact?.address) &&
							<a className='button' href={props.contact?.phone ? ('tel:' + props.contact.phone) : ('http://maps.google.com/?q=' + props.contact.address)}>
								<Button
									title={props.contact?.phone ? props.contact?.phone : props.contact?.address}
									padding='0.5rem 0'
									iconXOffset='5px'
									iconIsLeft
									iconColor={Color.nile70}
									icon={props.contact?.phone ? faPhone : faAddressBook}
									contentAlign='left'
								/>
							</a>
						}
						{
							props.contact?.email &&
							<a className='button' href={'mailto:' + props.contact.email}>
								<Button
									title={props.contact.email}
									padding='0.5rem 0'
									iconXOffset='5px'
									iconIsLeft
									iconColor={Color.nile70}
									icon={faEnvelope}
									contentAlign='left'
								/>
							</a>
						}
					</ContactInfoView>
				</ColumnDetails>
			</ContactDetailsView>
			<LatestActivitiesView>
				<TitleView>
					<TitleText>Latest Activity</TitleText>
				</TitleView>
				{activities &&
					<TextTimestampRows padding='0 1.5rem' rows={activities}/>
				}
			</LatestActivitiesView>
			<NotesView>
				<TitleView>
					<TitleText>Notes</TitleText>
				</TitleView>
			</NotesView>
			{props.contact.contact_id && <Notes type={CONTACT} id={props.contact.contact_id} disableEditorImage={true} rowPadding='10px 5px 0 10px' height='650px'/>}
		</CompanyContactView>
	);
}
