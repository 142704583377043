import React, {useState} from 'react';
import styled from 'styled-components';
import {FormInput} from '../Shared/CreateForm';
import {breakpoint} from '../../breakpoints';
import {DropdownWithText} from '../Shared/DropDownWithText';

const Location = styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    ${breakpoint('small only')} {
		flex-direction: column
	}
`;

export const LocationFields = (props) => {
    const [data, setData] = useState(props.getLocation(props.index) ? props.getLocation(props.index) : {
                                                                                                                    "address": "",
                                                                                                                    "subaddress": "",
                                                                                                                    "city": "",
                                                                                                                    "state_prov": "",
                                                                                                                    "country": "",
                                                                                                                    "postal_code": ""
                                                                                                                 });

    const updateLocationInfo = (value, id) => {
        let newData = {...data}
        newData[id] = value;
        props.updateLocation(newData, props.index);
        setData(newData);
    };

    return (
        <Location>
            <FormInput
                    type='text'
                    label='Address'
                    inputId={'address' + props.index}
                    flex = ' 0 0 84%'
                    placeholder='...'
                    value={data.address ? data.address : ""}
                    onChange={(e) => {
                        updateLocationInfo(e.target.value, "address");
                    }}
                />
                <FormInput
                    type='text'
                    label='Suite, Apt, etc'
                    inputId={'subaddress' + props.index}
                    flex = '0 0 14%'
                    placeholder='...'
                    value={data.subaddress ? data.subaddress : ""}
                    onChange={(e) => {
                        updateLocationInfo(e.target.value, "subaddress");
                    }}
                />
                <FormInput
                    type='text'
                    label='City'
                    inputId={'city' + props.index}
                    flex = '0 0 43%'
                    placeholder='...'
                    value={data.city ? data.city : ""}
                    onChange={(e) => {
                        updateLocationInfo(e.target.value, "city");
                    }}
                />
                <FormInput
                    type='text'
                    label='State / Province'
                    inputId={'state_prov' + props.index}
                    flex = '0 0 38%'
                    placeholder='...'
                    value={data.state_prov ? data.state_prov : ""}
                    onChange={(e) => {
                        updateLocationInfo(e.target.value, "state_prov");
                    }}
                />
                <FormInput
                    type='text'
                    label='ZIP / Postal Code'
                    inputId={ 'postal_code' + props.index}
                    flex = '0 0 15%'
                    placeholder='...'
                    value={data.postal_code ? data.postal_code : ""}
                    onChange={(e) => {
                        updateLocationInfo(e.target.value, "postal_code");
                    }}
                />
                <DropdownWithText
                    label="Country"
                    data={props.countries.filter(country => country !== "")}
                    flex = '0 0 30%'
                    width="100%"
                    placeholder="-select-"
                    defaultValue={data.country ? data.country : "Canada"} //Canada should be the default value
                    onChange={(value) => updateLocationInfo(value, "country")} 
                />
        </Location>
    )
};