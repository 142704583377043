import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {
	ACCOUNT_REP,
	CLIENT,
	Color,
	CONTACT_NAME,
	DUE_DATE,
	FontSize,
	NAME,
	SALES_DASH_TAB_CLIENT,
	SALES_DASH_TAB_CONTACT,
	STATUS,
	TIER
} from '../../constants';
import {Button, TransparentButton} from '../Shared/Button';
import {SearchInput} from '../Shared/SearchInput';
import {StyledBase} from '../Shared/StyledBase';
import {FlexTableController} from '../Shared/FlexTableController';
import {StyledText} from '../Shared/StyledText';
import {Link} from 'react-router-dom';
import {StatusText} from '../Shared/StatusText';
import {API, useGetData} from "../../projlibs/api";
import S3ImageController from "../Shared/S3ImageController";
import {StyledInitials} from "../Shared/StyledInitials";
import {PageController} from "../Shared/PageController";
import {formatText} from "../../projlibs/helperFunctions";

const CONTACTS = SALES_DASH_TAB_CONTACT(true);
const COMPANIES = SALES_DASH_TAB_CLIENT(true);

const SEARCH_PLACEHOLDER = `Search ${SALES_DASH_TAB_CONTACT(true).toLowerCase()} or ${SALES_DASH_TAB_CLIENT(true).toLowerCase()}...`;

const ACTIVE = 'Active';

const SalesOverviewView = styled.div`
	${StyledBase}
	background-color: ${Color.white};
	margin-bottom: 32px;
	border-top: 0.25rem solid ${Color.fuel};
	border-radius: 3px 3px 0 0;
	box-shadow: 0rem 0.0625rem 0.375rem ${Color.nile15};
`;

const FlexTableView = styled.div`
	background-color: ${Color.white};
	margin: 0 1.5rem;
`;

const SearchBoxView = styled.div`
	padding: 1.25rem 1.5rem 1.25rem 1rem;
`;

const SearchInputContainerStyle = {
	width: '100%',
	display: 'flex',
	flexDirection: 'row',
	overflow: 'hidden',
};

const ButtonView = styled.div`
	border-bottom: 2px solid ${Color.nile10};
	margin: 0rem 1.5rem 1rem 1.5rem;
`;

const SalesTabButton = styled(Button)`
	${(props) => css`
		color: ${props.selected ? Color.nile90 : Color.nile50};
		font-size: ${FontSize.body1};
		padding: 0px 24x 12px 16px;
		margin-bottom: -1px;
		border-width: 0px;
		border-bottom: ${props.selected ? '0.25rem solid' + Color.fuel : 'none'};
		border-radius: 0px;
	`}
`;

const CompanyLink = ({ companyId, companyName }) => (
	<Link to={'/clients/' + companyId}>
		<StyledText color={Color.nile} fontSize={FontSize.body2}>
			{companyName}
		</StyledText>
	</Link>
);

const SetupContactTableData = (contacts, setSelectedContact, selectedContact, contactStatuses) => ({
	titles: [
		'',
		'Name',
		SALES_DASH_TAB_CLIENT(false),
		'Email',
		'Status',
		'',
	],
	rows: contacts?.map((contact, index) => {
		if (contact.status && contactStatuses.Contact_Status?.find(status => status.contact_status_id === contact.status)){
			contact.status = contactStatuses.Contact_Status?.find(status => status.contact_status_id === contact.status).name;
		}

		return [
			{
				minWidth: '10px',
				element: (
					contact.img_s3_path ?
						<S3ImageController
							height='32px'
							width='32px'
							marginTop='0'
							marginBottom='0'
							marginRight='10px'
							marginLeft='10px'
							borderRadius='50%'
							borderWidth='1px'
							borderColor={Color.white}
							s3_path={contact.img_s3_path}
						/> :
						<StyledInitials
							size='32px'
							height='32px'
							width='32px'
							marginTop='0'
							marginBottom='0'
							marginRight='10px'
							marginLeft='10px'
							borderRadius='50%'
							fontSize={FontSize.body1}
							numberOfInitials={2}
							backgroundColor={contact.clientContact[0]?.Client?.color}>
							{contact.name}
						</StyledInitials>
				),
			},
			{
				doesGrow: true,
				sortValue: contact.name,
				element: (
					<StyledText
						cursor='pointer'
						onClick={() => setSelectedContact(contact)}
						color={Color.nile}
						fontSize={FontSize.body1}>
						{contact.name}
					</StyledText>
				),
			},
			{
				minWidth: '200px',
				sortValue: contact.company?.name,
				element: (<CompanyLink companyId={contact.clientContact[0]?.Client?.client_id}
									   companyName={contact.clientContact[0]?.Client?.name}/>),
			},
			{
				element: (<StyledText fontStyle='italic' color={Color.nile50}
									  fontSize={FontSize.body2}>{contact.email}</StyledText>),
			},
			{
				sortValue: contact.status ? contact.status : 'No Status',
				element: (contact.status &&
					<StatusText height='0.5rem' width='0.5rem' left='0.5rem' fontSize={FontSize.body2}
								statusColor={contact.status === ACTIVE ? Color.meadow : Color.sun}>{contact.status}</StatusText>),
			},
			{
				element: (
					selectedContact.contact_id === contact.contact_id ?
						<TransparentButton width='100%' title='Selected' backgroundColor={Color.fuel} disabled/> :
						<TransparentButton width='100%' title='Select' onClick={() => setSelectedContact(contact)}/>
				)
			}
		]
	}),
});

const SetupClientTableData = (clients) => ({
	titles: [
		'Name',
		'Address',
		'Status',
	],
	rows: clients?.map((client) => [
		{
			sortValue: client.name,
			element: (
				<Link to={'/clients/' + client.client_id}>
					<StyledText
					color={Color.nile}
					fontSize={FontSize.body1}>
					{client.name}
					</StyledText>
				</Link>
			),
		},
		{
			element: (<StyledText fontStyle='italic' color={Color.nile50} fontSize={FontSize.body2}>{client.Primary_Location?.address}</StyledText>),
		},
		{
			sortValue: client.Client_Status?.name,
			element: (<StyledText fontStyle='italic' color={Color.nile50} fontSize={FontSize.body2}>{formatText(client.Client_Status?.name)}</StyledText>),
		}
	]),
});

export const SalesOverview = (props) => {
	const [currentTab, setCurrentTab] = useState(CONTACTS);
	const [searchValue, setSearchValue] = useState('');
	const [contactData, setContactData] = useState([]);
	const [clientData, setClientData] = useState([]);
	const [orderBy, setOrderBy] = useState('');
	const [searchToSubmit, setSearchToSubmit] = useState('');
	const [currentPage, setCurrentPage] = useState(0);
	const [{ data: contactStatuses }] = useGetData("/contact/status");
	const [numPages, setNumPages] = useState(0);

	useEffect(() => {
		const getPath = () => {
			let url = `${currentTab === CONTACTS ? 'contact' : 'client'}/search?page=${currentPage}&`;

			if(searchToSubmit){
				url += `match_name=${searchToSubmit}&`;
			}
			if(orderBy){
				url+=orderBy;
			} else {
				url += "order_by=name:asc&";
			}
			return url;
		}

		API.get(getPath()).then(response => {
			 if (currentTab === CONTACTS) {
				setContactData(response.data.Contact);
				if(props.selectedContact === ""){
					props.setSelectedContact(response.data.Contact[0])
				}
			 }
			 else {
				 setClientData(response.data.Client);
			 }
			 setNumPages(response.data.page_count);
		})
	}, [searchToSubmit, orderBy, currentTab, currentPage, props])

	const updateOrderBy = (e, title) => {
		switch(title){
			case NAME:
				return "order_by=name:" + e.currentTarget.dataset.sortorder;
			case CLIENT:
				return "order_by=client_name:" + e.currentTarget.dataset.sortorder;
			case STATUS:
				return "order_by=status:" + e.currentTarget.dataset.sortorder;
			case DUE_DATE:
				return "order_by=date_due:" + e.currentTarget.dataset.sortorder;
			case CONTACT_NAME:
				return "order_by=contact_name:" + e.currentTarget.dataset.sortorder;
			case TIER:
				return "order_by=client_tier:" + e.currentTarget.dataset.sortorder;
			case ACCOUNT_REP:
				return "order_by=account_rep_name:" + e.currentTarget.dataset.sortorder;
			default:
				return "";
		}
	}

	const updateSorting = (e, title) => {
		setOrderBy(updateOrderBy(e, title));
		e.currentTarget.dataset.sortorder = e.currentTarget.dataset.sortorder === 'asc' ? 'desc' : 'asc';
	}

	const handleSearchSubmit = (e) => {
		e.preventDefault();
		setCurrentPage(0);
		setSearchToSubmit(searchValue);
	}

	const switchTabs = (e, tab) => {
		e.preventDefault();
		setCurrentTab(tab);
		setCurrentPage(0);
	}

	return (
		<SalesOverviewView {...props}>
			<SearchBoxView>
				<SearchInput
					containerStyle={SearchInputContainerStyle}
					width='100%'
					color={Color.nile90}
					placeholder={SEARCH_PLACEHOLDER}
					onChange={e => setSearchValue(e.target.value)}
					onSubmit={e => handleSearchSubmit(e)}
				/>
			</SearchBoxView>
			<ButtonView>
				{/* TODO: Since this tab functionality is used in Activity.jsx as well, we should create a reusable/shared component for it. */}
				<SalesTabButton
					transitionTime='0s'
					title={CONTACTS}
					selected={currentTab === CONTACTS}
					onClick={(e) => switchTabs(e,CONTACTS)}
				/>
				<SalesTabButton
					transitionTime='0s'
					title={COMPANIES}
					selected={currentTab === COMPANIES}
					onClick={(e) => switchTabs(e,COMPANIES)}
				/>
			</ButtonView>
			<FlexTableView>
				{/* TODO: When this resuable component gets created, it should accept 'Tab' components as children and automatically render them based on order. */}
				{
					(currentTab === CONTACTS && <FlexTableController tableData={SetupContactTableData(contactData, props.setSelectedContact, props.selectedContact, contactStatuses)} sort={(e, title) => updateSorting(e,title)}/>)
					||
					(currentTab === COMPANIES && <FlexTableController tableData={SetupClientTableData(clientData)} sort={(e, title) => updateSorting(e,title)}/>)
				}
				<PageController pageNumber={currentPage} setPageNumber={setCurrentPage} numPages={numPages} />
			</FlexTableView>
		</SalesOverviewView>
	);
};
