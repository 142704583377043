import { css } from 'styled-components';

export const StyledBase = css`
	${(props) => css`
		position: ${props.position};
		top: ${props.top};
		right: ${props.right};
		bottom: ${props.bottom};
		left: ${props.left};
		flex: ${props.flex};
		flex-grow: ${props.flexGrow};
		width: ${props.width};
		height: ${props.height};
		visibility: ${props.visibility};
		min-width: ${props.minWidth};
		min-height: ${props.minHeight};
		max-width: ${props.maxWidth};
		max-height: ${props.maxHeight};
		color: ${props.selected ? props.selectedColor : props.color};
		background: ${props.selected ? props.background : props.selectedBackground};
		background-color: ${props.selected
			? props.selectedBackgroundColor
			: props.backgroundColor};
		line-height: ${props.lineHeight};
		font-size: ${props.fontSize};
		font-weight: ${props.fontWeight};
		font-family: ${props.fontFamily};
		text-decoration: ${props.textDecorationLine};
		caret-color: ${props.caretColor};
		border-radius: ${props.borderRadius};
		border-width: ${props.borderWidth};
		border-color: ${props.borderColor};
		border-style: ${props.borderStyle};
		padding: ${props.padding};
		padding-top: ${props.paddingTop};
		padding-right: ${props.paddingRight};
		padding-bottom: ${props.paddingBottom};
		padding-left: ${props.paddingLeft};
		border-top: ${props.borderTop};
		border-right: ${props.borderRight};
		border-bottom: ${props.borderBottom};
		border-left: ${props.borderLeft};
		margin: ${props.margin};
		margin-top: ${props.marginTop};
		margin-right: ${props.marginRight};
		margin-bottom: ${props.marginBottom};
		margin-left: ${props.marginLeft};
		box-shadow: ${props.selected ? props.selectedBoxShadow : 'none'};
		z-index: ${props.zIndex};
		appearance: ${props.appearance};
		outline: ${props.outline};
		white-space: ${props.whiteSpace};
		filter: ${props.filter};

		overflow: ${props.overflow};
		overflow-x: ${props.overflowX};
		overflow-y: ${props.overflowY};
		
		display: ${props.display};
		align-items: ${props.alignItems};
		justify-content: ${props.justifyContent ? props.justifyContent : (props.contentAlign === 'left'
			? 'flex-start'
			: props.contentAlign === 'right'
			? 'flex-end'
			: 'center')};

		cursor: ${props.cursor};
		transition: all ${props.transitionTime} ease;

		&:disabled {
			filter: opacity(0.3);
			pointer-events: none;
		}

		&:hover,
		&:focus,
		&:active {
			transform: ${props.hoverTransform};
			filter: ${props.hoverFilter};
			box-shadow: ${props.hoverBoxShadow};
			background-color: ${props.hoverBackground};
			outline: none;
			color: ${props.hoverColor};
			border-color: ${props.hoverBorderColor};
		}

		::placeholder {
			color: ${props.placeholderColor};
		}
	`}
`;
