import React, {useContext, useEffect, useRef, useState} from 'react';
import styled, {css} from 'styled-components';
import {faCompressArrowsAlt, faExpandArrowsAlt} from '@fortawesome/pro-regular-svg-icons';
import {CLIENT, Color, CONTACT, FontSize, NOTE_CREATE_PERM, NOTE_UPDATE_PERM} from '../../constants';
import {StyledText} from '../Shared/StyledText';
import {Button, PrimaryButton} from '../Shared/Button';
import {breakpoint} from '../../breakpoints';
import {StyledBase} from '../Shared/StyledBase';
import {DataContext} from '../../data-context';
import {TinyMCEEditor} from "../Shared/TinyMCEEditor";
import {API} from "../../projlibs/api";
import {StyledInitials} from "../Shared/StyledInitials";
import {SearchInput} from '../Shared/SearchInput';
import {NoteCell} from './NoteCell';

// Notes: displays activity notes.
// Props:
// notes: array of notes.
const NotesView = styled.div`
	${StyledBase}
	justify-content: flex-start;
	display: flex;
	flex-direction: column;
`;

const NotesListView = styled.div`
	display: block;
	overflow: auto;
	${props => css`
		padding: ${props.padding ? props.padding : '0px 18px 90px'};
		height: ${props.isOverlay ?
			(props.editDisabled ? 'calc(100% - 210px)' : (props.editMinimized ? 'calc(100% - 360px)' : 'calc(100% - 625px)'))
		:
			(props.newHeight ? `calc(${props.newHeight} - 380px)` : 'unset')};
		${breakpoint('medium up')} {
			height: ${props.isOverlay ?
				(props.editDisabled ? 'calc(100% - 210px)' : (props.editMinimized ? 'calc(100% - 280px)' : 'calc(100% - 575px)'))
			:
				(props.newHeight ? `calc(${props.newHeight} - 380px)` : 'unset')};
			padding: ${props.padding ? props.padding : '0px 48px 8px'};
		}
	`}
`;

const NoteCellView = styled.div`
	display: flex;
	align-items: center;
	border-radius: 5px;
	padding-top: 8px;
	padding-left: 8px;
	${props => css`
		transition: background-color 0.3s ease-in-out;
		background-color: ${props.pinned ? Color.banana : Color.transparent};
	`}
`;

const CreateNoteView = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: calc(100% - 30px);
	height: 350px;
	display: flex;
	flex-direction: row;
	background-color: ${Color.white};
	padding: 15px;
	box-shadow: 0px -1px 6px ${Color.nile18};
`;

const CreateNoteMinimalView = styled.div`
	display:flex;
	position: absolute;
	bottom: 8px;
	left: 0;
	width: 100%;
	background-color: ${Color.white};
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
	padding-top:0.5rem;
	> button {
		margin:0rem 0.5rem;
		${breakpoint('medium up')} {
			margin:0rem 1rem;
		}
	}
	> form {
		display:flex;
		flex-direction:row;
		align-items:center;
		justify-content:space-between;
		flex-grow:1;
		width:100%;
		> input[type=text] {
			height:2.5rem;
			width:100%;
			min-width:45%;
			${breakpoint('medium up')} {
				min-width:calc(84% - 2rem);
			}
		}
		> button, input[type=submit] {
			margin:0rem 0.5rem;
			min-width:5rem;
			${breakpoint('medium up')} {
				margin:0rem 1rem;
				min-width:16%;
			}
		}
	}
`;

const TextButtonView = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-top: 8px;
`;

const EditorView = styled.div`
	min-width: 0;
	flex: 1;
	/* z-index of the editor was set to 1 so it is showing above overlays */
	z-index: 0;
	${StyledBase}
`;

const SearchInputContainerStyle = {
	width: '100%',
	margin: '0',
	borderRadius: '4px',
};

const SearchBoxView = styled.div`
	top: 0;
	left: 0;
	background-color: ${Color.white};
	${props => css`
		width: ${props.isOverlay ? 'calc(100% - 52px)' : 'unset'};
		padding: ${props.isOverlay ? '1rem 1.625rem 0.5rem 1.625rem' : '1rem'};
		${breakpoint('medium up')} {
			padding: ${props.isOverlay ? '0rem 3.5rem 0.5rem 3.5rem' : '1rem'};
			width: ${props.isOverlay ? 'calc(100% - 112px)' : 'unset'};;
		}
	`}
`;

const MinimizeToggleButton = styled(PrimaryButton)`
	margin:0rem 0.5rem 1rem 0rem;
	width: 48px;
	.svg-inline--fa {
		color:white;
	}
`;

export const getNotesPath = (type, id) => {
	let endpoint = 'project';
	switch(type) {
		case CLIENT:
			endpoint = 'client';
			break;
		case CONTACT:
			endpoint = 'contact';
			break;
		default:
			endpoint = 'project'
	}
	return `${endpoint}/${id}/note`;
}

const updateNotes = (type, id, setNotes) => {
	API.get(getNotesPath(type, id)).then(response => {
		const notes = response.data?.Note ? response.data.Note : [];
		setNotes(notes);
	});
}

const renderCreateNoteView = (props,context,newNote,setNewNote,SubmitNote,setEditMinimized,editMinimized) => {
	if(editMinimized){
		return (
			<CreateNoteMinimalView>
				<MinimizeToggleButton onClick={() => setEditMinimized(false)} icon={faExpandArrowsAlt} />
				<form onSubmit={(e) => {SubmitNote(e); document.getElementById('minimized-note-entry').value=''; return false;}}>
					<input id='minimized-note-entry' type='text' onChange={(e) => setNewNote('<p>'+e.currentTarget.value+'</p>')} />
					<PrimaryButton title='Submit' />
				</form>
			</CreateNoteMinimalView>
		);
	}
	return (
		<CreateNoteView>
			<div>
				<MinimizeToggleButton onClick={() => setEditMinimized(true)} icon={faCompressArrowsAlt} />
				{
					!props.disableEditorImage &&
					
						<StyledInitials
							size='48px'
							marginRight='16px'
							marginTop='0'
							borderRadius='50%'
							fontSize={FontSize.header4}
							numberOfInitials={2}
							backgroundColor={context.userColour ? context.userColour :  Color.zest}>
							{context.username}
						</StyledInitials>
				}
			</div>
			<EditorView>
				<TinyMCEEditor value={newNote} onChange={(content, editor) => setNewNote(content)}/>
				<TextButtonView>
					<StyledText fontSize={FontSize.body2} color={Color.nile50}>
						You can also press "Shift + Enter" to submit note
					</StyledText>
					<Button
						title='Submit'
						width='226px'
						height='40px'
						color={Color.white}
						backgroundColor={Color.zest}
						onClick={e => SubmitNote(e)}
					/>
				</TextButtonView>
			</EditorView>
		</CreateNoteView>
	);
}

export const Notes = (props) => {
	const context = useContext(DataContext);
	const [notes, setNotes] = useState([]);
	const [newNote, setNewNote] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const [editMinimized, setEditMinimized] = useState(window.innerWidth<=640?true:false);
	const mostRecentNoteRef = useRef(null);

	useEffect(() => {
		updateNotes(props.type, props.id, setNotes);
	}, [props.id, props.type]);

	useEffect(() => {
		if (mostRecentNoteRef && notes.length > 0) {
			mostRecentNoteRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [mostRecentNoteRef, notes]);

	const SubmitNote = (e) => {
		e.preventDefault()

		const note = {
			"description": newNote,
			"uploaded_by": context.userId,
			"pinned": false,
		}
		setNewNote("");

		API.post(getNotesPath(props.type, props.id), {"data": note}).then(() => updateNotes(props.type, props.id, setNotes)).catch(API.default_error_handler);
	}

	const DeleteNote = (noteId) => {
		API.put(`/note/${noteId}`, { data: { valid: false } }).then(() => updateNotes(props.type, props.id, setNotes)).catch(API.default_error_handler);
	};

	const PinNote = (noteId, pinnedStatus = true) => {
		API.put(`/note/${noteId}`, { data: { pinned: pinnedStatus } }).then(() => updateNotes(props.type, props.id, setNotes)).catch(API.default_error_handler);
	};

	return (
		<NotesView {...props}>
			{
				!props.disableSearch &&
				<SearchBoxView isOverlay={props.isOverlay}>
					<SearchInput
						width='100%'
						value={searchQuery}
						placeholder='Search notes'
						containerStyle={SearchInputContainerStyle}
						onChange={(e) => setSearchQuery(e.target.value)}
					/>
				</SearchBoxView>
			}
			<NotesListView editDisabled={!context.userPerms[NOTE_CREATE_PERM]} padding={props.rowPadding} newHeight={props.height} isOverlay={props.isOverlay} editMinimized={editMinimized} {...props.listStyling}>
				{
					notes.length === 0 &&
					<NoteCellView key='no-results'>
						<StyledText fontSize={FontSize.body1} color={Color.nile70}>
							No notes to display...
						</StyledText>
					</NoteCellView>
				}
				<div ref={mostRecentNoteRef}/>
				{notes
					.filter(note => note.description.toUpperCase().includes(searchQuery.toUpperCase()))
					.sort((a, b) => {
						//pinned notes are always at the top
						if(a.pinned < b.pinned){
							return 1;
						}
						//for non-pinned notes, sort newest-to-oldest
						//per client request
						else if(!a.pinned && !b.pinned){
							return a.created_at < b.created_at ? 1 : -1
						}
						//within pinned notes, sort newest-to-oldest
						//per client request
						else if(a.pinned && b.pinned) {
							return a.created_at < b.created_at ? 1 : -1
						}
						return -1;

					})
					.map((note, index) => (
					<NoteCell
						disableEdit={!context.userPerms[NOTE_UPDATE_PERM]}
						key={note.note_id}
						description={note.description}
						createdAt={note.created_at}
						pinned={note.pinned}
						updateNotes={() => updateNotes(props.type, props.id, setNotes)}
						userImage={note.User?.img_s3_path}
						fullName={note.User?.full_name}
						colour={note.User?.colour}
						pinNote={PinNote}
						deleteNote={DeleteNote}
						noteId={note.note_id}
						note={note}
					/>
				))}
			</NotesListView>
			{
				context.userPerms[NOTE_CREATE_PERM] &&
				renderCreateNoteView(props,context,newNote,setNewNote,SubmitNote,setEditMinimized,editMinimized)
			}
		</NotesView>
	);
}

CreateNoteView.defaultProps = {
	display: 'flex',
	flexDirection: 'row',
	width: 'calc(100% - 30px)',
	backgroundColor: Color.white,
	padding: '15px',
	boxShadow: `0px -1px 6px ${Color.nile18}`,
}

NotesView.defaultProps = {
	isOverlay: true,
	display: 'flex',
	height: '100vh',
};
