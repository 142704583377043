/*
FlexTable - A Flex table is a responsive table that uses display flex on mobile to fit to the screen better than a normal table element. 
*/

import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown,faCaretUp} from '@fortawesome/free-solid-svg-icons';

import styled, {css} from 'styled-components';
import {breakpoint} from '../../breakpoints.js';
import {FontSize} from '../../constants';
import {isAFunction} from "../../projlibs/helperFunctions";

//NOTE: we are going to have a function for this defined globally in the future
//so this is a temporary definition of this function for the purposes of getting it working
const remCalc=function (val) {
	return val/16.0;
};

const Style = styled.div`
	${props => css`
		background-color: ${props.theme.colors.tableBgLight};
		border-radius: 0.5rem;
		${breakpoint('small only')} {
			overflow-x: hidden;
		}
		overflow-x: auto;
		width:100%;
		.table-title-cont {
			padding-top:1rem;
			padding-bottom:1rem;
			.table-title {
				color:${props.theme.colors.bodyTextDark};
				line-height:${(17/13)};
				margin-bottom:0rem;
				font-size:${FontSize.header5};
				${breakpoint('medium')} {
					font-size:${remCalc(24)}rem;
				}
				${breakpoint('large')} {
					font-size:${remCalc(26)}rem;
				}
			}
		}
		table.FlexTable {
			border-collapse: collapse;
			border: none;
			margin-bottom:0rem;
			width:100%;
			${breakpoint('small only')} {
				display: flex;
				flex-flow: column;
			}
		}

		thead {
			background-color: ${props.theme.colors.tableBgLight};
			color: ${props.theme.colors.tableHdg};
			border: none;
			border-bottom: 1px solid ${props.theme.colors.tableRowBorder};
			opacity:0.5;
			text-align:left;
			${breakpoint('small only')} {
				display: none !important;
			}
			th {
				font-weight: ${props.theme.fontWeight.semibold};
				font-size: ${remCalc(13)}rem;
				line-height:${(17/13)};
				letter-spacing:0px;
				padding: 1rem;
				white-space: nowrap;
				.header-text {
					position: relative;
					font-size: 0.875rem;
					line-height: ${(17/14)};
				}
				.sort-icon {
					position: relative;
					margin-left:0.5rem;
				}
				&[data-sortorder='asc'] {
					.sort-icon.asc {
						display:none;
					}
				}
				&[data-sortorder='desc'] {
					.sort-icon.desc {
						display:none;
					}
				}
			}
		}
		tbody {
			border: none;
			tr {
				${breakpoint('small only')} {
					display: flex;
					flex-flow: column;
					
					&:nth-child(even) {
						background-color: ${props.theme.colors.tableRowBgAlternate};
					}
				}
				${breakpoint('medium')} {
					background-color: ${props.theme.colors.tableBgLight};
				}


				&:not(:last-child) {
					border-bottom: 1px solid ${props.theme.colors.tableRowBorder};
				}
			}
			td {
				padding: ${props.rowPadding ? props.rowPadding : '1rem'};
				font-size: 0.875rem;
				line-height: ${(17/14)};

				.mobile-col-title {
					${breakpoint('medium')} {
						display: none;
					}
				}
			}
		}
		.clickable {
			cursor:pointer;
		}
		.action-icon {
			font-size:${remCalc(24)}rem;
			color:${props.theme.colors.tableActionIcon};
			opacity:0.5;
			transition:opacity 0.5s ease;
			&:hover, &:focus, &:active {
				opacity:1;
			}
		}
	`}
`;

const FlexTableTitle = function(props) {
	if(props.tableTitle===undefined || props.tableTitle===null){
		return '';
	}
	//NOTE: a variable must be made for TitleTag
	//because jsx parsing requires tag names to be capitalized
	//in order to treat them as variables (rather than literals)
	if((typeof props.tableTitle)==='string'){
		let TitleTag=props.tableTitleTag;
		return (<div className='table-title-cont'>
			<div className='grid-x grid-padding-x'>
				<div className='small-12 cell'>
					<TitleTag className='table-title'>
						{props.tableTitle}
					</TitleTag>
				</div>
			</div>
		</div>);
	}
	return (<div className='table-title-cont'>
		{props.tableTitle}
	</div>);

};

const FlexTableHead = function (props) {
	return (
		<thead>
			<tr>
				{props.tableData.rows?.length > 0 && props.tableData?.titles?.map((title, index) => {
					//NOTE: columns are sortable by default, but you can specify in the rows
					//if you want a column to not be sortable
					let rows = props.tableData?.rows
					if (rows?.length > 0 && rows[0][index]?.hasOwnProperty('sortValue')) {
						return (
							<th
								key={'FlexTable-header-' + index}
								className='item sortable'
								data-sortorder='asc'
								onClick={(e) => {
										if(isAFunction(props.sort)){
											props.sort(e, title);
										}
										else {
											props.sortTable(e, index)
										}
									}
								}>
								<span className='header-text'>
									{title}
								</span>
								<span className='sort-icon asc'>
									<FontAwesomeIcon
										className='icon'
										icon={faCaretUp}
									/>
								</span>
								<span className='sort-icon desc'>
									<FontAwesomeIcon
										className='icon'
										icon={faCaretDown}
									/>
								</span>

							</th>
						);
					} else {
						return (
							<th
								key={'FlexTable-header-' + index}
								className='item'>
								<span className='header-text'>
									{title}
								</span>
							</th>
						);
					}
				})}
			</tr>
		</thead>
	);
};

const FlexTableBody = function (props) {
	const titles=props?.tableData?.titles;
	return (
		<tbody>
			{props.tableData?.rows?.map((row, rowIndex) => {
				return (
					<tr key={rowIndex} className='row'>
						{row?.map((columnData, columnIndex) => {
							let key = rowIndex + ' ' + columnIndex;
							let element = columnData.element;
							// Does Grow only works on one column, behaviour is undefined if you set multiple does grow
							return <td width={columnData.doesGrow ? '100%' : 'unset'} style={{minWidth: columnData.minWidth ? columnData.minWidth : 'unset'}} key={key}>
									<p className='mobile-col-title'>
											{titles[columnIndex]}
									</p>
									{element}
							</td>;
						})}
					</tr>
				);
			})}
		</tbody>
	);
};

export const FlexTable = props => (
	<Style {...props}>
		<div className='flex-table-container'>
			<FlexTableTitle {...props} />
			<table className='FlexTable'>
				<FlexTableHead {...props} />
				<FlexTableBody {...props} />
			</table>
		</div>
	</Style>
);

FlexTable.propTypes = {
	tableData: PropTypes.object.isRequired,
	tableTitleTag: PropTypes.string.isRequired,
	tableTitle: PropTypes.string,
	sortTable: PropTypes.func.isRequired,
};

FlexTable.defaultProps = {
	tableData: {},
	tableTitleTag: 'h2',
	tableTitle:null,
	sortTable: function (e,sortField) {},
};

