import React from 'react';
import styled, { css } from 'styled-components';
import { Color } from '../../constants';

// StyledList: General component used to display lists.
// Props:
// Can uss 'as=ol' to make this an ordered list.
export const StyledListView = styled.ul`
	${props => css`
		list-style: ${props.listStyle};
		display: ${props.display};
		width: ${props.width};
		height: ${props.height};
		min-width: ${props.minWidth};
		min-height: ${props.minHeight};
		color: ${props.color};
		align-self: ${props.alignSelf};
		background-color: ${props.backgroundColor};
		line-height: ${props.lineHeight};
		font-size: ${props.fontSize};
		font-weight: ${props.fontWeight};
		font-family: ${props.fontFamily};
		font-style: ${props.fontStyle};
		align-self: ${props.alignSelf};
		text-align: ${props.horizontalAlign};
		text-decoration: ${props.textDecorationLine};
		border-radius: ${props.borderRadius};
		border-width: ${props.borderWidth};
		border-color: ${props.borderColor};
		border-style: ${props.borderStyle};
		padding-top: ${props.paddingTop};
		padding-right: ${props.paddingRight};
		padding-bottom: ${props.paddingBottom};
		padding-left: ${props.paddingLeft};
		margin-top: ${props.marginTop};
		margin-right: ${props.marginRight};
		margin-bottom: ${props.marginBottom};
		margin-left: ${props.marginLeft};
		box-shadow: ${props.boxShadow};
		z-index: ${props.zIndex};

		li {
			position: relative;
			:not(:last-child) {
				margin-right: 0.5rem;
				padding-right: 0.5rem;
				:after {
					content: '${props.content}';
					position: absolute;
					right: -0.25rem;
					color: ${Color.nile18};
				}
			}
		}
	`}
`;

export const StyledList = props => (
	<StyledListView {...props}>
		{props.children &&
			Object.keys(props.children).map(
				(child, index) =>
					child && <li key={index}>{props.children[index]}</li>
			)}
	</StyledListView>
);

StyledListView.defaultProps = {
	marginTop: '0px',
	marginBottom: '0px',
	paddingLeft: '0px',
	paddingRight: '0px'
};
