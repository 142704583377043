import React from 'react';
import styled from 'styled-components';
import { Color } from '../../constants';
import { faSearch, faBars, faBell } from '@fortawesome/pro-regular-svg-icons';
import { SideNavButton } from '../Shared/OverlayViewButton';
import { SearchWidget } from '../Shared/SearchWidget';
import { Image } from '../Shared/Image';
import { HamburgerMenu } from './HamburgerMenu';
import { AlertList } from '../Overview/AlertList';

const MenuListView = styled.div`
	height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
	text-align: center;
	background: linear-gradient(270deg, ${Color.zest} 0%, ${Color.fuel} 100%);
	box-shadow: 0rem 0.0625rem 1rem ${Color.nile25};
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: auto 10px auto 0;
`;

const ResponsiveLogo = styled(Image)`
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 1rem;
    width: 241;
    height: 40px;
    @media (max-width: 480px) {
        width: 144px;
        height: 24px;
    }
`;

export const MobileNav = (props) => {
    return (
        <MenuListView>
            <ResponsiveLogo src='https://crcreativeco.com/wp-content/uploads/2017/12/CRCreativeco-logo.png' />
            <ButtonContainer>
                <SideNavButton location='right' icon={faBell} overlayWidth='400px'>
                    <AlertList
                        isOverlay
                        height='100%'
                        borderTop='none'
                        borderRadius='0'
                        location='right'
                    />
                </SideNavButton>
                <SideNavButton location='right' icon={faSearch}>
                    <SearchWidget location='right'/>
                </SideNavButton>
                <SideNavButton location='right' icon={faBars} overlayWidth='400px'>
                    <HamburgerMenu />
                </SideNavButton>
            </ButtonContainer>
        </MenuListView>
    );
};
