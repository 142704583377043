import React from 'react';
import styled, { css } from 'styled-components';
import { Color, FontSize, NAV_CLIENTS, NAV_PROJECTS, NAV_QUOTES } from '../../constants';
import { StyledText } from '../Shared/StyledText';
import { StyledLink } from '../Shared/StyledLink';
import { Link } from 'react-router-dom';

// ProjectInfo: Displays the project id, name and link
// Props:
// id: project id to display.
// name: project name to display.
// clientName: client name for project
// clientOnClick: callback triggered when client button is clicked
const ProjectInfoView = styled.span`
	${props => css``}
`;

export const ProjectInfo = props => (
	<ProjectInfoView {...props}>
		<StyledText color={Color.nile50} fontSize={FontSize.body2}>
			#{props.id}
		</StyledText>
		<Link to={`${props.isQuote ? NAV_QUOTES : NAV_PROJECTS}/${props.id}`} style={{ textDecoration: 'none' }}>
			<StyledText
				color={Color.nile}
				fontSize={FontSize.body1}
				marginBottom='4px'>
				{props.name}
			</StyledText>
		</Link>
		<StyledLink to={NAV_CLIENTS + `/${props.clientId}`}>{props.clientName}</StyledLink>
	</ProjectInfoView>
);

ProjectInfo.defaultProps = {
	className: 'ProjectInfo',
	target: '_self'
};
