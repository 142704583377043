import React, {useContext, useEffect, useState} from 'react';
import {useGetData} from '../../projlibs/api.js';
import {
    AdditionalFields,
    buildProject,
    CreateForm,
    extractTypeAndLabel,
    FormDropdown,
    FormInput,
    getNewMetaInfo,
    Header,
    Overlay,
    SubmitButton
} from '../Shared/CreateForm';
import {UserSelect} from '../Shared/UserSelect';
import {MetadataForm} from '../Shared/MetadataForm.jsx';
import {API} from "../../projlibs/api";
import {formatText} from '../../projlibs/helperFunctions.js';
import {error, success} from '../../projlibs/feedback.js';
import {MultiSelectDropdown} from "../Shared/MultiSelect";
import moment from "moment";
import {DropdownWithText} from '../Shared/DropDownWithText.jsx';
import styled from 'styled-components';
import {
    Color,
    CREATE_CONTACT,
    FontSize,
    MS_PER_S,
    NAV_PROJECTS,
    NAV_QUOTES,
    PROJECT_ARCHIVE_STATUS,
    QUOTE_REJECTED_STATUS
} from "../../constants";
import {useHistory} from "react-router-dom";
import {StyledText} from '../Shared/StyledText.jsx';
import {DataContext} from '../../data-context.js';
import {ClientSearchDropdown} from '../Clients/ClientSearchDropdown.jsx';
import {ContactFields} from '../Clients/ContactFields.jsx';
import {orderTypes} from "../../projlibs/helperFunctions";
import {DeleteButton} from "../Shared/OverlayViewButton";
import {Confirmation} from "../Shared/Confirmation";
import {DateInputType, DateTimeFormInput, DateTimeInputType} from '../Shared/DateTimeFormInput.jsx';

export const getExistingMetaFields = (metadata) => {

    return metadata.map(metaObject => {
        return {
            "meta_value": extractTypeAndLabel(metaObject.meta_value),
            "meta_name": metaObject.meta_name,
            "project_meta_template_id": metaObject.project_meta_template_id,
            "display_order": metaObject.display_order,
            "project_type_id": metaObject.project_type_id,
            "project_meta_id": metaObject.project_meta_id,
            "is_changed": false,
        };

    });
};

export const convertUser = () =>  {
    return user =>
        ({
            name: user.full_name,
            photo: user.img_s3_path,
            value: user.user_id.toString(),
            colour: user.colour ? user.colour : Color.zest,
        });
}

const ResponsiveConfirmButton = styled(DeleteButton)`
	border-width: 0;
	padding: 1rem 0;
	@media (min-width: 480px) {
		padding: 4px 16px;
	}
`;

export const ErrorsView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
`;

export const ErrorText = styled(StyledText)`
    color: ${Color.zest};
    font-size: ${FontSize.body2};
`;

const updateMetaDataForTemplate = (project_meta,template) => {
    //add in any metadata fields defined in the template
    //which do not (yet) exist in the stored project metadata
    for(const tmpl_metadata of template){
        //first try to get the section from the existing project metadata
        let proj_meta_section=null;
        let proj_meta_idx=-1;
        for(let meta_idx=0;meta_idx<project_meta.length;meta_idx++){
            const metadata=project_meta[meta_idx];
            if(metadata.meta_name===tmpl_metadata.meta_name){
                proj_meta_section=metadata;
                proj_meta_idx=meta_idx;
                break;
            }
        }
        //if the section didn't previously exist, add it now to the end
        if(proj_meta_section===null){
            project_meta.push(tmpl_metadata);
        //if the section existed, then check the labels
        }else{
            //check that each label/item exists
            for(let tmpl_item_idx=0;tmpl_item_idx<tmpl_metadata.meta_value.length;tmpl_item_idx++){
                const tmpl_meta_value=tmpl_metadata.meta_value[tmpl_item_idx];
                let tmpl_item_found=false;
                if(proj_meta_section!==null){
                    for(const meta_value of proj_meta_section.meta_value){
                        if(tmpl_meta_value.label===meta_value.label){
                            tmpl_item_found=true;
                        }
                    }
                }
                //if any items don't already exist, then add them now
                if(!tmpl_item_found){
                    //NOTE: we've been told that it's okay to include the new metadata at the end
                    //for existing projects when new metadata fields are added
                    project_meta[proj_meta_idx].meta_value.push({...tmpl_meta_value, value: null});
                }
            }
        }
    }
    
    //for any project_meta which is not in the current template
    //remove it from project metadata
    
    let new_project_meta=[];
    
    //for each item in the existing project_meta
    for(let meta_idx=0;meta_idx<project_meta.length;meta_idx++){
        const metadata=project_meta[meta_idx];
        
        //check if this section is in the template
        let new_meta_section=null;
        for(const tmpl_metadata of template){
            //if the section is within the template
            if(metadata.meta_name===tmpl_metadata.meta_name){
                new_meta_section=metadata;
                let new_project_meta_items=[];
                
                //for each item configured in project_meta, check if that item is in the template
                //the resulting new_project_meta_items list is the project meta which is present in the template
                for(const meta_value of metadata.meta_value){
                    for(const tmpl_meta_value of tmpl_metadata.meta_value){
                        if(meta_value.label===tmpl_meta_value.label){
                            new_project_meta_items.push(meta_value);
                            break;
                        }
                    }
                }
                new_meta_section.meta_value=new_project_meta_items;
            }
        }
        if(new_meta_section!=null){
            new_project_meta.push(new_meta_section);
        }
    }
    project_meta=new_project_meta;
    
    return project_meta;

}

export const getDetailedMetaData = (project_meta, projectTypes, projectType) => {
    if(projectTypes){
        const template = projectTypes.Project_Type.find(type => type.name === projectType).project_meta_template;
        for(const metadata of project_meta){
            for(const meta_value of metadata.meta_value){
                if(meta_value.type === 'dropdown' || meta_value.type === 'multiselect'){
                    const option_value = template.find(meta => meta.meta_name === metadata.meta_name).meta_value.find(value => value.label === meta_value.label);
                    if(option_value){
                        meta_value.options = option_value.options;
                    }
                }
            }
        }
        
        
        return updateMetaDataForTemplate(project_meta,template);
    }
}

//copy any previously-existing metadata into the new metadata format
const transferOldTypeMetaData = (old_project_meta, new_project_meta) => {
    //for each new metadata section
    for(let new_meta_sec_idx=0;new_meta_sec_idx<new_project_meta.length;new_meta_sec_idx++){
        let new_metadata=new_project_meta[new_meta_sec_idx];
        for(const old_metadata of old_project_meta){
            //if this section exists in the old metadata
            if(old_metadata.meta_name===new_metadata.meta_name){
                for(let new_meta_entry_idx=0;new_meta_entry_idx<new_metadata.meta_value.length;new_meta_entry_idx++){
                    let new_metadata_entry=new_metadata.meta_value[new_meta_entry_idx];
                    for(const old_metadata_entry of old_metadata.meta_value){
                        //if the section, label, and type matches then the value can be copied
                        if((new_metadata_entry.label===old_metadata_entry.label) && (new_metadata_entry.type===old_metadata_entry.type)){
                            //so do that
                            new_project_meta[new_meta_sec_idx].meta_value[new_meta_entry_idx].value=old_metadata_entry.value;
                            break;
                        }
                    }
                }
                
                //save cpu cycles
                break;
            }
        }
    }
    return new_project_meta;
}

export const CreateProjectsForm = (props) => {
    const history = useHistory();
    const { userId, projectStatuses, quoteStatuses } = useContext(DataContext);
    let project = {};
    if(props.project !== undefined){
        project = props.project[0];
    }
    
    const [{ data: userData }] = useGetData("/user?page_size=");
    const [errors, setErrors] = useState([]);
    const users = userData.User?.map(convertUser());

    const project_manager = props.project !== undefined && project.project_manager !== null ? users?.filter(user => user.value === project.project_manager.toString())[0] : users?.filter(user => user.value === `${userId}`)[0];
    const [projectType, setProjectType] = useState(props.project ? project.Project_Type?.name : "");
    const [client, setClient] = useState(props.project ? project.client_id : "");
    const [projectStatus, setProjectStatus] = useState(props.project ? { valueField: project.Project_Status?.name, textField: formatText(project.Project_Status?.name) } : "");
    const [selectedProjectStatus, setSelectedProjectStatus] = useState(props.project ? { valueField: project.Project_Status?.name, textField: formatText(project.Project_Status?.name) } : "");
    const [projectName, setProjectName] = useState(props.project ? project.name: "");
    const [projectManager, setProjectManager] = useState(props.project && project.project_manager ? project.project_manager.toString() : `${userId}`);
    const [contact, setContact] = useState(props.project && project.Contact ? `${project.Contact.contact_id}` : "");
    const [clientContacts, setClientContacts] = useState([]);
    const [dueDate, setDueDate] = useState(props.project && project.date_due !== null && project.date_due > 0 ? project.date_due : null);
    const [dateQuoted, setDateQuoted] = useState(props.project && project.date_quoted !== null && project.date_quoted > 0 ? project.date_quoted : (props.project ? null : moment().unix()));
    const [assignedUsers, setAssignedUsers] = useState(props.project && project.assigned_users ? project.assigned_users.map(convertUser()) : []);
    const [retailPrice, setRetailPrice] = useState(props.project ? project.retail_price : 0);
    const [deliveryPrice, setDeliveryPrice] = useState(props.project ? project.delivery_price : 0);
    const [setupFee, setSetupFee] = useState(props.project ? project.setup_fee : 0);
    const [discount, setDiscount] = useState(props.project ? project.discount : 0);
    const [actualHours, setActualHours] = useState(props.project ? project.actual_hours : 0);
    const [hourlyRate, setHourlyRate] = useState(props.project ? project.hourly_rate : 0);
    const [tempType, setTempType] = useState('');
    const mode = props.project === undefined ? 'create' : 'edit';
    const quoteStatusesToDisplay = mode === 'edit' ? quoteStatuses : quoteStatuses.filter(status => status !== QUOTE_REJECTED_STATUS);

    useEffect(() => {
        if(client){
            API.get(`client/${client}/contact`).then(resp => {
                setClientContacts(resp.data.Contact ? resp.data.Contact : []);
                setContact('');
            });
        }
    }, [client, project.Contact]);

    const [statuses, setStatuses] = useState(props.project ? projectStatuses[project.Project_Type?.name]: []);
    const [{ data: projectTypes }] = useGetData("/project/type");
    let types = projectTypes.Project_Type?.map(type => type.name);
    types = orderTypes(types);
    types = mode === 'edit' ? types?.filter(type => type !== project.Project_Type?.name) : types;

    const [metaFields, setMetaFields] = useState(props.project === undefined ? [] : getExistingMetaFields(project.project_meta));
    const [originalMetaFields, ] = useState(props.project === undefined ? [] : getExistingMetaFields(project.project_meta));
    const [metadata, setMetaData] = useState([]);
    const [{ data: contactStatuses }] = useGetData("/contact/status");
    const [isUsingNewContact, setIsUsingNewContact] = useState(false);
    const [newContact, setNewContact] = useState({});
    const [hasMetadataBeenUpdated, setHasMetadataBeenUpdated] = useState(false);
    
    useEffect(() => {
        if(project.project_meta && projectTypes.Project_Type && !hasMetadataBeenUpdated){
            setMetaData(getDetailedMetaData(project.project_meta, projectTypes, projectType))
            setHasMetadataBeenUpdated(true);
        }
    }, [projectTypes, project.project_meta, projectType, hasMetadataBeenUpdated])

    const handleStatusChange = (value) => {
        if (typeof value === 'string') {
            setProjectStatus({ valueField: value, textField: value });
        } else if (value.valueField) {
            setProjectStatus(value);
        }
        if(typeof value !== 'string'){
            setSelectedProjectStatus('');
        }
    };

    const handleSelectedStatusChange = (value) => {
        if (typeof value === 'string') {
            setSelectedProjectStatus({ valueField: value, textField: value });
        } else if (value.valueField) {
            setSelectedProjectStatus(value);
        }
    };

    const handleTypeChange = (value) => {
        if(value === ""){
            setProjectType("");
            setStatuses([]);
            setMetaData([]);
            setMetaFields([]);
        }
        else if(projectTypes.Project_Type){
            setProjectType(value);

            setStatuses(mode === 'edit' ? projectStatuses[value]: projectStatuses[value].filter(status => status !== PROJECT_ARCHIVE_STATUS));
            let [{metadata, metaFields}] = getNewMetaInfo(value, projectTypes);
            setMetaData(metadata);

            metaFields=transferOldTypeMetaData(originalMetaFields, metaFields);
            setMetaFields(metaFields);
        }
    }

    const changeType = (value) => {
        if(mode === 'edit'){
            setTempType(value);
            document.getElementById('confirmTypeChange').click()
        }
        else{
            handleTypeChange(value);
        }
    }

    const CreateProject = (projectToSend) => {
        API.post('/project', { data: projectToSend })
        .then((response) => {
            success('New project successfully created.');
            props.onClose();
            if(props.isQuote){
                history.push(
                    NAV_QUOTES + '/' + response.data.Project[0].project_id
                );
            }
            else {
                history.push(
                    NAV_PROJECTS + '/' + response.data.Project[0].project_id
                );
            }
            if (props.closeGlobalAdd) {
                props.closeGlobalAdd();
            }
        })
        .catch(API.default_error_handler);
    };
    const EditProject = (projectToSend) => {
        let newMetaFields = [];
        //if the project type changed
        if(projectType !==  project.Project_Type?.name){
            newMetaFields = projectToSend.project_meta;
            projectToSend.project_meta = originalMetaFields;

            //then invalidate any/all existing metadata
            projectToSend.project_meta = projectToSend.project_meta.map(meta => {
                return {
                    valid: false,
                    project_meta_id: meta.project_meta_id,
                }
            });

            projectToSend.project_meta.forEach(meta =>  {
                API.put(`/project/${project.project_id}/meta/${meta.project_meta_id}`, {data: {valid: false}})
            });
            projectToSend.project_meta = [];
        }
        //update the project
        API.put(`/project/${project.project_id}`, {"data": projectToSend}).then(() => {
            //if any new metadata fields were added (i.e. if project type changed) then set those now
            if(newMetaFields.length > 0){
                const AddedMetaData = newMetaFields.map(meta => AddMetaData(meta));
                const metaDataPromises = Promise.all(AddedMetaData);
                metaDataPromises.then(() => props.update()).catch(() => error('Error trying to create project meta data.'));
            }
            else {
                props.update();
            }
            success('Project successfully edited.');
            props.onClose();
        }).catch(API.default_error_handler);
    };
    const AddMetaData = (meta) => {
        return API.post(`/project/${project.project_id}/meta`, {"data": meta});
    }

    const SubmitForm = async (e) => {
        e.preventDefault();
        let errors = [];
        let projectToSend = buildProject(projectType,
            client,
            projectName,
            dueDate,
            dateQuoted,
            null,
            metaFields.filter(meta => meta.is_changed),
            assignedUsers,
            retailPrice,
            deliveryPrice,
            setupFee,
            discount,
            actualHours,
            hourlyRate,
            true);

        if(contact !== "") {
            projectToSend.contact_id = contact;
        }
        if (!client) {
            errors.push('Client field is required.');
        }

        if(projectManager !== ""){
            projectToSend.project_manager = parseInt(projectManager);
        }
        if(selectedProjectStatus  !== "" && selectedProjectStatus !== project.Project_Status?.name){
            projectToSend.project_status = projectStatus.valueField;
        } else if(selectedProjectStatus === '' || !selectedProjectStatus.valueField) {
            errors.push('Project status field is required.');
        }

        if(props.isQuote){
            projectToSend.is_quote = true;
        }
        if(isUsingNewContact) {
            try {
                const { data } = await API.post(`/client/${client}/contact`, {data: newContact});
                projectToSend.contact_id = data.Contact.contact_id;
            } catch (e) {
                API.default_error_handler(e);
            }
        }

        if(mode === 'edit' && errors.length === 0){
            EditProject(projectToSend);
            setErrors([]);
        } else if (errors.length === 0){
            CreateProject(projectToSend);
            setErrors([]);
        } else {
            setErrors(errors);
        }
    }

    const generateTitle = (isQuote, mode) => {
        return `${mode === 'edit' ? 'Edit' : 'Create'} your ${isQuote ? 'quote' : 'project'}`
    };

    const generateSubmitText = (isQuote, mode) => {
        return `${mode === 'edit' ? 'Edit' : 'Create'} ${isQuote ? 'quote' : 'project'}`
    };

    return (

        <Overlay className="Project Overlay">
            <Header onClose={props.onClose} title={generateTitle(props.isQuote, mode)} />
            <CreateForm className="Project Form" onSubmit={SubmitForm}>
                <FormInput
                    label='Project Name*'
                    inputId='projectName'
                    placeholder='...'
                    width='64%'
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    required
                />
                 <FormDropdown
                    label={props.isQuote ? "Quote Type*" : "Project Type*"}
                    values={types}
                    options={types}
                    placeholderValue={mode === 'edit' ? project.Project_Type?.name : undefined}
                    placeholder={mode === 'edit' ? project.Project_Type?.name : "-select-"}
                    value={mode === 'edit' ? projectType : undefined}
                    width='34%'
                    onChange={(e) => changeType(e.target.value)}
                    required
                    noDefault={mode === 'edit'}
                />
                <ClientSearchDropdown
                    mode={mode}
                    project={props.project ? props.project[0] : {}}
                    onChange={(value) => {
                        setClient(value);
                        setContact('');
                    }}
                    label='Client*'
                    width='64%'
                    height='42px'
                    placeholder='-select-'
                />
                <UserSelect
                    title="Project Manager"
                    options={users ? users : []}
                    value={project_manager === {} ? "" : project_manager?.value}
                    width='34%'
                    onChange={setProjectManager}
                />
                {
                    client && isUsingNewContact &&
                    <ContactFields
                        isBasic
                        statuses={contactStatuses}
                        getContact={() => newContact}
                        updateContact={(contact) => setNewContact(contact)}
                    />
                }
                {client &&
                    <>
                        {
                            !isUsingNewContact && clientContacts.length > 0 &&
                            <FormDropdown
                                key={client}
                                label="Contact"
                                values={[...clientContacts?.map(contact => contact.contact_id), CREATE_CONTACT]}
                                options={[...clientContacts?.map(contact => contact.name), '-Create New Contact-']}
                                placeholder='-select-'
                                defaultValue={contact}
                                width='49%'
                                onChange={(e) => {
                                    if (e.target.value === CREATE_CONTACT) {
                                        setIsUsingNewContact(true);
                                    } else {
                                        setContact(e.target.value);
                                    }
                                }}
                            />
                        }
                        {
                            isUsingNewContact &&
                            <AdditionalFields
                                type='button'
                                paddingLeft='0px'
                                title='Remove New Contact -'
                                onClick={() => setIsUsingNewContact(false)}
                            />
                        }
                    </>
                }
                {
                    projectType &&
                    <DropdownWithText
                        label={props.isQuote ? "Quote Status*" : "Project Status*"}
                        textField='textField'
                        data={props.isQuote ? quoteStatusesToDisplay : statuses}
                        width='49%'
                        height='42px'
                        placeholder='-select-'
                        defaultValue={mode === 'edit' ? { textField: formatText(project.Project_Status?.name), valueField: project.Project_Status?.name } : undefined}
                        value={projectStatus}
                        onSelect={value => handleSelectedStatusChange(value)}
                        onChange={value => handleStatusChange(value)}
                        required
                    />
                }
                <MultiSelectDropdown
                    label="Assigned Users"
                    defaultValue={assignedUsers}
                    placeholder='-select-'
                    height='44px'
                    width='100%'
                    allowCreate-={false}
                    data={users ? users : []}
                    textField={user => user.name}
                    onChange={value => setAssignedUsers(value)}
                />
                <DateTimeFormInput
                    type={DateTimeInputType}
                    label='In-Hand Due Date'
                    width='49%'
                    format='MM/DD/YYYY, hh:mm A'
                    placeholder='mm/dd/yyyy, hh:mm'
					value={dueDate ? dueDate * MS_PER_S : null}
					onChange={value => value?.unix() ? setDueDate(value?.unix()) : setDueDate(null)}
                />
                <DateTimeFormInput
                    type={DateInputType}
                    label='Date Quoted'
                    width='49%'
                    format='MM/DD/YYYY'
                    placeholder='mm/dd/yyyy'
                    value={dateQuoted ? dateQuoted * MS_PER_S : null}
                    onChange={value => value?.unix() ? setDateQuoted(value?.unix()) : setDateQuoted(null)}
                />
                {metadata.map(metadataItem => (
                    <MetadataForm
                        key={metadataItem.meta_name + projectType}
                        metaFields={metaFields}
                        setMetaFields={setMetaFields}
                        metadata={metadataItem}
                        retailPrice={retailPrice}
                        deliveryPrice={deliveryPrice}
                        actualHours={actualHours}
                        hourlyRate={hourlyRate}
                        setupFee={setupFee}
                        discount={discount}
                        setDiscount={setDiscount}
                        setRetailPrice={setRetailPrice}
                        setDeliveryPrice={setDeliveryPrice}
                        setActualHours={setActualHours}
                        setHourlyRate={setHourlyRate}
                        setSetupFee={setSetupFee}
                    />))}
                <ErrorsView>
                    {
                        errors.map(error => <ErrorText key={error}>{error}</ErrorText>)
                    }
                </ErrorsView>
                <SubmitButton title={generateSubmitText(props.isQuote, mode)} type='submit' />
                <ResponsiveConfirmButton type='button' id='confirmTypeChange' title=''>
                    <Confirmation title='Type Change' text='Changing the project type will delete any non-transferrable metadata on this project. Are you sure you want to continue?' onConfirm={() => handleTypeChange(tempType)} />
                </ResponsiveConfirmButton>
            </CreateForm>
        </Overlay>
    );
}
