import React, { useState } from 'react';
import styled from 'styled-components';
import { UnderlineLink } from '../Shared/Button';
import { FormInput } from '../Shared/CreateForm';
const Container = styled.div`
	width:100%;
	margin-bottom:24px;
`;
const PasswordWrapper = styled.div`
`;
export const ChangePassword = props => {
	const [openMenu,setOpenMenu] = useState(false);

	const changeMenu=(menuState)=>{
		setOpenMenu(menuState);
		if(!menuState){
			props.setPassword('');
			props.setPasswordConfirm('');
		}
	}
	return (
		<Container>
			<UnderlineLink marginBottoms='32px' display='block' onClick={e=>{e.preventDefault();changeMenu(!openMenu)}}type='button' title='Change Password' />
			{openMenu && <PasswordWrapper>
				<FormInput
					label='Password*'
					type='password'
					inputId='password_user'
					width='100%'
					display='block'
					placeholder='...'
					value={props.password}
					required
					onChange={(e) => props.setPassword(e.target.value)}
				/>
				<FormInput
					label='Confirm Password*'
					type='password'
					inputId='password_confirm'
					width='100%'
					placeholder='...'
					value={props.passwordConfirm}
					required
					onChange={(e) => props.setPasswordConfirm(e.target.value)}
				/>
			</PasswordWrapper>}

		</Container>
	);
};
