import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useGetData} from '../../projlibs/api.js';
import {Link, useHistory} from 'react-router-dom';
import {
	ACCOUNT_REP,
	CLIENT,
	CLIENT_CREATE_PERM,
	CLIENTS_SEARCH_ACC_REP,
	CLIENTS_SEARCH_ORDER_BY,
	CLIENTS_SEARCH_PAGE,
	CLIENTS_SEARCH_TIER,
	CLIENTS_SEARCH_VALUE,
	Color,
	CONTACT_NAME,
	FontSize,
	NAV_CLIENTS,
	TIER
} from '../../constants';
import {breakpoint} from '../../breakpoints';
import {SearchInput} from '../Shared/SearchInput';
import {ControlledFilterDropdown} from '../Shared/Dropdown';
import {FlexTableController} from '../Shared/FlexTableController';
import {StyledText} from '../Shared/StyledText';
import {StyledInitials} from '../Shared/StyledInitials';
import {Button} from '../Shared/Button';
import {TierText} from '../Shared/TierText';
import {faAngleRight} from '@fortawesome/pro-regular-svg-icons';
import {CreateFormButton} from '../Shared/OverlayViewButton';
import {CreateClientForm} from './CreateClientForm.jsx';
import {PageController} from "../Shared/PageController";
import {TableHeader, updateSorting, HoverUnderlineName} from "../Projects/Projects";
import {PageIndex} from '../Shared/PageIndex.jsx';
import {DataContext} from '../../data-context.js';
import {Helmet} from 'react-helmet';

const ClientView = styled.div`
	background-color: ${Color.white};
`;

const ClientHeader = styled.div`
	padding: 2rem 1.5rem;
	background-color: ${Color.nile5};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const FilterControlsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem 1rem 1rem 0;
	margin-right: 0.5rem;
	${breakpoint('medium up')} {
		margin: 0;
		flex-wrap: wrap;
		flex-direction: row;
	}
`;

const StyledFilterDropdown = styled(ControlledFilterDropdown)`
	flex: 1;
	width: 100%;
	margin: 0.25rem 0.4rem;
	${breakpoint('medium up')} {
		margin: 0.25rem 0.4rem;
		width: fit-content;
		max-width: 200px;
	}
`;

const NameContainer = styled.div`
	display: flex;
	align-items: center;
`;

const TierContainer = styled.div`
	width: fit-content;
	margin: 0;
`;

function SetupTableData(clients) {
	let history = useHistory();

	let tableData = {
		titles: [
			'',
			CLIENT,
			CONTACT_NAME,
			'Email',
			'Phone',
			ACCOUNT_REP,
			TIER,
			'',
		],

		rows: clients?.map((client, index) => {
			return [
				{
					element: (
						<Link to={`${NAV_CLIENTS}/${client.client_id}`} style={{ textDecoration: 'none' }}>
							<StyledInitials
								size='48px'
								marginTop='0'
								marginRight='0'
								marginBottom='0'
								borderRadius='4px'
								backgroundColor={client.color}>
								{client.name}
							</StyledInitials>
						</Link>
					),
				},
				{
					sortValue: client.name,
					element: (
						<Link to={`${NAV_CLIENTS}/${client.client_id}`} style={{ textDecoration: 'none' }}>
							<NameContainer>
								<HoverUnderlineName
									color={Color.black}
									fontSize={FontSize.body1}
									flexShrink='1'>
									{client.name}
								</HoverUnderlineName>
							</NameContainer>
						</Link>
					),
				},
				{
					sortValue: client.Primary_Contact?.name,
					element: (
						<StyledText fontSize={FontSize.body2}>
							{client.Primary_Contact?.name}
						</StyledText>
					),
				},
				{
					element: (
						<StyledText
							as='a'
							href={`mailto:${client.Primary_Contact?.email}`}
							color={Color.nile50}
							fontSize={FontSize.body2}
							textDecorationLine='underline'>
							{client.Primary_Contact?.email}
						</StyledText>
					),
				},
				{
					element: (
						<StyledText
							as='a'
							href={`tel:${client.Primary_Contact?.phone}`}
							color={Color.nile50}
							fontSize={FontSize.body2}
							textDecorationLine='underline'>
							{client.Primary_Contact?.phone}
						</StyledText>
					),
				},
				{
					sortValue: client.Account_Rep,
					element: (
						<StyledInitials
							size='32px'
							numberOfInitials='1'
							fontSize={FontSize.body1}
							backgroundColor={client.Account_Rep?.colour ? client.Account_Rep?.colour : Color.zest}
							marginTop='0'
							marginBottom='0'
							marginRight='16px'>
							{client.Account_Rep?.full_name}
						</StyledInitials>
					),
				},
				{
					sortValue: client.tier,
					element: (
						<TierContainer>
							<TierText
								display='flex'
								borderRadius='2px'
								horizontalAlign='center'
								marginRight='auto'
								fontSize={FontSize.body2}
								backgroundColor={Color.banana}>
								{client.client_tier}
							</TierText>
						</TierContainer>
					),
				},
				{
					element: (
						<Button
							fontSize='1.5rem'
							icon={faAngleRight}
							iconSize='lg'
							onClick={(event) =>
								history.push(
									NAV_CLIENTS + '/' + client.client_id
								)
							}
						/>
					),
				},
			];
		}),
	};

	return tableData;
}

export const getURL = ({rep = "", searchToSubmit = "", tier = "", orderBy = ""}={}) => {
	let url = '/client/search?';

	if(rep !== "" && rep !== "placeholder"){
		url += "match_account_rep=" + rep + "&";
	}
	if(searchToSubmit !== ""){
		url += "match_name=" + searchToSubmit + "&";
	}
	if(tier !== "" && tier !== "placeholder"){
		url += "match_tier=" + tier + "&";
	}
	if(orderBy !== ""){
		url += orderBy + "&";
	} else {
		url += "order_by=name:asc&";
	}
	//always show inactive clients on this view
	url += 'show_inactive=true&';
	return url;
};

export const Clients = (props) => {
	const { userPerms } = useContext(DataContext);

	const [searchValue, setSearchValue] = useState(localStorage.getItem(CLIENTS_SEARCH_VALUE) ? localStorage.getItem(CLIENTS_SEARCH_VALUE) : '');
	const [searchToSubmit, setSearchToSubmit] = useState(localStorage.getItem(CLIENTS_SEARCH_VALUE) ? localStorage.getItem(CLIENTS_SEARCH_VALUE) : '');

	const [currentPage, setCurrentPage] = useState(localStorage.getItem(CLIENTS_SEARCH_PAGE) ? parseInt(localStorage.getItem(CLIENTS_SEARCH_PAGE)) : 0);
	const [numPages, setNumPages] = useState(0);

	const [orderBy, setOrderBy] = useState('');
	const [resultCount, setResultCount] = useState(0);
	const [searchQuery, setSearchQuery] = useState('');

	const [tier, setTier] = useState(localStorage.getItem(CLIENTS_SEARCH_TIER) ? localStorage.getItem(CLIENTS_SEARCH_TIER) : '');
	const [rep, setRep] = useState(localStorage.getItem(CLIENTS_SEARCH_ACC_REP) ? localStorage.getItem(CLIENTS_SEARCH_ACC_REP) : '');

	const [{ data }, setUrl] = useGetData(getURL({
		rep: rep,
		tier: tier,
		searchToSubmit: searchToSubmit,
		orderBy: orderBy,
	}));

	const [{ data: userData }] = useGetData("/user");
	const users = userData.User?.map(user => user.full_name);
	const user_ids = userData.User?.map(user =>user.user_id.toString());

	const [{ data: tiers }] = useGetData("/client/tier");

	const handleSearchSubmit = (e) => {
		setSearchToSubmit(searchValue);
		e.preventDefault();
	}

	useEffect(() => {
		setNumPages(data.page_count);
		setResultCount(data.result_count);
	}, [data]);

	useEffect(() => {
		const localStorage = window.localStorage;
		const saveSearchSelections = (hasChanged = false) => {
			localStorage.setItem(CLIENTS_SEARCH_TIER, tier);
			localStorage.setItem(CLIENTS_SEARCH_ACC_REP, rep);
			localStorage.setItem(CLIENTS_SEARCH_ORDER_BY, orderBy);
			localStorage.setItem(CLIENTS_SEARCH_VALUE, searchToSubmit);
			localStorage.setItem(CLIENTS_SEARCH_PAGE, hasChanged ? 0 : currentPage);
		};
		let url = getURL({
			rep: rep,
			tier: tier,
			searchToSubmit: searchToSubmit,
			orderBy: orderBy,
		});

		//if the url is different then the query has changed and we want to go back to the first page
		if(url !== searchQuery){
			if (!window.localStorage.getItem(CLIENTS_SEARCH_PAGE)) {
				setCurrentPage(0);
			}
			saveSearchSelections(true);
		} else {
			saveSearchSelections(false);
		}
		setSearchQuery(url);

		url += "page=" + currentPage;
		setUrl(url);
	}, [rep, tier, currentPage, setUrl, orderBy, searchToSubmit, searchQuery]);

	return (
		<ClientView>
			<Helmet>
				<meta name="description" content='Clients' />
			</Helmet>
			<ClientHeader>
				<StyledText
					as='h1'
					color={Color.nile}
					fontSize={FontSize.header2}>
					Clients
				</StyledText>
				{
					userPerms[CLIENT_CREATE_PERM] &&
					<CreateFormButton title="New Client">
						<CreateClientForm />
					</CreateFormButton>
				}
			</ClientHeader>
			<TableHeader>
				<FilterControlsWrapper>
					<SearchInput
						value={searchValue}
						containerStyle={{ flex: '1', minWidth: '200px', width: '100%', margin: '0.5rem 0rem 0.5rem 0.75rem' }}
						borderColor={Color.nile15}
						hoverBorderColor={Color.nile5}
						placeholder='Search clients'
						onChange={(e) => setSearchValue(e.target.value)}
						onSubmit={e => handleSearchSubmit(e)}
					/>
					<StyledFilterDropdown
						value={tier}
						placeholder='Tier'
						options={tiers}
						values={tiers}
						onChange={(e) => setTier(e.target.value)}
					/>
					<StyledFilterDropdown
						value={rep}
						placeholder='Account Rep'
						options={users}
						values={user_ids}
						onChange={(e) => setRep(e.target.value)}
					/>
				</FilterControlsWrapper>
				<PageIndex resultCount={resultCount} currentPage={currentPage} />
			</TableHeader>
			<FlexTableController tableData={SetupTableData(data?.Client)} sort={(e, title) => updateSorting(e,title, setOrderBy)}/>
			<PageController pageNumber={currentPage} setPageNumber={setCurrentPage} numPages={numPages}/>
		</ClientView>
	);
};
