import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {
    Color,
    FontSize,
    NAV_CLIENTS,
    NAV_DASHBOARD,
    NAV_DASHBOARD_SALES,
    NAV_LOGIN,
    NAV_OVERVIEW,
    NAV_PROJECTS,
    NAV_QUOTES,
    NAV_REPORTS,
    NAV_USERS,
    VIEW_ACTIVE_PROJ_PERM,
    VIEW_CLIENTS_PERM,
    VIEW_FULL_DASH_PERM,
    VIEW_LEADS_PERM,
    VIEW_PROJECTS_PERM,
    VIEW_QUOTES_PERM,
    VIEW_REPORTS_PERM,
    VIEW_USER_MANAGER_PERM,
} from '../../constants';
import {
    faAddressBook,
    faArrowRight,
    faChartLine,
    faFolder,
    faHome,
    faMoneyCheckAlt,
    faPlus,
    faSignOutAlt,
    faUser,
    faUserEdit,
    faUsers
} from '@fortawesome/pro-regular-svg-icons';
import {breakpoint} from '../../breakpoints';
import {StyledBase} from "../Shared/StyledBase";
import {Button, LeftMenuButton} from "../Shared/Button";
import {StyledText} from "../Shared/StyledText";
import {DataContext} from '../../data-context';
import S3ImageController from '../Shared/S3ImageController';
import {StyledInitials} from '../Shared/StyledInitials';
import {clearCookies} from '../../react-utils/src/helperfunctions';
import {useHistory} from 'react-router-dom';
import {CreateFormButton, SideNavButton} from '../Shared/OverlayViewButton';
import {GlobalAdd} from './GlobalAdd';
import {CreateUserForm} from '../Users/CreateUserForm';
import {API} from '../../projlibs/api';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const HamburgerMenuView = styled.div`
	${StyledBase}
	min-width: 0;
	width: 100%;
	height: 100%;
	text-align: left;
	background-color: ${Color.white};
	position: relative;
	${breakpoint('medium up')} {
		min-height: 100vh;
		width: 400px;
		height: unset;
	}
`;

const MainView = styled.div`
	${StyledBase}
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
	height: calc(100vh - 1rem);
    padding: 1.5rem 2rem 2rem 2rem;
`;

const UserDetailContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${Color.nile15};
`;

const NavButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1rem;
`;

const StyledMenuButton = styled(LeftMenuButton)`
    color: ${Color.nile90};
    width: 100%;
`;

export const HamburgerMenu = ({ onClose }) => {
    const [isHovering, setIsHovering] = useState(false);
    const context = useContext(DataContext);
    const history = useHistory();
	const img_s3_path = context.userImageURL;
    const current_user_name = context.username;
    const Logout = () => {
		context.setSessionId('');
		context.setUserId('');
		clearCookies();
		history.push(NAV_LOGIN);
    }
    const [data, setData] = useState(({}));
	let user = data?.User ? data.User[0] : undefined;
	useEffect(() => {
		API.get(`/user/${context.userId}?show_invalid=true`).then(response => {
			setData(response.data);
		});
	}, [context.userId]);
    return (
        <HamburgerMenuView>
            <Button
                zIndex={2}
                icon={faArrowRight}
                iconSize='2x'
                onClick={onClose}
                borderStyle='none'
                position='absolute'
                paddingTop='1rem'
                paddingRight='0.5rem'
                paddingLeft='0.5rem'
                paddingBottom='0.5rem'
                right='1rem'
                top='2rem'
                backgroundColor='transparent'
                iconColor={Color.nile}
            />
            <MainView>
                <UserDetailContainer>
                    <CreateFormButton id='edit-current-user-button-hamburger' position='fixed' visibility='hidden' minWidth='0px' minHeight='0px' width='0px' height='0px' >
                        <CreateUserForm user={user} isEditingOwn />
                    </CreateFormButton>
                    <div style={{zIndex: 1, backgroundColor: Color.transparent, width: 'fit-content', height: 'fit-content', cursor: 'pointer'}} onMouseOver={() => setIsHovering(true)} onMouseOut={() => setIsHovering(false)}>
                        <FontAwesomeIcon
                            size='2x'
                            color={Color.white}
                            icon={faUserEdit}
                            onClick={() => document.getElementById('edit-current-user-button-hamburger').click()}
                            style={{ zIndex: 4, position: 'absolute', top: '58px', left: '52px', opacity: isHovering ? '1' : '0', transition: 'all 0.4s ease', cursor: 'pointer' }}/>
                        {
                            img_s3_path ?
                            <S3ImageController
                                cursor='pointer'
                                height='70px'
                                width='70px'
                                onClick={() => document.getElementById('edit-current-user-button-hamburger').click()}
                                borderRadius='50%'
                                borderWidth='2px'
                                marginRight='1rem'
                                filter={isHovering ? 'blur(5px) !important' : 'unset'}
                                borderColor={Color.zest}
                                s3_path={img_s3_path}
                            /> :
                            <StyledInitials
                                cursor='pointer'
                                size='70px'
                                height='70px'
                                width='70px'
                                marginRight='1rem'
                                onClick={() => document.getElementById('edit-current-user-button-hamburger').click()}
                                borderRadius='50%'
                                filter={isHovering ? 'blur(5px) !important' : 'unset'}
                                fontSize={FontSize.body1}
                                numberOfInitials={2}
                                backgroundColor={context.userColour ? context.userColour : Color.zest}>
                                {current_user_name}
                            </StyledInitials>	
                        }
                    </div>
                    <StyledText fontSize={FontSize.header4}>{current_user_name}</StyledText>
                </UserDetailContainer>
                <NavButtonsContainer>
                    <SideNavButton
                        fontSize={FontSize.body1}
                        iconXOffset='5%'
                        width='100%'
                        marginBottom='1rem'
                        backgroundColor={Color.nile10}
                        hoverBackground={Color.fuel}
                        borderRadius='5px'
                        location='right'
                        title='Create...'
                        icon={faPlus}
                        overlayWidth='400px'>
                        <GlobalAdd location='right' />
                    </SideNavButton>
                    {
                        context.userPerms[VIEW_ACTIVE_PROJ_PERM] &&
                        <StyledMenuButton
                            hoverBackground={Color.fuel}
                            title={'Dashboard'}
                            icon={faHome}
                            onClick={() => {
                                history.push(NAV_DASHBOARD);
                                onClose();
                            }}
                        />
                    }
                    {
                        context.userPerms[VIEW_FULL_DASH_PERM] &&
                        <StyledMenuButton
                            hoverBackground={Color.fuel}
                            title={'Overview'}
                            iconSize='lg'
                            icon={['fac', 'FolderCheck']}
                            onClick={() => {
                                history.push(NAV_OVERVIEW);
                                onClose();
                            }}
                        />
                    }
                    {
                        context.userPerms[VIEW_LEADS_PERM] &&
                        <StyledMenuButton
                            hoverBackground={Color.fuel}
                            title={'Leads'}
                            icon={faUsers}
                            onClick={() => {
                                history.push(NAV_DASHBOARD_SALES);
                                onClose();
                            }}
                        />
                    }
                    {
                        context.userPerms[VIEW_PROJECTS_PERM] &&
                        <StyledMenuButton
                            hoverBackground={Color.fuel}
                            title={'Projects'}
                            icon={faFolder}
                            onClick={() => {
                                history.push(NAV_PROJECTS);
                                onClose();
                            }}
                        />
                    }
                    {
                        context.userPerms[VIEW_QUOTES_PERM] &&
                        <StyledMenuButton
                            hoverBackground={Color.fuel}
                            title={'Quotes'}
                            icon={faMoneyCheckAlt}
                            onClick={() => {
                                history.push(NAV_QUOTES);
                                onClose();
                            }}
                        />
                    }
                    {
                        context.userPerms[VIEW_CLIENTS_PERM] &&
                        <StyledMenuButton
                            hoverBackground={Color.fuel}
                            title={'Clients'}
                            icon={faAddressBook}
                            onClick={() => {
                                history.push(NAV_CLIENTS);
                                onClose();
                            }}
                        />
                    }
                    {
                        context.userPerms[VIEW_REPORTS_PERM] &&
                        <StyledMenuButton
                            hoverBackground={Color.fuel}
                            title={'Reports'}
                            icon={faChartLine}
                            onClick={() => {
                                history.push(NAV_REPORTS);
                                onClose();
                            }}
                        />
                    }
                    {
                        context.userPerms[VIEW_USER_MANAGER_PERM] &&
                        <StyledMenuButton
                            hoverBackground={Color.fuel}
                            title={'User Managers'}
                            icon={faUser}
                            onClick={() => {
                                history.push(NAV_USERS);
                                onClose();
                            }}
                        />
                    }
                    <StyledMenuButton
                        hoverBackground={Color.fuel}
                        title={'Logout'}
                        icon={faSignOutAlt}
                        onClick={() => {
                            onClose();
                            Logout();
                        }}
                        selected={false}
                    />
                </NavButtonsContainer>
                </MainView>
        </HamburgerMenuView>
    );
};
