import styled, { css } from 'styled-components';
import { Color, FontSize } from '../../constants';
import { StyledText } from './StyledText';

// StatusText: General component used to display a router Link
// Props:
export const StatusText = styled(StyledText)`
	${props => css`
	align-self: ${props.alignSelf};
	text-align: ${props.textAlign};
	color: ${props.color};
	font-size: ${props.fontSize};
	position: relative;
	padding-left: 1.5rem;

	&::before {
		position: absolute;
		content: '';
		height: ${props.height ? props.height : '1rem'};
		width: ${props.width ? props.width : '1rem'};
		top: 50%;
		transform: translateY(-50%);
		left: ${props.left ? props.left : '-0.5rem'};
		background-color: ${props.statusColor};
		border-radius: 50%;
	}
	`}
`;

StatusText.defaultProps = {
	color: Color.nile,
	fontSize: FontSize.body1
};
