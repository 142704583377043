import {useEffect, useState} from 'react';
import axios from 'axios';

import {config} from '../config.js';
import {parse_cookies} from './/cookie-management.js';
import {clearCookies} from '../react-utils/src/helperfunctions.js';
import {error} from './feedback.js';

export class API {
	static _merge_cfg(cfg_delta){
		//start with the configuration defined in the config file
		let cfg=config.axios;
		
		//add in session (authorization) headers as needed
		const cookies=parse_cookies();
		if(cookies.hasOwnProperty('session_id')){
			cfg['headers']['Authorization']=cookies['session_id'];
		}

		//and finally set anything explicitly defined by the user
		const keys=Object.keys(cfg_delta);
		for(let key_idx=0;key_idx<keys.length;key_idx++){
			cfg[keys[key_idx]]=cfg_delta[keys[key_idx]];
		}
		
		//and return the newly merged configuration
		return cfg;
	}
	
	static default_error_handler(err){
		if (!err) {
			return;
		}
		console.log(err)
		if(('response' in err) && (err.response!==undefined) && (err.response!==null)){
			if(err.response.status===401){
				clearCookies();
				window.localStorage.clear();
				window.location.href='/login';
			} else if (err.response.data && err.response.data.message) {
				error(`Request failed: ${err.response.data.message}`);
			} else {
				error('Sorry we are unable to process your request.');
			}
		} else {
			error('Sorry we are having trouble connecting to the servers.');
		}
	}
	
	static request(path,cfg_delta){
		cfg_delta['url']=path;
		return axios.request(API._merge_cfg(cfg_delta));
	}
	
	static get(path,cfg_delta={}){
		cfg_delta['method']='get';
		return API.request(path,cfg_delta);
	}
	static put(path,cfg_delta={}){
		cfg_delta['method']='put';
		return API.request(path,cfg_delta);
	}
	static post(path,cfg_delta={}){
		cfg_delta['method']='post';
		return API.request(path,cfg_delta);
	}
	static delete(path,cfg_delta={}){
		cfg_delta['method']='delete';
		return API.request(path,cfg_delta);
	}
}

export const usePostData = (url, dataToPost) => {
	let errorResponse = "";
	let data = {};
	let isLoading = true;
	const postData = async () => {
		try {
			const result = await API.post(url, {"data": dataToPost});
			data = result;
		} catch (error) {
			errorResponse = error;
			API.default_error_handler(error);
		}
		isLoading = false;
	};
	postData();
	return [{data, isLoading, errorResponse}];
}

export const usePutData = (url, dataToPut) => {
	let errorResponse = '';
	let data = {};
	let isLoading = true;
	const putData = async () => {
		try {
			const result = await API.put(url, { 'data': dataToPut })
			data = result;
		} catch (error) {
			errorResponse = error;
			API.default_error_handler(error);
		}
		isLoading = false;
	};
	putData();
	return [{data, isLoading, errorResponse}];
}

// Custom hook that will get data from url.
// Hook will only be called once when component mounts.
// To make fire again, just call again, with a new url.
// data: data returned by server if successful
// url: endpoint of get request
// isLoading: is request in progress.
// error: error if request is not successful
export const useGetData = (initialUrl, initialData = []) => {
	const [data, setData] = useState(initialData);
	const [url, setUrl] = useState(initialUrl);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState();
	useEffect(() => {
		const getData = async () => {
			setError();
			setIsLoading(true);
			try {
				const result = await API.get(url);
				setData(result.data);
			} catch (error) {
				setError(error);
				API.default_error_handler(error);
			}
			setIsLoading(false);
		};
		getData();
	}, [url]);
	return [{ data, isLoading, error }, setUrl];
};
