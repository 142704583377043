import styled, { css } from 'styled-components';

// Label: General component used to display an empty view or label.
// Props:
// all StyledBase props are available
// horizontalAlign: aligns text horizontally. values:left (default), center and right.
// verticalAlign: aligns text vertically, values: center (default), top, bottom.
export const StyledText = styled.p`
	${(props) => css`
		overflow-wrap: ${props.overflowWrap};
		float: ${props.float};
		width: ${props.width};
		height: ${props.height};
		min-width: ${props.minWidth};
		min-height: ${props.minHeight};
		color: ${props.color};
		cursor: ${props.cursor};
		background-color: ${props.backgroundColor};
		line-height: ${props.lineHeight};
		font-size: ${props.fontSize};
		font-weight: ${props.fontWeight};
		font-family: ${props.fontFamily};
		text-decoration: ${props.textDecorationLine};
		border-radius: ${props.borderRadius};
		border-width: ${props.borderWidth};
		border-color: ${props.borderColor};
		border-style: ${props.borderStyle};
		padding:${props.padding};
		padding-top: ${props.paddingTop};
		padding-right: ${props.paddingRight};
		padding-bottom: ${props.paddingBottom};
		padding-left: ${props.paddingLeft};
		margin:${props.margin};
		margin-top: ${props.marginTop};
		margin-right: ${props.marginRight};
		margin-bottom: ${props.marginBottom};
		margin-left: ${props.marginLeft};
		box-shadow: ${props.boxShadow};
		z-index: ${props.zIndex};
		text-align: ${props.horizontalAlign};
		font-style: ${props.fontStyle};
		text-transform: ${props.textTransform};
		white-space: ${props.whiteSpace};
		display:${props.display};
		justify-content: ${props.horizontalAlign === 'left'
			? 'flex-start'
			: props.horizontalAlign === 'right'
			? 'flex-end'
			: 'center'};

		align-items: ${props.verticalAlign === 'top'
			? 'flex-start'
			: props.verticalAlign === 'bottom'
			? 'flex-end'
			: 'center'};
		flex-grow: ${props.flexGrow};
		flex-shrink: ${props.flexShrink};
	`}
`;

StyledText.defaultProps = {
	display: 'flex',
	flexShrink: 1,
	whiteSpace:'pre-wrap',
	horizontalAlign: 'left',
	verticalAlign: 'center',
	margin:'0px',
};
