import React, {useEffect, useState, useContext} from 'react';
import styled from 'styled-components';
import {Color, FontSize, NAV_USERS, USER_UPDATE_PERM} from '../../constants';
import {API} from '../../projlibs/api.js';
import {StyledText} from '../Shared/StyledText';
import S3ImageController from '../Shared/S3ImageController';
import {Button} from '../Shared/Button';
import {StyledList} from '../Shared/StyledList';
import {StyledLink} from '../Shared/StyledLink';
import {StatusText} from '../Shared/StatusText';
import moment from 'moment';
import {useHistory} from "react-router-dom";
import {EditFormButton, DeleteButton} from "../Shared/OverlayViewButton";
import {CreateUserForm} from "./CreateUserForm";
import { success, error } from '../../projlibs/feedback';
import { DeleteConfirmation } from '../Shared/DeleteConfirmation';
import {StyledInitials} from "../Shared/StyledInitials";
import { DataContext } from '../../data-context';
import { StyledBase } from '../Shared/StyledBase';
import { Helmet } from 'react-helmet';

const UserDetailsView = styled.div`
	padding: 3rem 1.5rem;
	max-width: 1070px;
`;

const UserDetailsNavigationAndControls = styled.div`
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
`;

const UserDetailsControls = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
`;

const UserImage = styled(S3ImageController)`
	margin-right: 1rem;
	border-radius: 50%;
`;

const UserDetailsHeader = styled.div`
	padding: 2rem 0rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

const UserDetailsInformation = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${Color.white};
	border-radius: 4px;
	box-shadow: 0 1px 6px 0 ${Color.nile15};
	margin-bottom: 1rem;
`;

const InformationCell = styled.div`
	padding: 1.5rem;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	background-color: white;
	@media (min-width: 600px) {
		flex-flow: row nowrap;
	}
	${StyledBase}
`;

const LabelTextCell = styled.div`
	align-self: flex-start;
	padding: 0 0.5rem;
	@media (max-width: 600px) {
		padding: 0.5rem 0;
	}
`;

const LabelText = styled(StyledText)`
	color: ${Color.nile50};
	font-size: 13px;
`;

const InformationCellText = styled(StyledText)`
	color: ${Color.nile};
	font-size: 19px;
`;

const DetailsTopContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid ${Color.nile5};
	@media (min-width: 600px) {
		flex-direction: row;
	}
`;

export const UserDetails = (props) => {
	const { userPerms, userId } = useContext(DataContext);
	const history = useHistory();
	const [data, setData] = useState(({}));
	const [resetIsLoading, setResetIsLoading] = useState(false);
	let user = data?.User ? data.User[0] : undefined;

	useEffect(() => {
		API.get(`/user/${props.user_id}?show_invalid=true`).then(response => {
			setData(response.data);
		});
	}, [props.user_id]);

	const UpdateData = () => {
		API.get(`/user/${props.user_id}?show_invalid=true`).then(response => {
			setData(response.data);
		});
	};

	const SubmitResetEmail = () => {
        setResetIsLoading(true);
        API.post('/request-reset', { data: { email: user.email } }).then(response => {
			setResetIsLoading(false);
			success('Reset email sent.');
        }).catch(err => {
			setResetIsLoading(false);
			error('Reset email failed to send.');
        })
    };

	const DeleteUser = () => {
		API.put(`/user/${props.user_id}`, { data: { valid: false } }).then(() => {
			history.push(NAV_USERS);
			success('User successfully deleted.');
		}).catch(API.default_error_handler);
	}

	return (
		<UserDetailsView>
			<Helmet>
				<meta name="description" content={user?.full_name} />
			</Helmet>
			<UserDetailsNavigationAndControls>
				<StyledList
					display='flex'
					fontSize={FontSize.body1}
					listStyle='none'
					content='/'>
					<StyledLink to={NAV_USERS}>Users</StyledLink>
					<StyledText color={Color.nile50}>
						{user?.full_name}
					</StyledText>
				</StyledList>
				{
					userPerms[USER_UPDATE_PERM] &&
					<UserDetailsControls>
						<DeleteButton title='Delete user'>
							<DeleteConfirmation onDelete={DeleteUser} />
						</DeleteButton>
						<EditFormButton>
							<CreateUserForm user={user} update={UpdateData} isEditingOwn={user?.user_id===parseInt(userId)} />
						</EditFormButton>
					</UserDetailsControls>
				}
			</UserDetailsNavigationAndControls>
			<UserDetailsHeader>
				{user?.img_s3_path ?
					<UserImage
						height='80px'
						width='80px'
						s3_path={user?.img_s3_path}
					/>
					:
					<StyledInitials
						size='80px'
						marginTop='0'
						marginBottom='0'
						marginRight='1rem'
						fontSize={FontSize.header2}
						borderRadius='50%'
						backgroundColor={Color.zest}
						numberOfInitials={2}>
						{user?.full_name}
					</StyledInitials>
				}
				<StyledText
					as='h1'
					color={Color.nile}
					fontSize={FontSize.header2}>
					{user?.full_name}
				</StyledText>
			</UserDetailsHeader>
			<UserDetailsInformation>
				<DetailsTopContainer>
					<InformationCell justifyContent='space-between' width='calc(100% - 3rem)' borderRight={`1px solid ${Color.nile10}`}>
						<LabelTextCell>
							<LabelText>{user?.groups?.length > 1 ? 'Roles' : 'Role'}</LabelText>
							<InformationCellText textTransform='capitalize'>
								{user?.groups
									? user?.groups
											.map((r) => r.group_name)
											.join(', ')
									: 'No Role'}
							</InformationCellText>
						</LabelTextCell>
						<LabelTextCell>
							<LabelText>Email</LabelText>
							<InformationCellText>{user?.email}</InformationCellText>
						</LabelTextCell>
						<LabelTextCell>
							<LabelText>Date Joined</LabelText>

							<InformationCellText>
								{moment.unix(user?.created_at).format('YYYY/MM/DD')}
							</InformationCellText>
						</LabelTextCell>
					</InformationCell>
					<InformationCell minWidth='115px' width='fit-content' paddingLeft='0'>
						{user?.valid ? (
								<StatusText fontSize={FontSize.header5} height='12px' width='12px' statusColor={Color.meadow}>
									Active
								</StatusText>
							) : (
								<StatusText fontSize={FontSize.header5} height='12px' width='12px' left="6px" statusColor={Color.poppy}>
									Inactive
								</StatusText>
						)}
					</InformationCell>
				</DetailsTopContainer>
				<InformationCell width='fit-content'>
					{
						userPerms[USER_UPDATE_PERM] &&
						<Button
							onClick={() => SubmitResetEmail()}
							disabled={resetIsLoading}
							title={resetIsLoading ? 'Resetting...' : 'Reset Password'}
							borderRadius='3px'
							color={Color.nile}
							borderColor={Color.nile15}
							padding='1rem 0.75rem'
							hoverBackground={Color.nile10}
						/>
					}
				</InformationCell>
			</UserDetailsInformation>
		</UserDetailsView>
	);
};
