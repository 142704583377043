import React from 'react';
import styled from 'styled-components';
import {Color, FontSize, FontWeight} from '../../constants';
import {StyledText} from './StyledText';
import moment from 'moment';

// Metadata: Displays 2 StyledText, a title and it's value
// Props:
// title: title to display.
// value: value to display.
// type: type of value to display.  String, int, float, date, paragraph
const MetadataView = styled.div`
	padding-bottom: 1rem;
	.p-value {
		p, li {
			font-weight: ${FontWeight.normal};
		}
		> p:first-child {
			margin-top:0px;
		}
	}
`;

const Title = styled(StyledText)``;

const Value = styled(StyledText)``;

export const Metadata = (props) => {
	const type=props?.type;
	let value=props?.value;
	if(type==='bool'){
		value=(props.value==='true'?'Yes':'No');
	}
	else if(type==='multiselect' && value.length > 0){
		value = value.join(", ")
	}else if(type==='date'){
		value=value?moment.unix(props.value).utc().format('MMM. DD, YYYY'):'-';
	}else if(!value){
		value='-';
	}
	return (
		<MetadataView {...props}>
			<Title>{props.label}</Title>
			{(props?.type==='paragraph')?
				<div className='p-value' dangerouslySetInnerHTML={{ __html: (value) }} />
			:
				<Value>
					{value}
				</Value>
			}
		</MetadataView>
	);
};

Metadata.defaultProps = {};

Title.defaultProps = {
	color: Color.nile50,
	fontSize: FontSize.body2,
	marginBottom: '0.25rem'
};

Value.defaultProps = {
	color: Color.nile,
	fontSize: FontSize.body1
};
