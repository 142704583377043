import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { AdditionalFields} from '../Shared/CreateForm';
import { LocationFields} from './LocationFields';
import { breakpoint } from '../../breakpoints';

const Location = styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    ${breakpoint('small only')} {
		flex-direction: column;
	}
`;

export const LocationForm = (props) => {
    const [locationFields, setLocationFields] = useState([]);

    const addLocation = (e) => {
        e.preventDefault();
        props.setCounter(props.counter + 1);
    }

    const updateLocations = useCallback((data, index) => {
        let locations = [...props.locations];
        locations[index] = data;
        props.setLocations(locations);
    }, [props])

    const getLocation = useCallback((index) => {
        return props.locations[index] ? props.locations[index] : "";
    }, [props])  
    
    useEffect(() => {
        let fields = [];
        const counter = props.counter;
        for (let i = 0; i < counter; i++){
            fields.push(<LocationFields updateLocation={updateLocations} getLocation={getLocation} countries={props.countries} index={i} key={i} />);
        }
        setLocationFields(fields);
    },[updateLocations, getLocation, props])

    return (
        <Location>
            {locationFields}
            <AdditionalFields
                title='Add additional location +'
                paddingLeft='0px'
                onClick={(e) => addLocation(e)}
            />
        </Location>
    )
};