import React from 'react';
import {config} from "../../config";
import {Editor} from "@tinymce/tinymce-react";

export const TinyMCEEditor = (props) => (
    <Editor
        initialValue={props.initialValue}
        apiKey={config.tinyMCEKey}
        value={props.value ? props.value : ''}
        id={props.id ? props.id : 'input-content'}
        name={props.name ? props.name : 'content'}
        init={{
            plugins: 'link image media lists',
            height: props.height ? props.height : '256px',
            width: props.width ? props.width : '100%',
            placeholder: props.placeholder ? props.placeholder : 'Add a note...',
            toolbar:
                'undo redo | bold italic | numlist bullist | alignleft aligncenter alignright | code formatpainter',
        }}
        onEditorChange={props.onChange}
    />
)
