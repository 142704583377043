import React, {useContext, useState} from 'react';
import styled, {css} from 'styled-components';
import {CLIENT, Color, FontSize, NOTE_READ_PERM} from '../../constants';
import {DataContext} from '../../data-context';
import {StyledText} from '../Shared/StyledText';
import {Button} from '../Shared/Button';
import {Notes} from './Notes';
import {Logs} from './Logs';
import {Hours} from './Hours';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {breakpoint} from '../../breakpoints';

const NOTES = 'Notes';
const ACTIVITY_LOG = 'Activity Log';
const LOGGED_HOURS = 'Logged Hours';

// Projects: displays all projects in a list.  Has search and filter functionality
// Props:
// projects: array of projects.
const ActivityView = styled.div`
	${breakpoint('medium up')} {
		width: 704px;
		min-height: 100%;
	}
	min-width: 100%;
	background-color: ${Color.white};
	position: static;
`;

const MainView = styled.div`
	padding: 52px 0px;
	/* ${breakpoint('medium')} {
		padding: 105px 0px;
	} */
`;

const LogAndHoursView = styled.div`
	padding: 0px 26px;
	${breakpoint('medium')} {
		padding: 0px 56px;
	}
`;

const ButtonView = styled.div`
	border-bottom: 2px solid ${Color.nile10};
`;

const ActivityButton = styled(Button)`
	${(props) => css`
		color: ${props.selected ? Color.nile90 : Color.nile50};
		font-size: ${FontSize.body1};
		padding: 0px 24x 12px 16px;
		border-width: 0px;
		border-bottom: ${props.selected
			? '0.25rem solid' + Color.fuel
			: 'none'};
		border-radius: 0px;
		margin-bottom: -1px;
	`}
`;

const StyledLogs = styled(Logs)`
	height: calc(100vh - 142px);
`;

const StyledHours = styled(Hours)`
	height: calc(100vh - 142px);
`;

export const Activity = (props) => {
	const context = useContext(DataContext);
	const [currentTab, setCurrentTab] = useState(context.userPerms[NOTE_READ_PERM] ? NOTES : ACTIVITY_LOG);
	return (
		<ActivityView {...props}>
			<Button
				icon={faTimes}
				iconSize='3x'
				onClick={props.onClose}
				position='absolute'
				right='1.5rem'
				top='1.5rem'
			/>
			<MainView>
				<LogAndHoursView>
					<StyledText
						as='h1'
						color={Color.nile}
						fontSize={FontSize.header2}
						marginBottom='16px'>
						Activity
					</StyledText>
					<ButtonView>
						{
							context.userPerms[NOTE_READ_PERM] &&
							<ActivityButton
								title={NOTES}
								transitionTime='0s'
								selected={currentTab === NOTES}
								onClick={() =>
									setCurrentTab(NOTES)
								}></ActivityButton>
						}
						<ActivityButton
							title={ACTIVITY_LOG}
							transitionTime='0s'
							selected={currentTab === ACTIVITY_LOG}
							onClick={() =>
								setCurrentTab(ACTIVITY_LOG)
							}></ActivityButton>
						{props.type !== CLIENT &&
						<ActivityButton
							title={LOGGED_HOURS}
							transitionTime='0s'
							selected={currentTab === LOGGED_HOURS}
							onClick={() =>
								setCurrentTab(LOGGED_HOURS)
							}></ActivityButton>
						}
					</ButtonView>
					{(currentTab === ACTIVITY_LOG && (
						<StyledLogs name={props.name} type={props.type} id={props.type === CLIENT ? props.clientId : props.projectId}/>
					)) ||
						(currentTab === LOGGED_HOURS && <StyledHours projectId={props.projectId} />)}
				</LogAndHoursView>
				{currentTab === NOTES && (
					<Notes isOverlay={true} type={props.type} id={props.type === CLIENT ? props.clientId : props.projectId}/>
				)}
			</MainView>
		</ActivityView>
	);
};
