import React from 'react';
import styled, { css } from 'styled-components';
import Moment from 'moment';
import { FontSize, Color } from '../../constants';
import { StyledText } from './StyledText';
// FormattedDate: Displays and formats a date.
// Date can be in Date or unixTimeStamp format.
// Props:
// All StyledBase props are available.
// date: date to display.
// useTimeSinceFormat: display date in time passed since now format.  i.e. 30 minutes ago
// preText: add the preText string before the date
// postText: add the postText string after the date
export function date(props, format='MMM DD, YYYY') {
	//internal dates are UTC, so make sure we account for timezone differences
	let tzOffsetSeconds=((new Date()).getTimezoneOffset())*60.0;
	if(props.hasOwnProperty('date')){
		if(props.hasOwnProperty('convertUTC') && props.convertUTC===true){
			props.date+=tzOffsetSeconds;
		}
	}
	let momentDate = Number.isFinite(props.date)
		? Moment.unix(props.date)
		: Moment(props.date);
	return props.useTimeSinceFormat
		? momentDate.fromNow()
		: momentDate.format(format);
}
const FormattedDateView = styled(StyledText)`
	${props => css`
		display: flex;
		text-align: ${props.textAlign};
		flex-wrap: wrap;
		font-style: ${props.fontStyle};
		::before {
			content: ${props.preText};
		}
		::after {
			content: ${props.postText};
		}
	`}
`;

const Dash = styled.hr`
	width: 0.75rem;
	border: none;
	border-bottom: 1px solid ${Color.nile};
	margin: 0;
`;

export const FormattedDate = props => (
	<React.Fragment>
		{props.date ? <FormattedDateView {...props}>{props.preText} {props.format ? date(props, props.format) : date(props)} {(!props.useTimeSinceFormat && !props.hideTime) && date(props, 'h:mma')}</FormattedDateView> : <Dash/>}
	</React.Fragment>
);
FormattedDate.defaultProps = {
	className: 'FormattedDate',
	fontSize: FontSize.body2,
	postText: '',
	preText: ''
};
