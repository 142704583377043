import React from 'react';
import {ProjectStatusGroup} from '../Projects/ProjectStatusGroup';

// ProjectOverviewController: Controller for ProjectOverview
// Props:
// title: the title text to display for this group of projects (e.g. the status name)
// projects: the list of projects to display
// formatData: the function to format a raw object into the table view display version of the object
//
// State:
// isCollapsed: list of projects are either expanded or collapsed.
export class ProjectStatusGroupController extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			page: 0,
			orderBy: '',
			isCollapsed: props.isOpen ? false : true,
			isLoading: false,
		};
	}

	setPage = function (page) {
		this.setState({ page: page });
	}.bind(this);

	setOrderBy = function (orderBy) {
		this.setState({ orderBy: orderBy });
	}.bind(this);

	componentDidUpdate = function (prevProps, prevState) {
		if (prevProps.isOpen !== this.props.isOpen) {
			if (this.props.isOpen && this.props.projects.length === 0) {
				this.props.handleLoadMore(this.state.page, this.state.orderBy);
			}
			this.setState({ isCollapsed: !this.props.isOpen });
		}
		if (prevProps.groupExpand !== this.props.groupExpand) {
			if (this.props.groupExpand && this.props.projects.length === 0) {
				this.props.handleLoadMore(this.state.page, this.state.orderBy);
			}
			this.setState({ isCollapsed: !this.props.groupExpand });
		}
		if (prevState.orderBy !== this.state.orderBy) {
			this.props.handleLoadMore(this.state.page, this.state.orderBy);
		}
	};
	
	collapseToggle = function () {
		if (this.state.isCollapsed && this.props.projects.length === 0) {
			this.props.handleLoadMore(this.state.page, this.state.orderBy);
		}
		this.setState({
			isCollapsed:(!this.state.isCollapsed)
		});
	}.bind(this);

	render() {
		return (
			<ProjectStatusGroup
				page={this.state.page}
				setPage={this.setPage}
				statuses={this.props.statuses}
				orderBy={this.state.orderBy}
				setOrderBy={this.setOrderBy}
				handleProjectStatusChange={this.props.handleProjectStatusChange}
				handleLoadMore={this.props.handleLoadMore}
				resultCount={this.props.resultCount}
				projects={this.props.projects}
				projectType={this.props.projectType}
				title={this.props.title}
				isCollapsed={this.props.resultCount === 0 ? true : this.state.isCollapsed}
				collapseToggle={this.collapseToggle}
				highlightColor={this.props.highlightColor}
				isQuote={this.props.isQuote}
				quoteStatuses={this.props.quoteStatuses}
			/>
		);
	}
}

