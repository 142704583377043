import React from 'react';
import styled, {css} from 'styled-components';
import {Color} from '../../constants';

// Input: Used for general input.  Can also have left or right icon.  i.e search input.
// Props:
// 1-1 prop fields are width. height, value, placeholder, placeholderColor, fontSize, color, backgroundColor, borderRadius, borderWidth, borderColor, margins, box-shadow and onKeyPress
// icon: fontAwesomeIcon that will displayed  It can be left or right aligned.
// iconIsLeft: is icon on the left side of the or right side.
// NOTE: input-specific properties such as required, disabled, onChange, and onKeyUp will be honored in this component
// since those properties are passed directly to the <input> tag within this component
const Style = styled.div`
	${props => css`
		margin-right: ${props.marginRight};
		padding-top: ${props.paddingTop ? props.paddingTop : '1rem'};
		padding-bottom: ${props.paddingRight ? props.paddingRight : '1rem'};
		&:first-child {
			padding-top: 0;
		}
		&:last-child {
			padding-bottom: 0;
		}
		input[type='checkbox'] {
			/* For some reason font size controls the size of a checkbox. */
			font-size: 1rem;
			margin: 0;
			padding: 0;
		}

		label {
			font-size: 1rem;
			color: ${Color.nile};
			margin-left: 0.5rem;
		}
	`}
`;

export const StyledCheckbox = props => (
	<Style {...props}>
		<input type='checkbox' id={props.checkboxId} name={props.id} onChange={props.onChange} checked={props.checked} disabled={props.disabled} />
		<label htmlFor={props.checkboxId}>{props.label}</label>
	</Style>
);

StyledCheckbox.defaultProps = {
	fontSize: '1rem',
	borderRadius: '0.25rem',
	borderWidth: '0.0625rem',
	borderStyle: 'solid',
	width: 'auto',
};
