import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../breakpoints';
import { Overlay, HeaderExitInline } from '../Shared/CreateForm';
import { StyledText } from '../Shared/StyledText';
import { Color, FontWeight } from '../../constants';
import { FlatPrimaryButton, TransparentButton } from './Button';

const ConfirmationOverlay = styled(Overlay)`
	height: calc(100% - 6rem);
	@media (min-height: 500px) {
		height: fit-content;
    }
    ${breakpoint('medium up')} {
        width: 300px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const DeleteButton = styled(FlatPrimaryButton)`
    background-color: ${Color.coral};
    width: 45%;
`;

const CancelButton = styled(TransparentButton)`
    width: 45%;
`;

const DialogText = styled(StyledText)`
    padding-left: 5px;
    padding-bottom: 1rem;
    font-weight: ${FontWeight.normal};
`;

export const DeleteConfirmation = (props) => (
    <ConfirmationOverlay>
        <HeaderExitInline onClose={props.onClose} title='Delete Confirmation'/>
        <DialogText>
            If this was the action you wanted to do, please click 'Delete', otherwise cancel and return to the page.
        </DialogText>
        <ButtonContainer>
            <CancelButton title='Cancel' onClick={props.onClose} />
            <DeleteButton hoverBackground={Color.poppy} title='Delete' onClick={() =>{
                props.onDelete();
                props.onClose();
            }} />
        </ButtonContainer>
    </ConfirmationOverlay>
);
