import React, {useContext, useEffect, useState} from 'react';
import {CreateForm, FormInput, Header, Overlay, SubHeading, SubmitButton} from '../Shared/CreateForm';
import {CompanyContactForm} from './ContactForm.jsx';
import {LocationForm} from './LocationForm.jsx';
import {UserSelect} from '../Shared/UserSelect';
import {useGetData} from '../../projlibs/api.js';
import {DropdownWithText} from '../Shared/DropDownWithText';
import {error, success} from '../../projlibs/feedback.js';
import {API, usePutData} from "../../projlibs/api";
import {DataContext} from "../../data-context";
import {config} from "../../config";
import {ErrorsView, ErrorText} from "../Overview/AddAlertForm";
import {NAV_CLIENTS} from "../../constants";
import {useHistory} from "react-router-dom";

const INACTIVE = 'inactive';

export const CreateClientForm = (props) => {
    const history = useHistory();
    const context = useContext(DataContext);
    const mode = props.client === undefined ? 'create' : 'edit';
    const client = props.client;
    const client_id = client?.client_id;
    const [contactsVisible, setContactsVisible] = useState(true);
    const [locationVisible, setLocationVisible] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [locations, setLocations] = useState([]);
    const [companyName, setCompanyName] = useState(client ? client.name : "");
    const [clientTier, setClientTier] = useState(client ? client.client_tier : "");
    const [clientStatus, setClientStatus] = useState(client ? client.Client_Status?.name : "");
    const [website, setWebsite] = useState(client && client.website ? client.website : "");
    const [source, setSource] = useState(client && client.source ? client.source : "");
    const [address, setAddress] = useState(client && client.Primary_Location?.address ? client.Primary_Location.address : "");
    const [suite, setSuite] = useState(client && client.Primary_Location?.subaddress ? client.Primary_Location.subaddress : "");
    const [city, setCity] = useState(client && client.Primary_Location?.city ? client.Primary_Location.city : "");
    const [state, setState] = useState(client && client.Primary_Location?.state_prov ? client.Primary_Location.state_prov : "");
    const [zip, setZip] = useState(client && client.Primary_Location?.postal_code ? client.Primary_Location.postal_code : "");
    const [country, setCountry] = useState(client && client.Primary_Location?.country ? client.Primary_Location.country : "");
    const [accountRep, setAccountRep] = useState(client ? client.Account_Rep?.user_id.toString() : "");
    const [selectedServices, ] = useState(client && client.client_services ? client.client_services.map(client_service => client_service.Service) : []);
    const [locationCounter, setLocationCounter] = useState(1);
    const [contactCounter, setContactCounter] = useState(1);
    const [errors, setErrors] = useState([]);
    const [{ data: statuses }] = useGetData("/client/status");
    const [{ data: tiers }] = useGetData("/client/tier");
    //NOTE: locations with other countries can exist in the database but must be manually entered
    //the dropdown here is intentionally restricted to "Canada", "US", and "UK"
    const countries = ["Canada","US","UK"]; // const [{ data: countries }] = useGetData("/client/country");
    const [{ data: contactStatuses }] = useGetData("/contact/status");
    const [{ data: userData }] = useGetData("/user?page_size=");
    const users = userData.User?.map(user => { return {"name": user.full_name, "photo": user.img_s3_path, value: user.user_id.toString(), "colour": user.colour}});

    useEffect(() => {
        setContactCounter(contacts.length > 0 ? contacts.length : 1);
    }, [contacts]);

    useEffect(() => {
        setLocationCounter(locations.length > 0 ? locations.length : 1);
    }, [locations]);

    useEffect(() => {
        if(client){
            API.get(`/client/${client.client_id}/contact`).then(response => {
                const secondaryContacts = response.data.Contact ? response.data.Contact.filter(contact => contact.contact_id !== client.Primary_Contact.contact_id).map(contact => {
                    return {
                        "name": contact.name,
                        "phone": contact.phone,
                        "email": contact.email,
                        "status": contact.status,
                        "title": contact.title,
                        "contact_id": contact.contact_id
                    }
                }) : [];
                const primContact = client.Primary_Contact ? {
                    "name": client.Primary_Contact?.name,
                    "phone": client.Primary_Contact?.phone,
                    "email": client.Primary_Contact?.email,
                    "status": client.Primary_Contact?.status,
                    "title": client.Primary_Contact?.title,
                    "contact_id": client.Primary_Contact?.contact_id
                } : undefined;
                const totalContacts = primContact ? [primContact, ...secondaryContacts] : secondaryContacts;
                setContacts(totalContacts);
            }).catch(API.default_error_handler);

            API.get(`/client/${client.client_id}/location`).then(response => {
                setLocations(response.data.Location ? response.data.Location.filter(location => location.location_id !== client.Primary_Location?.location_id).map(location => {
                    return {
                        "address": location?.address,
                        "subaddress": location?.subaddress,
                        "city": location?.city,
                        "state_prov": location?.state_prov,
                        "country": location?.country,
                        "postal_code": location?.postal_code,
                        "location_id": location?.location_id
                    }
                }) : []);
            }).catch(API.default_error_handler);
        }
    }, [client]);

    const toggleContactsView = (e) => {
        e.preventDefault();
        setContactsVisible(!contactsVisible);
    }

    const toggleLocationsView = (e) => {
        e.preventDefault();
        setLocationVisible(!locationVisible);
    }

    const SubmitForm = (e) => {
        e.preventDefault();
        setErrors([]);
        let hasError = false;

        if(clientTier === ""){
            hasError = true;
            setErrors(prev => [...prev, 'Select a Client Tier.']);
        }

        if(clientStatus === ""){
            hasError = true;
            setErrors(prev => [...prev, 'Select a Client Status.']);
        }

        if(accountRep === ""){
            hasError = true;
            setErrors(prev => [...prev, 'Select an Account Rep.']);
        }

        if(hasError){
            return;
        }

        const primaryLocation = {
            "address": address,
            "subaddress": suite,
            "city": city,
            "state_prov": state,
            "country": country,
            "postal_code": zip
        };

        if(mode === 'edit' && client.Primary_Location){
            primaryLocation.location_id = client.Primary_Location.location_id;
        }

        const allLocations = [primaryLocation, ...locations];
        const newClient = {
            "name": companyName,
            "client_tier": clientTier,
            "client_status": clientStatus,
            "website": website,
            "source": source,
            "account_rep_id": parseInt(accountRep),
            "contacts": contacts,
            "locations": allLocations,
            "services": selectedServices.map(service => service.service_id),
        };

        if(mode === 'edit'){
            EditClient(newClient);
        }
        else{
            CreateClient(newClient);
        }
        props.onClose();
        if (props.closeGlobalAdd) {
            props.closeGlobalAdd();
        }
    }

    const EditClient = (client) => {
        const newContactPromises = client.contacts.map((newContact, index) => {
            if (newContact.contact_id) {
                if(newContact.image !== undefined){
                    const id = newContact.contact_id
                    const path = 'contact/' + id + '/' + newContact.image.name;
                    context.aws.uploadFile(config.bucket_name, path, newContact.image, error => {
                    }, success => {
                        // if the file is large and has to upload in parts then large aws saves it in the variable Key instead of key
                        let key = success.Key ? success.Key : success.key;
                        SavePath(key, id, client_id);
                    });
                }
                return newContact;
            }
            newContact.is_primary = index === 0;
            return API.post(`/client/${client_id}/contact`, {data: newContact}).then(response => ({
                ...newContact,
                contact_id: response.data.Contact.contact_id,
            }));
        });
        const newLocationPromises = client.locations.map((newLocation, index) => {
            if (newLocation.location_id) {
                return newLocation;
            }
            newLocation.is_primary = index === 0;
            return API.post(`/client/${client_id}/location`, {data: newLocation}).then(response => ({
                ...newLocation,
                location_id: response.data.Location.location_id,
            }));
        });
        Promise.all(newContactPromises).then(newContacts => {
            Promise.all(newLocationPromises).then(newLocations => {
                client.contacts = newContacts;
                client.locations = newLocations;
                API.put(`/client/${client_id}`, {"data": client}).then(() => {
                    props.update();
                    success('Client successfully edited.');
                }).catch(API.default_error_handler);
            }).catch(() => error('Error while creating new locations.'));
        }).catch(() => error('Error while creating new contacts.'));
    }

    const CreateClient = (client) => {
        API.post("/client", {data: client}).then(response => {
            success('Client successfully created.')
            const client_id = response.data.Client.client_id
            API.get(`/client/${client_id}/contact`).then(response => {
                contacts.forEach(contact => {
                    if(contact.image){
                        const match = response.data.Contact.find(serverContact => contact.email === serverContact.email);
                        const id = match.contact_id;
                        const path = 'contact/' + id + '/' + contact.image.name;
                        context.aws.uploadFile(config.bucket_name, path, contact.image, API.default_error_handler, success => {
                            // if the file is large and has to upload in parts then large aws saves it in the variable Key instead of key
                            let key = success.Key ? success.Key : success.key;
                            SavePath(key, id, client_id);
                        });
                    }
                })
            }).catch(API.default_error_handler);
            history.push(
                NAV_CLIENTS + '/' + response.data.Client.client_id
            );
        }).catch(API.default_error_handler);
    }

    const SavePath = (key, contact_id, client_id) => {
        usePutData(`client/${client_id}/contact/${contact_id}`, {"img_s3_path": key});
    };


    const handleSelectTier = (value) => {
        if(typeof value === 'string'){
            setClientTier(value);
        }
    }

    const handleSelectCountry = (value) => {
        if(typeof value === 'string'){
            setCountry(value);
        }
    }

    const handleSelectStatus = (value) => {
        if(typeof value === 'string'){
            setClientStatus(value);
        }
    }

    return (
        <Overlay>
            <Header onClose={props.onClose} title={mode === 'edit' ? 'Edit a client' : 'Create a Client'}></Header>
            <CreateForm onSubmit={SubmitForm}>
                <FormInput
                    label='Company Name*'
                    inputId='companyName'
                    placeholder='...'
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                />
                <UserSelect 
                    title="Account Rep*"
                    options={users ? users : []} 
                    flex='0 0 49%'
                    value={accountRep}
                    onChange={setAccountRep}
                />   
                 <DropdownWithText
                    label="Client Tiers*" 
                    data={tiers.filter(tier => tier !== "")}
                    flex = '0 0 49%'
                    width='100%'
                    placeholder="-select-"
                    defaultValue={clientTier}
                    onChange={(value) => handleSelectTier(value)}
                />
                <DropdownWithText
                    label="Client Status*" 
                    data={mode === 'edit' ? statuses.filter(status => status !== "") : statuses.filter(status => status !== "" && status !== INACTIVE)}
                    flex = '0 0 49%'
                    width='100%'
                    placeholder="-select-"
                    defaultValue={clientStatus}
                    onChange={(value) => handleSelectStatus(value)} 
                />
                <FormInput
                    label='Website'
                    inputId='website'
                    flex = ' 0 0 49%'
                    placeholder='...'
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                />
                <FormInput
                    label='Source'
                    inputId='source'
                    flex = ' 0 0 49%'
                    placeholder='...'
                    value={source}
                    onChange={(e) => setSource(e.target.value)}
                />
                <FormInput
                    label='Address'
                    inputId='address'
                    flex = ' 0 0 84%'
                    placeholder='...'
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
                <FormInput
                    label='Suite, Apt, etc'
                    inputId='suite'
                    flex = '0 0 14%'
                    placeholder='...'
                    value={suite}
                    onChange={(e) => setSuite(e.target.value)}
                />
                <FormInput
                    label='City'
                    inputId='city'
                    flex = '0 0 43%'
                    placeholder='...'
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                />
                <FormInput
                    label='State / Province'
                    inputId='state'
                    flex = '0 0 38%'
                    placeholder='...'
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                />
                <FormInput
                    label='ZIP / Postal Code'
                    inputId='zip'
                    flex = '0 0 15%'
                    placeholder='...'
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                />
                <DropdownWithText
                    label="Country"
                    data={countries.filter(country => country !== "")}
                    flex = '0 0 30%'
                    width='100%'
                    placeholder="-select-"
                    defaultValue={country}
                    onChange={(value) => handleSelectCountry(value)} 
                />
                <SubHeading
                    title='Company Contact(s)'
                    unicodeIcon = {contactsVisible ? '\f0d8' : '\f0d7'}
                    justifyContent='flex-start'
                    onClick={(e) => toggleContactsView(e)}
				/>
                {contactsVisible &&
                    <CompanyContactForm setContacts={setContacts} statuses={contactStatuses} counter={contactCounter} setCounter={setContactCounter} contacts={contacts} /> }
                <SubHeading
                    title='Location'
                    unicodeIcon = {locationVisible ? '\f0d8' : '\f0d7'}
                    justifyContent='flex-start'
                    onClick={(e) => toggleLocationsView(e)}
				/>
                {locationVisible && 
                    <LocationForm setLocations={setLocations} counter={locationCounter} setCounter={setLocationCounter} locations={locations} countries={countries}/> }
                <ErrorsView>
                    {
                        errors.map(error => <ErrorText key={error}>{error}</ErrorText>)
                    }
                </ErrorsView>
                <SubmitButton title={mode === 'edit' ? 'Edit client' : 'Create Client'} type="submit" />
            </CreateForm>
        </Overlay>
    );
}
