import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import {Color, FontSize, PROJECT_CREATE_PERM, CLIENT_CREATE_PERM} from '../../constants';
import {faArrowLeft, faBuilding, faClock, faFolder, faMoneyCheck, faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {breakpoint} from '../../breakpoints';
import {StyledBase} from "../Shared/StyledBase";
import {Button} from "../Shared/Button";
import {StyledText} from "../Shared/StyledText";
import {GlobalAddButton} from "../Shared/OverlayViewButton";
import {CreateProjectsForm} from "../Projects/CreateProjectsForm";
import {CreateClientForm} from "../Clients/CreateClientForm";
import {AddAlertForm} from '../Overview/AddAlertForm';
import { DataContext } from '../../data-context';

const GlobalAddView = styled.div`
	${StyledBase}
	min-width: 0;
	min-height: 0;
	width: 100%;
	height: 100%;
	text-align: left;
	background-color: ${Color.white};
	position: relative;
	${breakpoint('medium up')} {
		min-height: 100vh;
		width: 400px;
		height: unset;
	}
`;

const MainView = styled.div`
	${StyledBase}
	height: calc(100vh - 1rem);
    ${props => css`
        padding: ${props.location === 'right' ? '2rem' : '2rem 2rem 2rem 5.5rem'};
    `}
`;

export const GlobalAdd = ({ onClose, location = 'left' }) => {
    const { userPerms } = useContext(DataContext);
    return (
        <GlobalAddView className='add-modal'>
            <Button
                zIndex={2}
                icon={location === 'right' ? faArrowRight : faArrowLeft}
                iconSize='2x'
                onClick={onClose}
                borderStyle='none'
                position='absolute'
                paddingTop='0.5rem'
                paddingRight='0.5rem'
                paddingLeft='0.5rem'
                paddingBottom='0.5rem'
                left={location === 'right' ? 'unset' : '1rem'}
                right={location === 'right' ? '1rem' : 'unset'}
                top='2rem'
                backgroundColor='transparent'
                iconColor={Color.nile}
            />
            <StyledText marginLeft={location === 'right' ? '2rem' : '88px'} paddingTop='32px' fontSize={FontSize.header2}>Create</StyledText>
            <MainView location={location}>
                <StyledText fontSize={FontSize.body2} color={Color.nile50} marginBottom='8px'>What do you want to create?</StyledText>
                {
                    userPerms[PROJECT_CREATE_PERM] &&
                    <>
                        <GlobalAddButton iconXOffset='20px' title="Project" icon={faFolder}>
                            <CreateProjectsForm closeGlobalAdd={onClose} />
                        </GlobalAddButton>
                        <GlobalAddButton title="Quote" icon={faMoneyCheck}>
                            <CreateProjectsForm closeGlobalAdd={onClose} isQuote={true} />
                        </GlobalAddButton>
                    </>
                }
                {
                    userPerms[CLIENT_CREATE_PERM] &&
                    <GlobalAddButton iconXOffset='22px' title="Client" icon={faBuilding}>
                        <CreateClientForm closeGlobalAdd={onClose} />
                    </GlobalAddButton>
                }
                <GlobalAddButton iconXOffset='20px' title="Alert" icon={faClock}>
                    <AddAlertForm closeGlobalAdd={onClose} />
                </GlobalAddButton>
            </MainView>
        </GlobalAddView>
    );
};
