import React from 'react';
import styled from 'styled-components';
import { Color, FontSize } from '../../constants';
import { StyledText } from '../Shared/StyledText';
import { TransparentButton, FlatPrimaryButton } from '../Shared/Button';

const StyledTitle = styled(StyledText)`
    color: ${Color.nile90};
    font-size: ${FontSize.header5};
`;

const ReportHeaderView = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    flex-direction: row;
`;

const GenerateButtons = styled.div`
    display: flex;
    flex-direction: row;
`;

const GenerateReportButton = styled(FlatPrimaryButton)`
    margin: 0 0 0 1rem;
`;

export const ReportHeader = (props) => (
    <ReportHeaderView>
        <StyledTitle>Customize your report</StyledTitle>
        <GenerateButtons>
            <TransparentButton disabled={props.csvDisabled} onClick={props.csvOnClick} title='Get as CSV'/>
            <GenerateReportButton disabled={props.generateDisabled} onClick={props.generateOnClick} margin='0 0 0 1rem' title='Generate Report'/>
        </GenerateButtons>
    </ReportHeaderView>
);
