import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoint } from '../../breakpoints';
import { Color, FontSize } from '../../constants';
import { StyledBase } from '../Shared/StyledBase';
import { StyledText } from '../Shared/StyledText';

// ProjectStatusBubble: Extends div to display the projects title and status number.
// text, color and backgroundColor are dependent on the project status number passed in.
// Props:
// title: text to displayed
// value: value to be displayed in RoundedNumber
// backgroundColor: backgroundColor for RoundedNumber
const ProjectStatusButtonView = styled.div`
	${(props) => css`
		${StyledBase}
		font-size: ${FontSize.body2};
		background-color: ${props.value > 0 ? Color.silver : Color.white};
		color: ${props.value > 0 ? Color.nile : Color.regent};
		pointer-events: ${props.value > 0 ? 'auto' : 'none'};
		border-color: ${props.value > 0 ? 'transparent' : Color.porcelain};

		${breakpoint('small only')} {
			flex-direction: column;
			align-items: center;
			padding-top: 0.25rem;
		}
	`}
`;

ProjectStatusButtonView.defaultProps = {
	display: 'flex',
	alignItems: 'center',
	borderRadius: '20px',
	borderStyle: 'solid',
	borderWidth: '1px',
	padding: '8px',
	margin: '4px',
	borderColor: 'red',
	cursor: 'pointer',
	hoverFilter: 'invert(0.1)',
};

// Component used to display project status number.
const RoundedNumber = styled(StyledText)`
	${(props) => css`
		background-color: ${props.value > 0
			? props.backgroundColor
			: Color.silver};
		color: ${props.value === 0 ? 'black' : 'white'};

		${breakpoint('small only')} {
			margin-top: 4px;
		}
		${breakpoint('medium')} {
			margin-left: 20px;
		}
	`}
`;

RoundedNumber.defaultProps = {
	fontSize: FontSize.body2,
	borderRadius: '8px',
	padding: '0px 5px',
	margins: '0px',
};

export const ProjectStatusButton = (props) => (
	<ProjectStatusButtonView {...props}>
		{props.title}
		<RoundedNumber
			value={props.value}
			backgroundColor={props.backgroundColor}
			horizontalAlign='center'>
			{props.value > 0 ? props.value : '-'}
		</RoundedNumber>
	</ProjectStatusButtonView>
);
