import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {CLIENT, CLIENT_UPDATE_PERM, Color, FontSize, NAV_CLIENTS, VIEW_CLIENT_DELETE} from '../../constants';
import {StyledText} from '../Shared/StyledText';
import {Button} from '../Shared/Button';
import {ActivityViewButton, DeleteButton, EditFormButton} from '../Shared/OverlayViewButton';
import {ClientDetailsHeader} from './ClientDetailsHeader';
import {Activity} from '../Activity/Activity';
import {StyledLink} from '../Shared/StyledLink';
import {StyledList} from '../Shared/StyledList';
import {Projects} from '../Projects/Projects';
import {Quotes} from '../Quotes/Quotes';
import {Locations} from './Locations';
import {Contacts} from './Contacts';
import {API} from "../../projlibs/api";
import {useHistory} from "react-router-dom";
import {CreateClientForm} from "./CreateClientForm";
import {success} from '../../projlibs/feedback';
import {DeleteConfirmation} from '../Shared/DeleteConfirmation';
import {DataContext} from '../../data-context';
import {Services} from "./Services";
import {Helmet} from 'react-helmet';

const PROJECTS = 'Projects';
const QUOTES = 'Quotes';
const CONTACTS = 'Contacts';
const LOCATIONS = 'Locations';
const WEB = 'Web Services';
const ARCHIVE = 'Archive';
const REJECTED = 'Rejected Quotes';

// ClientDetails: display client details
// Props:
// client_id: client_id of selected client.
const ClientDetailsView = styled.div``;

const MainView = styled.div``;

const ClientDetailsNavigationAndControls = styled.div`
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: flex-start;
	padding: 2rem 0 0.5rem 1.5rem;
	@media (min-width: 480px) {
		padding: 2rem 0 2rem 1.5rem;
		justify-content: space-between;
		flex-flow: row nowrap;
	}
`;

const ClientDetailsControls = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	padding: 1.5rem 0;
	@media (min-width: 480px) {
		padding: 0;
		width: fit-content;
	}
`;

const ClientDetailsEditButtons = styled.div`
	display: flex;
	flex-direction: column;
	@media (min-width: 480px) {
		flex-direction: row;
	}
`;

const ClientButtonsContainer = styled.div`
	display: flex;
	flex-wrap: nowrap;
	width: calc(100vw - 44px);
	background-color: ${Color.nile5};
	border-radius: 22px;
	padding: 2px;
	margin: 16px auto;
	overflow-y: auto;
	@media (min-width: 520px) {
		width: fit-content;
	}
`;

const ClientButton = styled(Button)``;

ClientButton.defaultProps = {
	backgroundColor: 'transparent',
	fontSize: '14px',
	color: Color.nile,
	borderRadius: '20px',
	padding: '12px 16px',
	selectedBackgroundColor: Color.white,
	hoverBoxShadow: '0 1px 4px ' + Color.nile18,
};

const ResponsiveStyledList = styled(StyledList)`
	font-size: ${FontSize.body1};
	display: flex;
	align-self: flex-start;
	@media (min-width: 480px) {
		align-self: inherit;
	}
`;

const ResponsiveDeleteButton = styled(DeleteButton)`
	border-width: 0;
	width: fit-content;
	padding: 1rem 0;
	@media (min-width: 480px) {
		padding: 4px 16px;
	}
`;

export const ClientDetails = (props) => {
	const { userPerms } = useContext(DataContext);
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState(PROJECTS);
	const [data, setData] = useState({});
	const [notes, setNotes] = useState([]);
	let client = data?.Client ? data.Client[0] : undefined;

	useEffect(() => {
		API.get(`/client/${props.client_id}`).then(response => {
			setData(response.data);
			switch(window.location.hash){
				case '#projects':
					setCurrentTab(PROJECTS);
					break;
				case '#quotes':
					setCurrentTab(QUOTES);
					break;
				case '#rejected':
					setCurrentTab(REJECTED);
					break;
				case '#contacts':
					setCurrentTab(CONTACTS);
					break;
				case '#locations':
					setCurrentTab(LOCATIONS);
					break;
				case '#web-services':
					setCurrentTab(WEB);
				case '#archive':
					setCurrentTab(ARCHIVE);
					break;
				default:
					break;
			}
		});
	}, [props.client_id]);

	useEffect(() => {
		API.get('client/'+props.client_id+'/note').then(response => {
			const notes = response.data?.Note ? response.data.Note : [];
			setNotes(notes);
		});
	}, [props.client_id,setNotes]);

	const UpdateData = () => {
		API.get(`/client/${props.client_id}`).then(response => {
			setData(response.data);
		});
	};

	const DeleteClient = () => {
		API.put(`/client/${props.client_id}`, { data: { valid: false } }).then(() => {
			history.push(NAV_CLIENTS);
			success('Client successfully deleted.');
		}).catch(API.default_error_handler);
	}

	const SetPrimaryContact = (contact_id) => {
		API.put(`/client/${props.client_id}`, { data: { primary_contact_id: contact_id } }).then(() => {
			success('Contact successfully updated.');
			UpdateData();
		}).catch(API.default_error_handler);
	}

	const removeContact = (contact_id) => {
		API.put(`/client/${props.client_id}/contact/${contact_id}`, { data: { valid: false } }).then(() => {
			success('Contact successfully deleted.');
			UpdateData();
		}).catch(API.default_error_handler);
	}

	const SetPrimaryLocation = (location_id) => {
		API.put(`/client/${props.client_id}`, { data: { primary_location_id: location_id } }).then(() => {
			success('Location successfully updated.');
			UpdateData();
		}).catch(API.default_error_handler);
	}
	
	return (
		<ClientDetailsView>
			<Helmet>
				{
					client &&
					<title>{`${client?.client_id} | ${client?.name}`}</title>
				}
				<meta name="description" content={client?.name} />
			</Helmet>
			<ClientDetailsNavigationAndControls>
				<ResponsiveStyledList
					alignSelf='start'
					display='flex'
					listStyle='none'
					content='/'>
					<StyledLink to={NAV_CLIENTS}>Clients</StyledLink>

					<StyledText color={Color.nile50}>{client?.name}</StyledText>
				</ResponsiveStyledList>
				<ClientDetailsControls>
					{
						userPerms[CLIENT_UPDATE_PERM] &&
						<ClientDetailsEditButtons>
							{
								userPerms[VIEW_CLIENT_DELETE] &&
								<ResponsiveDeleteButton title='Delete client'>
									<DeleteConfirmation onDelete={DeleteClient} />
								</ResponsiveDeleteButton>
							}
							<EditFormButton>
								<CreateClientForm client={client} update={UpdateData}/>
							</EditFormButton>
						</ClientDetailsEditButtons>
					}
					<ActivityViewButton notes={notes}>
						<Activity clientId={client?.client_id} name={client?.name}type={CLIENT}/>
					</ActivityViewButton>
				</ClientDetailsControls>
			</ClientDetailsNavigationAndControls>
			<MainView>
				<ClientDetailsHeader client={client} switchToContacts={e => setCurrentTab(CONTACTS)}/>
				<ClientButtonsContainer>
					<ClientButton
						title={PROJECTS}
						selected={currentTab === PROJECTS}
						onClick={() => setCurrentTab(PROJECTS)}
					/>
					<ClientButton
						title={QUOTES}
						selected={currentTab === QUOTES}
						onClick={() => setCurrentTab(QUOTES)}
					/>
					<ClientButton
						title={REJECTED}
						selected={currentTab === REJECTED}
						onClick={() => setCurrentTab(REJECTED)}
					/>
					<ClientButton
						title={CONTACTS}
						selected={currentTab === CONTACTS}
						onClick={() => setCurrentTab(CONTACTS)}
					/>
					<ClientButton
						title={LOCATIONS}
						selected={currentTab === LOCATIONS}
						onClick={() => setCurrentTab(LOCATIONS)}
					/>
		                        <ClientButton
						title={WEB}
						selected={currentTab === WEB}
						onClick={() => setCurrentTab(WEB)}
					/>
					<ClientButton
						title={ARCHIVE}
						selected={currentTab === ARCHIVE}
						onClick={() => setCurrentTab(ARCHIVE)}
					/>
				</ClientButtonsContainer>
				{currentTab === ARCHIVE && <Projects {...props} allowingCopy={true} archive />}
				{currentTab === REJECTED && <Quotes {...props} rejected />}
				{currentTab === PROJECTS && <Projects {...props} />}
		  		{currentTab === WEB && <Services clientId={client.client_id} />}
				{currentTab === QUOTES && <Quotes {...props} />}
				{currentTab === LOCATIONS && <Locations {...props} client={client} SetPrimaryLocation={SetPrimaryLocation}/>}
				{currentTab === CONTACTS && <Contacts {...props} client={client} SetPrimaryContact={SetPrimaryContact} removeContact={removeContact}/>}
			</MainView>
		</ClientDetailsView>
	);
};

ClientDetails.defaultProps = {
	className: 'ClientDetails',
};
