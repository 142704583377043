import React from 'react';
import styled, {css} from 'styled-components';
import { Color, FontSize } from '../../constants';
import { StyledText } from '../Shared/StyledText';
import { Metadata } from './Metadata';
import { Image } from './Image';
import nothingHereYet from '../../assets/nothing-here-yet.jpeg';

// MetaList: display list of MetaData
// Props:
// metadataList: array of metadata to display.

const MetadataListView = styled.div`
	${props => css`
		width: 100%;
		min-width: fit-content;
		min-height: 21.4375rem;
		border-top: 0.25rem solid ${Color.fuel};
		border-radius: 3px 3px 0 0;
		background-color:${Color.white};
		box-shadow: 0rem 0.0625rem 0.375rem ${Color.nile15};
		margin-top: 1rem;
		margin-right: 1rem;
		box-sizing:border-box;
	`}
`;


const HeaderView = styled.div`
	height: 4.25rem;
	border-bottom: 0.0625rem solid ${Color.nile10};
	${StyledText} {
		font-size: ${FontSize.header5};
		color: ${Color.nile};
		padding: 1.75rem 1.5rem 1rem;
	}
`;
const NoDataView  = styled.div`
	text-align:center;
	height:100%;
`;

const ListView = styled.div`
	height:100%;
	padding: 1.5rem 1.5rem 0.5rem;
`;

export const MetadataList = props => (
	<MetadataListView {...props}>
		<HeaderView>
			<StyledText>{props.meta_name}</StyledText>
		</HeaderView>
		<ListView>
			{props.meta_value.length===0
			?
				<NoDataView>
					<Image
						src={nothingHereYet}
					/>
				</NoDataView>
			:
				props.meta_value.map(metadata => (
					<Metadata {...metadata} key={metadata.label} />
				))
			}
		</ListView>
		
	</MetadataListView>
);
