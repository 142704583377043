import styled, { css } from 'styled-components';
import { Color } from '../../constants';
import { StyledText } from './StyledText';

// TierText: General component used to display a tier text component
// Props:
function getTierColor(tier) {
	switch (tier) {
		case 'Everyday':
			return Color.banana;
		case 'VIP':
			return Color.mantis;
		default:
			return Color.silver;
	}
}

export const TierText = styled(StyledText)`
	${(props) => css`
		width: fit-content;
		background-color: ${getTierColor(props.children)};
	`}
`;

TierText.defaultProps = {
	borderRadius:'4px',
	paddingTop: '5px',
	paddingBottom: '5px',
	paddingLeft: '8px',
	paddingRight: '8px',
};
