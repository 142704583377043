import React from 'react';
import {withRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AWS_IDENTITY_ID, AWS_TOKEN, GlobalStyle, SESSION_ID, USER_ID} from './constants.js';
import {ThemeProvider} from 'styled-components';
import {MainTheme} from './components/Theme';
import {API} from './projlibs/api.js';
import {DataContext} from './data-context.js';
import AppController from './components/RootView/AppController.jsx';
import {Helmet} from 'react-helmet';
import {parse_cookies} from './projlibs/cookie-management.js';
import {clearCookies} from './react-utils/src/helperfunctions.js';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

class App extends React.Component {
	constructor(props) {
		super(props);
		const setOverlayIsOpen = (isOpen) => {
			this.setState(prevState => ({ dataContext: {...prevState.dataContext, overlayIsOpen: isOpen}}));
		}
		const setUserPerms = (perms) => {
			this.setState(prevState => ({ dataContext: {...prevState.dataContext, userPerms: perms}}));
		}
		const setCurrentUserId = (userId) => {
			this.setState(prevState => ({ dataContext: {...prevState.dataContext, userId: userId}}));
		}
		const setCurrentSessionId = (sessionId) => {
			this.setState(prevState => ({ dataContext: {...prevState.dataContext, userSessionId: sessionId}}));
		}
		const setCurrentUserImageURL = (imageURL) => {
			this.setState(prevState => ({ dataContext: {...prevState.dataContext, userImageURL: imageURL}}));
		}
		const setCurrentUsername = (username) => {
			this.setState(prevState => ({ dataContext: {...prevState.dataContext, username: username}}));
		}
		const setUserColour = (colour) => {
			this.setState(prevState => ({ dataContext: {...prevState.dataContext, userColour: colour}}));
		}
		const setCurrentUserRoles = (roles) => {
			this.setState(prevState => ({ dataContext: {...prevState.dataContext, userRoles: roles}}));
		}
		this.getProjectList = this.getProjectList.bind(this);
		this.getUserPerms = this.getUserPerms.bind(this);
		this.getAlerts = this.getAlerts.bind(this);
		this.getAlertsByUserId = this.getAlertsByUserId.bind(this);
		this.getProjectStatuses = this.getProjectStatuses.bind(this);
		this.getQuoteStatuses = this.getQuoteStatuses.bind(this);
		const cookies = parse_cookies();
		const existingAuthenticationExists = cookies[AWS_TOKEN] && cookies[AWS_IDENTITY_ID] && cookies[SESSION_ID] && cookies[USER_ID];
		this.state = {
			//you can define any additional custom fields you want as normal
			created_at: new Date(),
			
			//data context for react context provider
			//initial value is defined in data-context.js, so we use that initial value here
			//and update it as needed
			dataContext: {
				...DataContext._currentValue,
				userSessionId: existingAuthenticationExists ? cookies[SESSION_ID] : null,
				userId: existingAuthenticationExists ? cookies[USER_ID] : null,
				setUserPerms: setUserPerms,
				setOverlayVisibility: setOverlayIsOpen,
				setUserId: setCurrentUserId,
				setSessionId: setCurrentSessionId,
				setUserImageURL: setCurrentUserImageURL,
				setUserName: setCurrentUsername,
				setUserColour: setUserColour,
				setUserRoles: setCurrentUserRoles,
				getProjectList: this.getProjectList,
				getUserPerms: this.getUserPerms,
				getAlerts: this.getAlerts,
				getProjectStatuses: this.getProjectStatuses,
				getQuoteStatuses: this.getQuoteStatuses,
				getAlertsByUserId: this.getAlertsByUserId,
			},
		};
		if (existingAuthenticationExists) {
			this.getProjectList();
			this.getAlerts();
			this.getProjectStatuses();
			this.getQuoteStatuses();
			API.get(`/user/${cookies[USER_ID]}`).then(response => {
				const name = response.data.User[0].full_name;
				const colour = response.data.User[0].colour;
				const image = response.data.User[0].img_s3_path;
				const roles = response.data.User[0].groups ? response.data.User[0].groups.map(group => group.group_name) : [];
				let permissions = {};
				response.data.User[0].groups.forEach(group => permissions = {...permissions, ...group.permissions});
				setUserPerms(permissions);
				setCurrentUsername(name);
				setUserColour(colour);
				setCurrentUserImageURL(image);
				setCurrentUserRoles(roles);
			}).catch(API.default_error_handler);
			const awsTokenIdentityId = cookies[AWS_IDENTITY_ID];
			const awsToken = cookies[AWS_TOKEN];
			this.state.dataContext.aws.initAWSInstance(awsTokenIdentityId, awsToken).catch(() => {
				this.state.dataContext.aws.handleCredentialRenewalError().catch(API.default_error_handler);
			});
		} else {
			clearCookies();
		}
	}

	getAttachedResource(alert) {
		if (alert.project) {
			return {
				url: `/projects/${alert.project.project_id}`,
				name: alert.project.name,
			};
		}
		if (alert.client) {
			return {
				url: `/clients/${alert.client.client_id}`,
				name: alert.client.name,
			};
		}
		if (alert.contact) {
			return {
				url: `/contacts/${alert.contact.contact_id}`,
				name: alert.contact.name
			};
		}
		return null;
	};
	
	getAlertDate(alert) {
		if (alert.show_at_time) {
			return alert.show_at_time;
		}
		if (alert.project) {
			return alert.project.status_set_at + alert.show_after_status_timedelta;
		}
		if (alert.client) {
			return alert.client.status_set_at + alert.show_after_status_timedelta;
		}
		if (alert.contact) {
			return alert.contact.status_set_at + alert.show_after_status_timedelta;
		}
		return null;
	};

	getAlerts() {
		API.get('/alert?page_size=').then(({ data }) => {
			let dataContext = this.state.dataContext;
			dataContext.alerts = data.Alert ? data.Alert.map(alert => ({
				id: alert.alert_id,
				title: alert.content,
				date: this.getAlertDate(alert),
				attach: this.getAttachedResource(alert),
				due_date: alert.due_date,
				overdue: false,
				show_after_status_timedelta: alert.show_after_status_timedelta,
				show_after_status: alert.show_after_status,
				show_at_time: alert.show_at_time,
				visibility_group_id: alert.visibility_group_id,
				visibility_user_id: alert.visibility_user_id,
			})) : [];
			this.setState({ dataContext: dataContext });
		}).catch(API.default_error_handler);
	}

	async getAlertsByUserId(userId) {
		return new Promise((resolve, reject) => {
			API.get(`/user/${userId}/alert?page_size=`).then(({ data }) => {
				const normalizedAlerts = data.Alert ? data.Alert.map(alert => ({
					id: alert.alert_id,
					title: alert.content,
					date: this.getAlertDate(alert),
					attach: this.getAttachedResource(alert),
					overdue: false,
					show_after_status_timedelta: alert.show_after_status_timedelta,
					show_after_status: alert.show_after_status,
					show_at_time: alert.show_at_time,
					visibility_group_id: alert.visibility_group_id,
					visibility_user_id: alert.visibility_user_id,
				})) : [];
				resolve(normalizedAlerts);
			}).catch(reject);
		});
	}

	async getQuoteStatuses() {
		try {
			let masterList = this.state.dataContext.defaultQuoteStatusOrder;

			const quoteStatuses = await API.get('/project/status?is_quote=true');

			const statuses = [...new Set(quoteStatuses.data.Project_Status?.map(status => status.name))];
			masterList = masterList.filter(status => {
				return statuses.some(givenStatus => givenStatus === status);
			});

			let dataContext = this.state.dataContext;
			dataContext.quoteStatuses = masterList;
			this.setState({dataContext: dataContext});
		} catch (err) {
			API.default_error_handler(err);
		}
	}

	async getProjectStatuses() {
		try {
			const masterList = this.state.dataContext.defaultStatusOrder;
			const allStatuses = masterList;
			const { data: { Project_Status: statuses } } = await API.get('/project/status');
			const { data: { Project_Type: types } } = await API.get('/project/type');
			const typesMap = Object.fromEntries(types.map(type => [type.project_type_id, type.name]));
			let projectStatuses = {};
			for (const status of statuses) {
				const type = typesMap[status.project_type_id];
				if (!type) {
					continue;
				}
				if (projectStatuses[type]) {
					projectStatuses[type].push(status.name);
				} else {
					projectStatuses[type] = [status.name];
				}
			}
			let orderedStatuses = {};
			for(const type in projectStatuses){
				for(const status of masterList) {
					if (projectStatuses[type].includes(status)) {
						if (orderedStatuses[type]) {
							orderedStatuses[type].push(status);
						} else {
							orderedStatuses[type] = [status];
						}
					}
				}
			}

			let dataContext = this.state.dataContext;
			dataContext.projectStatuses = orderedStatuses;
			dataContext.allStatuses = allStatuses;
			this.setState({ dataContext: dataContext });
		} catch (err) {
			API.default_error_handler(err);
		}
	}

	getProjectList() {
		API.get('/project?limit=1000')
			.then((response) => {
				let dataContext = this.state.dataContext;
				dataContext.projects = response.data['Project'];
				this.setState({ dataContext: dataContext });
			})
			.catch(API.default_error_handler);
	}

	getUserPerms(user_id) {
		API.get('/user/' + user_id + '/permissions')
			.then((response) => {
				let dataContext = this.state.dataContext;
				dataContext.userPerms = response.data;
				this.setState({ dataContext: dataContext });
			})
			.catch(API.default_error_handler);
	}

	render() {
		return (
			<DataContext.Provider value={this.state.dataContext}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<div className='App'>
						<Helmet>
							<link
								href='https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap'
								rel='stylesheet'
							/>
							<title>CR Creative CO CRM</title>
							<meta name="description" content='CR Creative Co CRM' />
						</Helmet>
						<GlobalStyle overlayIsOpen={this.state.dataContext.overlayIsOpen}/>
						<ThemeProvider theme={MainTheme}>
							<ToastContainer />
							<AppController/>
						</ThemeProvider>
					</div>
				</MuiPickersUtilsProvider>
			</DataContext.Provider>
		);
	}
}

export default withRouter((props) => <App {...props} />);
