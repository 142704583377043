import React from 'react';
import styled from 'styled-components';
import {Color, FontSize, FontWeight, NAV_OVERVIEW} from '../../constants';
import {FormInput, SubmitButton} from '../Shared/CreateForm';
import {StyledText} from '../Shared/StyledText';
import {breakpoint} from '../../breakpoints';
import {useGetData} from '../../projlibs/api';
import {useHistory} from 'react-router-dom';

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding: 2rem;
`;

const Title = styled(StyledText)`
    color: ${Color.zest};
    font-size: ${FontSize.header3};
    padding-bottom: 2rem;
    font-weight: ${FontWeight.semibold};
`;

const ResetPassButton = styled(SubmitButton)`
    margin: 2rem 0 0 0;
    width: 100%;
    ${breakpoint('medium up')} {
        width: 200px;
    }
`;

const ErrorsView = styled.div`
    display: flex;
    flex-direction: column;
`;

const ErrorText = styled(StyledText)`
    color: ${Color.zest};
    font-size: ${FontSize.body2};
`;

export const ResetPassword = (props) => {
    const [{ isLoading, error }] = useGetData(`/reset?code=${props.resetCode}`)
    const history = useHistory();
    return (
        <InputsContainer>
            {
                (!isLoading && !error) &&
                <>
                    <Title>Reset Password</Title>
                    <FormInput
                        type='password'
                        label='New Password'
                        placeholder='******'
                        width='400px'
                        onChange={(e) => props.setNewPassword(e.target.value)}
                    />
                    <FormInput
                        type='password'
                        label='Confirm Password'
                        placeholder='******'
                        width='400px'
                        onChange={(e) => props.setConfirmPassword(e.target.value)}
                    />
                    <ErrorsView>
                        {
                            props.errors.map(error => <ErrorText key={error}>{error}</ErrorText>)
                        }
                    </ErrorsView>
                    <ResetPassButton
                        disabled={!props.newPassword || !props.confirmPassword || props.loading}
                        title={props.loading ? 'Resetting...' : 'Reset Password'}
                        onClick={() => props.SubmitNewPassword()}
                    />
                </>
            }
            {
                (!isLoading && error) &&
                <>
                    <Title>Reset password link expired</Title>
                    <ResetPassButton title='Return' onClick={() => history.push(NAV_OVERVIEW)}/>
                </>
            }
        </InputsContainer>
    );
}