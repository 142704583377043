import config from '../config';
import RAAWSUtils from '../react-utils/src/RAAWSUtils';
import {getHeaders} from "./helperFunctions";
import { clearCookies } from '../react-utils/src/helperfunctions';

class CRMRAAWS extends RAAWSUtils {

    async handleCredentialRenewalError() {
        try {
            const url = config.axios.baseURL + '/aws-refresh';
            const refreshReq = await fetch(url, { headers: getHeaders() });
            const respJson = await refreshReq.json();
            this.setToken(respJson.AWSToken.Token);
            this.setIdentityId(respJson.AWSToken.IdentityId);
            return await this.initAWSInstance(this.getIdentityId(), this.getToken(), config.aws_region_id, config.identity_pool_id);
        } catch (e) {
            clearCookies();
            window.location.href = '/login';
        }
    }

    uploadFile(bucketName, key, file, errorCallback, successCallback, flushing = false) {
        key = `files/${key}`;
        super.uploadFile(bucketName, key, file, errorCallback, successCallback, flushing);
    }
}

export default CRMRAAWS;