import React, {useState} from 'react';
import styled from 'styled-components';
import {Color, FontSize} from '../../constants';
import {ProjectStatusButton} from './ProjectStatusButton';
import {Dropdown} from '../Shared/Dropdown';
import {UnderlineLink} from '../Shared/Button';
import {HashLink} from 'react-router-hash-link';
import {breakpoint} from '../../breakpoints';

// ProjectOverview: display status of projects
// Props:
// statuses: array of status for project.
// filters: array of strings to be used for status filter dropdown
// isCollapsed: is status view expanded or collapsed
// toggleProjectOverview: collapse button callback
// dropdownOnChange: dropdown onChange callback
const ProjectOverviewView = styled.div`
	background-color: ${Color.white};
	padding: 0.625rem 1.5rem;
	margin-bottom: 32px;
	border-top: 0.25rem solid ${Color.fuel};
	border-radius: 3px 3px 0 0;
	box-shadow: 0rem 0.0625rem 0.375rem ${Color.nile15};
`;

const HeaderView = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 1rem;

	h2 {
		font-size: ${FontSize.header5};
	}
	
	${breakpoint('small down')} {
		flex-direction:column;
	}
`;

const StatusListView = styled.div`
	display: flex;
	flex-wrap: wrap;
	overflow-y: auto;
	max-height: ${(props) => (props.isCollapsed ? '100px' : 'fit-content')};
	margin-bottom: 16px;
`;

const FooterView = styled.div`
	position: relative;
	text-align: center;

	.gradientView {
		display: ${(props) => (props.isCollapsed ? 'auto' : 'none')};
		position: absolute;
		left: 0;
		top: -2.5rem;
		width: 100%;
		height: 2.5rem;
		background: linear-gradient(
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.8),
			white
		);
	}
`;

export const ProjectOverview = (props) => {
	const [filterStatus, setFilterStatus] = useState('');
	const { statuses: statusValues } = props;
	const handleFilterStatusChange = (status) => {
		if (status === 'placeholder') {
			setFilterStatus('');
		} else {
			setFilterStatus(status);
		}
	};
	return (
		<ProjectOverviewView {...props}>
			<HeaderView>
				<h2>{props.title}</h2>
				<Dropdown
					placeholder='Filter by status'
					options={statusValues}
					values={statusValues}
					onChange={(e) => handleFilterStatusChange(e.target.value)}
					fontSize={FontSize.body1}
				/>
			</HeaderView>
			<StatusListView isCollapsed={props.isCollapsed}>
				{props.projectGroups?.filter(group => group.status === filterStatus || !filterStatus).map((group) => (
					<HashLink key={group.name} to={`#${group.name}`} style={{ textDecoration: 'none' }} onClick={() => props.handleManualExpand(group.name)}>
						<ProjectStatusButton
							title={group.name}
							value={group.resultCount}
							backgroundColor={group.highlightColor}
						/>
					</HashLink>
				))}
			</StatusListView>
			<FooterView isCollapsed={props.isCollapsed}>
				<div className='gradientView'></div>
				<UnderlineLink
					title={props.isCollapsed ? 'Show all' : 'Collapse'}
					unicodeIcon={props.isCollapsed ? '\f0d7' : '\f0d8'}
					underline='underline'
					onClick={props.toggleProjectOverview}
				/>
			</FooterView>
		</ProjectOverviewView>
	);
};
