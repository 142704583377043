import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {MS_PER_S, CLIENT_NAME, CONTACT_NAME, Color, CONTACT_EMAIL, CREATED} from '../../constants';
import {StyledText} from '../Shared/StyledText';
import {breakpoint} from '../../breakpoints';
import {FlexTableController} from '../Shared/FlexTableController';
import {API, useGetData} from '../../projlibs/api';
import downloadCsv from 'download-csv';
import {ReportHeader} from './ReportHeader';
import moment from 'moment';
import {updateSorting} from "./Reports";
import { DateTimeFormInput, DateInputType } from '../Shared/DateTimeFormInput';
import { PageController } from '../Shared/PageController';
import { PageIndex } from '../Shared/PageIndex';
import { FormattedDate } from '../Shared/FormattedDate';

const CustomizeReportView = styled.div`
	padding: 0.5rem 1.5rem;
`;

const ClientSelectionView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    ${breakpoint('medium up')} {
        flex-direction: row;
    }
`;

const ReportToolbar = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    ${breakpoint('medium up')} {
        flex-direction: row;
    }
`;

const SetupTableDataClientEmails = (userTotalTimes) => ({
    titles: [
        CLIENT_NAME,
        CONTACT_NAME,
        CONTACT_EMAIL,
        CREATED,
    ],
    rows: userTotalTimes.map((entry) => {
        return [
            {
                element: (
                    <StyledText>{entry.clientContact[0]?.Client?.name}</StyledText>
                ),
            },
            {
                sortValue: entry.name,
                element: (
                    <StyledText>{entry.name}</StyledText>
                ),
            },
            {
                sortValue: entry.email,
                element: (
                    <StyledText>{entry.email}</StyledText>
                ),
            },
            {
                sortValue: entry.created_at,
                element: (
                    <FormattedDate
                        date={entry.created_at}
                        hideTime={true}
                        format='MM/DD/YYYY'
                        color={Color.nile50}
                        fontStyle='italic'
                    />
                ),
            }
        ];
    }),
});


const generateContactEmailUrl = (startDate, endDate, pageIndex = 0, pageSize = 20, orderBy) => {
    let url = `/contact/search?page_size=${pageSize}&page=${pageIndex}&email_required=true&full=true`;
    if (startDate) {
        url += `&after=${startDate}`;
    }
    if (endDate) {
        url += `&before=${endDate}`;
    }
    if (orderBy) {
        url += `&${orderBy}`;
    }
    return url;
};

export const ClientEmails = () => {
    const [isGenerated, setIsGenerated] = useState(false);
    const [resultCount, setResultCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [emailEntries, setEmailEntries] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [persistStartDate, setPersistStartDate] = useState();
    const [endDate, setEndDate] = useState(moment().unix());
    const [persistEndDate, setPersistEndDate] = useState();
    const [orderBy, setOrderBy] = useState("");

    const [{ data: projectStatusData }] = useGetData('/project/status');
    const projectStatuses = projectStatusData?.Project_Status;
    const [{ data: projectTypeData }] = useGetData('/project/type');
    const projectTypes = projectTypeData?.Project_Type?.map(type => ({ id: type.project_type_id, color: type.color, name: type.name }));
    const handleCSV = () => {
        if (emailEntries?.length !== 0) {
            API.get(generateContactEmailUrl(persistStartDate, persistEndDate, 0, '', orderBy)).then(response => {
                const results = response?.data?.Contact ? response?.data?.Contact : [];
                const csvData = results.map(result => {
                    const created_at=new Date(result.created_at * MS_PER_S);
                    let month=(created_at.getMonth()+1)+'';
                    while(month.length<2){
                            month='0'+month;
                    }
                    let date=(created_at.getDate())+'';
                    while(date.length<2){
                            date='0'+date;
                    }
                    return {
                        CLIENT_NAME: '"'+(result.clientContact[0]?.Client?.name.replaceAll('"',''))+'"',
                        CONTACT_NAME: '"'+(result.name.replaceAll('"',''))+'"',
                        '"Contact Email"': '"'+(result.email.replaceAll('"','').replaceAll(',',''))+'"',
                        '"Created At"': '"'+created_at.getFullYear()+'-'+(month)+'-'+(date)+'"'
                    };
                });
                downloadCsv(csvData, null, `Contacts_${Math.floor(new Date().getTime() / 1000)}`);
            }).catch(API.default_error_handler);
        }
    };
    const handleGenerate = () => {
        setIsGenerated(true);
        setPersistStartDate(startDate);
        setPersistEndDate(endDate);
    };
    useEffect(() => {
        if (isGenerated) {
            API.get(generateContactEmailUrl(persistStartDate, persistEndDate, pageIndex, 20, orderBy)).then(response => {
                setResultCount(response?.data?.result_count ? response?.data?.result_count : 0)
                setEmailEntries(response?.data.Contact ? response?.data.Contact : []);
            }).catch(API.default_error_handler);
        }
    }, [pageIndex, persistStartDate, persistEndDate, orderBy, isGenerated]);
    return (
        <CustomizeReportView>
            <ReportHeader generateOnClick={handleGenerate} csvOnClick={handleCSV} csvDisabled={!persistStartDate && !persistEndDate} generateDisabled={false}/>
            <ReportToolbar>
                <ClientSelectionView>
                    <DateTimeFormInput
                        label='Start Date'
                        placeholder='mm/dd/yyyy'
                        format='MMM D, YYYY'
                        width='25%'
                        value={startDate ? startDate * MS_PER_S : null}
                        type={DateInputType}
                        onChange={(e) => setStartDate(e ? e.unix() : null)}
                    />
                    <DateTimeFormInput
                        label='End Date'
                        placeholder='mm/dd/yyyy'
                        format='MMM D, YYYY'
                        width='25%'
                        marginLeft='12px'
                        value={endDate ? endDate * MS_PER_S : null}
                        type={DateInputType}
                        onChange={(e) => setEndDate(e ? e.unix() : null)}
                    />
                </ClientSelectionView>
                <PageIndex resultCount={resultCount} currentPage={pageIndex} />
            </ReportToolbar>
            {
                (projectStatuses && projectTypes) &&
                <FlexTableController rowPadding='0.5rem 1rem 0.5rem 1rem' tableData={SetupTableDataClientEmails(emailEntries)} sort={(e, title) => updateSorting(e,title, setOrderBy)}/>
            }
            <PageController pageNumber={pageIndex} setPageNumber={setPageIndex} numPages={Math.ceil(resultCount / 20)} />
        </CustomizeReportView>
    );
};
