import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Color, FontSize, NAME, HOURS, DATE_ENTERED} from '../../constants';
import {StyledText} from '../Shared/StyledText';
import {FormattedDate} from '../Shared/FormattedDate';
import S3ImageController from '../Shared/S3ImageController';
import {FlexTableController} from '../Shared/FlexTableController';
import {StyledBase} from '../Shared/StyledBase';
import {API} from '../../projlibs/api';
import {Button, UnderlineLink} from '../Shared/Button';
import {StyledInitials} from "../Shared/StyledInitials";
import {faCheck, faEdit, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {DataContext} from '../../data-context';
import {Input} from '../Shared/Input';
import moment from 'moment';
import {success} from '../../projlibs/feedback';
import {FormInput} from "../Shared/CreateForm";
import { DeleteButton } from '../Shared/OverlayViewButton';
import { DeleteConfirmation } from '../Shared/DeleteConfirmation';
import { OverlayView } from '../Shared/OverlayView';

// Hours displays activity hours in a table.
// Props:
// hours: array of hours.

const HoursView = styled.div`
	${StyledBase};
	min-width: 0%;
	overflow-y: auto;
`;

const NameContainer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
`;

const EditTimeContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const HoursButton = styled(Button)`
	background-color: ${Color.transparent};
	margin: 0;
	height: 35px;
	min-height: 35px;
	width: 35px;
	min-width: 35px;
	border-radius: 50%;
	border-color: ${Color.transparent};
`;

const DeleteHoursButton = styled(DeleteButton)`
	${OverlayView} {
        border-radius: 50%;
		width: 100%;
		height: unset;
        max-width: 100vw;
    }
`;

const formatHours = (hour, editingState, setEditingState, editDate, setEditDate, editHrs, setEditHrs, editMinutes, setEditMinutes, handleEditTime, handleDeleteTime) => {
	return [
		{
			doesGrow: true,
			sortValue: hour.name,
			element: (
				<NameContainer>
					{hour.img ?
						<S3ImageController
							s3_path={hour.img}
							width='32px'
							height='32px'
							borderRadius='50%'
							marginRight='16px'
						/>
						:
						<StyledInitials
							size='32px'
							marginTop='0'
							marginBottom='0'
							marginRight='16px'
							fontSize={FontSize.body1}
							borderRadius='50%'
							backgroundColor={Color.zest}
							numberOfInitials={2}>
							{hour.name}
						</StyledInitials>
					}
					<StyledText>{hour.name}</StyledText>
				</NameContainer>
			),
		},
		{
			minWidth: '105px',
			sortValue: hour.entered_at,
			element: (
				editingState === hour.id ?
				<FormInput
					label='New Date'
					height='33px'
					marginBottom='0px'
					defaultValue={editDate}
					type="date"
					onChange={(e) => setEditDate(e.target.value)}
				/> :
				<FormattedDate hideTime={true} format='MMM DD, YYYY' date={hour.entered_at} color={Color.nile} />
			),
		},
		{
			minWidth: '50px',
			sortValue: hour.hrs,
			element: (
				editingState === hour.id ?
				<EditTimeContainer>
					<Input label='H' width='40px' onChange={e => setEditHrs(e.target.value)} fontSize={FontSize.body2} defaultValue={Math.floor(hour.hrs)} />
					<Input label='M' width='40px' onChange={e => setEditMinutes(e.target.value)} fontSize={FontSize.body2} defaultValue={(Math.floor(hour.hrs * 60)) % 60} />
				</EditTimeContainer> :
				<StyledText color={Color.nile} fontSize={FontSize.body2}>
					{hour.hrs}
				</StyledText>
			),
		},
		{
			element: (
				hour.isEditable &&
				(	
					editingState === hour.id ?
					<HoursButton hoverBackground={Color.meadow} icon={faCheck} onClick={() => {
						setEditingState(null);
						handleEditTime();
					}} /> :
					<HoursButton hoverBackground={Color.banana} icon={faEdit} onClick={() => {
						setEditDate(moment.unix(hour.entered_at).format('YYYY-MM-DD'));
						setEditHrs(Math.floor(hour.hrs));
						setEditMinutes((Math.floor(hour.hrs * 60)) % 60);
						setEditingState(hour.id);
					}} />
				)
			),
		},
		{
			element: (
				hour.isEditable &&
				<DeleteHoursButton hoverBackground={Color.coral} icon={faTrash}>
					<DeleteConfirmation onDelete={() => handleDeleteTime(hour.id)} />
				</DeleteHoursButton>
			),
		},
	];
};

const updateOrderBy = (e, title) => {
	switch(title){
		case NAME:
			return "order_by=name:" + e.currentTarget.dataset.sortorder;
		case HOURS:
			return "order_by=duration_mins:" + e.currentTarget.dataset.sortorder;
		case DATE_ENTERED:
			return "order_by=entered_at:" + e.currentTarget.dataset.sortorder;
		default:
			return "";
	}
}

export const updateSorting = (e, title, setter) => {
	setter(updateOrderBy(e, title));
	e.currentTarget.dataset.sortorder = e.currentTarget.dataset.sortorder === 'asc' ? 'desc' : 'asc';
}

export const Hours = (props) => {
	const [pageSize, setPageSize] = useState(10);
	const [editingState, setEditingState] = useState();
	const [editDate, setEditDate] = useState();
	const [editHrs, setEditHrs] = useState();
	const [editMinutes, setEditMinutes] = useState();
	const [loggedHours, setLoggedHours] = useState([]);
	const [resultCount, setResultCount] = useState(0);
	const [orderBy, setOrderBy] = useState('');
	const context = useContext(DataContext);
	useEffect(() => {
		if (props.projectId) {
			API.get(`/project/${props.projectId}/time?page_size=${pageSize}&${orderBy ? orderBy : 'order_by=entered_at:desc'}`).then(({ data }) => {
				setResultCount(data.result_count);
				setLoggedHours(data.Time_Entry ? data.Time_Entry.map(entry => ({
					id: entry.time_entry_id,
					name: entry.Logged_For_User?.full_name,
					entered_at: entry.entered_at,
					hrs: (entry.duration_mins / 60).toFixed(2),
					img: entry.Logged_For_User?.img_s3_path,
					isEditable: context.userId === `${entry.Logged_For_User?.user_id}`,
				})) : []);
			}).catch(API.default_error_handler);
		}
	}, [props.projectId, pageSize, context.userId, orderBy]);
	const handleShowMore = () => {
		setPageSize(prev => prev + 10);
	};
	const handleEditTime = () => {
		API.put(`/project/${props.projectId}/time/${editingState}`, {
			data: {
				duration_mins: (editHrs * 60) + (editMinutes - 0),
				entered_at: editDate ? moment(editDate, 'YYYY-MM-DD').unix() : moment().unix(),
			}
		}).then(() => {
			API.get(`/project/${props.projectId}/time?page_size=${pageSize}&${orderBy ? orderBy : 'order_by=entered_at:desc'}`).then(({ data }) => {
				setResultCount(data.result_count);
				setLoggedHours(data.Time_Entry?.map(entry => ({
					id: entry.time_entry_id,
					name: entry.Logged_For_User?.full_name,
					entered_at: entry.entered_at,
					hrs: (entry.duration_mins / 60).toFixed(2),
					img: entry.Logged_For_User?.img_s3_path,
					isEditable: context.userId === `${entry.Logged_For_User?.user_id}`,
				})));
			}).catch(API.default_error_handler);
			success('Time entry successfully edited.');
		}).catch(API.default_error_handler);
	};
	const handleDeleteTime = (timeEntryId) => {
		API.put(`/project/${props.projectId}/time/${timeEntryId}`, {
			data: {
				valid: false,
			}
		}).then(() => {
			API.get(`/project/${props.projectId}/time?page_size=${pageSize}&${orderBy ? orderBy : 'order_by=entered_at:desc'}`).then(({ data }) => {
				setResultCount(data.result_count);
				setLoggedHours(data.Time_Entry?.map(entry => ({
					id: entry.time_entry_id,
					name: entry.Logged_For_User?.full_name,
					entered_at: entry.entered_at,
					hrs: (entry.duration_mins / 60).toFixed(2),
					img: entry.Logged_For_User?.img_s3_path,
					isEditable: context.userId === `${entry.Logged_For_User?.user_id}`,
				})));
			}).catch(API.default_error_handler);
			success('Time entry successfully deleted.');
		}).catch(API.default_error_handler);
	};
	return (
		<HoursView {...props}>
			<FlexTableController
				tableData={{
					titles: [
						'Name',
						'Date Entered',
						'Hrs',
						'',
						'',
					],
					rows: loggedHours ? loggedHours.map(hour => formatHours(
						hour,
						editingState,
						setEditingState,
						editDate,
						setEditDate,
						editHrs,
						setEditHrs,
						editMinutes,
						setEditMinutes,
						handleEditTime,
						handleDeleteTime)) : [],
				}}
				sort={(e, title) => updateSorting(e, title, setOrderBy)}
			/>
			{
				resultCount > pageSize &&
				<UnderlineLink title='Show More' unicodeIcon='\f0d7' underline='underline' onClick={handleShowMore} />
			}
		</HoursView>
	);
};
