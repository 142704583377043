import { toast } from 'react-toastify';

const TOAST_POSITION = 'bottom-right';

const TOAST_PROPERTIES = {
    position: TOAST_POSITION,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

export const warning = (message, delay = 8000) => toast.warn(message, {
    ...TOAST_PROPERTIES,
    autoClose: delay > 0 ? delay : false,
});

export const info = (message, delay = 5000) => toast.info(message, {
    ...TOAST_PROPERTIES,
    autoClose: delay > 0 ? delay : false,
});

export const success = (message, delay = 5000) => toast.success(message, {
    ...TOAST_PROPERTIES,
    autoClose: delay > 0 ? delay : false,
});

export const error = (message, delay = 10000) => toast.error(message, {
    ...TOAST_PROPERTIES,
    autoClose: delay > 0 ? delay : false,
});
