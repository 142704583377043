import React from 'react';
import SelectSearch from 'react-select-search';
import {StyledText} from './StyledText'
import styled, {css} from 'styled-components';
import {Color, FontSize, FontWeight} from '../../constants';
import {breakpoint} from '../../breakpoints';
import S3ImageController from './S3ImageController';
import {StyledInitials} from "./StyledInitials";

const renderRow = (props, option, snapshot, className) => (
    <Row {...props} className="select-search select-search--multiple" type="button">
        {option.photo
            ?
            <S3ImageController
                s3_path={option.photo}
                width='32px'
                height='32px'
                marginTop='auto'
                marginBottom='auto'
                borderRadius='50%'
            />
            :
            <StyledInitials
                size='32px'
                marginTop='auto'
                marginBottom='auto'
                fontSize={FontSize.header5}
                borderRadius='50%'
                backgroundColor={option.colour ? option.colour : Color.zest}
                numberOfInitials={2}>
                {option.name}
            </StyledInitials>
        }
            <StyledText
                fontWeight={FontWeight.medium}
                marginLeft="15px" 
                lineHeight="44px" 
                fontSize={FontSize.body1}>
                {option.name}
            </StyledText>
    </Row>
)

const Row = styled.button`
    width: 100%;
    display: flex;
    background-color: ${Color.silver};
    cursor: pointer;
    &:hover {
        p {
            color: ${Color.white};
        }
        background-color: ${Color.fuel};
    }
`;

const SelectView = styled.div`
	${(props) => css`
        label {
            display: block;
            color: ${Color.nile50};
            font-size: ${FontSize.body2};
            
        }

        width: ${props.width};
        flex: ${props.flex};
        ${breakpoint('small only')} {
            width:100%;
        }

        .select-search {
            position: relative;
            box-sizing: border-box;
        }

        .select-search *,
        .select-search *::after,
        .select-search *::before {
            box-sizing: inherit;
        }

        .select-search__input {
            height: 44px;
            width: 100%;
            padding: 0 16px;
            background: ${Color.silver};
            border: 1px solid ${Color.nile15};
            box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
            border-radius: 3px;
            font-size: ${FontSize.body1};
            margin-bottom: 24px;
        }

        .select-search__select {
            /* margin-top: 0.5rem; */
            background: #fff;
            box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
        }

        .select-search__options {
            margin-top: 0;
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
        }

        .select-search__row:not(:first-child) {
            border-top: 1px solid ${Color.nile10};
        }

        .select-search__option {
            display: block;
            height: 36px;
            width: 100%;
            padding: 0 16px;
            background: ${Color.white};
            border: none;
            outline: none;
            font-family: 'Noto Sans', sans-serif;
            font-size: 14px;
            text-align: left;
            cursor: pointer;
        }

        .select-search--multiple .select-search__option {
            height: 48px;
        }

        .select-search--multiple {
            border-color: ${Color.transparent};
            overflow: hidden;
        }

        .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
            transform: rotate(45deg);
            border-right: 1px solid ${Color.black};
            border-bottom: 1px solid ${Color.black};
            pointer-events: none;
        }

        .select-search--multiple .select-search__input {
            border-radius: 3px 3px 0 0;
        }

        .select-search:not(.select-search--multiple) .select-search__input:hover {
            border: 1px solid ${Color.fuel};
        }

        .select-search:not(.select-search--multiple) .select-search__select {
            position: absolute;
            z-index: 2;
            top: 44px;
            right: 0;
            left: 0;
            border-radius: 0 0 5px 5px;
            overflow: auto;
            max-height: 360px;
        }

        .select-search--multiple .select-search__select {
            position: relative;
            overflow: auto;
            max-height: 30px;
            border-top: 1px solid #eee;
            border-radius: 0 0 3px 3px;
            width:100%;
        }
    `}
`;

export const UserSelect = (props) => {
    return (
        <SelectView {...props}>
            {props.title && <label style={{ textAlign: 'start'}}>{props.title}</label>}
            <SelectSearch
                options={props.options ? props.options : []}
                renderOption={renderRow}
                search={props.disableSearch ? false : true}
                placeholder={props.placeholder ? props.placeholder : "Select User"}
                onChange={props.onChange}
                getOptions={props.getOptions}
                value={props.value}
            />
        </SelectView>
    );
};