/*
FlexTable - A Flex table is a responsive table that uses display flex on mobile to fit to the screen better than a normal table element. 
*/

import React from 'react';
import PropTypes from 'prop-types';

import {FlexTable} from './FlexTable.jsx';
import styled from 'styled-components';
import { breakpoint } from '../../breakpoints.js';

/*
props (component-level arguments):
	tableTitle: A string or react component to display prior to the table content
	tableTitleTag: the tag to use when displaying the table title (h2 by default)
	tableData: An object that consists of a headerData object and a rows array that consists of row data objects.a
		The format for this tableData is given by the following example:
		{
			"headerData": [
				{"title":"Title","field":"title"},
				{"title":"Date","field":"date"},
				{"title":"Format","field":"format"},
				{"title":"Tags","field":"tags","sortable":false},
				{"title":"","field":"actionIcon","sortable":false},
			],
			"rows": [
				{
					"title":<h3 className="title has-img title-square-thumbnail"><img src='https://picsum.photos/64/64' alt='logo' /> Keynote Template</h3>,
					"date":"03/15/2019",
					"format":"Design",
					"tags":<TagArray tags={["keynote","design","template"]} />,
					"actionIcon":<Button className="fas fa-arrow-down" onClick={() => this.downloadFile()} />,
					"cssClasses":{
						"title":"title-cell",
					}
				},
			]
		}
*/

const OverflowGradient = styled.div`
	width: 150px;
	height: 100%;
	top: 0;
	right: 0;
	position: absolute;
	background: rgb(255,255,255);
	background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	pointer-events: none;
	${breakpoint('small only')} {
		display: none;
	}
`;

export class FlexTableController extends React.Component {
	constructor(props) {
		super(props);

		//NOTE: this is stored as state rather than being used directly as props
		//because this table implements sorting functionality
		//and the sorted data isn't necessarily what's passed directly as props
		this.state = {
			tableData: this.props.tableData,
		};
		this.sortTable = this.sortTable.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			tableData: nextProps.tableData,
		};
	}

	sortTable(e, sortKey) {
		// Check what the target's current sort order is
		// If asc, sort a-0 > z-9, else z-9 < a-0
		if(e.currentTarget.dataset.sortorder === 'asc') {
			this.setState({
				tableData: {
					"titles": this.state.tableData.titles,
					"rows": this.state.tableData.rows.sort((a,b) => {
						// When creating the tableData rows, set the isCaseSensitive = true on a field/column
						// in order to do case sensitive sorting
						// NOTE: Will only work on string fields!
						if (a[sortKey].isCaseSensitive || typeof a[sortKey].sortValue !== 'string') {
							return (a[sortKey].sortValue>b[sortKey].sortValue)?1:((a[sortKey].sortValue<b[sortKey].sortValue)?-1:0);
						} else {
							return (a[sortKey].sortValue?.toLowerCase()>b[sortKey].sortValue?.toLowerCase())?1:((a[sortKey].sortValue?.toLowerCase()<b[sortKey].sortValue?.toLowerCase())?-1:0);
						}
					}),
				}
			});
			e.currentTarget.dataset.sortorder = 'desc';
		} else {
			this.setState({
				tableData: {
					"titles": this.state.tableData.titles,
					"rows": this.state.tableData.rows.sort((a,b) => {
						if (a[sortKey].isCaseSensitive || typeof a[sortKey].sortValue !== 'string') {
							return (a[sortKey].sortValue<b[sortKey].sortValue)?1:((a[sortKey].sortValue>b[sortKey].sortValue)?-1:0);
						} else {
							return (a[sortKey].sortValue?.toLowerCase()<b[sortKey].sortValue?.toLowerCase())?1:((a[sortKey].sortValue?.toLowerCase()>b[sortKey].sortValue?.toLowerCase())?-1:0);
						}
					}),
				}
			});
			e.currentTarget.dataset.sortorder = 'asc';
		}
		
		// Reset all other sort orders, so first click is always a-0 > z-9
		Array.prototype.slice.call(document.querySelectorAll(".FlexTable thead tr .item.sortable")).forEach(function(x) {
			if(x !== e.currentTarget) {
				x.dataset.sortorder = 'asc';
			}
		});
	}

	render(){
		return(
			<div style={{position: 'relative'}}>
				<FlexTable sortTable={this.sortTable} {...this.props} tableData={this.state.tableData} />
				<OverflowGradient />
			</div>
		);
	}
}

FlexTableController.propTypes = {
	tableData: PropTypes.object.isRequired,
	tableTitleTag: PropTypes.string.isRequired,
	tableTitle: PropTypes.string,
};

FlexTableController.defaultProps = {
	tableData: {},
	tableTitleTag: 'h2',
	tableTitle:null,
};

