import React, {useContext, useState} from 'react';
import {useGetData} from '../../projlibs/api.js';
import styled from 'styled-components';
import {CreateForm, FormInput, Header, Overlay, SubmitButton} from '../Shared/CreateForm';
import {FileButton} from "../Shared/Button";
import {faPlus, faMinus, faArchive} from "@fortawesome/free-solid-svg-icons";
import {InvisibleInput} from "../Shared/Input";
import {config} from "../../config";
import {DataContext} from "../../data-context";
import {API} from "../../projlibs/api";
import {StyledText} from "../Shared/StyledText";
import {Color, FontSize, NAV_USERS} from "../../constants";
import {StyledBase} from "../Shared/StyledBase";
import {breakpoint} from "../../breakpoints";
import {MultiSelectDropdown} from "../Shared/MultiSelect";
import {StyledCheckbox} from "../Shared/StyledCheckbox";
import {success, warning} from '../../projlibs/feedback.js';
import {useHistory} from "react-router-dom";
import {TwitterPicker} from 'react-color';
import {ChangePassword} from './ChangePassword';
import {error} from '../../projlibs/feedback';

export const FileUploadContainer = styled.div`
    ${StyledBase}
    width: 100%;
    .button {
        margin-bottom:24px;
        margin-left: 0;
    }
    ${breakpoint('medium up')} {
        margin-bottom:24px;
        width:auto;
        min-width: 24%;
        display:flex;
        flex-direction:row;
        align-items:flex-end;
        .button {
            margin-left: 16px;
            margin-bottom:0px;
        }
    }
`;

export const ColourLabel = styled.label`
  display: block;
  color: ${Color.nile50};
  font-size: ${FontSize.body2};
`;
export const ColourContainer = styled.div`
`;

export const ResponsiveFileButton = styled(FileButton)`
    width: 100%;
    white-space: nowrap;
    ${breakpoint('medium up')} {
        width: auto;
    }
`;

export const UPLOAD_IMAGE_MIME_TYPES = ['image/jpeg', 'image/png'];

export const CreateUserForm = (props) => {
    const history = useHistory();
    const [name, setName] = useState(props.user ? props.user.full_name : "");
    const [password, setPassword] = useState("");
    const [colour, setColour] = useState(props.user ? props.user.colour : '');
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [email, setEmail] = useState(props.user ? props.user.email : "");
    const [selectedRoles, setSelectedRoles] = useState(props.user ? props.user.groups.map(role => role.group_name) : []);
    const [file, setFile] = useState();
    const [fileMessage, setFileMessage] = useState("");
    const [valid, setValid] = useState(props.user ? props.user.valid : true);
    const [{ data: roleData }] = useGetData("/group");
    const [fileCleared, setFileCleared] = useState(false);
    const roles = roleData.Group?.map(role => role.group_name);
    const mode = props.user === undefined ? 'create' : 'edit';
    const context = useContext(DataContext);

    const SubmitForm = (e) => {
        e.preventDefault();

        const user = {
            "full_name": name,
            "password": password,
            "email": email,
            "groups": selectedRoles,
            "password_confirm": passwordConfirm
        };

        if(colour !== ''){
            user.colour = colour
        }

        if(mode === 'edit'){
            EditUser(user);
        }
        else{
            CreateUser(user);
            props.onClose();
        }
    };

    const CreateUser = (user) => {
        API.post("/user", {"data": user}).then( response => {
            const id = response.data.User.user_id;
            if (file) {
                const path = 'user/' + id + '/' + file.name;
                context.aws.uploadFile(config.bucket_name, path, file, err => {
                    warning('Sorry we are having trouble uploading the user image.');
                }, uploadResponse => {
                    // if the file is large and has to upload in parts then large aws saves it in the variable Key instead of key
                    let key = uploadResponse.Key ? uploadResponse.Key : uploadResponse.key;
                    SavePath(key, id);
                });
            }
            success('User successfully created.');
            history.push(
                NAV_USERS + '/' + response.data.User.user_id
            );
        }).catch(API.default_error_handler)
    };

    const EditUser = (user) => {
        if(props.isEditingOwn && user.password){
            if(user.password !== user.password_confirm){
                error("Passwords do not match");
                return false;
            }
        }else{
            delete user.password;
            delete user.password_confirm;
        }

        user.valid = valid;
        if(user.img_s3_path!==null){
            if(fileCleared){
                user.img_s3_path=null;
            }
        }
        API.put(`/user/${props.user.user_id}`, {"data": user}).then( response => {
                const id = props.user.user_id;
                if (file) {
                    const path = 'user/' + id + '/' + file.name;
                    context.aws.uploadFile(config.bucket_name, path, file, err => {
                        warning(err.response?.data?.message);
                    }, uploadResponse => {
                        // if the file is large and has to upload in parts then large aws saves it in the variable Key instead of key
                        let key = uploadResponse.Key ? uploadResponse.Key : uploadResponse.key;
                        SavePath(key, id);
                        success('User successfully edited.');
                        if (props.isEditingOwn) {
                            history.go(0); // reloads page
                        } else {
                            props.update();
                        }
                    });
                //NOTE: this success must be in an else statement
                //to be mutually exclusive with file uploads
                //because otherwise the page refresh occurs
                //before the asynchronous file upload call completes
                }else{
                    success('User successfully edited.');
                    if (props.isEditingOwn) {
                        history.go(0); // reloads page
                    } else {
                        props.update();
                        //NOTE: page reload is necessary
                        //to get the new image to show up on the left hand navbar
                        history.go(0); // reloads page
                    }
                }
            }
        ).catch(API.default_error_handler);
    }

    const SavePath = (key, user_id) => {
        API.put(`/user/${user_id}`, { data: { img_s3_path: key } }).catch(API.default_error_handler);
    };

    const setFileObject = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        if((file!==undefined) && (file!==null)) {
            setFile(file);
            setFileMessage(file.name);
            
            //if the user selects a new file then the file isn't cleared
            //even if it previously was
            setFileCleared(false);
        }
    };
    
    const clearFileObject = () => {
        setFile(null);
        setFileMessage('');
        
        //NOTE: the fileCleared variable is needed to distinguish the cases
        //where the user didn't select a file
        //from the cases where the user cleared a previously-selected file
        setFileCleared(true);
    };

    return (
        <Overlay height='fit-content'>
            <Header onClose={props.onClose} title={mode === 'edit' ? (props.isEditingOwn ? "Edit Personal Data" : "Edit a user") : "Create a user"} />
            <CreateForm onSubmit={SubmitForm}>
                <FormInput
                    label='Name*'
                    // NOTE: width here is only used for medium-up; mobile is 100%
                    width={(file || ((mode==='edit') && (props.user.img_s3_path!==null)))?'50%':'75%'}
                    inputId='name'
                    placeholder='...'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <FileUploadContainer marginTop={fileMessage ? "0" : "16px"}>
                    <div>
                        <StyledText fontSize={FontSize.body2} color={Color.zest} marginLeft="16px">{fileMessage}</StyledText>
                        <ResponsiveFileButton
                            title={((mode==='edit') && (props.user.img_s3_path!==null))?'Replace Image':'Upload Image'}
                            icon={faPlus}
                            height='44px'
                            onClick={(e) => {
                                e.preventDefault();
                                let input = document.getElementById("fileUploadInput") ;
                                input.click()}
                            }
                        />
                    </div>
                    {((mode==='edit') && (props.user.img_s3_path!==null)) ?
                        <ResponsiveFileButton
                            title={fileCleared ? 'Keep Image' : 'Remove Image'}
                            icon={fileCleared ? faArchive : faMinus}
                            height='44px'
                            onClick={(e) => {
                                e.preventDefault();
                                if(fileCleared){
                                    setFileCleared(false);
                                }else{
                                    clearFileObject();
                                }
                            }}
                        />
                    : (file &&
                        <ResponsiveFileButton
                            title={'Remove Image'}
                            icon={faMinus}
                            height='44px'
                            onClick={(e) => {
                                e.preventDefault();
                                clearFileObject();
                            }}
                        />
                    )
                    }
                    <InvisibleInput inputType='file' accept={UPLOAD_IMAGE_MIME_TYPES} id="fileUploadInput" onChange={(e) => setFileObject(e)}/>
                </FileUploadContainer>
                    <FormInput
                        label='Email*'
                        inputId='email'
                        width = '49%'
                        placeholder='...'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                {props.isEditingOwn &&
                <ColourContainer>
                    <ColourLabel>Colour</ColourLabel>
                    <TwitterPicker triangle='hide' color={colour ? colour : undefined} onChangeComplete={(colour) => setColour(colour.hex)}/>
                </ColourContainer>
                }
                {props.isEditingOwn && <ChangePassword password={password} passwordConfirm={passwordConfirm} setPasswordConfirm={setPasswordConfirm} setPassword={setPassword}/>}
                {mode === 'create' &&
                    <FormInput
                        label='Password*'
                        type='password'
                        inputId='password'
                        width='49%'
                        placeholder='...'
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                }
                {
                    !props.isEditingOwn &&
                    <MultiSelectDropdown label="Roles" defaultValue={selectedRoles} marginBottom='12px' placeholder='-select-' height='44px' width = '100%' allowCreate-={false} data={roles} onChange={value => setSelectedRoles(value)}/>
                }
                {mode === 'edit' && !props.isEditingOwn &&
                    <StyledCheckbox
                        id='permission1'
                        checked={valid}
                        label='Active'
                        marginRight='50%'
                        paddingTop='0'
                        onChange={(e) => setValid(!valid)}
                    />
                }
                <SubmitButton title={mode === 'edit' ? "Edit user" : "Create user"} type="submit" />
            </CreateForm>
        </Overlay>
    );
}
