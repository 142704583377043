import styled, { css } from 'styled-components';
import { StyledBase } from './StyledBase';

// Label: General component used to use as a div.
// Props:
// all display props are available
export const StyledDiv = styled.div`
	${(props) => css`
		${StyledBase}
	`}
`;
