import React from 'react';
import styled, {css} from 'styled-components';
import {Color, DEFAULT_ASSIGNED_USER_COLOUR, FontSize} from '../../constants';
import {StyledInitials} from './StyledInitials';
import {getInitials} from '../../projlibs/helperFunctions';
import S3ImageController from './S3ImageController';

// AssignedUser: StyledText and Image of the assigned user.  StyledText has user's initials and Image displays their profile
// Props:
// 1-1 prop fields are src, which is url of profile image, text, backgroundColor and margins
const AssignedUserView = styled.div`
	${(props) => css`
		display: flex;
		top: ${props.top};
		left: ${props.left};
		align-items: center;
		align-self: ${props.alignSelf};
		margin-top: ${props.marginTop};
		margin-right: ${props.marginRight};
		margin-bottom: ${props.marginBottom};
		margin-left: ${props.marginLeft};
		position: relative;
		/* Changed from 1 to 0 since these bubbles would be on top of the overlay and since they don't stack it doesn't need the z-index */
		z-index: 0;
	`}
`;

const StyledS3Image = styled(S3ImageController)`
	${(props) => css`
		left: ${props.left};
		position: absolute;
	`}
	border: 1px solid ${Color.white};
	width: 32px;
	height: 32px;
	border-radius: 50%;
`;

/* TODO:
Currently the AssignedUser.jsx is built to give a letter and a picture, because that was what the mocks show.
Really, that should be an array of users, and if the user does not have a picture it should be the first letter of their name and a color. 
So, this component will need to be functionally re-written.
*/

const AssignedInitials = styled(StyledInitials)`
	${(props) => css`
		left: ${props.left};
		position: absolute;
		background-color: ${props.colour};

	`}
	margin: 0;
	border: 1px solid ${Color.white};
	width: 32px;
	height: 32px;
	border-radius: 50%;
`;

export const AssignedUser = (props) => (
	<AssignedUserView {...props}>
		{
			props.users.map((user, index) => (
				user.img_s3_path ?
				<StyledS3Image
					key={index}
					left={`${(props.users.length - index - 1) * 19}px`}
					s3_path={user.img_s3_path}
				/> :
				<AssignedInitials
					colour={user.colour ? user.colour : DEFAULT_ASSIGNED_USER_COLOUR}
					key={index}
					left={`${(props.users.length - index - 1) * 19}px`}>
						{getInitials(user.full_name, 2)}
				</AssignedInitials>
			))
		}
	</AssignedUserView>
);

AssignedInitials.defaultProps = {
	numberOfInitials:'1',
	size: '32px',
	fontSize: FontSize.header5,
	backgroundColor: Color.sun,
	borderColor: Color.white,
};
