import React from 'react';
import CRMRAAWS from "./projlibs/awsMiddleLayer";

//The Data Model State is stored as a React Context to avoid having to pass it down manually to everything which uses it
//NOTE: this could also be done with Redux
//initially we set blank, null, or other sane default values but these will be overwritten as data from network calls is received
const DataContext = React.createContext({
	//login session
	userSessionId: '',
	setSessionId: () => {},
	userId: 0,
	setUserId: () => {},
	userImageURL: '',
	setUserImageURL: () => {},
	username: '',
	setUserName: () => {},
	userColour: '',
	setUserColour: () => {},
	userRoles: [],
	setUserRoles: () => {},
	projectStatuses: {},
	allStatuses: [],
	quoteStatuses: [],
	
	//aws
	aws: new CRMRAAWS(),
	
	//overlay
	overlayIsOpen: false,
	setOverlayVisibility: () => {},
	
	//current user permissions
	userPerms: {},
	setUserPerms: () => {},

	alerts: [],
	getAlerts: () => {},
	getAlertsByUserId: () => {},

	//project list
	projects: [],

	defaultQuoteStatusOrder: [
		'Quote Inquiry',
		'Quote New Request',
		'Quote in Progress',
		'Quote Info Required',
		'Quote Ready',
		'Quote Waiting',
		'Quote Rejected',
		'Archived'
	],

	defaultStatusOrder: [
		'GD New Project',
		'GD In Progress',
		'GD Ready',
		'GD Waiting',
		'GD Changes',
		'GD Approved',
		'Marketing Scheduling Required',
		'Web New Project',
		'Web Inquiry',
		'Web In Progress',
		'Web More Info',
		'Web Ready',
		'Web Waiting',
		'Web Changes',
		"Marketing Overview",
		"Marketing Inquiry",
		"Marketing Inquiry Waiting",
		"Marketing New Project",
		"Marketing Scheduling Required",
		"Marketing In Progress",
		"Marketing Changes",
		"Marketing Ready",
		"Marketing Waiting",
		"Marketing Review",
		"Marketing Approved",
		'Digital Billing Review',
		'Second Look',
		'Ready for Print',	
		'Out-Sourced',
		'Prod. Schedule',
		'In-Production',
		'Post Production',
		'Apparel Required',               
		'Backorder',
		'Quality Control',
		'Distribution Required',		
		'Deposit Required',
		'Deposit Received',
		'Install Required',
		'Ready to Bill',
		'Billed',
		'RB Monthly',
		'Delivery Required',
		'Shipping Required',
		'Customer Pick-Up',
		'Client Profile',
		'Web 45d Followup',
		'On Hold',
		'MIA',
		'Archived'
	]

});

//displayName is used internally by React Context for dev tools views
//to make debugging easier
DataContext.displayName = 'CRM Data Context';

export { DataContext };
