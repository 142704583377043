import React from 'react';
import styled, { css } from 'styled-components';
import { Color, FontSize } from '../../constants';

// Input: Used for general input.  Can also have left or right icon.  i.e search input.
// Props:
// 1-1 prop fields are width. height, value, placeholder, placeholderColor, fontSize, color, backgroundColor, borderRadius, borderWidth, borderColor, margins, box-shadow and onKeyPress
// icon: fontAwesomeIcon that will displayed  It can be left or right aligned.
// iconIsLeft: is icon on the left side of the or right side.
// NOTE: input-specific properties such as required, disabled, onChange, and onKeyUp will be honored in this component
// since those properties are passed directly to the <input> tag within this component
const Style = styled.div`
	${props => css`
		label {
			display: block;
			color: ${Color.nile50};
			font-size: ${FontSize.body2};
		}

		input {
			border-radius: ${props.borderRadius};
			width: 100%;
			box-sizing: border-box;
			background-color: ${props.backgroundColor ? props.backgroundColor : Color.nile5};
			color: ${props.color};
			height: ${props.height};
			text-align: left;
			font-size: ${props.fontSize};
			font-weight: ${props.theme.fontWeight.semibold};
			border-radius: ${props.borderRadius};
			border-width: ${props.borderWidth};
			border-color: ${props.borderColor};
			border-style: ${props.borderStyle};
			padding-top: ${props.paddingTop ? props.paddingTop : '0.5rem'};
			padding-right: ${props.paddingRight ? props.paddingRight : '0.5rem'};
			padding-bottom: ${props.paddingBottom ? props.paddingBottom : '0.5rem'};
			padding-left: ${props.paddingLeft ? props.paddingLeft : '0.5rem'};
			outline: none;
			transition: color 0.3s ease, border-color 0.3s ease;

			::placeholder {
				color: ${props.placeholderColor};
				opacity: 1;
			}
			
			&:hover, &:focus, &:active {
				color: ${props.hoverColor};
				border-color: ${props.hoverBorderColor ? props.hoverBorderColor : Color.zest };
				~ .icon {
					color: ${props.hoverColor};
				}
			}

		}

		margin: ${props.margin};
		margin-top: ${props.marginTop};
		margin-right: ${props.marginRight};
		margin-bottom: ${props.marginBottom};
		margin-left: ${props.marginLeft};
		width: ${props.width};
		flex: ${props.flex};

		//NOTE: div is not a focusable element and so it does not have focus or active states
		&:hover {
			border-color: ${props.hoverBorderColor};
			box-shadow: ${props.hoverBoxShadow};
		}
	`}
`;

export const Input = props => (
	<Style {...props}>
		{props.label && <label htmlFor={props.inputId}>{props.label}</label>}
		<input id={props.inputId} required={props.required} name={props.inputId} defaultValue={props.defaultValue} value={props.value} onChange={props.onChange} type={props.type ? props.type : 'text'} placeholder={props.placeholder} max={props.max} min={props.min} step={props.step} pattern={props.pattern}/>
	</Style>
);

Input.defaultProps = {
	fontSize: '1rem',
	borderColor: Color.nile15,
	borderRadius: '0.25rem',
	borderWidth: '0.0625rem',
	borderStyle: 'solid',
	width: '100%',
};

export const InvisibleInput = styled.input.attrs(props => ({
	type: props.inputType,
	id: props.id,
	onChange: props.onChange,
	accept: props.accept
}))`
  ${props => css`
    display:none;
  `}
`;
