import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Color, NAV_CLIENTS } from '../../../constants';
import { StyledText } from '../StyledText';
import { getInitials } from '../../../projlibs/helperFunctions';

const ClientResultView = styled.div`
	padding: 0.5rem 1rem 0.5rem 1rem;
	display: flex;
	border-radius: 3px;
	background-color: ${Color.white};
	cursor: pointer;
	&:hover {
		background-color: ${Color.ghost};
	}
`;

const ClientNameSquare = styled.div`
	${(props) => css`
		float: left;
		position: relative;
		display: flex;
		width: 35px;
		min-width: 35px;
		height: 35px;
		min-height: 35px;
		border-radius: 3px;
		justify-content: center;
		align-items: center;
		vertical-align: center;
		background-color: ${props.backgroundColor};
	`}
`;

const ClientNameSquareInitials = styled(StyledText)`
	text-align: center;
	color: ${Color.white};
`;

export const ClientSearchResult = ({ clientName, clientId, closeSearch, clientColor }) => {
	const history = useHistory();
	const handleClick = () => {
		history.push(`${NAV_CLIENTS}/${clientId}`);
		closeSearch();
	};
	return (
		<ClientResultView onClick={handleClick}>
			<ClientNameSquare backgroundColor={clientColor}>
				<ClientNameSquareInitials>
					{getInitials(clientName, 2)}
				</ClientNameSquareInitials>
			</ClientNameSquare>
			<StyledText paddingLeft="1rem" color={Color.nile90}>{clientName}</StyledText>
		</ClientResultView>
	);
}
