import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Color, DUE_DATE, FontSize, PROJECT_UPDATE_PERM} from '../../constants';
import {ProjectType} from '../Shared/ProjectType';
import {DropdownControlled} from '../Shared/Dropdown';
import {FormattedDate} from '../Shared/FormattedDate';
import {StyledText} from '../Shared/StyledText';
import {AssignedUser} from '../Shared/AssignedUser';
import {API} from '../../projlibs/api';
import {Link} from 'react-router-dom';
import S3ImageController from '../Shared/S3ImageController';
import {StyledInitials} from "../Shared/StyledInitials";
import {OldLinkView} from '../Shared/OldLinkView';
import {breakpoint} from '../../breakpoints';
import {DataContext} from '../../data-context';
import {success} from "../../projlibs/feedback";

// ProjectDetailsHeader: display the header in ProjectDetails
// Props:
// project: Project object with many fields used to set the various UI elements.
const ProjectDetailsHeaderView = styled.div``;

const BodyView = styled.div`
	display: flex;
	width: 100%;
	min-width: fit-content;
	background-color: ${Color.white};
	box-shadow: 0rem 0.0625rem 0.375rem ${Color.nile15};
	flex-direction: column;
	${StyledText} {
		font-size: 0.8125rem;
		color: ${Color.nile50};
		margin: 0.5rem 0rem;
	}
	${breakpoint('medium up')} {
		min-width: 600px;
		flex-direction: row;
	}
`;

const LeftView = styled.div`
	width: calc(100% - 3rem);
	padding: 1.5rem;
	border-right: 0.0625rem solid ${Color.mischka};
	display: flex;
	flex-flow: column;
	${StyledText} {
		margin: 0.25rem 0rem;
	}
	${breakpoint('medium up')} {
		width: fit-content;
	}
`;

const CenterView = styled.div`
	width: calc(100% - 3rem);
	display: flex;
	justify-content: flex-start;
	padding: 0.75rem 24px 2.75rem;
	a {
		color: ${Color.nile};
		font-size: 1.1875rem;
		margin-left: 1rem;
	}
	${breakpoint('medium up')} {
		padding: 2.75rem 1rem;
		justify-content: space-evenly;
		width: 100%;
	}
	${breakpoint('small down')}{
		flex-direction:column;
		align-items:flex-start;
		div{
			padding-right:0;
		}
	}
`;

const CenterLeft = styled.div`
	display: flex;
	align-items: center;

	${StyledText} {
		height: 3.5rem;
		color: ${Color.black};
		font-size: 1.4375rem;
		border-radius: 0.5rem;
		margin-top: 0rem;
		margin-bottom: 0rem;
	}
	padding-right: 2rem;
	${breakpoint('medium up')} {
		padding: 0;
	}
`;

const CenterRight = styled.div`
	display: flex;
	align-items: center;

	${StyledText} {
		color: ${Color.nile};
		font-size: 1.1875rem;
		margin-left: 1rem;
	}
`;

const RightView = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	${breakpoint('medium up')} {
		border-left: 0.0625rem solid ${Color.mischka};
		justify-content: space-around;
		flex-direction: column;
		width: fit-content;
	}
`;

const TopView = styled.div`
	padding: 0 24px 1.5rem;
	${breakpoint('medium up')} {
		padding: 0.75rem 1rem;
		border-bottom: 0.0625rem solid ${Color.mischka};
	}
`;

const BottomView = styled.div`
	height: 100%;
	min-width: 100px;
	padding: 0rem 1rem;
`;

const generateUpdateProjectUrl = (projectId) => `/project/${projectId}`;

const UpdateProjectStatus = (e, newStatus, type, projectId, updateProject) => {
	e.stopPropagation();
	API.put(generateUpdateProjectUrl(projectId), {'data' :{ 'project_status': newStatus, 'project_type': type }}).then(() => {
		updateProject();
		success("Status Updated Successfully");
	});
}

export const ProjectDetailsHeader = (props) => {
	const [userData, setUserData] = useState({});
	const { userPerms } = useContext(DataContext);
	const manager = userData?.User ? userData?.User[0] : null;
	const statusValues = props.statuses ? props.statuses : [];

	useEffect(() => {
		if(props.project.project_manager){
			API.get(`/user/${props.project.project_manager}`).then(resp => setUserData(resp.data));
		}
	}, [props.project.project_manager]);
	const valid_url = props.project && props.project.old_project_url
	return (
		<ProjectDetailsHeaderView>
			<StyledText
				as='h1'
				color={Color.nile}
				fontSize={FontSize.header2}
				marginBottom='24px'>
				#{props.project?.project_id} | {props.project?.name}
			</StyledText>
			<BodyView>
				<LeftView>
					<ProjectType
						viewColor={props.project?.Project_Type?.color ? props.project?.Project_Type?.color : Color.bay}
						title={props.project?.Project_Type?.name}
						fontSize='0.8125rem'
						marginBottom='0.75rem'
					/>
					<StyledText>Status</StyledText>
					{props.is_quote ?
						<DropdownControlled
							disabled={!userPerms[PROJECT_UPDATE_PERM]}
							key={props.project.project_id}
							options={props.quoteStatuses}
							values={props.quoteStatuses}
							value={props.project?.Project_Status?.name}
							onChange={(e) => UpdateProjectStatus(e, e.target.value, props.project?.Project_Type?.name, props.project?.project_id, props.updateProject)}
						/>
						:
						props.statuses &&
						<DropdownControlled
							disabled={!userPerms[PROJECT_UPDATE_PERM]}
							key={props.project.project_id}
							options={statusValues}
							values={statusValues}
							value={props.project?.Project_Status?.name}
							onChange={(e) => UpdateProjectStatus(e, e.target.value, props.project?.Project_Type?.name, props.project?.project_id, props.updateProject)}
						/>
					}
					<FormattedDate
						date={props.project?.modified_at}
						format='MMM DD, YYYY'
						preText='Last updated:'/>
				</LeftView>
				<CenterView>
					<div>
						<StyledText>Client</StyledText>
						<Link to={`/clients/${props.project?.client_id}`} style={{ textDecoration: 'none', marginLeft: '0px' }}>
							<CenterLeft>
								<StyledText horizontalAlign='center'>{props.project?.Client?.name}</StyledText>
							</CenterLeft>
						</Link>
					</div>
					{
						manager &&
						<div>
							<StyledText>Project Manager</StyledText>
							<CenterRight>
								{manager.img_s3_path ?
									<S3ImageController
										s3_path={manager.img_s3_path}
										width='3.5rem'
										height='3.5rem'
										borderRadius='50%'
									/>
									:
									<StyledInitials
										size='3.5rem'
										marginTop='0'
										marginBottom='0'
										fontSize={FontSize.header5}
										borderRadius='50%'
										backgroundColor={manager.colour ? manager.colour : Color.zest}
										numberOfInitials={2}>
										{manager.full_name}
									</StyledInitials>
								}
								<StyledText>
									{manager.full_name}
								</StyledText>
							</CenterRight>
						</div>
					}
				</CenterView>
				<RightView>
					<TopView>
						<StyledText>{DUE_DATE}</StyledText>
						{
							props.project?.date_due ? <FormattedDate date={props.project?.date_due} /> : <StyledText>__</StyledText>
						}
					</TopView>
					<BottomView>
						<StyledText>Assigned</StyledText>
						<AssignedUser
							top='20px'
							users={props.project?.assigned_users}
						/>
					</BottomView>
				</RightView>
			</BodyView>
			{
				typeof(props.project?.old_project_url)==='string' && valid_url && 
				<OldLinkView>
					This is an imported project; the original data is at <br />
				<a href={valid_url?props.project.old_project_url:''} target='_blank' rel='noopener noreferrer'>
						{props.project?.old_project_url}
					</a>
				</OldLinkView>
			}
			{
				typeof(props?.project?.copied_from_id)==='number' &&
				<OldLinkView>
					This is a copied project; the original data is at <br />
					<a href={'/projects/'+props?.project?.copied_from_id} target='_blank' rel='noopener noreferrer'>
						{window.location.protocol+'//'+window.location.host+'/projects/'+props?.project?.copied_from_id}
					</a>
				</OldLinkView>
			}
		</ProjectDetailsHeaderView>
	);
};
