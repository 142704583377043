import React from 'react';
import styled from 'styled-components';
import { Color, FontSize, FontWeight } from '../../constants';
import { StyledText } from '../Shared/StyledText';
import { StyledBase } from './StyledBase';

const PageIndexView = styled.div`
    ${StyledBase}
	display: flex;
	flex-direction: row;
	margin-left: auto;
`;

export const PageIndex = (props) => (
    <PageIndexView {...props}>
        <StyledText
            whiteSpace='nowrap'
            as='h1'
            color={Color.nile}
            marginRight='4px'
            fontSize={FontSize.body1}>
            {
                props.resultCount > 0 ?
                `${props.currentPage * props.pageSize + 1}-${props.currentPage * props.pageSize + props.pageSize > props.resultCount ? props.resultCount : props.currentPage * props.pageSize + props.pageSize}`
                : 0
            }
        </StyledText>
        <StyledText
            fontWeight={FontWeight.normal}
            whiteSpace='nowrap'
            as='h1'
            marginRight='16px'
            color={Color.nile}
            fontSize={FontSize.body1}>
            of {props.resultCount}
        </StyledText>
    </PageIndexView>
);

PageIndex.defaultProps = {
    pageSize: 10,
};
