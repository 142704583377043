import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {HOURS, NAME, MS_PER_S} from '../../constants';
import {StyledText} from '../Shared/StyledText';
import {breakpoint} from '../../breakpoints';
import {FlexTableController} from '../Shared/FlexTableController';
import {DropdownWithText} from '../Shared/DropDownWithText';
import {API, useGetData} from '../../projlibs/api';
import {convertMinutesToHours} from '../../projlibs/helperFunctions';
import downloadCsv from 'download-csv';
import {ReportHeader} from './ReportHeader';
import moment from 'moment';
import {updateSorting} from "./Reports";
import { DateTimeFormInput, DateInputType } from '../Shared/DateTimeFormInput';

const CustomizeReportView = styled.div`
	padding: 0.5rem 1.5rem;
`;

const ProjectSelectionView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    ${breakpoint('medium up')} {
        flex-direction: row;
    }
`;

const ReportToolbar = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    ${breakpoint('medium up')} {
        flex-direction: row;
    }
`;

const SetupTableDataTotalTime = (userTotalTimes) => ({
    titles: [
        NAME,
        HOURS,
    ],
    rows: userTotalTimes.map((entry) => {
        return [
            {
                sortValue: entry.name,
                element: (
                    <StyledText>{entry.name}</StyledText>
                ),
            },
            {
                sortValue: entry.total_time,
                element: (
                    <StyledText>{entry.total_time ? convertMinutesToHours(entry.total_time) : 0}</StyledText>
                ),
            }
        ];
    }),
});

const generateUserSearchUrl = (query) => `/user/search?page_size=&match_name=${query}&order_by=created_at:desc`;

const generateUserTimeUrl = (userId, startDate, endDate, pageIndex = 0, pageSize = 10, orderBy) => {
    let url = `/user/${userId}/time?page_size=${pageSize}&page=${pageIndex}&full=true`;
    if (startDate) {
        url += `&after=${startDate}`;
    }
    if (endDate) {
        url += `&before=${endDate}`;
    }
    if (orderBy) {
        url += `&${orderBy}`;
    }
    return url;
};

export const UserTimeTotals = () => {
    const [pageIndex, ] = useState(0);
    const [userResults, setUserResults] = useState([]);
    const [userResultsIsLoading, setUserResultsIsLoading] = useState(false);
    const [timeEntries, setTimeEntries] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [persistStartDate, setPersistStartDate] = useState();
    const [endDate, setEndDate] = useState(moment().unix());
    const [persistEndDate, setPersistEndDate] = useState();
    const [selectedUser, setSelectedUser] = useState('');
    const [persistSelectedUser, setPersistSelectedUser] = useState();
    const [orderBy, setOrderBy] = useState("");

    const [{ data: projectStatusData }] = useGetData('/project/status');
    const projectStatuses = projectStatusData?.Project_Status;
    const [{ data: projectTypeData }] = useGetData('/project/type');
    const projectTypes = projectTypeData?.Project_Type?.map(type => ({ id: type.project_type_id, color: type.color, name: type.name }));
    useEffect(() => {
        handleUserSearchChange('');
    }, []);
    const handleUserSearchChange = (query) => {
        if (typeof query === 'string') {
            setUserResultsIsLoading(true);
            setUserResults([]);
            API.get(generateUserSearchUrl(query)).then(response => {
                const results = response.data?.User;
                const normalizedResults = results ? results.map(result => ({
                    textField: result.full_name,
                    valueField: `${result.user_id}`,
                })) : [];
                setUserResults(normalizedResults);
                setUserResultsIsLoading(false);
            }).catch(error => {
                API.default_error_handler(error);
                setUserResultsIsLoading(false);
            });
        }
    };
    const handleSelectedUserChange = (value) => {
        if (typeof value === 'string') {
            setSelectedUser(value);
        }
    };
    const handleCSV = () => {
        if (timeEntries?.length !== 0) {
            API.get(generateUserTimeUrl(persistSelectedUser, persistStartDate, persistEndDate, 0, '', orderBy)).then(response => {
                let totalTime = 0.0;
                if (response.data.Time_Entry) {
                    response.data.Time_Entry.forEach(entry => totalTime += entry.duration_mins)
                }
                let name = userResults.find(user => user.valueField === persistSelectedUser)?.textField;
                const results = [{ name: name, total_time: totalTime ? convertMinutesToHours(totalTime) : 0 }];
                const csvData = results.map(result => {
                    return {
                        User: '"'+(result.name?.replaceAll('"',''))+'"',
                        User_Id: `${persistSelectedUser}`,
                        Total_Hrs: `${result.total_time}`,
                    };
                });
                downloadCsv(csvData, null, `${name}_Time_Entries_${Math.floor(new Date().getTime() / 1000)}`);
            }).catch(API.default_error_handler);
        }
    };
    const handleGenerate = () => {
        setPersistSelectedUser(selectedUser);
        setPersistStartDate(startDate);
        setPersistEndDate(endDate);
    };
    useEffect(() => {
        if (persistSelectedUser) {
            API.get(generateUserTimeUrl(persistSelectedUser, persistStartDate, persistEndDate, 0, '', orderBy)).then(response => {
                let totalTime = 0.0;
                if (response.data.Time_Entry) {
                    response.data.Time_Entry.forEach(entry => totalTime += entry.duration_mins)
                }
                let name = userResults.find(user => user.valueField === persistSelectedUser)?.textField;
                setTimeEntries([{ name: name, total_time: totalTime}]);
            }).catch(API.default_error_handler);
        }
    }, [persistSelectedUser, pageIndex, persistStartDate, persistEndDate, orderBy, userResults]);
    return (
        <CustomizeReportView>
            <ReportHeader generateOnClick={handleGenerate} csvOnClick={handleCSV} csvDisabled={!persistSelectedUser} generateDisabled={!selectedUser}/>
            <ReportToolbar>
                <ProjectSelectionView>
                    <DropdownWithText
                        disableFiltering
                        busy={userResultsIsLoading}
                        label='Select a User'
                        data={userResults}
                        textField='textField'
                        width='30%'
                        height='42px'
                        placeholder='-select-'
                        onChange={(value) => handleUserSearchChange(value)}
                        onSelect={(value) => handleSelectedUserChange(value.valueField)}
                    />
                    <DateTimeFormInput
                        label='Start Date'
                        placeholder='mm/dd/yyyy'
                        format='MMM D, YYYY'
                        width='25%'
                        marginLeft='12px'
                        value={startDate ? startDate * MS_PER_S : null}
                        type={DateInputType}
                        onChange={(e) => setStartDate(e ? e.unix() : null)}
                    />
                    <DateTimeFormInput
                        label='End Date'
                        placeholder='mm/dd/yyyy'
                        format='MMM D, YYYY'
                        width='25%'
                        marginLeft='12px'
                        value={endDate ? endDate * MS_PER_S : null}
                        type={DateInputType}
                        onChange={(e) => setEndDate(e ? e.unix() : null)}
                    />
                </ProjectSelectionView>
            </ReportToolbar>
            {
                (projectStatuses && projectTypes) &&
                <FlexTableController rowPadding='0.5rem 1rem 0.5rem 1rem' tableData={SetupTableDataTotalTime(timeEntries, projectTypes)} sort={(e, title) => updateSorting(e,title, setOrderBy)}/>
            }
        </CustomizeReportView>
    );
};
