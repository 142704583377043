import React, {useState} from 'react';
import {CreateForm, FormDropdown, FormInput, Header, Overlay, SubmitButton} from '../Shared/CreateForm';
import styled from 'styled-components';
import {StyledCheckbox} from '../Shared/StyledCheckbox.jsx';
import {breakpoint} from '../../breakpoints.js';
import {SERVICE_FREQUENCY_LIST, SERVICE_STATUS_LIST, SERVICE_TYPE_LIST, MS_PER_S} from '../../constants.js';
import moment from 'moment';
import { DateTimeFormInput, DateInputType } from '../Shared/DateTimeFormInput';

const ServiceFormOverlay = styled(Overlay)`
    ${breakpoint('medium up')} {
        height: fit-content;
    }
    width: 100%;
    height: calc(100% - 6rem);
`;

export const CreateServiceForm = (props) => {
    const mode = props.service ? 'edit' : 'create';
    const [name, setName] = useState(props.service ? props.service.name : '');
    const [type, setType] = useState(props.service ? props.service.type : '');
    const [renewal, setRenewal] = useState(props.service ? props.service.renewal : moment().unix());
    const [expiry, setExpiry] = useState(props.service ? (props.service.expiry ? props.service.expiry : null) : null);
    const [rate, setRate] = useState(props.service ? props.service.rate : '');
    const [frequency, setFrequency] = useState(props.service ? props.service.frequency : '');
    const [isPrimaryDomain, setIsPrimaryDomain] = useState(props.service ? props.service.is_primary : false);
    const [status, setStatus] = useState(props.service ? props.service.status : '');
    const SubmitForm = () => {
        props.onSubmit({
            id: props.service ? props.service.web_service_id : null,
            type: type,
            name: name,
            renewal: renewal,
            expiry: expiry,
            rate: rate,
            frequency: frequency,
            is_primary: mode === 'edit' ? isPrimaryDomain : null,
            primary: mode === 'edit' ? null : isPrimaryDomain,
            status: status,
        }, mode);
        props.onClose();
    };
    return (
        <ServiceFormOverlay>
            <Header onClose={props.onClose} title={mode === 'edit' ? 'Edit a Service' : 'Create a Service'}/>
            <CreateForm onSubmit={SubmitForm}>
                <FormDropdown
                    label='Service Type'
                    placeholder='-select-'
                    defaultValue={type}
                    width='100%'
                    values={SERVICE_TYPE_LIST}
                    options={SERVICE_TYPE_LIST}
                    onChange={e => setType(e.target.value)}
                    required
                />
                <FormInput
                    label='Service Name'
                    placeholder='...'
                    defaultValue={name}
                    width='100%'
                    onChange={e => setName(e.target.value)}
                    required
                />
                <DateTimeFormInput
                    label='Renewal'
                    format='MM/DD/YYYY'
                    placeholder='mm/dd/yyyy'
                    value={renewal ? renewal * MS_PER_S : null}
                    width='49%'
                    type={DateInputType}
                    onChange={e => setRenewal(e?.unix())}
                />
                <DateTimeFormInput
                    label='Expiry'
                    format='MM/DD/YYYY'
                    placeholder='mm/dd/yyyy'
                    value={expiry ? expiry * MS_PER_S : null}
                    width='49%'
                    type={DateInputType}
                    onChange={e => setExpiry(e?.unix())}
                />
                <FormInput
                    label='Rate ($)'
                    placeholder={0}
                    defaultValue={rate}
                    width='49%'
                    type='number'
                    step='0.01'
                    onChange={e => setRate(e.target.value)}
                    required
                />
                <FormDropdown
                    label='Frequency'
                    placeholder='-select-'
                    defaultValue={frequency}
                    width='49%'
                    values={SERVICE_FREQUENCY_LIST}
                    options={SERVICE_FREQUENCY_LIST}
                    onChange={e => setFrequency(e.target.value)}
                    required
                />
                <StyledCheckbox
                    label='Primary Domain?'
                    checked={isPrimaryDomain}
                    width='49%'
                    onChange={() => setIsPrimaryDomain(!isPrimaryDomain)}
                />
                <FormDropdown
                    label='Active Status'
                    placeholder='-select-'
                    defaultValue={status}
                    values={SERVICE_STATUS_LIST}
                    options={SERVICE_STATUS_LIST}
                    width='49%'
                    onChange={e => setStatus(e.target.value)}
                    required
                />
                <SubmitButton title={mode === 'edit' ? 'Edit Service' : 'Create Service'} type='submit' width='100%'/>
            </CreateForm>
        </ServiceFormOverlay>
    );
};
