import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Color, MS_PER_S, FontSize, NAV_PROJECTS, NAV_CLIENTS, TYPE, JOB_NUMBER, NAME, CLIENT, CREATED, PROJECT_TOTAL_PRICE} from '../../constants';
import {StyledText} from '../Shared/StyledText';
import {breakpoint} from '../../breakpoints';
import {FlexTableController} from '../Shared/FlexTableController';
import {date, FormattedDate} from '../Shared/FormattedDate';
import {API} from '../../projlibs/api';
import downloadCsv from 'download-csv';
import {ReportHeader} from './ReportHeader';
import moment from 'moment';
import {updateSorting} from "./Reports";
import { DateTimeFormInput, DateInputType } from '../Shared/DateTimeFormInput';
import { FormInput } from '../Shared/CreateForm';
import { ProjectType } from '../Shared/ProjectType';
import { Link } from 'react-router-dom';
import { StyledLink } from '../Shared/StyledLink';
import { PageIndex } from '../Shared/PageIndex';
import { PageController } from '../Shared/PageController';
import { warning, success } from '../../projlibs/feedback';

const PAGE_SIZE = 20;

const CustomizeReportView = styled.div`
	padding: 0.5rem 1.5rem;
`;

const ProjectSelectionView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    ${breakpoint('medium up')} {
        flex-direction: row;
    }
`;

const HoverUnderlineName = styled(StyledText)`
	:hover {
		text-decoration: underline;
	}
`;

const ReportToolbar = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    ${breakpoint('medium up')} {
        flex-direction: row;
    }
`;

const SetupTableData = (completedProjects = []) => ({
    titles: [
        TYPE,
        CREATED,
        JOB_NUMBER,
        NAME,
        CLIENT,
        PROJECT_TOTAL_PRICE,
    ],
    rows: completedProjects.map(project => [
        {
            sortValue: project.Project_Type?.name,
            element: (
                <ProjectType
                    viewColor={project.Project_Type?.color}
                    title={project.Project_Type?.name}
                />
            ),
        },
        {
            minWidth: '80px',
            sortValue: project.created_at,
            element: (
                <FormattedDate
                    date={project.created_at}
                    color={Color.nile}
                />
            ),
        },
        {
            sortValue: project?.project_id,
            element: (
                <StyledText color={Color.nile50} fontSize={FontSize.body1}>
                    {project?.project_id}
                </StyledText>
            ),
        },
        {
            sortValue: project?.name,
            element: (
                <Link to={`${NAV_PROJECTS}/${project?.project_id}`} style={{ textDecoration: 'none' }}>
                    <HoverUnderlineName
                        color={Color.nile}
                        fontSize={FontSize.body1}
                        marginBottom='4px'>
                        {project?.name}
                    </HoverUnderlineName>
                </Link>
            ),
        },
        {

            sortValue: project?.Client?.name,
            element: (
                <StyledLink to={`${NAV_CLIENTS}/${project?.client_id}`}>
                    {project?.Client?.name}
                </StyledLink>
            ),
        },
        {
            sortValue: project?.calculated_total_price,
            element: (
                <StyledText color={Color.nile50} fontSize={FontSize.body1}>
                    {project?.calculated_total_price}
                </StyledText>
            ),
        },
    ]),
});

const generateProjectSearchUrl = (persistStartDate, persistEndDate, persistMinimumTotal, persistMaximumTotal, orderBy, pageIndex, pageSize = PAGE_SIZE) => {
    let projectSearchUrl = `/project/search?match_project_status=Archived&page_size=${pageSize}&page=${pageIndex}`;
    if (persistStartDate) {
        projectSearchUrl += `&after=${persistStartDate}`;
    }
    if (persistEndDate) {
        projectSearchUrl += `&before=${persistEndDate}`;
    }
    if (persistMinimumTotal) {
        projectSearchUrl += `&min_total_price=${persistMinimumTotal}`;
    }
    if (persistMaximumTotal) {
        projectSearchUrl += `&max_total_price=${persistMaximumTotal}`;
    }
    if (orderBy) {
        if(orderBy.includes('project_name')){
            const newOrderBy = orderBy.replace('project_name', 'name');
            projectSearchUrl += `&${newOrderBy}`;
        }
        else{
            projectSearchUrl += `&${orderBy}`;
        }
    }
    return projectSearchUrl;
};

export const LargeOrder = () => {
    const [pageIndex, setPageIndex] = useState(0);
    const [archivedProjects, setArchivedProjects] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [persistStartDate, setPersistStartDate] = useState(null);
    const [endDate, setEndDate] = useState(moment().unix());
    const [persistEndDate, setPersistEndDate] = useState(null);
    const [minimumTotal, setMinimumTotal] = useState('0');
    const [persistMinimumTotal, setPersistMinimumTotal] = useState(null);
    const [maximumTotal, setMaximumTotal] = useState('0');
    const [persistMaximumTotal, setPersistMaximumTotal] = useState(null);
    const [hasGenerated, setHasGenerated] = useState(false);
    const [resultCount, setResultCount] = useState(0);
    const [orderBy, setOrderBy] = useState("");
    const handleGenerate = () => {
        setHasGenerated(true);
        setPersistMinimumTotal(minimumTotal ? parseFloat(minimumTotal) : null);
        setPersistMaximumTotal(maximumTotal ? parseFloat(maximumTotal) : null);
        setPersistStartDate(startDate);
        setPersistEndDate(endDate);
    };
    const handleCSV = () => {
        if (resultCount !== 0) {
            if (resultCount > 1000) {
                warning("CSV request for more than 1000 entries can take up to 3 minutes or more.");
            }
            API.get(generateProjectSearchUrl(persistStartDate, persistEndDate, persistMinimumTotal, persistMaximumTotal, orderBy, pageIndex, ''), {timeout: 0}).then(response => {
                const results = response.data.Project ? response.data.Project : [];
                const csvData = results.map(result => ({
                    Project_Type: `"${result.Project_Type?.name.replaceAll('"', '').replaceAll(',','')}"`,
                    Created_At: `"${result.created_at ? date({ date: result.created_at }) : `No Date`}"`,
                    Job_Number: `${result.project_id}`,
                    Project_Name: `"${result.name.replaceAll('"', '').replaceAll(',','')}"`,
                    Client_Name: `"${result.Client?.name.replaceAll('"', '').replaceAll(',','')}"`,
                    Total_Price: `${result.calculated_total_price}`,
                }));
                downloadCsv(csvData, null, `Completed_Projects_${Math.floor(new Date().getTime() / MS_PER_S)}`);
                success("CSV successfully assembled and downloaded.");
            }).catch(() => API.default_error_handler);
        }
    };
    useEffect(() => {
        if (hasGenerated) {
            API.get(generateProjectSearchUrl(persistStartDate, persistEndDate, persistMinimumTotal, persistMaximumTotal, orderBy, pageIndex)).then(response => {
                let projects = response.data.Project ? response.data.Project : [];
                setResultCount(response.data.result_count);
                setArchivedProjects(projects);
            }).catch(API.default_error_handler);
        }
    }, [persistStartDate, persistEndDate, orderBy, hasGenerated, persistMinimumTotal, persistMaximumTotal, pageIndex]);
    return (
        <CustomizeReportView>
            <ReportHeader generateOnClick={handleGenerate} csvOnClick={handleCSV} csvDisabled={!hasGenerated} generateDisabled={false}/>
            <ReportToolbar>
                <ProjectSelectionView>
                    <FormInput
                        label='Minimum Total Price'
                        height='44px'
                        width='150px'
                        type='number'
                        marginBottom='0px'
                        value={minimumTotal}
                        onChange={(e) => setMinimumTotal(e.target.value)}
                    />
                    <FormInput
                        label='Maximum Total Price'
                        height='44px'
                        width='150px'
                        marginLeft='12px'
                        type='number'
                        marginBottom='0px'
                        value={maximumTotal}
                        onChange={(e) => setMaximumTotal(e.target.value)}
                    />
                    <DateTimeFormInput
                        label='Start Date'
                        placeholder='mm/dd/yyyy'
                        format='MMM D, YYYY'
                        width='25%'
                        marginLeft='12px'
                        value={startDate ? startDate * MS_PER_S : null}
                        type={DateInputType}
                        onChange={(e) => setStartDate(e ? e.unix() : null)}
                    />
                    <DateTimeFormInput
                        label='End Date'
                        placeholder='mm/dd/yyyy'
                        format='MMM D, YYYY'
                        width='25%'
                        marginLeft='12px'
                        value={endDate ? endDate * MS_PER_S : null}
                        type={DateInputType}
                        onChange={(e) => setEndDate(e ? e.unix() : null)}
                    />
                </ProjectSelectionView>
                <PageIndex resultCount={resultCount} currentPage={pageIndex} pageSize={PAGE_SIZE} />
            </ReportToolbar>
            <FlexTableController rowPadding='0.5rem 1rem 0.5rem 1rem' tableData={SetupTableData(archivedProjects)} sort={(e, title) => updateSorting(e,title, setOrderBy)}/>
            <PageController pageNumber={pageIndex} setPageNumber={setPageIndex} numPages={Math.ceil(resultCount / PAGE_SIZE)} />
        </CustomizeReportView>
    );
};
