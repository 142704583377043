import React from 'react';
import styled, { css } from 'styled-components';
import { Color, FontSize } from '../../constants';
import { DateTimePicker, TimePicker, DatePicker } from '@material-ui/pickers';

export const TimeInputType = 'time';
export const DateInputType = 'date';
export const DateTimeInputType = 'datetime';
export const InputTypes = [TimeInputType, DateInputType, DateTimeInputType];

const Style = styled.div`
	${props => css`
		label {
			display: block;
			color: ${Color.nile50};
			font-size: ${FontSize.body2};
		}

		input {
			height: ${props.height ? props.height : '44px'};
			border-radius: ${props.borderRadius};
			width: 100%;
			box-sizing: border-box;
			background-color: ${props.backgroundColor ? props.backgroundColor : Color.nile5};
			color: ${props.color};
			height: ${props.height};
			text-align: left;
			font-size: ${props.fontSize};
			font-weight: ${props.theme.fontWeight.semibold};
			border-radius: ${props.borderRadius};
			border-width: ${props.borderWidth};
			border-color: ${props.borderColor};
			border-style: ${props.borderStyle};
			padding-top: ${props.paddingTop ? props.paddingTop : '0.5rem'};
			padding-right: ${props.paddingRight ? props.paddingRight : '0.5rem'};
			padding-bottom: ${props.paddingBottom ? props.paddingBottom : '0.5rem'};
			padding-left: ${props.paddingLeft ? props.paddingLeft : '0.5rem'};
			outline: none;
			transition: color 0.3s ease, border-color 0.3s ease;

			::placeholder {
				color: ${props.placeholderColor};
				opacity: 1;
			}
			
			&:hover, &:focus, &:active {
				/* border-bottom: unset; */
				color: ${props.hoverColor};
				border-color: ${props.hoverBorderColor ? props.hoverBorderColor : Color.zest };
				~ .icon {
					color: ${props.hoverColor};
				}
			}

		}
		.MuiFormControl-root {
			width: 100%;
		}
		.MuiInput-underline:before {
			border-bottom: unset;
		}
		.MuiInput-underline:hover:not(.Mui-disabled):before {
			border-width: 0;
		}

		margin: ${props.margin};
		margin-top: ${props.marginTop};
		margin-right: ${props.marginRight};
		margin-bottom: ${props.marginBottom};
		margin-left: ${props.marginLeft};
		width: ${props.width};
		flex: ${props.flex};

		//NOTE: div is not a focusable element and so it does not have focus or active states
		&:hover {
			border-color: ${props.hoverBorderColor};
			box-shadow: ${props.hoverBoxShadow};
		}
	`}
`;

export const DateTimeFormInput = (props) => {
	switch(props.type) {
		case TimeInputType:
			return (
				<Style {...props}>
					<label htmlFor={props.inputId}>{props.label}</label>
					<TimePicker
						id={props.inputId}
						clearable={props.clearable ? props.clearable : true}
						onChange={props.onChange ? props.onChange : () => {}}
						value={props.value}
						emptyLabel={props.placeholder}
						format={props.format}
						invalidDateMessage={props.validation ? 'Invalid Date' : null}
					/>
				</Style>
			);
		case DateInputType:
			return (
				<Style {...props}>
					<label htmlFor={props.inputId}>{props.label}</label>
					<DatePicker
						id={props.inputId}
						clearable={props.clearable ? props.clearable : true}
						onChange={props.onChange ? props.onChange : () => {}}
						value={props.value}
						emptyLabel={props.placeholder}
						format={props.format}
						invalidDateMessage={props.validation ? 'Invalid Date' : null}
					/>
				</Style>
			);
		case DateTimeInputType:
			return (
				<Style {...props}>
					<label htmlFor={props.inputId}>{props.label}</label>
					<DateTimePicker
						id={props.inputId}
						clearable={props.clearable ? props.clearable : true}
						onChange={props.onChange ? props.onChange : () => {}}
						value={props.value}
						emptyLabel={props.placeholder}
						format={props.format}
						invalidDateMessage={props.validation ? 'Invalid Date' : null}
					/>
				</Style>
			);
		default:
			throw new Error(`DateTimeFormInput: input type ${props.type} is not supported. Please use one of the following: ${InputTypes.join(',')}.`)
	}
};

Style.defaultProps = {
	borderColor: Color.nile15,
	borderRadius: '0.25rem',
	borderWidth: '0.0625rem',
	borderStyle: 'solid',
	fontSize: '0.9rem',
	marginBottom: '24px',
};
