import React from 'react';
import styled, { css } from 'styled-components';
import { StyledText } from '../Shared/StyledText';
import { Color, FontSize } from '../../constants';

// ProjectType: Displays the project type and a coloured view.
// Props:
// 1-1 props fields are color, fontSize and margins
// title: project title to display.
// viewColor: color of rounded view
const ProjectTypeView = styled.div`
	${props => css`
		display: flex;

		${StyledText} {
			color: ${Color.nile1};
			font-size: ${FontSize.caption1};
			padding: 8px;
			text-transform: uppercase;
		}
	`}
`;

const RoundedView = styled.div`
	${props => css`
		min-width: 8px;
		background-color: ${props.viewColor};
		border-radius: 4px;
	`}
`;

export const ProjectType = props => (
	<ProjectTypeView {...props}>
		<RoundedView viewColor={props.viewColor}></RoundedView>
		<StyledText fontSize={props.fontSize}>{props.title}</StyledText>
	</ProjectTypeView>
);

ProjectType.defaultProps = {
	className: 'ProjectType',
	viewColor: 'blue'
};
