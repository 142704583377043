import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {ContactFields} from './ContactFields';
import {AdditionalFields} from '../Shared/CreateForm';
import {breakpoint} from '../../breakpoints';

const CompanyContact = styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    ${breakpoint('small only')} {
		flex-direction: column
	}
`;

export const CompanyContactForm = (props) => {
    const [contactFields, setContactFields] = useState([]);

    const addContact = (e) => {
        e.preventDefault();
        props.setCounter(props.counter + 1);
    }
    
    const updateContacts = useCallback((data, index) => {
        let contacts = [...props.contacts];
        contacts[index] = data;
        props.setContacts(contacts);
    }, [props])

    const getContact = useCallback((index) => {
        return props.contacts[index] ? props.contacts[index] : "";
    }, [props])    

    useEffect(() => {
        let fields = [];
        for (let i = 0; i < props.counter; i++){
            fields.push(<ContactFields statuses={props.statuses} contacts={props.contacts} getContact={getContact} updateContact={updateContacts} index={i} key={i} />);
        }
        setContactFields(fields);
    },[updateContacts, getContact, props.counter, props.contacts, props.statuses])

    return (
        <CompanyContact>   
            {contactFields}
            <AdditionalFields
                title='Add additional Contact +'
                paddingLeft='0px'
                onClick={(e) => addContact(e)}
            />
        </CompanyContact>
    )
};