import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {useHistory} from 'react-router-dom';
import {Color, FontSize, NAV_PROJECTS, NAV_QUOTES, PROJECT, STATUS, TYPE} from '../../constants';
import {StyledText} from '../Shared/StyledText';
import {Button, TransparentButton} from '../Shared/Button';
import {Dropdown} from '../Shared/Dropdown';
import {ProjectType} from '../Shared/ProjectType';
import {ProjectInfo} from '../Shared/ProjectInfo';
import {FormattedDate} from '../Shared/FormattedDate';
import {FlexTableController} from '../Shared/FlexTableController';
import {faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';
import {faAngleRight} from '@fortawesome/pro-regular-svg-icons';
import {updateSorting} from './Projects';
import {DataContext} from '../../data-context';

// ProjectStatusGroup: Displays a group of projects which have a shared status attribute
// Props:
// title: project group title to display.
// projects: the list of projects to display
// formatData: the format data function which maps raw project data into a table view
// highlightColor: the color of the border to display
// isCollapsed: whether or not the display should be "collapsed" versus open
// collapseToggle: the function to change current collapsed/uncollapsed state
const ProjectStatusGroupView = styled.div`
	${(props) => css`
		background-color: ${props.resultCount > 0 ? Color.white : Color.transparent};
		max-height: ${props.isCollapsed ? '61px' : 'auto'};
		overflow: hidden;
		border-radius: 5px;
		margin-bottom: 0.5rem;
		box-shadow: ${props.resultCount > 0 ? `0px 1px 6px ${Color.nile15}` : 'unset'};
		border-width: ${props.resultCount > 0 ? 'unset' : '1px'};
		border-color: ${props.resultCount > 0 ? 'unset' : Color.mischka};
		border-style: ${props.resultCount > 0 ? 'unset' : 'solid'};
	`}
`;

const GroupSelect = styled.div`
	${(props) => css`
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding: 16px 0px;
		border-left: ${props.resultCount > 0 ? `4px solid ${props.highlightColor}` : 'unset'};
		border-bottom: ${props.resultCount > 0 ? `1px ${props.isCollapsed ? 'none' : 'solid'} ${props.highlightColor}` : 'unset'};
	`}
`;

const CountBubble = styled.div`
	border-radius: 50%;
	min-width: 1.5rem;
	height: 1.5rem;
	padding: 0.35rem;
	font-size: ${FontSize.body1};
	color: ${Color.nile};
	background-color: ${Color.nile5};
	line-height: 1.5rem;
	vertical-align: middle;
	text-align: center;
`;

const ShowMoreView = styled.div`
	justify-content: center;
	display: flex;
	padding: 16px;
`;

function SetupTableData(projects, statuses, onStatusChange, orderBy, collapseToggle, isQuote) {
	let history = useHistory();

	let tableData = {
		titles: [TYPE, PROJECT, STATUS, 'Last Updated', 'Date Due', ''],
		rows: projects.map((project) => {
			const typeStatuses = statuses[project.Project_Type?.name];
			const statusValues = typeStatuses ? typeStatuses.filter(status => status !== project?.Project_Status?.name) : [];
			return [
				{
					sortValue: project.Project_Type?.name,
					element: (
						<ProjectType
							viewColor={project.Project_Type?.color}
							title={project.Project_Type?.name}
						/>
					),
				},
				{
					doesGrow: true,
					sortValue: project.name,
					element: (
						<ProjectInfo
							clientId={project?.client_id}
							id={project.project_id}
							name={project?.name}
							clientName={project?.Client?.name}
							isQuote={isQuote}
						/>
					),
				},
				{
					element: (
						<Dropdown
							key={project.project_id}
							maxWidth={'10rem'}
							placeholder={project.Project_Status?.name}
							options={isQuote ? statuses : statusValues}
							values={isQuote ? statuses : statusValues}
							onChange={(e) => {
								e.stopPropagation();
								collapseToggle();
								onStatusChange(e.target.value, project.project_id, project.Project_Type?.name);
							}}
						/>
					),
				},
				{
					sortValue: project.modified_at,
					element: (
						<FormattedDate
							date={project.modified_at}
							color={Color.nile50}
						/>
					),
				},
				{
					sortValue: project.date_due,
					element: (
						<FormattedDate
							date={project.date_due}
							color={Color.nile50}
						/>
					),
				},
				{
					element: (
						<Button
							icon={faAngleRight}
							iconSize='2x'
							onClick={(event) =>
								history.push(
									(isQuote ? NAV_QUOTES : NAV_PROJECTS) + '/' + project.project_id
								)
							}
						/>
					),
				},
			];
		}),
	};

	return tableData;
}

export const ProjectStatusGroup = (props) => {
	const {page, setPage } = props;
	const { projectStatuses } = useContext(DataContext);
	const handleLoadMore = () => {
		props.handleLoadMore(page + 1, props.orderBy);
		setPage(page + 1);
	};
	useEffect(() => {
		if (props.projects.length <= 25) {
			setPage(0);
		}
	}, [props.projects.length, setPage]);
	return (
		<ProjectStatusGroupView {...props}>
			<GroupSelect
				onClick={props.resultCount > 0 ? props.collapseToggle : null}
				resultCount={props.resultCount}
				isCollapsed={props.resultCount === 0 ? true : props.isCollapsed}
				highlightColor={props.resultCount > 0 ? props.highlightColor : Color.silver}>
				<StyledText
					as='h3'
					flexGrow='1'
					color={Color.nile}
					fontSize={FontSize.header5}
					lineHeight={21 / 19}
					marginLeft='16px'>
					{props.title}
				</StyledText>
				<CountBubble>
					{props.resultCount > 0 ? props.resultCount : '-'}
				</CountBubble>
				<Button
					disabled={props.resultCount === 0}
					backgroundColor={props.resultCount > 0 ? Color.white : Color.transparent}
					icon={props.resultCount === 0 ? faCaretDown : (props.isCollapsed ? faCaretDown : faCaretUp)}
					iconColor={Color.nile}
					iconSize='2x'
					marginRight='16px'
				/>
			</GroupSelect>
			<FlexTableController tableData={SetupTableData(props.projects, props.isQuote ? props.quoteStatuses : projectStatuses, props.handleProjectStatusChange, props.orderBy, props.collapseToggle, props.isQuote)} sort={(e, title) => updateSorting(e, title, props.setOrderBy)} />
			{
				(props.resultCount > (page + 1) * 25 && props.projects.length > 0) &&
				<ShowMoreView>
					<TransparentButton maxWidth='300px' flex='1' title='Load More' onClick={handleLoadMore} />
				</ShowMoreView>
			}
		</ProjectStatusGroupView>
	);
};

ProjectStatusGroup.propTypes = {
	title: PropTypes.string.isRequired,
	highlightColor: PropTypes.string,
	isCollapsed: PropTypes.bool,
	collapseToggle: PropTypes.func.isRequired,
};

ProjectStatusGroup.defaultProps = {
	title: '',
	highlightColor: 'transparent',
	isCollapsed: false,
	collapseToggle: function () {},
};
