import React, {useState} from 'react';
import {useGetData} from '../../projlibs/api.js';
import {CreateForm, FormInput, Header, Overlay, SubmitButton} from '../Shared/CreateForm';
import {API} from "../../projlibs/api";
import {success} from '../../projlibs/feedback.js';
import {formatText} from '../../projlibs/helperFunctions.js';
import styled, { css } from 'styled-components';
import {StyledCheckbox} from '../Shared/StyledCheckbox.jsx';
import {breakpoint} from '../../breakpoints.js';
import { StyledText } from '../Shared/StyledText.jsx';
import { Color, PAGE_PERMISSIONS, VIEW_LEADS_PERM, VIEW_ACTIVE_PROJ_PERM, VIEW_FULL_DASH_PERM, VIEW_FULL_DASH_DEPS, VIEW_ACTIVE_PROJ_DEPS, VIEW_LEADS_DEPS, VIEW_PROJECTS_PERM, VIEW_PROJECTS_DEPS, VIEW_QUOTES_PERM, VIEW_QUOTES_DEPS, VIEW_CLIENTS_DEPS, VIEW_CLIENTS_PERM, VIEW_REPORTS_PERM, VIEW_REPORTS_DEPS, VIEW_USER_MANAGER_PERM, VIEW_USER_MANAGER_DEPS, PAGE_PERMISSION_DEPS } from '../../constants.js';



const CheckboxContainer = styled.div`
    display: flex;
    width: 100%;
    height: fit-content;
    padding: 0.5rem 0.5rem;
    ${props => css`
        ${breakpoint('medium up')} {
            height: ${props.height ? props.height : '300px'};
        }
    `}
    flex-flow: column wrap;
`;

const GroupFormOverlay = styled(Overlay)`
    ${breakpoint('medium up')} {
        height: calc(100% - 6rem);
    }
    height: calc(100% - 80px);
`;

const isLinkedToDashboardPerm = (permission, permissions) => {
    if (permissions.includes(VIEW_FULL_DASH_PERM)) {
        if (VIEW_FULL_DASH_DEPS.includes(permission)) {
            return true;
        }
    }
    if (permissions.includes(VIEW_ACTIVE_PROJ_PERM)) {
        if (VIEW_ACTIVE_PROJ_DEPS.includes(permission)) {
            return true;
        }
    }
    if (permissions.includes(VIEW_LEADS_PERM)) {
        if (VIEW_LEADS_DEPS.includes(permission)) {
            return true;
        }
    }
    if (permissions.includes(VIEW_PROJECTS_PERM)) {
        if (VIEW_PROJECTS_DEPS.includes(permission)) {
            return true;
        }
    }
    if (permissions.includes(VIEW_QUOTES_PERM)) {
        if (VIEW_QUOTES_DEPS.includes(permission)) {
            return true;
        }
    }
    if (permissions.includes(VIEW_CLIENTS_PERM)) {
        if (VIEW_CLIENTS_DEPS.includes(permission)) {
            return true;
        }
    }
    if (permissions.includes(VIEW_REPORTS_PERM)) {
        if (VIEW_REPORTS_DEPS.includes(permission)) {
            return true;
        }
    }
    if (permissions.includes(VIEW_USER_MANAGER_PERM)) {
        if (VIEW_USER_MANAGER_DEPS.includes(permission)) {
            return true;
        }
    }
    return false;
};

export const CreateGroupForm = (props) => {
    const [{ data: permissionData }] = useGetData('/permissions');
    const [name, setName] = useState(props.group ? props.group.group_name : '');
    const [selectedPermissions, setSelectedPermissions] = useState(props.group ? Object.keys(props.group.permissions) : []);
    const permissions = permissionData?.Permissions ? permissionData?.Permissions : [];
    const existingGroupId = props.group ? props.group.group_id : undefined;
    const mode = props.group === undefined ? 'create' : 'edit';

    const handlePermissionChanged = (permission) => {
        if (selectedPermissions.includes(permission)) {
            setSelectedPermissions(selectedPermissions.filter(p => p !== permission));
        } else {
            setSelectedPermissions([...selectedPermissions, permission]);
        }
    };

    const handlePagePermissionChanged = (permission) => {
        if (selectedPermissions.includes(permission)) {
            let permissionDeps = [permission];
            if (permission.startsWith('view.')) {
                let selectedPagePermissions = selectedPermissions.filter(perm => perm !== permission && perm.startsWith('view.'));
                let currentPageDependencies = [];
                selectedPagePermissions.forEach(perm => {
                    let dependencies = PAGE_PERMISSION_DEPS[perm];
                    if (dependencies) {
                        currentPageDependencies = [...currentPageDependencies, ...dependencies];
                    }
                });
                let removedPageDependencies = PAGE_PERMISSION_DEPS[permission];
                if (removedPageDependencies) {
                    permissionDeps = [...permissionDeps, ...removedPageDependencies.filter(perm => !currentPageDependencies.includes(perm))];
                }
            } else {

            }
            setSelectedPermissions(selectedPermissions.filter(p => !permissionDeps.includes(p)));
        } else {
            let permissionDeps = [permission];
            let deps = PAGE_PERMISSION_DEPS[permission];
            if (deps) {
                permissionDeps.push(...deps);
            }
            setSelectedPermissions([...new Set([...selectedPermissions, ...permissionDeps])]);
        }
    };

    const SubmitForm = (e) => {
        e.preventDefault();
        const rolePermissions = Object.fromEntries(selectedPermissions.map(permission => ([permission, true])));
        const group = {
            group_name: name,
            permissions: rolePermissions,
        };
        if (mode === 'edit') {
            EditGroup(group, existingGroupId)
        } else {
            CreateGroup(group);
        }
        props.onClose();
    };

    const CreateGroup = (group) => {
        API.post('/group', { data: group }).then(response => {
            success('Role successfully created.');
            props.updateData();
        }).catch(API.default_error_handler);
    };

    const EditGroup = (group, groupId) => {
        API.put(`/group/${groupId}`, { data: group }).then(response => {
            success('Role successfully updated.');
            props.updateData();
        }).catch(API.default_error_handler);
    };
    
    return (
        <GroupFormOverlay>
            <Header onClose={props.onClose} title={mode === 'edit' ? 'Edit a Role' : 'Create a Role'}/>
            <CreateForm onSubmit={SubmitForm}>
                <FormInput
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label='Name*'
                    width='100%'
                    placeholder='...'
                    required
                />
                <StyledText padding='0.5rem 0' color={Color.nile70}>Read, Create, & Edit Permissions</StyledText>
                <CheckboxContainer>
                    {
                        permissions &&
                        permissions.map(permission => (
                            <StyledCheckbox
                                disabled={isLinkedToDashboardPerm(permission, selectedPermissions)}
                                paddingTop='0'
                                key={permission}
                                checkboxId={permission}
                                checked={selectedPermissions.includes(permission)}
                                label={formatText(permission.split('.').join(' '))}
                                onChange={() => handlePermissionChanged(permission)}
                            />
                        ))
                    }
                </CheckboxContainer>
                <StyledText padding='0.5rem 0' color={Color.nile70}>Page Permissions</StyledText>
                <CheckboxContainer height='120px'>
                    {
                        PAGE_PERMISSIONS &&
                        PAGE_PERMISSIONS.map(permission => (
                            <StyledCheckbox
                                paddingTop='0'
                                key={permission}
                                checkboxId={permission}
                                checked={selectedPermissions.includes(permission)}
                                label={formatText(permission.split('.').join(' '))}
                                onChange={() => handlePagePermissionChanged(permission)}
                            />
                        ))
                    }
                </CheckboxContainer>
                <SubmitButton title={mode === 'edit' ? 'Edit Role' : 'Create Role'} type='submit' width='100%'/>
            </CreateForm>
        </GroupFormOverlay>
    );
};
