import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Color, FontSize, NAV_PROJECTS, NAV_QUOTES } from '../../../constants';
import { StyledText } from '../StyledText';

const StatusBubble = styled.div`
	padding: 0.25rem 0.5rem;
	width: fit-content;
	vertical-align: center;
	font-size: ${FontSize.body2};
	border-radius: 9px;
	background-color: ${Color.transparent};
	border: 1px solid ${Color.ghost};
	color: ${Color.nile90};
`;

const ProjectResultView = styled.div`
	padding: 0.5rem 1rem 0.5rem 1rem;
	border-radius: 3px;
	background-color: ${Color.white};
	cursor: pointer;
	&:hover {
		background-color: ${Color.ghost};
		${StatusBubble} {
			border-color: ${Color.nile90};
		}
	}
	display: flex;
`;

const ProjectNumberView = styled.div`
	float: left;
	height: fit-content;
	padding-right: 0.7rem;
	border-right: 1.5px solid ${Color.ghost};
`;

const ProjectInfoView = styled.div`
	padding-left: 0.7rem;
`;

export const ProjectSearchResult = ({ name, projectId, clientName, closeSearch, isQuote, status }) => {
	const history = useHistory();
	const handleClick = () => {
		history.push(`${isQuote ? NAV_QUOTES : NAV_PROJECTS}/${projectId}`);
		closeSearch();
	};
	return (
		<ProjectResultView onClick={handleClick}>
			<ProjectNumberView>
				<StyledText color={Color.nile90}>{projectId}</StyledText>
			</ProjectNumberView>
			<ProjectInfoView>
				<StyledText color={Color.nile90}>{name}</StyledText>
				<StyledText color={Color.nile50} fontSize={FontSize.body2}>{clientName}</StyledText>
				<StatusBubble>{status}</StatusBubble>
			</ProjectInfoView>
		</ProjectResultView>
	);
};
