import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useHistory, Link} from 'react-router-dom';
import {
	Color,
	CREATED,
	DESIGN_DUE_DATE,
	DUE_DATE,
	FontSize,
	NAV_QUOTES,
	PROJECT_CREATE_PERM,
	QUOTE_REJECTED_STATUS,
	QUOTES_SEARCH_ASSIGNEE,
	QUOTES_SEARCH_CLIENT,
	QUOTES_SEARCH_CLIENT_NAME,
	QUOTES_SEARCH_ORDER_BY,
	QUOTES_SEARCH_PAGE,
	JOB_NUMBER,
	NAME,
	CLIENT_NAME,
	NAV_CLIENTS,
	QUOTES_SEARCH_STATUS,
	QUOTES_SEARCH_VALUE,
	TYPE
} from '../../constants';
import {API, useGetData} from '../../projlibs/api.js';
import {breakpoint} from '../../breakpoints';
import {SearchInput} from '../Shared/SearchInput';
import {ControlledFilterDropdown, Dropdown} from '../Shared/Dropdown';
import {FlexTableController} from '../Shared/FlexTableController';
import {StyledText} from '../Shared/StyledText';
import {Button} from '../Shared/Button';
import {FormattedDate} from '../Shared/FormattedDate';
import {ProjectType} from '../Shared/ProjectType';
import {faAngleRight} from '@fortawesome/pro-regular-svg-icons';
import {CheckButton, CreateFormButton} from '../Shared/OverlayViewButton';
import {PageController} from '../Shared/PageController';
import {getDesignDueDate,getQuotedHours, getURL, TableHeader, updateSorting} from "../Projects/Projects";
import {CreateProjectsForm} from "../Projects/CreateProjectsForm";
import {PageIndex} from '../Shared/PageIndex';
import {AssignedUser} from "../Shared/AssignedUser";
import {DataContext} from '../../data-context';
import {ClientSearchDropdown} from '../Clients/ClientSearchDropdown';
import {ApproveProjectForm} from "../ProjectDetails/ApproveProjectForm";
import {ApproveQuote} from "../ProjectDetails/ProjectDetails";
import { Helmet } from 'react-helmet';
import { success } from '../../projlibs/feedback';
import { StyledLink } from '../Shared/StyledLink';

const QuoteView = styled.div`
	background-color: ${Color.white};
`;

const QuoteHeader = styled.div`
	padding: 2rem 1.5rem;
	background-color: ${Color.nile5};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const FilterControlsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem 1rem 1rem 0;
	margin-right: 0.5rem;
	${breakpoint('medium up')} {
		margin: 0;
		flex-wrap: wrap;
		flex-direction: row;
	}
`;

const HoverUnderlineName = styled(StyledText)`
	:hover {
		text-decoration: underline;
	}
`;

const StyledFilterDropdown = styled(ControlledFilterDropdown)`
	flex: 1;
	width: 100%;
	margin: 0.25rem 0.4rem;
	${breakpoint('medium up')} {
		margin: 0.25rem 0.4rem;
		width: fit-content;
		max-width: 200px;
	}
`;

function SetupTableData(quotes, statuses, userPerms, projectStatuses) {
	let history = useHistory();
	let tableData = {
		titles: [
			TYPE,
			CREATED,
			JOB_NUMBER,
			NAME,
			CLIENT_NAME,
			'Quoted Hrs',
			'Assigned',
			'Status',
			DESIGN_DUE_DATE,
			DUE_DATE,
			'',
			'',
		],

		rows: quotes?.map((project) => {
			const projStatuses = projectStatuses[project.Project_Type.name]
			return [
				{
					sortValue: project.Project_Type?.name,
					element: (
						<ProjectType
							viewColor={project.Project_Type?.color}
							title={project.Project_Type?.name}
						/>
					),
				},
				{
					minWidth: '80px',
					sortValue: project.created_at,
					element: (
						<FormattedDate
							date={project.created_at}
							color={Color.nile}
						/>
					),
				},
				{
					sortValue: project?.project_id,
					element: (
						<StyledText color={Color.nile50} fontSize={FontSize.body1}>
							{project?.project_id}
						</StyledText>
					),
				},
				{
					doesGrow: true,
					sortValue: project?.name,
					element: (
						<Link to={`${NAV_QUOTES}/${project?.project_id}`} style={{ textDecoration: 'none' }}>
							<HoverUnderlineName
								color={Color.nile}
								fontSize={FontSize.body1}
								marginBottom='4px'>
								{project?.name}
							</HoverUnderlineName>
						</Link>
					),
				},
				{
					sortValue: project?.Client?.name,
					element: (
						<StyledLink to={`${NAV_CLIENTS}/${project?.client_id}`}>
							{project?.Client?.name}
						</StyledLink>
					),
				},
				{
					sortValue: project?.quoted_hours_min,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body2}>
							{getQuotedHours(project)}
						</StyledText>
					),
				},
				{
					minWidth: '80px',
					element: (
						<AssignedUser
							users={project?.assigned_users.slice(0,3)}
						/>
					),
				},
				{
					sortValue: project.Project_Status?.name,
					element: (
						<Dropdown
							minViewWidth='100%'
							disabled={!userPerms[PROJECT_CREATE_PERM]}
							placeholderValue={project.Project_Status?.name}
							options={statuses}
							values={statuses}
							onChange={(e) => UpdateQuoteStatus(e, e.target.value, project.Project_Type?.name, project.project_id)}
							placeholder={project.Project_Status?.name}
						/>
					),
				},
				{
					minWidth: '80px',
					sortValue: project?.design_due_date,
					element: (
						<FormattedDate
							date={getDesignDueDate(project)}
							color={Color.nile50}
							fontStyle='italic'
							hideTime
						/>
					),
				},
				{
					sortValue: project.due_date,
					element: (
						<FormattedDate
							date={project.date_due}
							color={Color.nile50}
							fontStyle='italic'
						/>
					),
				},
				{
					element: (
						<CheckButton>
							<ApproveProjectForm approve statuses={projStatuses} approveQuote={(status) => ApproveQuote(history, project.project_id, project.Project_Type?.name, status)} id={project.project_id}/>
						</CheckButton>
					),
				},
				{
					element: (
						<Button
							fontSize='1.5rem'
							icon={faAngleRight}
							iconSize='lg'
							onClick={(event) =>
								history.push(
									NAV_QUOTES + '/' + project.project_id
								)
							}
						/>
					),
				},
			];
		}),
	};

	return tableData;
}

const generateUpdateQuoteUrl = (quoteId) => `/project/${quoteId}`;

const UpdateQuoteStatus = (e, newStatus, type, quoteId) => {
	e.stopPropagation();
	API.put(generateUpdateQuoteUrl(quoteId), { data: { 'project_status': newStatus, 'project_type': type } }).then(() => success('Status Updated Successfully')).catch(API.default_error_handler);
};

const StyledClientSearchDropdown = styled(ClientSearchDropdown)`
	input {
		height: fit-content;
	}
	flex: 1;
	width: 100%;
	margin-top: 0.4rem;
	margin-right: 0.4rem;
	margin-bottom: auto;
	margin-left: 0.8rem;
	${breakpoint('medium up')} {
		margin-right: 0.1rem;
		margin-top: auto;
		min-width: 200px;
		width: fit-content;
		max-width: 200px;
	}
`;

const NoResultsText = styled.h3`
	color: ${Color.nile50};
	align-self: center;
	text-align: center;
`;

export const Quotes = (props) => {
	const { userPerms, projectStatuses, quoteStatuses} = useContext(DataContext);
	const [searchValue, setSearchValue] = useState((props.client_id || props.rejected) ? '' : localStorage.getItem(QUOTES_SEARCH_VALUE) ? localStorage.getItem(QUOTES_SEARCH_VALUE) : '');
	const [searchToSubmit, setSearchToSubmit] = useState((props.client_id || props.rejected) ? '' : localStorage.getItem(QUOTES_SEARCH_VALUE) ? localStorage.getItem(QUOTES_SEARCH_VALUE) : '');
	const [client, setClient] = useState((props.client_id || props.rejected) ? '' : localStorage.getItem(QUOTES_SEARCH_CLIENT) ? localStorage.getItem(QUOTES_SEARCH_CLIENT) : '');
	const [clientName, setClientName] = useState((props.client_id || props.rejected) ? '' : localStorage.getItem(QUOTES_SEARCH_CLIENT_NAME) ? localStorage.getItem(QUOTES_SEARCH_CLIENT_NAME) : '')
	const [assignee, setAssignee] = useState((props.client_id || props.rejected) ? '' : localStorage.getItem(QUOTES_SEARCH_ASSIGNEE) ? localStorage.getItem(QUOTES_SEARCH_ASSIGNEE) : '');
	const [status, setStatus] = useState((props.client_id || props.rejected) ? '' : localStorage.getItem(QUOTES_SEARCH_STATUS) ? localStorage.getItem(QUOTES_SEARCH_STATUS) : '');
	const [currentPage, setCurrentPage] = useState((props.client_id || props.rejected) ? 0 : localStorage.getItem(QUOTES_SEARCH_PAGE) ? parseInt(localStorage.getItem(QUOTES_SEARCH_PAGE)) : 0);
	const [numPages, setNumPages] = useState(0);
	const [orderBy, setOrderBy] = useState((props.client_id || props.rejected) ? '' : localStorage.getItem(QUOTES_SEARCH_ORDER_BY) ? localStorage.getItem(QUOTES_SEARCH_ORDER_BY) : '');
	const [resultCount, setResultCount] = useState(0);
	const [searchQuery, setSearchQuery] = useState('');

	const [{ data }, setUrl] = useGetData(getURL({
		client: client,
		assignee: assignee,
		status: status,
		rejected: props.rejected,
		searchToSubmit: searchToSubmit,
		client_id: props.client_id,
		location: 'quote',
		orderBy: orderBy,
	}));
	const statuses = quoteStatuses.filter(status => status !== QUOTE_REJECTED_STATUS);
	const [{ data: userData }] = useGetData("/user");
	const users = userData.User?.map(user => user.full_name);
	const user_ids = userData.User?.map(user => user.user_id.toString());

	const tableData = SetupTableData(data.Project, quoteStatuses, userPerms, projectStatuses);

	useEffect(() => {
		const localStorage = window.localStorage;
		const saveSearchSelections = (hasChanged = false) => {
			localStorage.setItem(QUOTES_SEARCH_CLIENT, client);
			localStorage.setItem(QUOTES_SEARCH_CLIENT_NAME, clientName);
			localStorage.setItem(QUOTES_SEARCH_ASSIGNEE, assignee);
			localStorage.setItem(QUOTES_SEARCH_STATUS, status);
			localStorage.setItem(QUOTES_SEARCH_ORDER_BY, orderBy);
			localStorage.setItem(QUOTES_SEARCH_VALUE, searchToSubmit);
			localStorage.setItem(QUOTES_SEARCH_PAGE, hasChanged ? 0 : currentPage);
		};
		let url = getURL({
			client: client,
			assignee: assignee,
			status: status,
			rejected: props.rejected,
			searchToSubmit: searchToSubmit,
			client_id: props.client_id,
			location: 'quote',
			orderBy: orderBy,
		});

		//if the url is different then the query has changed and we want to go back to the first page
		if(url !== searchQuery){
			if (!window.localStorage.getItem(QUOTES_SEARCH_PAGE)) {
				setCurrentPage(0);
			}
			saveSearchSelections(true);
		} else {
			saveSearchSelections(false);
		}
		setSearchQuery(url);
		url += "page=" + currentPage;
		setUrl(url);
	}, [client, assignee, status, currentPage, setUrl, orderBy, searchToSubmit, searchQuery, props.client_id, clientName, props.rejected]);

	useEffect(() => {
		setNumPages(data.page_count);
		setResultCount(data.result_count);
	}, [data]);

	const handleSearchSubmit = (e) => {
		setSearchToSubmit(searchValue);
		e.preventDefault();
	}

	return (
		<QuoteView {...props}>
			<Helmet>
				<meta name="description" content='Quotes' />
			</Helmet>
			{props.client_id === undefined && (
				<QuoteHeader>
					<StyledText
						as='h1'
						color={Color.nile}
						fontSize={FontSize.header2}>
						Quotes
					</StyledText>
					{
						userPerms[PROJECT_CREATE_PERM] &&
						<CreateFormButton title="New Quote">
							<CreateProjectsForm isQuote={true} />
						</CreateFormButton>
					}
				</QuoteHeader>
			)}
			<TableHeader>
				<FilterControlsWrapper>
					<SearchInput
						value={searchValue}
						containerStyle={{ flex: '1', minWidth: '200px', width: '100%', margin: '0.5rem 0rem 0.5rem 0.75rem' }}
						borderColor={Color.nile15}
						hoverBorderColor={Color.nile5}
						placeholder='Search quotes'
						onChange={(e) => setSearchValue(e.target.value)}
						onSubmit={e => handleSearchSubmit(e)}
					/>
					{props.client_id  === undefined && (
						<StyledClientSearchDropdown
							returnName
							mode='edit'
							project={client ? { project_id: 'Filter', client_id: parseInt(client), Client: {name: clientName} } : undefined}
							width='64%'
							height='42px'
							placeholder='Client'
							onChange={(value) => {
								setClient(value.valueField ? value.valueField : '');
								setClientName(value.textField ? value.textField : '');
							}}
						/>
					)}
					<StyledFilterDropdown
						value={assignee}
						placeholder='Assignee'
						options={users}
						values={user_ids}
						onChange={(e) => setAssignee(e.target.value)}
					/>
					{!props.rejected &&
						<StyledFilterDropdown
							value={status}
							placeholder='Status'
							options={statuses}
							values={statuses}
							onChange={(e) => setStatus(e.target.value)}
						/>
					}
				</FilterControlsWrapper>	
				<PageIndex resultCount={resultCount} currentPage={currentPage} />
			</TableHeader>
			{
				statuses &&
				<FlexTableController tableData={tableData}  sort={(e, title) => updateSorting(e,title, setOrderBy)}/>
			}
			{
				numPages === 0 &&
				<NoResultsText>No Quote Results</NoResultsText>
			}
			<PageController pageNumber={currentPage} setPageNumber={setCurrentPage} numPages={numPages}/>
		</QuoteView>
	);
};
