import React from 'react';
import styled from 'styled-components';
import { Color, FontSize, FontWeight } from '../../constants';
import { FormInput, SubmitButton } from '../Shared/CreateForm';
import { UnderlineLink } from '../Shared/Button';
import { StyledText } from '../Shared/StyledText';

const InputsContainer = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding: 2rem;
`;

const ForgotPasswordLink = styled(UnderlineLink)`
    background-color: ${Color.silver};
    align-self: flex-start;
    width: fit-content;
    color: ${Color.regent};
    font-weight: ${FontWeight.normal};
`;

const Title = styled(StyledText)`
    color: ${Color.zest};
    font-size: ${FontSize.header3};
    padding-bottom: 2rem;
    font-weight: ${FontWeight.semibold};
`;

const LoginButton = styled(SubmitButton)`
    margin: 2rem 0 0 0;
    width: 200px;
`;

const NotificationView = styled.div`
    display: flex;
    flex-direction: column;
`;

const ErrorText = styled(StyledText)`
    color: ${Color.zest};
    font-size: ${FontSize.body2};
`;

const SuccessText = styled(StyledText)`
    color: ${Color.forest};
    font-size: ${FontSize.body2};
`;

export const SignIn = (props) => (
    <InputsContainer onSubmit={(e) => {
        e.preventDefault();
        props.SubmitLogin();
    }}>
        <Title>Portal Login</Title>
        <FormInput
            label='Username'
            placeholder='...'
            width='400px'
            onChange={(e) => props.setUsername(e.target.value)}
        />
        <FormInput
            type='password'
            label='Password'
            placeholder='******'
            width='400px'
            onChange={(e) => props.setPassword(e.target.value)}
        />
        <ForgotPasswordLink type='button' name='forgot-pass' title='Forgot Password?' onClick={() => props.setPage('forgot_pass')}/>
        {
            <NotificationView>
                {
                    props.notifications.map(notif => <SuccessText key={notif}>{notif}</SuccessText>)
                }
                {
                    props.errors.map(error => <ErrorText key={error}>{error}</ErrorText>)
                }
            </NotificationView>
        }
        <LoginButton type='submit' disabled={props.loading} title={props.loading ? 'Logging in...' : 'Login'} />
    </InputsContainer>
);
