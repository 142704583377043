import React from 'react';
import styled from 'styled-components';
import { Color, FontSize } from '../../constants';
import { FlexTableController } from '../Shared/FlexTableController';
import { StyledText } from '../Shared/StyledText';
import { Button } from '../Shared/Button';
import { useGetData } from '../../projlibs/api.js';
import {
	faStar,
} from '@fortawesome/free-solid-svg-icons';
import { PageIndex } from '../Shared/PageIndex';
import { PageController } from '../Shared/PageController';


const LocationsView = styled.div`
	background-color: ${Color.white};
`;

const LocationListView = styled.div``;

const PageIndexContainer = styled.div`
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const NoResultsText = styled.h3`
	color: ${Color.nile50};
	align-self: center;
	text-align: center;
`;

const SetupTableData = (locations,client,SetPrimary) => {

	let tableData = {
		titles: [
			'Address',
			'Suite',
			'City',
			'State/Prov',
			'ZIP/Postal Code',
			'Country',
			'',
		],
		rows: locations.Location?.map((location) => {
			let isPrimary=location.location_id===client?.primary_location_id;
			return [
				{
					sortValue: location.address,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body1}>
							{location.address}
						</StyledText>
					),
				},
				{
					sortValue: location.subaddress,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body1}>
							{location.subaddress}
						</StyledText>
					),
				},
				{
					sortValue: location.city,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body1}>
							{location.city}
						</StyledText>
					),
				},
				{
					sortValue: location.state_prov,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body1}>
							{location.state_prov}
						</StyledText>
					),
				},
				{
					sortValue: location.postal_code,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body1}>
							{location.postal_code}
						</StyledText>
					),
				},
				{
					sortValue: location.country,
					element: (
						<StyledText
							color={Color.nile}
							fontSize={FontSize.body1}>
							{location.country}
						</StyledText>
					),
				},
				{
					element: (
						isPrimary ? 
							<div title='Currently Primary Location'>
								<Button icon={faStar} iconColor={Color.sun} disabled={true} />
							</div>
						:
							<div title='Mark as Primary Location'>
								<Button icon={faStar} iconColor={Color.nile15} onClick={() => {SetPrimary(location.location_id)}} />
							</div>
					)
				},
			];
		}),
	};
	return tableData;
}

export const Locations = (props) => {
	const [{ data: locations }] = useGetData('/client/' + props.client_id + '/location');
	return (
		<LocationsView>
			<LocationListView>
				<PageIndexContainer>
                    <PageIndex resultCount={locations?.Location ? locations.Location.length : 0} currentPage={0} />
                </PageIndexContainer>
				<FlexTableController
					tableData={SetupTableData(locations,props.client,props.SetPrimaryLocation)}
				/>
				{
                    locations?.Location?.length === 0 &&
                    <NoResultsText>No Locations</NoResultsText>
                }
				<PageController pageNumber={0} numPages={locations?.Location?.length > 0 ? 1 : 0} />
			</LocationListView>
		</LocationsView>
	);
};
