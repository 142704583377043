import React from 'react';
import styled, { css } from 'styled-components';
import { Color, FontSize } from '../../constants';
import { StyledBase } from './StyledBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Button: Used for general button use.
// LeftMenuButton: button with matching styles of left menu button
// As more button types are needed, just extend / export them and add the appropriate default props.
// Props:
// All StyledBase props are available.
// onClick: callback triggered when button is clicked
// contentAlign: horizontally aligns text and icon of the button.  Center (default), left and right.
// icon: fontAwesomeIcon that will displayed  It can be left or right aligned.
// iconIsLeft: is icon on the left side of title.  If not, then it will be on the right side.
// iconXOffset: offset added between icon and title.
// iconColor: color of icon.
// iconSize: multiple size by this integer, 2x, 3x, etc
// iconRotation: rotate icon by 90 deg increments. none, 90, 180, 270.
// selected: is button selected or not.  If yes, will set selected color attributes.
// selectedColor, selectedBackgroundColor, selectedIconColor and selectedBoxShadow are set when button selected = true.
// hoverFilter: filter selector used when hovering button.
// hoverTransform: transform selector used when hovering button.

export const ButtonView = styled.button`
	${(props) => css`
		${StyledBase}
	`}
`;

export const StyledFontAwesome = styled(FontAwesomeIcon)`
	${(props) => css`
		margin-left: ${props.marginleft};
		margin-right: ${props.marginright};
		order: ${props.order};
	`}
`;

export const Button = (props) => (
	<ButtonView {...props}>
		{props.icon && (
			<StyledFontAwesome
				className='icon'
				icon={props.icon}
				size={props.iconSize}
				rotation={props.iconRotation}
				marginleft={props.iconIsLeft ? 0 : props.iconXOffset}
				marginright={props.iconIsLeft ? props.iconXOffset : 0}
				order={props.iconIsLeft ? 0 : 1}
				color={
					props.selected ? props.selectedIconColor : props.iconColor
				}
			/>
		)}
		{props.title}
	</ButtonView>
);

export const PrimaryButton = styled(Button)``;

export const LeftMenuButton = styled(Button)``;

export const FileButton = styled(Button)``;

export const BackButton = styled(Button)``;

export const TransparentButton = styled(Button)``;

export const FlatPrimaryButton = styled(Button)``;

export const UnderlineLink = styled(Button)`
	${(props) => css`
		margin-top: 0;
		margin-right: auto;
		font-size: ${props.fontSize};
		padding: ${props.padding};
		margin-bottom: ${props.marginBottom ? props.marginBottom : 0};
		flex: ${props.flex}
		width: ${props.width}
		margin-left: auto;
		color: ${Color.nile};
		float: ${props.float};
		cursor: pointer;
		position: ${props.position ? props.position : 'relative'};
		left: ${props.left ? props.left : 0};
		right: ${props.right ? props.right : 0};
		top: ${props.top ? props.top : 0};
		border-bottom: 1px dotted ${Color.nile};
		margin: ${props.margin ? props.margin : 0};
		justify-content: ${props.justifyContent};
		border-radius: 0;
		padding: 0;
		line-height: 1;
		&::before {
			content: '';
			position: absolute;
			width: 0%;
			height: 1px;
			bottom: -1px;
			background-color: ${Color.nile};
			transition: width 0.2s ease-in-out;
		}
		&::after {
			content: ${(props) =>
				`"${props.unicodeIcon ? props.unicodeIcon : ''}"`};
			position: absolute;
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			right: -1rem;
		}

		&:focus {
			outline: none;
			&::before {
				outline: none;
			}
		}
		&:hover {
			&::before {
				width: 100%;
			}
		}
	`}
`;

Button.defaultProps = {
	fontSize: FontSize.body2,
	color: Color.nile50,
	selectedColor: Color.nile,
	iconIsLeft: 'true',
	iconColor: Color.nile50,
	selectedIconColor: Color.nile,
	selectedBackgroundColor: Color.white,
	backgroundColor: Color.white,
	borderColor: 'transparent',
	borderRadius: '4px',
	padding: '4px 16px',
	display: 'inline-flex',
	alignItems: 'center',
	cursor: 'pointer',
	transitionTime: '0.3s',
};

LeftMenuButton.defaultProps = {
	width: '14.0625rem',
	height: '2.5rem',
	fontSize: '1rem',
	contentAlign: 'left',
	iconXOffset: '0.5rem',
	selectedBackgroundColor: Color.white,
	selectedIconColor: Color.sun,
	selectedColor: Color.nile,
	iconColor: Color.nile50,
	color: Color.nile50,
	selectedBoxShadow: '0rem 0.0625rem 0.25rem rgba(22, 45, 76, 0.25)',
	hoverBoxShadow: '0 1px 4px 0 rgba(15,22,45,0.18)',
	borderRadius: '2px',
	marginBottom: '1rem',
	iconYOffset: 0,
	iconMarginLeft: '0.5rem',
};

FileButton.defaultProps = {
	height: '40px',
	fontSize: '14px',
	color: Color.nile,
	iconXOffset: '0.5rem',
	iconColor: Color.nile50,
	borderColor: Color.nile15,
	borderRadius: '2px',
	marginLeft: '16px',
	hoverBoxShadow: '0 1px 4px 0 rgba(15,22,45,0.18)',
};

BackButton.defaultProps = {
	height: '40px',
	fontSize: '14px',
	color: Color.nile,
	iconXOffset: '0.5rem',
	iconColor: Color.nile,
	paddingLeft:'0',
	iconSize:'2x',
};

PrimaryButton.defaultProps = {
	fontSize: FontSize.body2,
	color: Color.white,
	backgroundColor: Color.zest,
	borderColor: Color.zest,
	borderRadius: '0.25rem',
	padding: '0.75rem',
	display: 'block',
	transitionTime: '0.3s',
	hoverBackground: Color.fuel,
	hoverBorderColor: Color.fuel,
};

FlatPrimaryButton.defaultProps = {
	fontSize: FontSize.body2,
	color: Color.white,
	backgroundColor: Color.zest,
	borderColor: Color.transparent,
	borderRadius: '0.25rem',
	transitionTime: '0.3s',
	hoverBackground: Color.fuel,
	hoverBorderColor: Color.transparent,
};

TransparentButton.defaultProps = {
	fontSize: FontSize.body2,
	color: Color.black,
	backgroundColor: Color.transparent,
	borderRadius: '0.25rem',
	borderWidth: '1px',
	padding: '0.75rem',
	display: 'block',
	transitionTime: '0.3s',
	borderColor: Color.nile10,
	hoverBackground: Color.nile5,
	hoverBorderColor: Color.transparent,
};
