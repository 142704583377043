import React from 'react';
import styled from 'styled-components';
import { ServiceSearchResult } from '../Search/ServiceSearchResult';
import { StyledBase } from '../StyledBase';
import { isAFunction } from '../../../projlibs/helperFunctions';

const ServiceSearchesContainer = styled.div`
	${StyledBase}
`;

/**
 * Accepts a list of services and displays them.
 * @param {Array} services - array of service objects each containing a 'name', 'id', and 'clientName' 
 */
export const ServiceSearches = (props) => (
	<ServiceSearchesContainer {...props}>
		{ props.services && props.services.map(service =>
			<ServiceSearchResult
				key={service.service_id}
				name={service.name}
				serviceId={service.service_id}
				clientName={service.clientName}
				clientId={service.client_id}
				status={service.status}
				closeSearch={isAFunction(props.closeSearch) ? props.closeSearch : undefined}
			/>
		)}
	</ServiceSearchesContainer>
);
