import React from 'react';
import styled, { css } from 'styled-components';
import { Color, FontSize } from '../../constants';
import { Multiselect } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css';

const Style = styled.div`
	${props => css`
		label {
			display: block;
			color: ${Color.nile50};
			font-size: ${FontSize.body2};
        }

		input {
			border-radius: ${props.borderRadius};
			width: 100%;
			box-sizing: border-box;
			background-color: ${props.backgroundColor ? props.backgroundColor : Color.nile5};
			color: ${props.color};
			height: ${props.height};
			min-height: ${props.minHeight};
			text-align: left;
			font-size: ${props.fontSize};
			font-weight: ${props.theme.fontWeight.semibold};
			border-radius: ${props.borderRadius};
			border-width: ${props.borderWidth};
			border-color: ${props.borderColor};
			border-style: ${props.borderStyle};
			padding-top: ${props.paddingTop ? props.paddingTop : '0.5rem'};
			padding-right: ${props.paddingRight ? props.paddingRight : '0.5rem'};
			padding-bottom: ${props.paddingBottom ? props.paddingBottom : '0.5rem'};
			padding-left: ${props.paddingLeft ? props.paddingLeft : '0.5rem'};
			outline: none;
			transition: color 0.3s ease, border-color 0.3s ease;

			::placeholder {
				color: ${props.placeholderColor};
				opacity: 1;
			}
			
			&:hover, &:focus, &:active {
				color: ${props.hoverColor};
				border-color: ${props.hoverBorderColor ? props.hoverBorderColor : Color.zest };
				~ .icon {
					color: ${props.hoverColor};
				}
			}
		}

		button {
			}

		margin: ${props.margin};
		margin-top: ${props.marginTop};
		margin-right: ${props.marginRight};
		margin-bottom: ${props.marginBottom};
		margin-left: ${props.marginLeft};
		width: ${props.width};
		flex: ${props.flex};

		//NOTE: div is not a focusable element and so it does not have focus or active states
		&:hover {
			border-color: ${props.hoverBorderColor};
			box-shadow: ${props.hoverBoxShadow};
		}
	`}
`;

export const MultiSelectDropdown = props => (
    <Style {...props}>
        {props.label && <label htmlFor={props.inputId}>{props.label}</label>}
        <Multiselect
			filter={props.filter}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            data={props.data}
            onChange={props.onChange}
            textField={props.textField}/>
    </Style>
);

MultiSelectDropdown.defaultProps = {
    backgroundColor: Color.white,
    placeholderColor: Color.black,
    hoverBorderColor:Color.zest,
    marginBottom: '24px',
};
