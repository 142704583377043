import React, {useCallback, useContext, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import styled from 'styled-components';
import {HeaderExitInline, Overlay} from './CreateForm';
import {StyledText} from './StyledText';
import {Color, MS_PER_S} from '../../constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {faFileUpload} from '@fortawesome/pro-light-svg-icons';
import {DataContext} from '../../data-context';
import config from '../../config';
import {error} from '../../projlibs/feedback';
import {API} from '../../projlibs/api';
import {FlexTableController} from './FlexTableController';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {breakpoint} from "../../breakpoints";

const DropzoneContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 2px dashed ${Color.nile50};
    border-radius: 5px;
    width: 100%;
    height: calc(100% - 6rem);
    outline: none;
`;

const DropzoneOverlay = styled(Overlay)`
    height: calc(100% - 6rem);
    width: auto;
    ${breakpoint('small only')} {
      width: 70%;
      margin: auto;
      top: 2.5%;
    }
`;

const DropzoneInput = styled.input`
    border: none;
`;

const DropzoneText = styled(StyledText)`
    color: ${Color.nile70};
    padding-top: 1rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
`;

const UploadIcon = styled(FontAwesomeIcon)`
    align-self: center;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
`;

const SetupTableData = (uploads, uploadedFiles, failedUploads) => ({
    titles: [
        'File Name',
        'Size',
        'Upload Status',
    ],
    rows: uploads?.map((upload) => [
        {
            sortValue: upload.name,
            element: (
                <StyledText>{upload.name}</StyledText>
            ),
        },
        {
            sortValue: upload.size,
            element: (
                <StyledText>{Math.round(upload.size / MS_PER_S)} KB</StyledText>
            ),
        },
        {
            element: (
                uploadedFiles.includes(upload.name) ?
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    color={Color.meadow}
                />
                :
                (
                    failedUploads.includes(upload.name) ?
                    <FontAwesomeIcon
                        color={Color.poppy}
                        icon={faTimes}
                    /> :
                    <FontAwesomeIcon
                        icon={faSpinner}
                    />
                )
            )
        }
    ]),
});

export const FileDropzone = (props) => {
    const [uploads, setUploads] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [failedUploads, setFailedUploads] = useState([]);
    const { updateFiles } = props;
    const context = useContext(DataContext);
    const onDrop = useCallback(acceptedFiles => {
        setUploads(prevUploads => [...prevUploads, ...acceptedFiles]);
        const HandleFile = (file) => {
            if (file) {
                const path = 'project/' + props.projectId + '/' + file.name;
                context.aws.uploadFile(config.bucket_name, path, file, () => {
                    setFailedUploads(prevUploads => [...prevUploads, file.name]);
                    error(`Error trying to upload file: ${file.name}`);
                }, success => {
                    // if the file is large and has to upload in parts then large aws saves it in the variable Key instead of key
                    let key = success.Key ? success.Key : success.key;
                    SavePath(key, file);
                });
            }
        };
        const SavePath = (key, file) => {
            const fileToSend = {
                s3_path: key,
                file_name: file.name,
                file_size: file.size,
                uploaded_by: context.userId,
            }
            API.post(`/project/${props.projectId}/file`, { data: fileToSend }).then(() => {
                setUploadedFiles(prevUploads => [...prevUploads, file.name]);
                updateFiles();
            }).catch(err => {
                setFailedUploads(prevUploads => [...prevUploads, file.name]);
                API.default_error_handler(err);
            });
        }
        acceptedFiles.forEach(file => HandleFile(file));
    }, [context.aws, context.userId, props.projectId, updateFiles]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop });
    const tableData = SetupTableData(uploads, uploadedFiles, failedUploads);
    return (
        <DropzoneOverlay>
            <HeaderExitInline onClose={props.onClose} title='Add Files' />
            <DropzoneContainer {...getRootProps()}>
                <DropzoneInput {...getInputProps()} />
                <FlexTableController tableData={tableData}/>
                {
                    uploads.length === 0 &&
                    <InnerContainer>
                        <UploadIcon
                            color={Color.nile70}
                            icon={faFileUpload}
                            size='8x'
                        />
                        {
                            isDragActive ?
                            <DropzoneText>Drop the files here ...</DropzoneText> :
                            <DropzoneText>Drag 'n' drop some files here, or click to select files</DropzoneText>
                        }
                    </InnerContainer>
                }
            </DropzoneContainer>
        </DropzoneOverlay>
      )
};
