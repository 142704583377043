import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {API} from '../../projlibs/api.js';
import {Color, FontSize, NAV_USERS, FontWeight, USER_UPDATE_PERM} from '../../constants';
import {FlexTableController} from '../Shared/FlexTableController';
import {StyledText} from '../Shared/StyledText';
import {FlatPrimaryButton} from '../Shared/Button';
import {faEdit} from '@fortawesome/pro-regular-svg-icons';
import {CreateFormButton} from "../Shared/OverlayViewButton";
import {breakpoint} from '../../breakpoints';
import { FormattedDate } from '../Shared/FormattedDate.jsx';
import { formatText } from '../../projlibs/helperFunctions.js';
import { CreateGroupForm } from './CreateGroupForm.jsx';
import { DataContext } from '../../data-context.js';

const GroupView = styled.div`
	background-color: ${Color.white};
	height: 100%;
`;

const GroupHeader = styled.div`
	padding: 2rem 1.5rem;
	background-color: ${Color.nile5};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const ButtonContainer = styled.div`
	display: flex;
    flex-direction: column;
    ${breakpoint('medium up')} {
	    flex-direction: row;
    }
`;

const UserButton = styled(FlatPrimaryButton)`
    margin-right: 0.5rem;
    width: 122px;
`;

const GroupListView = styled.div``;

const FormatPermissions = (permissions) => {
    return Object.entries(permissions).map(([key]) => FormatPermissionText(key)).join(', ');
};

const FormatPermissionText = (permission) => formatText(permission.split('.').join(' '));

const SetupTableData = (groups, updateData, userPerms) => ({
    titles: ['Name', 'Permissions', 'Last Modified', ''],
    rows: groups.map((group) => ([
        {
            sortValue: group.group_name,
            element: (<StyledText fontSize={FontSize.body1}>{formatText(group.group_name)}</StyledText>)
        },
        {
            element: (<StyledText fontSize={FontSize.body1} fontWeight={FontWeight.normal}>{FormatPermissions(group.permissions)}</StyledText>),
        },
        {
            sortValue: group.modified_at,
            element: (<FormattedDate date={group.modified_at} />)
        },
        {
            element: (
                userPerms[USER_UPDATE_PERM] ?
                <CreateFormButton hoverBackground={Color.fuel} iconXOffset='0' icon={faEdit} >
                    <CreateGroupForm updateData={updateData} group={group}/>
                </CreateFormButton> : null
            ),
        },
    ])),
});

export const Groups = () => {
    const { userPerms } = useContext(DataContext);
    const history = useHistory();
    const [groupData, setGroupData] = useState([]);
    useEffect(() => {
        API.get('/group?page_size=').then(({ data }) => {
            if (data.Group) {
                setGroupData(data.Group);
            }
        }).catch(API.default_error_handler);
    }, []);
    const updateData = () => API.get('/group?page_size=').then(({ data }) => {
        if (data.Group) {
            setGroupData(data.Group);
        } else {
            setGroupData([]);
        }
    });
    return (
        <GroupView>
            <GroupHeader>
                <StyledText
                    as='h1'
                    color={Color.nile}
                    fontSize={FontSize.header2}>
                    Users - Roles
                </StyledText>
                <ButtonContainer>
                    <UserButton title='Users' onClick={() => history.push(NAV_USERS)} />
                    {
                        userPerms[USER_UPDATE_PERM] &&
                        <CreateFormButton title='New Role'>
                            <CreateGroupForm updateData={updateData} />
                        </CreateFormButton>
                    }
                </ButtonContainer>
            </GroupHeader>
            <GroupListView>
                <FlexTableController tableData={SetupTableData(groupData, updateData, userPerms)}  />
            </GroupListView>
        </GroupView>
    );
};
