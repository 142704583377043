import styled, { css } from 'styled-components';

// Image: General component used to display an image.
// Props:
// all StyledBase props are available
// onClick: callback for when image is clicked on
// hoverTransform: transform selector used when hovering image.
// hoverFilter: filter selector used when hovering image.
export const Image = styled.img.attrs(props => ({
	src: props.src ? props.src : props.placeHolderImageURL
}))`
	${props => css`
		cursor: ${props.cursor};
		left: ${props.left};
		position: ${props.position};
		right: ${props.right};
		top: ${props.top};
		filter: ${props.filter};
		bottom: ${props.bottom};
		color: ${props.color};
		width: ${props.width};
		height: ${props.height};
		min-width: ${props.minWidth};
		min-height: ${props.minHeight};
		background-color: ${props.backgroundColor};
		border-radius: ${props.borderRadius};
		border-width: ${props.borderWidth ? props.borderWidth : '0rem'};
		border-color: ${props.borderColor};
		border-style: ${props.borderStyle ? props.borderStyle : 'solid'};
		padding-top: ${props.paddingTop};
		padding-right: ${props.paddingRight};
		padding-bottom: ${props.paddingBottom};
		padding-left: ${props.paddingLeft};
		margin-top: ${props.marginTop};
		margin-right: ${props.marginRight};
		margin-bottom: ${props.marginBottom};
		margin-left: ${props.marginLeft};
		box-shadow: ${props.boxShadow};
		z-index: ${props.zIndex};
		object-fit: ${props.objectFit};
		transition: all 0.3s ease;

		&:hover,
		&:focus,
		&:active {
			transform: ${props.hoverTransform};
			filter: ${props.hoverFilter};
		}
	`}
`;

Image.defaultProps = {
	borderWidth: '0rem',
	objectFit: 'cover',
	hoverTransform: 'none',
	hoverFilter: 'none'
};
