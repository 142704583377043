import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { NAV_LOGIN } from '../../constants';

/**
 * The idea here is for you to be able to use this Route instead in order to
 * provide authorization and redirects if the user is not logged in.
 * @param {Array<Component>} children - This should be only 1 element, that element being the route to render when authorized.
 */
export const PrivateRoute = (props) => (
    <Route
        {...props}
        render={props.isAuthorized ? props.render : () => <Redirect to={NAV_LOGIN} />}
    />
);
