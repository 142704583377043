import {createGlobalStyle, css} from 'styled-components';
import config from './config';
import {
	ACTUAL_HRS,
	BILLING_INST,
	DATE_ORDER,
	DELIVERY_COST,
	DELIVERY_PRICE,
	DISCOUNT,
	EXPECTED_DELIVERY_DATE,
	HOURLY_RATE,
	MARKUP,
	ORDERED_BY,
	PO_NUMBER,
	PRODUCT_COST,
	QUOTED_HRS_MAX,
	QUOTED_HRS_MIN,
	RETAIL_PRICE,
	SETUP_FEE,
	SUPPLIER,
	TOTAL_HRS,
	TOTAL_PRICE,
} from './office-use-constants';

export const DESIGNER_USER_TYPE = 'designer';
export const ADMIN_USER_TYPE = 'admin';
export const SALES_USER_TYPE = 'sales';

export const MS_PER_S = 1000;

export const Color = {
	athens: '#fafbfc',

	nile: 'rgba(22, 45, 76, 1)',
	nile90: 'rgba(22, 45, 76, 0.9)',
	nile70: 'rgba(22, 45, 76, 0.7)',
	nile50: 'rgba(22, 45, 76, 0.5)',
	nile25: 'rgba(22, 45, 76, 0.25)',
	nile18: 'rgba(22, 45, 76, 0.18)',
	nile15: 'rgba(22, 45, 76, 0.15)',
	nile10: 'rgba(22, 45, 76, 0.1)',
	nile5: 'rgba(22, 45, 76, 0.05)',

	regent: '#8a94a6',
	ghost: '#c5cad2',
	mischka: '#dcdfe5',
	porcelain: '#e7e9ed',
	silver: '#f3f4f6',

	poppy: '#c62828',
	meadow: '#03b866',
	nova: '#ffd008',

	lilac: '#9d62e6',
	heart: '#773ebf',
	charmed: '#ae1ebf',

	wategos: '#63d0e6',
	picton: '#32bcf6',
	blizzard: '#b7e5fa',

	orchid: '#cb47b3',
	hot: '#ff54b3',
	daisy: '#ff86c9',

	banana: '#ffe34e',
	cheeto: '#ff8824',
	sun: '#ffb725',

	marine: '#3d8bd3',
	bay: '#2a33ab',
	peri: '#8a97e8',

	mantis: '#7adc77',
	forest: '#27bf2b',
	vida: '#aeff6b',

	downy: '#66cbc4',
	coral: '#ff2e3e',
	grapefruit: '#ff837f',

	zest: '#e48b2b',
	fuel: '#f2b72b',

	white: '#ffffff',
	black: '#000000',

	transparent: 'transparent',
	
	darkBlue: '#172B4D'
};

export const FontSize = {
	header1: '40px',
	header2: '33px',
	header3: '27px',
	header4: '23px',
	header5: '19px',
	body1: '16px',
	body2: '13px',
	caption1: '11px',
	caption2: '9px'
};

export const FontWeight = {
	light: '100',
	normal: '400',
	medium: '500',
	semibold: '600',
	bold: '700'
};

export const FontFamily = 'Source Sans Pro';

// Global styles are defined here.
// Any non-general styles should be defined in the related styled component.
export const GlobalStyle = createGlobalStyle`
	${props => css`
		body {
			margin: 0;
			overflow: ${props.overlayIsOpen ? 'hidden' : 'visible'};
		}
		* {
			font-family: ${FontFamily}, sans-serif;
			font-weight: ${FontWeight.semibold};
		}

		h1 {
			font-size: 2.5rem;
		}
		h2 {
			font-size: 2.0625rem;
		}
		h3 {
			font-size: 1.6875rem;
		}
		h4 {
			font-size: 1.4375rem;
		}
		h5 {
			font-size: 1.1875rem;
		}
		body {
			font-size: 1rem;
		}
	`}
`;

// COOKIE STRINGS
export const AWS_IDENTITY_ID = 'AWSToken.IdentityId';
export const AWS_TOKEN = 'AWSToken.Token';
export const SESSION_ID = 'session_id';
export const USER_ID = 'user_id';
export const EMAIL = 'email';

// NAV STRINGS
export const NAV_LOGIN = '/login';
export const NAV_RESET_PASSWORD = '/reset';
export const NAV_OVERVIEW = '/overview';
export const NAV_DASHBOARD_SALES = '/dashboard-sales';
export const NAV_DASHBOARD = '/dashboard';
export const NAV_PROJECTS = '/projects';
export const NAV_QUOTES = '/quotes';
export const NAV_CLIENTS = '/clients';
export const NAV_REPORTS = '/reports';
export const NAV_USERS = '/users';
export const NAV_KITCHEN_SINK = '/kitchen-sink';

// LABELS (that may be changed)
export const SALES_DASH_TAB_CONTACT = (isPlural) => isPlural ? 'Contacts' : 'Contact';
export const SALES_DASH_TAB_CLIENT = (isPlural) => isPlural ? 'Clients' : 'Client';

// Date Related Constants
export const MILLISECONDS_PER_DAY = 86400000;
export const SECONDS_PER_DAY = 86400;

// Search Base Urls/Endpoints
export const SEARCH_BASE_CLIENT = '/client';
export const SEARCH_BASE_PROJECT = '/project';
export const SEARCH_BASE_QUOTE = '/quote';
export const SEARCH_BASE_NOTE = '/note';
export const SEARCH_BASE_SERVICE = '/client/service';

// Search Related Constants
export const SEARCH_PAGE_SIZE = 5;

// Local Storage Constants
// Projects
export const PROJECT_SEARCH_VALUE = 'projects.search';
export const PROJECT_SEARCH_TYPE = 'projects.type';
export const PROJECT_SEARCH_CLIENT = 'projects.client';
export const PROJECT_SEARCH_CLIENT_NAME = 'projects.client.name';
export const PROJECT_SEARCH_ASSIGNEE = 'projects.assignee';
export const PROJECT_SEARCH_STATUS = 'projects.status';
export const PROJECT_SEARCH_PAGE = 'projects.page';
export const PROJECT_SEARCH_ORDER_BY = 'projects.orderby';
// QUOTES
export const QUOTES_SEARCH_VALUE = 'quotes.search';
export const QUOTES_SEARCH_CLIENT = 'quotes.client';
export const QUOTES_SEARCH_CLIENT_NAME = 'quotes.client.name';
export const QUOTES_SEARCH_ASSIGNEE = 'quotes.assignee';
export const QUOTES_SEARCH_STATUS = 'quotes.status';
export const QUOTES_SEARCH_PAGE = 'quotes.page';
export const QUOTES_SEARCH_ORDER_BY = 'quotes.orderby';
// CLIENTS
export const CLIENTS_SEARCH_VALUE = 'clients.search';
export const CLIENTS_SEARCH_TIER = 'clients.tier';
export const CLIENTS_SEARCH_ACC_REP = 'clients.account_rep';
export const CLIENTS_SEARCH_PAGE = 'clients.page';
export const CLIENTS_SEARCH_ORDER_BY = 'clients.orderby';

//TABLE STRINGS
export const CLIENT_NAME = 'Client Name';
export const CLIENT_PROFILE = 'Client Profile';
export const JOB_NUMBER = 'Job Number';
export const NAME = 'Name';
export const STATUS = 'Status';
export const TYPE = 'Type';
export const CREATED = 'Created';
export const UPDATED = 'Updated';
export const DESIGN_DUE_DATE = 'Design Due Date';
export const PROJECT = 'Project';
export const CONTACT = 'Contact';
export const CLIENT = 'Client';
export const DUE_DATE = 'In-Hand Due Date';
export const CONTACT_NAME = 'Contact Name';
export const TIER = 'Tier';
export const ACCOUNT_REP = 'Account Rep';
export const QUOTE = 'Quote';
export const HOURS  = 'Hrs';
export const TOTAL_HOURS  = 'Total Hrs';
export const CONTACT_DATE  = 'Contact Date';
export const DATE_ENTERED = 'Date Entered';
export const USER  = 'User';
export const SUBMITTED_DATE  = 'Date Submitted';
export const COMPLETED_DATE  = 'Date Completed';
export const CONTACT_EMAIL = 'Contact Email';
export const PROJECT_TOTAL_PRICE = 'Total Price';

// Roles
export const ADMIN = 'administrators';
export const DESIGNER = 'designers';
export const SALES = 'sales';
export const DESIGN = 'design';
export const MANAGEMENT = 'management';

// Permissions
export const USER_READ_PERM = 'user.read';
export const USER_CREATE_PERM = 'user.create';
export const USER_UPDATE_PERM = 'user.update';
export const NOTE_READ_PERM = 'note.read';
export const NOTE_CREATE_PERM = 'note.create';
export const NOTE_UPDATE_PERM = 'note.update';
export const FILE_READ_PERM = 'file.read';
export const FILE_CREATE_PERM = 'file.create';
export const FILE_UPDATE_PERM = 'file.update';
export const PROJECT_READ_PERM = 'project.read';
export const PROJECT_CREATE_PERM = 'project.create';
export const PROJECT_UPDATE_PERM = 'project.update';
export const CLIENT_READ_PERM = 'client.read';
export const CLIENT_CREATE_PERM = 'client.create';
export const CLIENT_UPDATE_PERM = 'client.update';

// Project Statuses
export const PROJECT_ARCHIVE_STATUS = 'Archived'; //current imported data
export const QUOTE_REJECTED_STATUS = 'Quote Rejected';

export const LOAD_MORE = 'LOAD_MORE';
export const CREATE_CONTACT = 'CREATE CONTACT';
export const DEFAULT_ASSIGNED_USER_COLOUR = "teal";

export const PROJECT_DETAILS = 'Project Details';
export const OFFICE_USE = 'Office Use';
export const JOB_DESC = 'Job Description';
export const QUOTED_HOURS_MIN = 'Quoted Hours Min';

//Project Types
export const DESIGN_ONLY='Design Only';
export const PRINT='Print';
export const SIGNAGE='Signage';
export const WEB='Web';
export const VEHICLE_DECALS='Vehicle Decals';
export const PROMOTIONAL_ITEMS='Promotional Products';
export const WEARABLES='Wearables';
export const MARKETING='Marketing';
export const OLD_TYPE='Old System Type';

// Service Fields
export const SERVICE_TYPE = 'Type';
export const SERVICE_NAME = 'Name';
export const SERVICE_RENEWAL = 'Renewal';
export const SERVICE_EXPIRY = 'Expiry';
export const SERVICE_RATE = 'Rate';
export const SERVICE_FREQUENCY = 'Frequency';
export const SERVICE_PRIMARY = 'Primary';
export const SERVICE_STATUS = 'Status';

export const SERVICE_STATUS_LIST = ['Active', 'Inactive'];
export const SERVICE_TYPE_LIST = ['Domain', 'Server', 'Hosting', 'Email', 'Plugin', 'Digital Signage', 'Social', 'Email Marketing', 'Marketing Management', 'Give Back Program', 'Other'];
export const SERVICE_FREQUENCY_LIST = ['Yearly', 'Monthly'];

export const VIEW_REPORTS_PERM = 'view.reports';
export const VIEW_REPORTS_DEPS = ['client.read', 'project.read', 'user.read'];
export const VIEW_USER_MANAGER_PERM = 'view.user.manager';
export const VIEW_USER_MANAGER_DEPS = ['user.create', 'user.read', 'user.update'];
export const VIEW_CLIENTS_PERM = 'view.clients';
export const VIEW_CLIENTS_DEPS = ['client.read', 'project.read', 'client.update', 'client.create'];
export const VIEW_PROJECTS_PERM = 'view.projects';
export const VIEW_PROJECTS_DEPS = ['client.read', 'project.read', 'project.update', 'project.create', 'client.update'];
export const VIEW_QUOTES_PERM = 'view.quotes';
export const VIEW_QUOTES_DEPS = ['client.read', 'project.read', 'project.update', 'project.create', 'client.update'];
export const VIEW_FULL_DASH_PERM = 'view.overview';
export const VIEW_FULL_DASH_DEPS = ['client.read', 'project.read', 'project.update'];
export const VIEW_ACTIVE_PROJ_PERM = 'view.dashboard';
export const VIEW_ACTIVE_PROJ_DEPS = ['client.read', 'project.read', 'project.update'];
export const VIEW_LEADS_PERM = 'view.leads';
export const VIEW_LEADS_DEPS = ['client.read', 'client.update', 'note.create', 'note.update', 'note.read'];
export const VIEW_CLIENT_DELETE = 'view.client.delete';
export const VIEW_PROJECT_DELETE = 'view.project.delete';
export const VIEW_QUOTE_DELETE = 'view.quote.delete';
export const PAGE_PERMISSION_DEPS = {
	[VIEW_FULL_DASH_PERM]: VIEW_FULL_DASH_DEPS,
	[VIEW_ACTIVE_PROJ_PERM]: VIEW_ACTIVE_PROJ_DEPS,
	[VIEW_LEADS_PERM]: VIEW_LEADS_DEPS,
	[VIEW_PROJECTS_PERM]: VIEW_PROJECTS_DEPS,
	[VIEW_QUOTES_PERM]: VIEW_QUOTES_DEPS,
	[VIEW_CLIENTS_PERM]: VIEW_CLIENTS_DEPS,
	[VIEW_REPORTS_PERM]: VIEW_REPORTS_DEPS,
	[VIEW_USER_MANAGER_PERM]: VIEW_USER_MANAGER_DEPS,
	[VIEW_CLIENT_DELETE]: [],
	[VIEW_PROJECT_DELETE]: [],
	[VIEW_QUOTE_DELETE]: [],
};
export const PAGE_PERMISSIONS = [
	VIEW_FULL_DASH_PERM,
	VIEW_ACTIVE_PROJ_PERM,
	VIEW_LEADS_PERM,
	VIEW_PROJECTS_PERM,
	VIEW_QUOTES_PERM,
	VIEW_CLIENTS_PERM,
	VIEW_REPORTS_PERM,
	VIEW_USER_MANAGER_PERM,
	VIEW_CLIENT_DELETE,
	VIEW_PROJECT_DELETE,
	VIEW_QUOTE_DELETE
];

// Office Use Meta Fields (for Ordering)
export const OfficeUseOrdering = {
	[QUOTED_HRS_MIN]: 1,
	[QUOTED_HRS_MAX]: 2,
	[TOTAL_HRS]: 3,
	[ACTUAL_HRS]: 4,
	[HOURLY_RATE]: 5,
	[SETUP_FEE]: 6,
	[PO_NUMBER]: 7,
	[BILLING_INST]: 8,
	[SUPPLIER]: 9,
	[DATE_ORDER]: 10,
	[ORDERED_BY]: 11,
	[PRODUCT_COST]: 12,
	[DELIVERY_COST]: 13,
	[MARKUP]: 14,
	[RETAIL_PRICE]: 15,
	[DELIVERY_PRICE]: 16,
	[DISCOUNT]: 17,
	[TOTAL_PRICE]: 18,
	[EXPECTED_DELIVERY_DATE]: 19,
	// The config will override any default ordering
	...config.office_use_ordering,
};
