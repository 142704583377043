import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../data-context';
import styled from 'styled-components';
import {
	CLIENT_CREATE_PERM,
	CLIENT_READ_PERM,
	Color,
	FontSize,
	NOTE_READ_PERM,
	PROJECT_CREATE_PERM,
	PROJECT_READ_PERM,
	USER_CREATE_PERM
} from '../../constants';
import {faBell, faPlus, faSearch, faUserEdit} from '@fortawesome/pro-regular-svg-icons';
import S3ImageController from '../Shared/S3ImageController';
import {CreateFormButton, SideNavButton} from '../Shared/OverlayViewButton';
import {SearchWidget} from '../Shared/SearchWidget';
import {GlobalAdd} from "./GlobalAdd";
import {StyledInitials} from '../Shared/StyledInitials';
import {AlertList} from '../Overview/AlertList';
import {CreateUserForm} from '../Users/CreateUserForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {API} from '../../projlibs/api';

const MenuListView = styled.div`
	width: 64px;
	text-align: center;
	background: linear-gradient(0deg, ${Color.zest} 0%, ${Color.fuel} 100%);
	box-shadow: 0rem 0.0625rem 0.375rem ${Color.nile15};
`;

export const MenuList = (props) => {
	const [isHovering, setIsHovering] = useState(false);
	const context = useContext(DataContext);
	const img_s3_path = context.userImageURL;
	const current_user_name = context.username;
	const [data, setData] = useState(({}));
	let user = data?.User ? data.User[0] : undefined;
	useEffect(() => {
		API.get(`/user/${context.userId}?show_invalid=true`).then(response => {
			setData(response.data);
		});
	}, [context.userId]);
	return (
		<MenuListView {...props}>
			<CreateFormButton id='edit-current-user-button' position='fixed' visibility='hidden' minWidth='0px' minHeight='0px' width='0px' height='0px' >
				<CreateUserForm user={user} isEditingOwn />
			</CreateFormButton>
			<div style={{zIndex: 2, backgroundColor: Color.transparent, width: 'fit-content', height: 'fit-content'}} onMouseOver={() => setIsHovering(true)} onMouseOut={() => setIsHovering(false)}>
				<FontAwesomeIcon
					size='lg'
					onClick={() => document.getElementById('edit-current-user-button').click()}
					color={Color.white}
					icon={faUserEdit}
					style={{ zIndex: 1, position: 'fixed', top: '42px', left: '20px', opacity: isHovering ? '1' : '0', transition: 'all 0.4s ease', cursor: 'pointer' }}/>
				{
					img_s3_path ?
					<S3ImageController
						height='40px'
						width='40px'
						marginTop='32px'
						marginBottom='16px'
						marginRight='10px'
						marginLeft='10px'
						borderRadius='50%'
						borderWidth='1px'
						borderColor={Color.white}
						s3_path={img_s3_path}
						cursor='pointer'
						filter={isHovering ? 'blur(5px) !important' : 'unset'}
						onClick={() => document.getElementById('edit-current-user-button').click()}
					/> :
					<StyledInitials
						size='40px'
						height='40px'
						width='40px'
						marginTop='32px'
						marginBottom='16px'
						marginRight='10px'
						marginLeft='10px'
						borderRadius='50%'
						fontSize={FontSize.body1}
						numberOfInitials={2}
						backgroundColor={user?.colour ? user.colour : Color.zest}
						cursor='pointer'
						filter={isHovering ? 'blur(5px) !important' : 'unset'}
						onClick={() => document.getElementById('edit-current-user-button').click()}>
						{current_user_name}
					</StyledInitials>	
				}
			</div>
			<SideNavButton icon={faBell} overlayWidth='400px'>
				<AlertList
					isUnified
					isOverlay
					height='100%'
					borderTop='none'
					borderRadius='0'
				/>
			</SideNavButton>
			{
				(context.userPerms[CLIENT_READ_PERM] || context.userPerms[PROJECT_READ_PERM] || context.userPerms[NOTE_READ_PERM]) &&
				<SideNavButton icon={faSearch}>
					<SearchWidget/>
				</SideNavButton>
			}
			{
				(context.userPerms[CLIENT_CREATE_PERM] || context.userPerms[PROJECT_CREATE_PERM] || context.userPerms[USER_CREATE_PERM]) &&
				<SideNavButton icon={faPlus} overlayWidth='400px'>
					<GlobalAdd/>
				</SideNavButton>
			}
		</MenuListView>
	);
}

MenuList.defaultProps = {
	className: 'MenuList',
};
