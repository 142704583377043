import React from 'react';
import styled from 'styled-components';
import { ProjectSearchResult } from '../Search/ProjectSearchResult';
import { StyledBase } from '../StyledBase';
import { isAFunction } from '../../../projlibs/helperFunctions';

const ProjectSearchesContainer = styled.div`
	${StyledBase}
`;

/**
 * Accepts a list of projects and displays them.
 * @param {Array} projects - array of project objects each containing a 'name', 'id', and 'clientName' 
 */
export const ProjectSearches = (props) => (
	<ProjectSearchesContainer {...props}>
		{ props.projects && props.projects.map(project =>
			<ProjectSearchResult
				isQuote={props.isQuotes}
				key={project.id}
				status={project.status}
				name={project.name}
				projectId={project.id}
				clientName={project.clientName}
				closeSearch={isAFunction(props.closeSearch) ? props.closeSearch : undefined}
			/>
		)}
	</ProjectSearchesContainer>
);
