//parse cookies that are currently set in the user's browser
//and return them as an object/hash table
//arguments:
//	no arguments
//return:
//	returns the current cookies (from document.cookie) in a usable format
//side-effects:
//	no side-effects
export function parse_cookies(){
	let cookies={};
	let cookie_strings=document.cookie.split(';');
	for(let i=0;i<cookie_strings.length;i++){
		const cookie_parts=cookie_strings[i].trim().split('=');
		const cookie_name=cookie_parts[0];
		const cookie_value=cookie_parts[1];
		cookies[cookie_name]=cookie_value;
	}
	return cookies;
}

//set the cookie for the / path on the current domain
//arguments:
//      cookie_name: the name of the cookie to set
//      cookie_value: the value to set the cookie to, as a string
//return:
//      none
//side-effects:
//      sets the given cookie in the user's browser to the given value
export function set_cookie(cookie_name,cookie_value){
        document.cookie=cookie_name+'='+cookie_value+';path=/;';
}

//delete the cookie for the / path on the current domain
//arguments:
//      cookie_name: the name of the cookie to delete
//return:
//      none
//side-effects:
//      sets the cookie to be expired in the user's browser
//      which is the javascript version of deleting it
export function delete_cookie(cookie_name){
        document.cookie=cookie_name+'=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

