export const QUOTED_HRS_MIN = 'Quoted Hours Min';
export const QUOTED_HRS_MAX = 'Quoted Hours Max';
export const TOTAL_HRS = 'Total Hours Logged';
export const ACTUAL_HRS = 'Actual Hours';
export const HOURLY_RATE = 'Hourly Rate';
export const SETUP_FEE = 'Setup Fee';
export const PO_NUMBER = 'PO#';
export const BILLING_INST = 'Billing Instructions';
export const SUPPLIER = 'Supplier';
export const DATE_ORDER = 'Date Ordered';
export const ORDERED_BY = 'Ordered By';
export const PRODUCT_COST = 'Product Cost';
export const DELIVERY_COST = 'Delivery Cost';
export const MARKUP = 'Markup';
export const RETAIL_PRICE = 'Retail Price';
export const DELIVERY_PRICE = 'Delivery Price';
export const DISCOUNT = 'Discount';
export const TOTAL_PRICE = 'Total Price';
export const EXPECTED_DELIVERY_DATE = 'Expected Delivery Date';