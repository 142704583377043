import React from 'react';
import styled, { css } from 'styled-components';

import { Color, FontSize, FontWeight } from '../../constants';
import { Image } from './Image';
import { AlertCell } from '../Overview/AlertCell';
import { StyledText } from './StyledText';
import { ProjectType } from './ProjectType';
import { FlexTableController } from './FlexTableController';
import { Button, LeftMenuButton } from './Button';
import {
	faClock,
	faHome,
	faFolder,
	faSearch
} from '@fortawesome/free-solid-svg-icons';
import { AssignedUser } from './AssignedUser';
import { Metadata } from './Metadata';
import { Input } from './Input';
import { ProjectInfo } from '../Shared/ProjectInfo';
import { FormattedDate } from '../Shared/FormattedDate';
import { Dropdown } from '../Shared/Dropdown';

// KitchenSink: used to display all available components. Good place to add component for PRs
// Props:none

const displayKitchenSink = true;
const displayImages = false;
const displayAlertCell = false;
const displayLabels = false;
const displayAssignedUsers = false;
const displayButtons = false;
const displayMetaData = true;
const displayInputs = true;
const displayFlexTable = true;

const SampleFlexTableData = {
	headerData: [
		{
			title: 'Type',
			field: 'type',
			sortable: true,
			sortField: 'type_value'
		},
		{
			title: 'Project',
			field: 'project',
			sortable: false
		},
		{
			title: 'Status',
			field: 'status',
			sortable: false
		},
		{
			title: 'Due Date',
			field: 'due_date',
			sortable: true,
			sortField: 'due_date_value'
		}
	],
	rows: [
		{
			type_value: 'ProjectType',
			type: <ProjectType viewColor='blue' title={'Type 1'} />,
			project: (
				<ProjectInfo
					id={'1'}
					name={'Project name 1'}
					clientName='Client name 1'
				/>
			),
			status: (
				<Dropdown
					selectedOption='in-progress'
					options={{
						'new':'New Project',
						'in-progress':'In Progress',
						'waiting':'Waiting',
						'completed':'Completed'
					}}
				/>
			),

			due_date_value: 14252452346,
			due_date: <FormattedDate date={1590092346} color={Color.nile50} />
		},
		{
			type_value: 'ProjectType',
			type: <ProjectType viewColor='yellow' title={'Type 2'} />,
			project: (
				<ProjectInfo
					id={'2'}
					name={'Project name 2'}
					clientName='Client name 2'
				/>
			),
			status: (
				<Dropdown
					selectedOption='waiting'
					options={{
						'new':'New Project',
						'in-progress':'In Progress',
						'waiting':'Waiting',
						'completed':'Completed'
					}}
				/>
			),

			due_date_value: 14252452346,
			due_date: <FormattedDate date={1598092346} color={Color.nile50} />
		}
	]
};

const KitchenSinkView = styled.div`
	${props => css`
		margin-top: 1.25rem;
	`}
`;

const onClickButton = event => {};

export const KitchenSink = props => (
	<KitchenSinkView {...props}>
		{displayKitchenSink && (
			<div>
				<h2>Kitchen Sink</h2>
				{displayImages && (
					<div>
						<Image
							src='https://picsum.photos/200/200'
							width='5rem'
							height='12.5rem'
							marginRight='1.875rem'
							borderWidth='0.125rem'
							borderColor='red'
						/>
						<Image
							src='https://picsum.photos/200/200'
							borderWidth='0.1875rem'
							borderRadius='50%'
							hoverTransform='scale(1.05)'
							hoverFilter='invert(0.2)'
						/>
						<Image
							src='https://picsum.photos/200/200'
							marginLeft='1.25rem'
							marginRight='1.25rem'
							borderRadius='1.25rem'
							boxShadow='0.375rem 0.375rem 0.25rem grey'
						/>
						<Image
							width='6.25rem'
							height='6.25rem'
							backgroundColor='yellow'
							borderWidth='0.125rem'
							borderRadius='50%'
						/>
					</div>
				)}

				{displayAlertCell && (
					<div>
						<AlertCell
							title='Proposal Template Design is 1 day overdue'
							date={new Date(1583522034112)}
							overdue={true}
							marginTop='1.25rem'
							marginRight='1.25rem'
						/>
						<AlertCell
							title='Paul Galizia - Arcade Sign status is now Quality Control'
							date={1582957358}
							overdue={false}
						/>
					</div>
				)}

				{displayLabels && (
					<div>
						<StyledText
							width='25rem'
							height='6.25rem'
							borderWidth='0.0625rem'
							borderRadius='0.25rem'
							verticalAlign='top'>
							Text aligned to the top, left edge. Lorem ipsum is
							placeholder text commonly used in the graphic,
							print, and publishing industries for previewing
							layouts and visual mockups.
						</StyledText>
						<StyledText
							width='6.25rem'
							height='6.25rem'
							color='teal'
							backgroundColor='red'
							fontSize={FontSize.header5}
							fontWeight={FontWeight.bold}
							borderRadius='50%'
							borderWidth='0.125rem'
							borderColor='teal'
							horizontalAlign='center'
							marginTop='0.625rem'
							marginBottom='0.625rem '
							boxShadow='0.375rem 0.375rem 0.375rem grey'>
							Centered Text!
						</StyledText>
					</div>
				)}
				{displayAssignedUsers && (
					<div>
						<AssignedUser
							src='https://picsum.photos/200/200'
							text='S'
							backgroundColor='teal'
						/>
						<AssignedUser
							src='https://picsum.photos/200/200'
							text='KS'
							backgroundColor='violet'
						/>
					</div>
				)}

				{displayButtons && (
					<div>
						<Button title='Load More' disabled={true} />
						<Button
							title='Add Alert'
							icon={faClock}
							iconXOffset='1.25rem'
							onClick={onClickButton}
							hoverTransform='scale(1.05)'
						/>
						<LeftMenuButton
							title='Dashboard'
							icon={faHome}
							onClick={onClickButton}
							hoverFilter='invert(0.1)'
						/>
						<LeftMenuButton
							title='Projects'
							icon={faFolder}
							selected={true}
							selectedBackgroundColor='aqua'
							onClick={onClickButton}
						/>
					</div>
				)}

				{displayMetaData && (
					<div>
						<Metadata
							title='Created By'
							value='Theo Smith'
							type='String'
							width='18.75rem'
						/>
						<Metadata
							title='Quoted Hours - Max'
							value='20'
							type='Int'
						/>
					</div>
				)}

				{displayInputs && (
					<div>
						<Input
							disabled={true}
							color='white'
							placeholderColor='white'
							backgroundColor='red'
							borderColor='green'
							borderWidth='0.125rem'
							height='2.75rem'
							icon={faSearch}
							placeholder='Search'
							iconIsLeft={true}
							hoverColor='gray'
							hoverBorderColor='teal'
						/>
						<Input
							height='2.75rem'
							icon={faSearch}
							placeholder='Search'
							hoverColor='red'
						/>
						<Input height='2.75rem' placeholder='Username' />
					</div>
				)}

				{displayFlexTable && (
					<div>
						{/* NOTE: title tags are optional and will be displayed if used but can be omitted
						<FlexTableController
							tableTitle='Example Title'
							tableTitleTag='h2'
							tableData={SampleFlexTableData}
						/>
*/}
						<FlexTableController tableData={SampleFlexTableData} />
					</div>
				)}
			</div>
		)}
	</KitchenSinkView>
);
