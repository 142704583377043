import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
	MS_PER_S,
	CLIENT_NAME,
	CLIENT_PROFILE,
	NAV_CLIENTS,
	Color,
	FontSize
} from '../../constants';
import { StyledText } from '../Shared/StyledText';
import { StyledLink } from '../Shared/StyledLink';
import { breakpoint } from '../../breakpoints';
import { FlexTableController } from '../Shared/FlexTableController';
import { API, useGetData } from '../../projlibs/api';
import downloadCsv from 'download-csv';
import { ReportHeader } from './ReportHeader';
import moment from 'moment';
import { DateTimeFormInput, DateInputType } from '../Shared/DateTimeFormInput';
import { PageController } from '../Shared/PageController';
import { PageIndex } from '../Shared/PageIndex';
import { MultiSelectDropdown } from "../Shared/MultiSelect";
import { StyledCheckbox } from '../Shared/StyledCheckbox';
import { Link } from 'react-router-dom';

const CustomizeReportView = styled.div`
	padding: 0.5rem 1.5rem;
`;

const ClientSelectionView = styled.div`
	width: 100%;
	display: flex;
	align-items:flex-start;
	flex-direction: column;
	${breakpoint('medium up')} {
		flex-direction: row;
		align-items:flex-start;
	}
`;

const CheckboxView = styled.div`
	display:flex;
	flex-direction:column;
`;
const ReportToolbar = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
`;


const generateClientUrl = (startDate, endDate, pageIndex = 0, pageSize = 20, orderBy, project_names = [], projectTypeExclude = false) => {
	let url = `/project/search?page_size=${pageSize}&page=${pageIndex}&email_required=true&full=true`;
	if (startDate) {
		url += `&after=${startDate}`;
	}
	if (endDate) {
		url += `&before=${endDate}`;
	}
	if (orderBy) {
		url += `&${orderBy}`;
	}
	let formatted_string = encodeURIComponent(project_names.join("|"));
	if (!formatted_string) {
		formatted_string = '';
	}
	url += `&project_types=${formatted_string}`;
	if (projectTypeExclude) {
		url += `&exclude=true`;
	}
	return url;
};

export const ClientNames = () => {
	const [isGenerated, setIsGenerated] = useState(false);
	const [resultCount, setResultCount] = useState(0);
	const [pageIndex, setPageIndex] = useState(0);
	const [clientInfo, setClientInfo] = useState([]);
	const [startDate, setStartDate] = useState(null);
	const [persistStartDate, setPersistStartDate] = useState();
	const [endDate, setEndDate] = useState(moment().unix());
	const [persistEndDate, setPersistEndDate] = useState();
	const [orderBy,] = useState("");
	const [selectedTypes, setSelectedTypes] = useState([]);
	const [projectTypeExclude, setProjectTypeExclude] = useState(false);

	const [{ data: projectTypeData }] = useGetData('/project/type');
	const projectTypes = projectTypeData?.Project_Type?.map(type => ({ id: type.project_type_id, color: type.color, name: type.name }));
	const handleCSV = () => {
		if (clientInfo?.length !== 0) {
			API.get(generateClientUrl(persistStartDate, persistEndDate, 0, '', orderBy, selectedTypes, projectTypeExclude)).then(response => {
				const results = response?.data?.Client ? response?.data?.Client : [];
				const csvData = results.map(result => {
					
					return {
						CLIENT_NAME: '"' + (result?.name.replaceAll('"', '')) + '"',
						CLIENT_PROFILE: `"${window.location.protocol}//${window.location.host}${NAV_CLIENTS}/${result?.client_id}"`
					};
				});
				downloadCsv(csvData, null, `New Business Development Report_${Math.floor(new Date().getTime() / 1000)}.csv`);
			}).catch(API.default_error_handler);
		}
	};
	const handleGenerate = () => {
		setIsGenerated(true);
		setPersistStartDate(startDate);
		setPersistEndDate(endDate);
	};
	useEffect(() => {
		if (isGenerated) {
			API.get(generateClientUrl(persistStartDate, persistEndDate, pageIndex, 20, orderBy, selectedTypes, projectTypeExclude)).then(response => {
				setResultCount(response?.data?.result_count ? response?.data?.result_count : 0);
				setClientInfo(response?.data.Client ? response?.data.Client : []);
			}).catch(API.default_error_handler);
		}
	}, [pageIndex, persistStartDate, persistEndDate, orderBy, isGenerated, selectedTypes, projectTypeExclude]);
	return (
		<CustomizeReportView>
			<ReportHeader generateOnClick={handleGenerate} csvOnClick={handleCSV} csvDisabled={!persistStartDate && !persistEndDate} generateDisabled={false} />
			<ReportToolbar>
				<ClientSelectionView>
					<DateTimeFormInput
						label='Start Date'
						placeholder='mm/dd/yyyy'
						format='MMM D, YYYY'
						width='25%'
						marginRight='12px'
						value={startDate ? startDate * MS_PER_S : null}
						type={DateInputType}
						onChange={(e) => setStartDate(e ? e.unix() : null)}
					/>
					<DateTimeFormInput
						label='End Date'
						placeholder='mm/dd/yyyy'
						format='MMM D, YYYY'
						width='25%'
						value={endDate ? endDate * MS_PER_S : null}
						type={DateInputType}
						onChange={(e) => setEndDate(e ? e.unix() : null)}
					/>
				</ClientSelectionView>
				<CheckboxView>
					<StyledCheckbox
						label={'Exclude Project Types'}
						checked={projectTypeExclude}
						width='49%'
						paddingRight='0'
						height='100%'
						onChange={() => setProjectTypeExclude(!projectTypeExclude)}
					/>
					<MultiSelectDropdown
						label="Project Types"
						defaultValue={[]}
						marginBottom='12px'
						placeholder='-select project types-'
						height='44px'
						width='100%'
						data={projectTypes?.map(type => type.name)}
						onChange={value => setSelectedTypes(value)}
					/>
				</CheckboxView>
				<PageIndex resultCount={resultCount} currentPage={pageIndex} />
			</ReportToolbar>
			<FlexTableController rowPadding='0.5rem 1rem 0.5rem 1rem' tableData={SetupTableDataClient(clientInfo)} />
			<PageController pageNumber={pageIndex} setPageNumber={setPageIndex} numPages={Math.ceil(resultCount / 20)} />
		</CustomizeReportView>
	);
};


const SetupTableDataClient = (userTotalTimes) => ({
	titles: [
		CLIENT_NAME,
		CLIENT_PROFILE
	],
	rows: userTotalTimes.map((entry) => {
		return [
			{
				element: (
					<StyledLink to={`${NAV_CLIENTS}/${entry.client_id}`}
						color={Color.nile}
						fontSize={FontSize.body1}>
						{entry.name}
					</StyledLink>
				),
			},
			{
				element: (
					<Link to={`${NAV_CLIENTS}/${entry.client_id}`} style={{ textDecoration: 'none' }}>
						<StyledText>{`${window.location.protocol}//${window.location.host}${NAV_CLIENTS}/${entry.client_id}`}</StyledText>
					</Link>
				),
			},
		];
	}),
});
